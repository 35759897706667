import { Button } from "components/Button";
import { candidateData, data } from "../data";
import { Candidates } from "../../Commons/Candidates/Candidates";
import { Colors } from "styles/Colors";
import { DropdownInput } from "components/form";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { InterviewVideos } from "../../Commons/InterviewVideos/InterviewVideos";
import { SectionHeading } from "components/Headings/SectionHeading";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useState } from "react";
import styled from "styled-components";

const actions = ({ isMdUp }) => (
  <div>
    <Button button rightIcon={faXmark}>
      Close
    </Button>
  </div>
);

export const ShortlistPage = React.memo(() => {
  const [tabvalue, setTabvalue] = useState("candidates");
  const { isMdUp } = useResponsiveLayout();

  return (
    <Container isMdUp={isMdUp}>
      <SectionHeading
        actions={tabvalue === "candidates" && actions(isMdUp)}
        companyName
        dropdown={
          <DropdownInput
            options={["Opton1", "DIGITAL ENVIRONMENT ARCHITECT"]}
            type="alt"
            bg="transparent"
            placeholder="DIGITAL ENVIRONMENT ARCHITECT"
            ml
          ></DropdownInput>
        }
      />
      <TabHeadercontainer>
        <TabHeader
          tabvalue={tabvalue}
          value="candidates"
          onClick={() => setTabvalue("candidates")}
        >
          Candidates
        </TabHeader>
        <TabHeader
          tabvalue={tabvalue}
          value="interviewvideos"
          onClick={() => setTabvalue("interviewvideos")}
        >
          Interview Videos
        </TabHeader>
        <TotalCandidates>
          {candidateData.length >= 0 ? candidateData.length : 0}
        </TotalCandidates>
        <TotalVideos>{data.length >= 0 ? data.length : 0}</TotalVideos>
      </TabHeadercontainer>
      {tabvalue === "candidates" ? (
        <CandidatesList isMdUp={isMdUp}>
          {candidateData.map((candidate) => (
            <Candidates data={candidate} />
          ))}
        </CandidatesList>
      ) : (
        <InterviewVideos data={data} />
      )}
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  padding-left : ${isMdUp ? "122px" : "16px"};
  padding-right :${isMdUp ? "122px" : "16px"};
  padding-bottom: 80px;
  & button {
    background: transparent;
    color: white;
    border: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
    margin-right: ${isMdUp ? "0px" : "10px"};
    

}
  `
);

const TabHeadercontainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  position: relative;
`;

const TabHeader = styled.h2(
  ({ tabvalue, value }) => `
font-family: Archivo;
font-style: normal;
cursor: pointer;
font-weight: bold;
font-size: 18px;
line-height: 26px;
padding-bottom: 18px;
margin-right: 35px;
color: ${tabvalue === value ? Colors.white : "rgba(255, 255, 255, 0.4)"} ;
`
);

const TotalCandidates = styled.p`
  position: absolute;
  color: #66e12c;
  left: 95px;
  font-weight: 400;
  font-size: 12px;
  right: 0;
  top: -7px;
`;

const TotalVideos = styled.p`
  position: absolute;
  color: #66e12c;
  left: 266px;
  font-weight: 400;
  font-size: 12px;
  right: 0;
  top: -7px;
`;

const CandidatesList = styled.div(
  ({ isMdUp }) => `
  display: flex;
  flex-wrap: wrap;
  justify-content: ${isMdUp ? "flex-start" : "center"};
  & > div {
    margin-right: ${isMdUp ? "20px" : "0px"};
  }
`
);
