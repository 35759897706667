export const numberWithCommas = (num) => {
    return num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const stringToNumber = (string) => {
    return Number(string?.replace(/,/g, ""));
};

/**
 * Helper function to get the name, extension, or full path of a given file.
 * Can be used to get individual components of a filepath, like the extension.
 * Can be used to add an extension if the given filepath does not have one (like old ziggeo recordings).
 * Also used for overriding the given extension, such as changing "file.mp4" to "file.jpg" for thumbnails.
 * @param file - The full filename
 * @param fallbackExtension - The extension to use if the actual extension is unknown or overridden
 * @param forceFallbackExtension - Use the `fallbackExtension` in the return, ignoring the actual extension.
 * @returns
 */
export const formatFilePath = (
    file,
    fallbackExtension,
    forceFallbackExtension
) => {
    const split = file.toString().toLowerCase().split(".");
    const name = split.length > 1 ? split.slice(0, -1).toString() : file;
    const extension = split.length > 1 ? split[split.length - 1] : undefined;
    return {
        name,
        extension:
            extension && !forceFallbackExtension
                ? extension
                : fallbackExtension,
        nameAndExtension: `${name}.${
            extension && !forceFallbackExtension ? extension : fallbackExtension
        }`,
    };
};

/**
 * Returns string of css classes to format colour based on the status
 * @param {string} status
 * @returns
 */
export const getStatusCssClasses = (status) => {
    switch (status.toLowerCase()) {
        case "applied":
            return " bg-purple-900 text-purple-300 ";
        case "in-review":
            return " bg-blue-900 text-blue-300 ";
        case "incompleted":
            return " bg-yellow-900 text-yellow-300 ";
        case "rejected":
            return " bg-red-900 text-red-300 ";
        case "accepted":
            return " bg-green-900 text-green-300 ";
        case "withdrawn":
            return " bg-gray-700 text-gray-300 ";
        default:
            return " bg-primary text-black ";
    }
};

/**
 * Returns a transformation of an application statius
 * @param {string} status
 * @returns
 */
export const formatStatus = (status) => {
    switch (status?.toLowerCase()) {
        case "accepted":
            return "SHORTLISTED";
        case "incompleted":
            return "IN-PROGRESS";
        default:
            return status;
    }
};

/**
 * Creates a browser text selection on the target element
 * @param {React.RefObject} nodeRef - Ref to the the target node.
 */
export const selectText = (nodeRef) => {
    const node = nodeRef.current;

    if (document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(node);
        range.select();
    } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
    } else {
        console.warn("Could not select text in node: Unsupported browser.");
    }
};

export const checkImageSizeAndRatio = async (
    file,
    minSize,
    targetAspectRatio
) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        var objectUrl = URL.createObjectURL(file);
        img.onload = async function () {
            let errors = [];
            const aspectRatio = this.width / this.height;

            if (this.width < minSize || this.height < minSize) {
                errors.push(
                    `This image is ${this.width}px * ${this.height}px in size. The minimum size is ${minSize}px.`
                );
            }
            if (aspectRatio !== targetAspectRatio) {
                errors.push(
                    `This image has an aspect ratio of ${aspectRatio.toFixed(
                        2
                    )}. The required aspect ratio is ${targetAspectRatio.toFixed(
                        2
                    )}.`
                );
            }

            URL.revokeObjectURL(objectUrl);
            resolve(errors);
        };
        img.src = objectUrl;
    });
};

export const checkImageFileSize = async (
    file,
    maxSize
) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        var objectUrl = URL.createObjectURL(file);
        img.onload = async function () {
            let errors = [];

            if (file.size > maxSize) {
                errors.push(
                    `This image size is ${(file.size/1000000).toFixed(2)}MB. The max size is ${(maxSize/1000000).toFixed(2)}MB.`
                )
            }

            URL.revokeObjectURL(objectUrl);
            resolve(errors);
        };
        img.src = objectUrl;
    })
}