import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

export const Collapse = React.memo(
  ({
    children,
    bdr,
    title,
    defaultOpen,
    fullWidth,
    titleAfter,
    space,
    show,
    // Currently forced collapsed on mobile viewports. New requirement to have default expanded on mobile.
    // Rather than a complete overhaul and update every implementation, adding this new flag to override
    viewportOverride,
    bg,
    ...rest
  }) => {
    const [open, isOpen] = useState(defaultOpen || false);
    const { isMdUp } = useResponsiveLayout();

    useEffect(() => {
      !isMdUp && !viewportOverride ? isOpen(false) : isOpen(defaultOpen);
    }, [defaultOpen, isMdUp, viewportOverride]);

    return (
      <>
        {fullWidth ? (
          <Container
            bdr={bdr}
            open={open}
            space={open ? space : false}
            bg={bg}
            {...rest}
          >
            <>
              <h4>
                <Button isMdUp={isMdUp} onClick={() => isOpen(!open)}>
                  {show !== false && <Icon
                    icon={
                      isMdUp
                        ? open
                          ? faCaretUp
                          : faCaretDown
                        : open
                          ? faCaretUp
                          : faCaretDown
                    }
                  />}
                  {title}
                </Button>
              </h4>
              {open ? <div>{children}</div> : null}
            </>
          </Container>
        ) : (
          <Wrapper fullWidth>
            {open ? <div>{children}</div> : null}
            <h4 onClick={() => isOpen(!open)}>
              {titleAfter && open ? titleAfter : title}
              &nbsp;
              <Icon icon={open ? faCaretUp : faCaretDown} />
            </h4>
          </Wrapper>
        )}
      </>
    );
  }
);

const Container = styled.div(
  ({ bdr, open, space, bg }) => `
  border-bottom:${bdr && open ? "1px solid #424343" : ""};
  background-color: ${!open ? bg ? bg : "#232424" : ""};
  border-radius: 5px;
  padding: ${!space ? "28px 20px" : "0"};
  & svg{
      float:right;
      margin-bottom: 20px;
  } 
`
);

const Button = styled.div(
  ({ isMdUp }) => `
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  font-size: ${isMdUp ? "18px" : "16px"};
`
);

const Wrapper = styled.div`
  & h4 {
    display: flex;
    width: fit-content;
    cursor: pointer;  
    }
  & svg {
    margin-left: 10px;
    }
  > div {
    display: block;
    }

`

