import {
    faBan,
} from '@fortawesome/free-solid-svg-icons'
import { headerHeight } from "styles/Layout";
import { Icon } from 'components/Icon'
import { useNavigate } from 'react-router-dom'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout';
import React from "react";
import styled from "styled-components";

export const AccessDenied = () => {
    const navigate = useNavigate()
    const size = useResponsiveLayout()

    return (
        <Container>
            <Content size={size}>
                <Icon icon={faBan} className='p-12 bg-red-800 rounded-full w-[42px] h-[42px]'></Icon>
                <Message>
                    <h1 className="text-white text-5xl font-bold">Access Denied</h1>
                    <p className="text-white">You don't have access to that resource</p>
                    <div className='grid gap-6 grid-cols-2'>
                        <button
                            type="button"
                            className={`border-2 border-solid border-secondary text-white py-4 px-6 rounded-lg`}
                            onClick={() => {
                                navigate('/')
                            }}

                        >
                            Go home
                        </button>
                        <button
                            type="button"
                            className={`bg-secondary text-white py-4 px-6 rounded-lg`}
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            Go back
                        </button>
                    </div>
                </Message>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    place-self: center;
    align-content: center;
    justify-items: center;
    height: calc(100svh - ${headerHeight}px);
    height: calc(100vh - ${headerHeight}px);
`

const Content = styled.div(({ size }) => `
    display: grid;
    grid-template-columns: ${size.isSmUp ? 'auto 1fr' : '1fr'};
    align-items: center;
    justify-items: ${size.isSmUp ? 'flex-start' : 'center'};
    text-align: ${size.isSmUp ? 'flex-start' : 'center'};
    gap: 32px;
`)

const Message = styled.div`
    display: grid;
    grid-auto-flow: row;
    gap: 12px;
`