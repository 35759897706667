import desi from "images/desi.png";
import desiround from "images/dasiround.png";
import krisround from "images/krisround.png";
import logonround from "images/logonround.png";
import philipround from "images/philipround.png";
import rex from "images/rex.png";
import rexround from "images/rexround.png";
import robround from "images/robround.png";
import videoThumbnail from "images/video.png";

export const data = [
  {
    user: {
      online: true,
      id: 1,
      avatar: philipround,
      name: "Philip Anselmo",
      rating: 4,
      videos: [
        { date: " 01 March 2022", video: videoThumbnail },
        { date: " 04 March 2022", video: videoThumbnail },
        { date: " 08 March 2022", video: videoThumbnail },
      ],
    },
  },
  {
    user: {
      id: 2,
      avatar: rexround,
      name: "Rex Brown",
      rating: 5,
      videos: [
        { date: " 01 March 2022", video: rex },
        { date: " 04 March 2022", video: rex },
        { date: " 08 March 2022", video: rex },
      ],
    },
  },
  {
    user: {
      id: 3,
      avatar: desiround,
      name: "Daisy Chainsaw",
      rating: 3,
      videos: [
        { date: " 01 March 2022", video: desi },
        { date: " 04 March 2022", video: desi },
        { date: " 08 March 2022", video: desi },
      ],
    },
  },
];

export const candidateData = [
  {
    candidate: {
      id: 1,
      avatar: philipround,
      name: "Philip Anselmo",
      rating: 4,
      compare: true,
      info: [
        {
          key: "Contract type",
          value: "Perm / Part time",
        },
        {
          key: "Location",
          value: "Tewkesbury, UK",
        },
        {
          key: "Tel / Mob",
          value: "07891 349 221",
        },
        {
          key: "Email",
          value: "phil@gmail.com",
        },
        {
          key: "Candidate ref",
          value: "PA1234",
        },
      ],
      skills: ["skill1", "skill2", "skill3", "skill4"],
    },
  },
  {
    candidate: {
      id: 2,
      avatar: rexround,
      name: "Rex Brown",
      rating: 5,
      compare: true,
      info: [
        {
          key: "Contract type",
          value: "Perm / Part time",
        },
        {
          key: "Location",
          value: "Tewkesbury, UK",
        },
        {
          key: "Tel / Mob",
          value: "07891 349 221",
        },
        {
          key: "Email",
          value: "phil@gmail.com",
        },
        {
          key: "Candidate ref",
          value: "PA1234",
        },
      ],
      skills: ["skill1", "skill2", "skill3", "skill4"],
    },
  },
  {
    candidate: {
      id: 3,
      avatar: desiround,
      name: "Daisy Chainsaw",
      rating: 3,
      compare: true,
      info: [
        {
          key: "Contract type",
          value: "Perm / Part time",
        },
        {
          key: "Location",
          value: "Tewkesbury, UK",
        },
        {
          key: "Tel / Mob",
          value: "07891 349 221",
        },
        {
          key: "Email",
          value: "phil@gmail.com",
        },
        {
          key: "Candidate ref",
          value: "PA1234",
        },
      ],
      skills: ["skill1", "skill2", "skill3", "skill4"],
    },
  },
  {
    candidate: {
      id: 4,
      avatar: logonround,
      name: "Daisy Chainsaw",
      rating: 3,
      compare: false,
      info: [
        {
          key: "Contract type",
          value: "Perm / Part time",
        },
        {
          key: "Location",
          value: "Tewkesbury, UK",
        },
        {
          key: "Tel / Mob",
          value: "07891 349 221",
        },
        {
          key: "Email",
          value: "phil@gmail.com",
        },
        {
          key: "Candidate ref",
          value: "PA1234",
        },
      ],
      skills: ["skill1", "skill2", "skill3", "skill4"],
    },
  },
  {
    candidate: {
      id: 5,
      avatar: robround,
      name: "Daisy Chainsaw",
      rating: 3,
      info: [
        {
          key: "Contract type",
          value: "Perm / Part time",
        },
        {
          key: "Location",
          value: "Tewkesbury, UK",
        },
        {
          key: "Tel / Mob",
          value: "07891 349 221",
        },
        {
          key: "Email",
          value: "phil@gmail.com",
        },
        {
          key: "Candidate ref",
          value: "PA1234",
        },
      ],
      skills: ["skill1", "skill2", "skill3", "skill4"],
    },
  },
  {
    candidate: {
      id: 6,
      avatar: krisround,
      name: "Daisy Chainsaw",
      rating: 2,
      info: [
        {
          key: "Contract type",
          value: "Perm / Part time",
        },
        {
          key: "Location",
          value: "Tewkesbury, UK",
        },
        {
          key: "Tel / Mob",
          value: "07891 349 221",
        },
        {
          key: "Email",
          value: "phil@gmail.com",
        },
        {
          key: "Candidate ref",
          value: "PA1234",
        },
      ],
      skills: ["skill1", "skill2", "skill3", "skill4"],
    },
  },
];
