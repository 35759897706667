import React from "react";
import styled from "styled-components";

export const Question = React.memo(({ children, color }) => {
  return <Container color={color}>{children}</Container>;
});

const Container = styled.div(
  ({ color }) => `
  margin-bottom: 30px;
  margin-top: 30px;
  background-color: ${color ? color : "#232424"} ;
  padding: 15px 30px;
  border-radius: 5px;
`
);
