import { Colors } from "styles/Colors";
import { Rating } from "components/Ratings";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";

import React from "react";
import styled from "styled-components";

const InfoRow = React.memo(({ label, value, showbdr }) => (
  <InfoRowContainer showbdr={showbdr}>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </InfoRowContainer>
));
export const ProfileArea = React.memo(({ data }) => {
  const { isMdUp } = useResponsiveLayout();
  return (
    <Container isMdUp={isMdUp}>
      <ImageWrapper isMdUp={isMdUp}>
        <img src={data.avatar || "/dummy-profile-picture.png"} alt="avatar" />
      </ImageWrapper>
      <SkillsContainer>
        <Name>{data.firstName}</Name>
        {/* <Rating rating={data.Rating}></Rating> */}
        <h4>Details</h4>
      </SkillsContainer>
      {/* <InfoRow
        showbdr
        label="Candidate ref"
        value={data.candidateRef}
      ></InfoRow> */}
      <InfoRow
        showbdr
        label="Contract type"
        value={data.ContractType}
      ></InfoRow>
      <InfoRow showbdr label="Location" value={data.Location}></InfoRow>
      <InfoRow showbdr label="Tel / Mob" value={data.tel}></InfoRow>
      <InfoRow label="Email" value={data.email}></InfoRow>
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  height: calc(100vh - 80px);
  height: calc(100svh - 80px);
display: flex;
background-color: black;
position: relative;
padding-bottom: 146px;
flex-direction: column;
min-width: ${isMdUp ? "361px" : "100%"};
& button {
    margin-left: 10px;
    margin-top: 10px;
}
`
);
const InfoRowContainer = styled.div(
  ({ showbdr }) => `
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: ${showbdr ? "2px solid #232424" : ""};
  padding-bottom: 15px;
  margin-left: 20px;
  margin-right:20px;
`
);

const Label = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: ${Colors.greyText};
`;
const Value = styled(Label)`
  color: ${Colors.white};
  margin-left: 60px;
`;
const SkillsContainer = styled.div`
  padding: 0px 20px;
  & h4 {
    margin-top: 40px;
    color: white;
    font-size: 18px;
    font-weight: 700;
  }
`;
const Name = styled.h2`
  line-height: 26px;
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
  color: ${Colors.white};
`;
const ImageWrapper = styled.div(
  ({ isMdUp }) => `
    overflow: hidden;
    min-height: 260px;
    position: relative;
    width: 100%;
  & img {
    display : ${isMdUp ? "inline" : "none"};
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
  }
 
`
);
