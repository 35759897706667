import { computed, signal } from "@preact/signals-react";
import { isCandidate } from "./state";
import { user } from "store/signals/state/user";
import { userAPI } from "store/userAPI";
import { useSignals } from "@preact/signals-react/runtime";


const emailSent = signal(false)

const showEmailBanner = computed(() => {
  return ((user.value && user.value?.emailVerified === false) && emailSent.value === false)
})

export const VerifyEmailBanner = () => {
  useSignals()
  const [send] = userAPI.endpoints.resendEmailVerification.useLazyQuery()
  if (!isCandidate.value) return null
  const handleSendEmail = async () => {
    await send()
    emailSent.value = true
  }
  if (!showEmailBanner.value) return null
  return (
    <div className="p-2 text-center text-dm text-white bg-orange-600">
      <p>Please verify your email address. Can't find your email? </p>
      <div onClick={handleSendEmail} className="underline"> Click here to resend.</div>
    </div>
  )
}