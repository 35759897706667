import { Colors } from "styles/Colors";
import { FlexColumnWrapper } from 'components/FlexColumnWrapper'
import { NewTagComponent } from "components/NewTagComponent";
import { numberWithCommas } from "utils/functions";
import { ReadMore } from "components/ReadMore";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const InfoRow = React.memo(({ label, value, }) =>
  value ? (
    <InfoRowContainer>
      <Label>{label}</Label>
      <Value>{label === "Email" ? <a href={`mailTo:${value}`} rel="noreferrer" target="_blank">{value}</a> : value}</Value>
    </InfoRowContainer >
  ) : null
);

export const ProfileInfo = React.memo(({ img, tags, data, views, auditLogs }) => {

  const [viewers, setViewers] = useState([])
  const [activityLog, setActivityLog] = useState([])

  useEffect(() => {
    // Sort the views by `updatedAt`
    const sorted = [...views].sort((a, b) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf())
    // Set to just names + count of views for this user
    const mapped = sorted.map(v => {
      return { avatar: v.user.avatar, name: v.user.name, count: views.filter(view => view.userId === v.userId).length }
    })

    // Remove dupes
    const unique = mapped.reduce(function (p, c) {
      if (!p.some(function (el) { return el.name === c.name; })) p.push(c);
      return p;
    }, []);

    setViewers(unique)
  }, [views])

  useEffect(() => {
    // Sort the logs by `createdAt`
    const sorted = [...auditLogs].sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())

    // Format for display
    const mapped = sorted.map(v => {
      return { avatar: v.user.avatar, name: v.user.name, details: v.details, timestamp: v.createdAt }
    })
    setActivityLog(mapped)
  }, [auditLogs])


  return (
    <Container className="p-10">
      <div className="flex justify-center">
        <Image
          src={img || "/dummy-profile-picture.png"}
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      </div>
      <Section>
        <Title>Details</Title>
        <InfoRow label="Contract type" value={data?.ContractType} />
        <InfoRow
          label="Location"
          value={data?.candidate?.prefferedLocation}
        />
        <InfoRow label="Tel / Mob" value={data.tel} />
        <InfoRow label="Email" value={data.email} />
        {data?.candidate?.salaryExpectation && <InfoRow
          label="Minimum Salary"
          value={"£ " + numberWithCommas(data?.candidate?.salaryExpectation)}
        />}
        <InfoRow
          label="Phone Number"
          value={data?.candidate?.phoneNo}
        />
        {data.candidate?.rightToWork && (
          <InfoRow
            label="Right To Work"
            value={data?.candidate?.rightToWork === true ? "Yes" : "No"}
          />
        )}
      </Section>
      <hr className="m-0 border-0 border-t" />
      <Section>
        <Title>Tags</Title>
        <FlexColumnWrapper>
          {tags?.map((i, index) => (
            <NewTagComponent key={index}>{i}</NewTagComponent>
          ))}
        </FlexColumnWrapper>
      </Section>
      {viewers.length > 0 &&
        <>
          <hr className="m-0 border-0 border-t" />
          <Section>
            <Title>Viewers</Title>
            <ReadMore className={viewers.length > 3 ? "mb-12" : ""} maxHeight="145" labelClosed={`+ ${viewers.length - 3} more`}><ul>{viewers.map((viewer, index) => (
              <li className="py-3 flex items-center justify-between gap-4" key={index}>
                <span className="flex items-center gap-4">
                  <img className="rounded-full inline" src={viewer.avatar} alt={`${viewer.name} avatar`} style={{
                    width: 28,
                    height: 28,
                    objectFit: "cover",
                  }} />
                  <span>{viewer.name}</span>
                </span>
                <span className="text-lg text-gray-400">({viewer.count})</span>
              </li>
            ))}</ul></ReadMore>

            <InfoRow
              label="Total Viewers"
              value={viewers.length}
            />
            <InfoRow
              label="Total Views"
              value={views.length}
            />
          </Section>
        </>
      }
      {
        activityLog.length > 0 &&
        <>
          <hr className="m-0 border-0 border-t" />
          <Section>
            <Title>Activity</Title>
            <ReadMore maxHeight="80" labelClosed={"Show more"}><ul className="grid gap-12">{activityLog.map((log, index) => (
              <ActivityEntry className="grid items-center gap-2" key={index}>
                <div>{log.details}</div>
                <div className="flex items-center gap-4 text-xl text-gray-400">
                  <span className="flex items-center gap-4">
                    <img className="rounded-full inline" src={log.avatar} width={22} alt={`${log.name} avatar`} />
                    <span>{log.name}</span>
                  </span>
                  <span>|</span>
                  <span>{new Date(log.timestamp).toLocaleDateString()} {new Date(log.timestamp).toLocaleTimeString()}</span>
                </div>
              </ActivityEntry>
            ))}</ul></ReadMore>
          </Section></>
      }
    </Container >
  );
});

const Container = styled.div`
  display: grid;
  align-content: flex-start;
  gap: 50px;
  position: relative;
  grid-area: info;
`

const Section = styled.section`
  display: grid;
  gap: 24px;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
`

const InfoRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Label = styled.div`
  line-height: 26px;
  color: ${Colors.greyText};
`

const Value = styled(Label)`
  color: ${Colors.white};
  margin-left: 20px;
  > a {
    color: ${Colors.white};
    text-decoration: underline;
  }
`;

const Title = styled.h2`
  margin
  line-height: 26px;
  font-weight: 700;
  font-size: 18px;
  color: ${Colors.white};
`

const Image = styled.img`
  height: 200px;
  width: 200px;
  border-radius: 80px;
`

const ActivityEntry = styled.li`
  &:after {
    position: relative;
    content: '';
    bottom: -12px;
    height: 1px;
    width: 100%;
    border-top: 1px dotted #424343;
  }
`