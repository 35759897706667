import { faClose, faQuestionCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import { Janus } from 'janus-gateway'
import { Recorder } from './Recorder'
import { RecorderFooter } from './RecorderFooter'
import { useAuthState } from 'Context';
import { useLogger } from "hooks/useLogger";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from "styled-components";

export const VideoRecorder = React.memo(({
  allowThumbnailUpload = false,
  allowUpload = true,
  backgroundImage,
  disableJanus,
  headerText,
  height,
  janusServer,
  killRecording = 0,
  onRecording,
  onComplete,
  onExit,
  overlayText,
  requiredVideoLength = 5,
  showGuide,
  thumbnailSelection = true,
  maxUploadFilesize
}) => {

  const { logger } = useLogger()
  const { userDetails } = useAuthState()

  const IS_PROD = process.env.REACT_APP_ENV === 'production'
  const JANUS_KEY = process.env.REACT_APP_JANUS_KEY
  // Videos must be at least 1 seconds long no matter what the prop `requiredVideoLength` prop says
  // This is because we save in 1 second chunks
  const MIN_VIDEO_LENGTH = 1
  // Refs
  const recorderRef = useRef(null)
  const liveRef = useRef(null)
  const liveRefShadow = useRef(null)
  const previewRef = useRef(null)
  const previewRefShadow = useRef(null)
  const wrapperRef = useRef(null)
  const uploadThumbRef = useRef(null)
  const guideIntervalRef = useRef(null)

  // Local state
  const [isStreaming, setIsStreaming] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [hasRecorded, setHasRecorded] = useState(false)
  const [hasData, setHasData] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isOverlayOpen, setIsOverlayOpen] = useState(true)

  // Recording timers
  const [recordingStartTime, setRecordingStartTime] = useState(
    new Date().valueOf(),
  )
  // const [recordingTime, setRecordingTime] = useState(0)
  // Track the duration of the actual recorded file
  // Overrides "recordingStartTime < recordingEndTime"
  const [recordedDuration, setRecordedDuration] = useState(0)

  // Thumbnails
  const thumbnailRefs = useRef([])
  const [hasChosenThumbnail, setHasChosenThumbnail] = useState(
    !selectThumbnail ? true : false,
  )
  // Used to track how many thumbnails are generated
  const [thumbnailCount, setThumbnailCount] = useState(0)

  // Raw blob data of thumbnail and video
  const [thumbnailData, setThumbnailData] = useState()
  const [videoData, setVideoData] = useState()

  const [guideSize, setGuideSize] = useState({ height: 'auto', width: 'auto' })

  // Video dimensions - used to size the guide
  const [videoDimensions, setVideoDimensions] = useState()

  // Whether this was a recording, or an upload
  const [wasRecorded, setWasRecorded] = useState(true)

  // Control question text box
  const [isMobileTextOpen, setIsMobileTextOpen] = useState(true)

  const size = useResponsiveLayout()

  /** JANUS */
  const [isJanusLoaded, setIsJanusLoaded] = useState(false)
  const janusRef = useRef()
  const recordPlayRef = useRef()
  const [janusRecordingId, setJanusRecordingId] = useState(
    Number(`${new Date().valueOf()}${Math.round(Math.random() * 100000)}`)
  )
  const [erroredState, setErroredState] = useState(
    { isErrored: false }
  )
  const bandwidth = 1024 * 1024

  const acodec = null
  const vcodec = null
  const vprofile = null
  const doSimulcast = false
  const doOpusred = false
  const recordData = null
  /** /JANUS  */

  /**
   * Fires when streaming starts
   * Sets the "preview" video element's src
   */
  function onStartStream(stream, width, height) {
    if (!liveRef?.current || !liveRefShadow?.current) return
    setIsOverlayOpen(false)
    liveRef.current.srcObject = stream
    liveRefShadow.current.srcObject = stream

    // Give 'er a sec to load
    setTimeout(() => {

      liveRef.current.play()
      liveRefShadow.current.play()

      // Set width/height aspect ratio for the video recorder guide - its size depends on the size of the video stream
      setVideoDimensions({
        width,
        height,
      })
      setIsStreaming(true)

      // Clear posters
      previewRef.current.removeAttribute('poster')
      previewRefShadow.current.removeAttribute('poster')
    }, 200);
  }

  /**
   * Fires when streaming stops
   * Sets the "preview" video element's src
   */
  function onStopStream() {
    if (!liveRef?.current || !liveRefShadow?.current) return
    liveRef.current.srcObject = null
    liveRefShadow.current.srcObject = null
    liveRef.current.pause()
    liveRefShadow.current.pause()
    setIsStreaming(false)
  }

  /**
   * Fires when recording starts
   * Sets recording state and started timestamp
   */
  async function onStartRecording({ startTime }) {
    if (onRecording) onRecording()
    // Init janus
    if (!isJanusLoaded && !disableJanus) await initJanus()
    if (!disableJanus) startJanusRecording()
    setIsRecording(true)
    setRecordingStartTime(startTime ? new Date(startTime).valueOf() : new Date().valueOf())
    setIsMobileTextOpen(false)
    setIsOverlayOpen(false)
  }

  /**
   * Fires when recording is stopped
   * Updates recording state and timestamp
   * Also stops the live stream
   */
  async function onStopRecording(isUpload = false) {
    if (
      !previewRef?.current ||
      !previewRefShadow.current ||
      !recorderRef?.current
    )
      return

    // Stop Janus stream - only on recordings, not uploads.
    if (!isUpload && recordPlayRef.current) {
      logger.info("Recording stopped - stopping Janus", { type: "Janus", })
      const stop = { request: 'stop' }
      try {
        recordPlayRef.current.send(stop)
        recordPlayRef.current.hangup()
      } catch (ex) {
        // Don't kill the process just because Janus didn't actually confirm the stop
        console.error(`Failed to stop Janus`, ex)
      }
    }

    // Check for an upload, track it so the parent knows to behave differently
    if (isUpload) {
      logger.info("Set as upload", { type: "VideoRecorder" })
      setWasRecorded(false)
    }

    recorderRef.current.stopStream()
    setIsRecording(false)
    setHasRecorded(true)

    // Close any overlays that were open again
    setIsMobileTextOpen(false)
    setIsOverlayOpen(false)
  }

  /**
   * Fires when recording data is available
   * Sets the "recorded" video element's src to the data from the blob
  */
  async function onCompleteRecording({ data, error }) {

    // Check for an error from the recorder - do this after stopping Janus etc
    if (error) {
      logger.error(`onCompleteRecording error`, { type: "VideoRecorder", error })
      setErroredState({ isErrored: true, error: error.toString() })
      return
    }

    if (
      !previewRef?.current ||
      !previewRefShadow.current ||
      !recorderRef?.current
    )
      return

    setVideoData(data)
    const dataUrl = URL.createObjectURL(data)
    previewRef.current.src = dataUrl
    previewRefShadow.current.src = dataUrl
    // Previews are set to autoplay to allow iOS Safari to get thumbs - stop them here
    previewRef.current.pause()
    previewRefShadow.current.pause()
    setHasData(true)
  }

  /**
   * Discards a recording, allowing the user to try again
   */
  const discardRecording = useCallback(() => {
    if (
      !previewRef?.current ||
      !previewRefShadow.current ||
      !recorderRef?.current
    )
      return

    previewRef.current.pause()
    previewRefShadow.current.pause()
    previewRef.current.srcElement = null
    previewRefShadow.current.srcElement = null
    setIsConfirmed(false)
    setHasRecorded(false)
    setHasData(false)
    setRecordingStartTime(new Date().valueOf())
    setRecordedDuration(0)
    setWasRecorded(true)
    // Generate a new ID
    setJanusRecordingId(Number(`${new Date().valueOf()}${Math.round(Math.random() * 100000)}`))
  }, [])

  /**
   * Confirms a recording and starts to generate thumbnails
   */
  function confirmRecording() {
    if (
      !previewRef?.current ||
      !previewRefShadow.current ||
      !previewRef?.current ||
      !previewRefShadow.current
    )
      return

    previewRef.current.pause()
    previewRefShadow.current.pause()
    setIsConfirmed(true)
    setThumbnailCount(0)
    setHasChosenThumbnail(thumbnailSelection ? false : true)
  }

  /**
   * Sets/selects a thumbnail
   * Updates preview video element poster
   * @param index the index of the thumbnail to select
   */
  function selectThumbnail(index) {
    if (!previewRef?.current || !previewRefShadow.current) return
    // If we selected a thumbnail and didn't skip, get the image data and store it in state
    if (index !== -1) {
      const imageUrl = thumbnailRefs.current[index].toDataURL('image/jpeg')
      previewRef.current.setAttribute('poster', imageUrl)
      previewRefShadow.current.setAttribute('poster', imageUrl)
      thumbnailRefs.current[index].toBlob((blob) => {
        setThumbnailData(blob)
        setHasChosenThumbnail(true)
      })
    } else {
      // "Skip" option - set chosen as true anyway      setHasChosenThumbnail(true)
    }
  }

  function onThumbnailUploaded(file) {
    console.log(file)
    setThumbnailData(file)
    setHasChosenThumbnail(true)
  }

  /**
   * Upload handler
   * @param {*} file - the file being uploaded
   */
  function onUpload(file) {
    // If set, check the filesize
    if (maxUploadFilesize) {
      const filesize = file.size
      console.log(`Filesize in bytes: ${filesize}`)
      if (filesize > maxUploadFilesize * 1024 * 1024) {
        const filesizeInMb = (filesize / 1024 / 1024).toFixed(2)
        logger.error(`File too large. Filesize in MB: ${filesizeInMb}`, { type: "VideoRecorder" })
        alert(`The selected file is too large. The maximum allowed filesize is ${maxUploadFilesize}MB. The selected file is ${filesizeInMb}MB.`)
        return;
      }
    }

    // Fire onRecording for the parent component
    if (onRecording) onRecording()
    // We can just call onStopRecording with 'true' as the isUpload flag
    onStopRecording(true);
    // Call onComplete with the data
    onCompleteRecording({ data: file });
  }


  /** 
   * Bind duration change event on preview element
   * Store actual recorded duration in state for use across the component
   * If below required threshold, reject and return to start
  */
  useEffect(() => {

    function onDurationChange(e) {
      if(killRecording) return

      /**
       * Bug in Chrome reports Duration as `Infinity`, unless it has the currentTime changed and reset (seek)
       * We use the Shadow ref since iOS Safari is a pain
       * iOS Safari will only play a video if the user first interacts themselves (which they can't in this context), or it is muted
       */
      previewRefShadow.current.currentTime = 10000
      setTimeout(() => {
        if (!previewRef?.current) return
        previewRefShadow.current.currentTime = 0.1
      }, 100)
      // End of bug fix

      const _recordedDuration = e.srcElement.duration
      setRecordedDuration(_recordedDuration)
      console.log(`Recorded duration: ${_recordedDuration}`)
      if (_recordedDuration < (requiredVideoLength < MIN_VIDEO_LENGTH ? MIN_VIDEO_LENGTH : requiredVideoLength)) {
        alert(`Videos must be at least ${requiredVideoLength < MIN_VIDEO_LENGTH ? MIN_VIDEO_LENGTH : requiredVideoLength} seconds long.`)
        discardRecording()
      }
    }

    if (!previewRefShadow.current) return
    const ref = previewRefShadow.current

    ref.addEventListener('durationchange', onDurationChange, false)

    return () => {
      ref.removeEventListener('durationchange', onDurationChange)
    }
  }, [discardRecording, requiredVideoLength, killRecording])

  /**
   * Responsible for generating thumbnails
   * Fires when a video is confirmed
   * Loops x times to generate x thumbnails
   * Performed on a slight delay as the video element needs to rerender before being shoved in a canvas
   * We use the Shadow ref since iOS Safari is a pain
   * iOS Safari will only play a video if the user first interacts themselves (which they can't in this context), or it is muted
   * We're lucky that the Shadow video is in fact muted and `playsinline` - just what we need to get iOS to behave.
   */
  useEffect(() => {
    if (
      !thumbnailSelection ||
      !isConfirmed ||
      !previewRefShadow.current ||
      !recorderRef?.current
    )
      return

    // Reset to 0.1 if this is the first thumb generation
    if (thumbnailCount === 0) {
      previewRefShadow.current.currentTime = 0.1
    }

    // If we've generated all thumbs, return and reset the position of the shadow preview to the main player current time so they're back in sync with each other
    if (thumbnailCount >= thumbnailRefs.current.length) {
      previewRefShadow.current.currentTime = previewRef.current.currentTime
      return
    }

    // Get x evenly spaced frames from the entire video duration
    // Get the increment in a nicer 2-decimal float
    const incAmount = Number((previewRefShadow.current.duration / thumbnailRefs.current.length).toFixed(2))

    // Sanitize so that if this is <0 or >= Infinity, increment by 1 second
    const inc = incAmount < 0 || incAmount >= Infinity ? 1 : incAmount

    // Calculate the new time after the increment. 
    const newTime = previewRefShadow.current.currentTime + (inc)

    // Check if we'd go over the duration of the recording - clamp to the end.
    // Stop 0.1s before the end of the recording in case the last frame is garbage as it often is
    const setTime = newTime > previewRefShadow.current.duration - 0.1 ? previewRefShadow.current.duration - 0.1 : newTime
    previewRefShadow.current.currentTime = setTime

    console.log(`Increment thumbnail generation by ${inc}`)
    console.log(`Generating thumbnail at ${setTime}`)

    // Write to the next canvas element
    const canvas = thumbnailRefs.current[thumbnailCount]
    canvas.width = previewRefShadow.current.videoWidth
    canvas.height = previewRefShadow.current.videoHeight
    const context = canvas.getContext('2d')
    if (context)
      context.drawImage(
        previewRefShadow.current,
        0,
        0,
        previewRefShadow.current.videoWidth,
        previewRefShadow.current.videoHeight,
      )


    // Update state and rerender - requires setTimeout to force wait for video to rerender before the next loop fires.
    setTimeout(() => {
      setThumbnailCount(thumbnailCount + 1)
    }, 1000)
  }, [thumbnailSelection, isConfirmed, thumbnailCount])

  /**
   * Final handler, if all selections are made we're finished
   * Responsible for gathering the important data and emitting it for actioning
   */
  useEffect(() => {
    if (!isConfirmed || !hasChosenThumbnail || !videoData) return

    // Required selection are made, emit completion and reset the component
    if (onComplete) onComplete({ recordingId: janusRecordingId, video: videoData, thumbnail: thumbnailData, wasRecorded, duration: recordedDuration })

    discardRecording()
  }, [janusRecordingId, hasChosenThumbnail, isConfirmed, videoData, thumbnailData, onComplete, wasRecorded, discardRecording, recordedDuration])

  /**
   * Exit handler - used for "come back later"
   */
  function exit() {
    if (onExit) onExit()
  }

  /** 
   * Kill recording if told to by the parent 
   */
  useEffect(() => {
    if (killRecording) {
      logger.info("Killing recording", { type: "VideoRecorder" })
      recorderRef.current?.stopRecording();
    }
  }, [killRecording, logger])

  /**
   * Resize handlers - we need to resize the video recorder guide
  */
  const handleResize = useCallback(() => {
    if (!videoDimensions || !wrapperRef.current) return
    clearInterval(guideIntervalRef.current)

    const guideInterval = setTimeout(() => {
      // Calculate the desired height depending on the video and viewport dimensions
      const dimensions = wrapperRef.current.getBoundingClientRect()

      // Calculate the aspect ratio of the video feed
      const aspectRatio = videoDimensions.width / videoDimensions.height

      /**
       * Set height based on the available dimensions.
       * Clamp to the available height
       * 
       * iOS randomly reports the incorrect video dimensions and behaves very strangely.
       * It is visibly portrait, but the width/height reports landscape (or vice versa).
       * If this is an iOS device reporting a height less than a width ("correct"), flip the guide width and height since it is in fact inverted.
       * iPads also have this issue, but their userAgent reports as Mac OS - useless to us.
       * I *hate* targeting stuff via userAgent - its bad practice - but I don't see a better way.
       */
      const isIOS = navigator.userAgent.toLowerCase().indexOf('iphone') > -1
      if (isIOS && videoDimensions.width > videoDimensions.height) console.warn('iOS target feed detected, inverting W/H')
      const height = isIOS && videoDimensions.width > videoDimensions.height ?
        Math.min(videoDimensions.width / (videoDimensions.height / dimensions.width), dimensions.height) :
        Math.min(videoDimensions.height / (videoDimensions.width / dimensions.width), dimensions.height)

      // Calculate width based off height * aspect ratio
      const width = height * aspectRatio

      const size = { width: `${Math.round(width)}px`, height: `${Math.round(height)}px` }
      console.log("Guide resized:", size)

      setGuideSize(size)
    }, 200);

    guideIntervalRef.current = guideInterval

  }, [videoDimensions, wrapperRef])

  // Bind event
  useEffect(() => {
    window.addEventListener('resize', handleResize, false)
    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove binding on unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  /**
   * Initialises a Janus instance
   * This is taken directly from Janus' documentation and has barely been modified. 
   * Its not very "React" but seems to work.
   */
  async function initJanus() {
    return new Promise((resolve, reject) => {
      Janus.init({
        debug: IS_PROD ? true : true,
        // React thinks this is a hook. It isn't 🙄
        // eslint-disable-next-line react-hooks/rules-of-hooks
        dependencies: Janus.useDefaultDependencies(), // or: Janus.useOldDependencies() to get the behaviour of previous Janus versions
        callback: function () {
          // Done!
          if (!IS_PROD) Janus.debug('Janus initialised, creating instance')
          setIsJanusLoaded(true)

          const janus = new Janus({
            server: janusServer,
            apisecret: JANUS_KEY,
            success: function () {
              if (!IS_PROD) Janus.debug('Janus instance created')
              janus.attach({
                plugin: 'janus.plugin.recordplay',
                opaqueId: `${janusRecordingId}`,
                success: function (pluginHandle) {
                  if (!IS_PROD) Janus.debug('recordplay attach success')
                  recordPlayRef.current = pluginHandle
                  resolve()
                },
                error: function (cause) {
                  if (!IS_PROD) Janus.debug('recordplay attach error')
                  if (!IS_PROD) Janus.debug(cause)
                  reject()
                },
                consentDialog: function (on) {
                  // e.g., Darken the screen if on=true (getUserMedia incoming), restore it otherwise
                  if (!IS_PROD) Janus.debug('recordplay attach consentDialog')
                  if (!IS_PROD) Janus.debug(on)
                },
                iceState: function (state) {
                  if (!IS_PROD) Janus.debug('ICE state changed to ' + state)
                },
                mediaState: function (medium, on, mid) {
                  if (!IS_PROD) Janus.debug(
                    'Janus ' +
                    (on ? 'started' : 'stopped') +
                    ' receiving our ' +
                    medium +
                    ' (mid=' +
                    mid +
                    ')',
                  )
                },
                webrtcState: function (on) {
                  if (!IS_PROD) Janus.debug(
                    'Janus says our WebRTC PeerConnection is ' +
                    (on ? 'up' : 'down') +
                    ' now',
                  )
                },
                slowLink: function (uplink, lost, mid) {
                  Janus.warn(
                    'Janus reports problems ' +
                    (uplink ? 'sending' : 'receiving') +
                    ' packets on mid ' +
                    mid +
                    ' (' +
                    lost +
                    ' lost packets)',
                  )
                },
                onmessage: function (msg, jsep) {
                  if (!recordPlayRef.current) return
                  if (!IS_PROD) Janus.debug(' ::: Got a message :::', msg)
                  const result = msg['result']
                  if (result) {
                    if (result['status']) {
                      const event = result['status']
                      if (event === 'preparing' || event === 'refreshing') {
                        if (!IS_PROD) Janus.debug('Preparing the recording playout')
                        recordPlayRef.current.createAnswer({
                          jsep: jsep,
                          // We only specify data channels here, as this way in
                          // case they were offered we'll enable them. Since we
                          // don't mention audio or video tracks, we autoaccept them
                          // as recvonly (since we won't capture anything ourselves)
                          tracks: [{ type: 'data' }],
                          success: function (jsep) {
                            if (!recordPlayRef.current) return
                            if (!IS_PROD) Janus.debug('Got SDP!', jsep)
                            const body = { request: 'start' }
                            recordPlayRef.current.send({
                              message: body,
                              jsep: jsep,
                            })
                          },
                          error: function (error) {
                            logger.error(`Encountered an error on createAnswer`, { type: "Janus", error })
                          },
                        })
                        if (result['warning']) alert(result['warning'])
                      } else if (event === 'recording') {
                        // Got an ANSWER to our recording OFFER
                        if (jsep)
                          recordPlayRef.current.handleRemoteJsep({ jsep: jsep })
                        const id = result['id']
                        if (id) {
                          logger.info(`The ID of the current recording is: ${id}`, { type: "Janus" })
                        }
                      } else if (event === 'stopped') {
                        if (!IS_PROD) Janus.debug('Session has stopped!')
                        const id = result['id']
                        if (id) {
                          if (janusRecordingId !== id) {
                            Janus.warn('Not a stop to our recording?')
                            return
                          }
                        }
                        recordPlayRef.current.hangup()
                      }
                    }
                  } else {
                    const error = msg['error']
                    logger.error(`Encountered an error during onmessage - result undefined `, { type: "Janus", error })
                    recordPlayRef.current.hangup()
                  }
                },

                oncleanup: function () {
                  if (!IS_PROD) Janus.debug(' ::: Got a cleanup notification :::')
                },
              })
            },
            error: function (cause) {
              setErroredState({ isErrored: true, error: "Unable to connect to recording server, please refresh and try again." })
              logger.error(`Encountered an error when connecting to Janus`, { type: "Janus", error: cause })
            },
            destroyed: function () {
              if (!IS_PROD) Janus.debug('Janus instance destroyed')
            },
          })

          janusRef.current = janus
        },
      })
    })
  }

  const startJanusRecording = useCallback(() => {
    if (!recordPlayRef.current) return
    // bitrate and keyframe interval can be set at any time:
    // before, after, during recording
    recordPlayRef.current.send({
      message: {
        request: 'configure',
        'video-bitrate-max': bandwidth, // a quarter megabit
        'video-keyframe-interval': 15000, // 15 seconds
      },
    })

    recordPlayRef.current.createOffer({
      // We want sendonly audio and video, since we'll just send
      // media to Janus and not receive any back in this scenario
      tracks: [
        { type: 'audio', capture: true, recv: false },
        { type: 'video', capture: true, recv: false, simulcast: doSimulcast },
      ],
      success: function (jsep) {
        if (!IS_PROD) Janus.debug('Got SDP!', jsep)
        let body = {
          request: 'record',
          name: `${userDetails.id}_${janusRecordingId}`,
          id: janusRecordingId,
          text: `${userDetails.id}_${janusRecordingId}`,
        }
        // We can try and force a specific codec, by telling the plugin what we'd prefer
        // Redundant and unused - these vars are "null" but leaving in as a stub
        if (acodec) body['audiocodec'] = acodec
        if (vcodec) body['videocodec'] = vcodec
        // For the codecs that support them (VP9 and H.264) you can specify a codec
        // profile as well (e.g., ?vprofile=2 for VP9, or ?vprofile=42e01f for H.264)
        if (vprofile) body['videoprofile'] = vprofile
        // You can use RED for Opus, if the browser supports it
        if (doOpusred) body['opusred'] = true
        // If we're going to send binary data, let's tell the plugin
        if (recordData === 'binary') body['textdata'] = false
        recordPlayRef.current.send({ message: body, jsep: jsep })
      },
      error: function (error) {
        Janus.error('WebRTC error...', error)
        alert('WebRTC error... ' + error.message)
        recordPlayRef.current.hangup()
        logger.error(`Encountered an error when creating a recordPlay offer`, { type: "Janus", error })
      },
    })
  }, [IS_PROD, bandwidth, doOpusred, doSimulcast, janusRecordingId, logger, userDetails])

  /**
   * Kill Janus if we leave
   */
  useEffect(() => {
    return () => {
      try {
        console.log("Destroying janus on leave")
        recordPlayRef.current.send({ message: { request: 'stop' } })
        recordPlayRef.current.hangup()
        janusRef.current.destroy()
      } catch (ex) {

      }
    }
  }, [])

  const videoComponents = useMemo(
    () => {
      return <>
        <video
          ref={liveRef}
          controls={false}
          muted
          playsInline
          autoPlay
          style={{ display: isStreaming ? 'block' : 'none' }}
        />

        <video
          ref={liveRefShadow}
          controls={false}
          muted
          playsInline
          autoPlay
          className="shadow"
          style={{ display: isStreaming ? 'block' : 'none' }}
        />

        <div
          className="fader"
          style={{ display: hasRecorded && !hasData ? 'grid' : 'none' }}>
          <h1 className="flex gap-6 text-white"><Icon icon={faSpinner} spin></Icon><span>Preparing your recording...</span></h1>
        </div>

        <video
          controls={true}
          ref={previewRef}
          playsInline
          autoPlay
          style={{ display: hasRecorded ? 'block' : 'none' }}
          onPlay={() => {
            previewRefShadow.current && previewRefShadow.current.play()
          }
          }
          onPause={() => {
            previewRefShadow.current && previewRefShadow.current.pause()
          }
          }
          onSeeked={() => {
            if (previewRef.current && previewRefShadow.current)
              previewRefShadow.current.currentTime =
                previewRef.current.currentTime
          }}
        />

        <video
          ref={previewRefShadow}
          controls={false}
          muted
          playsInline
          autoPlay
          className="shadow"
          style={{ display: hasRecorded ? 'block' : 'none' }}
        /></>
    },
    [hasData, hasRecorded, isStreaming],
  )


  return (
    <Wrapper ref={wrapperRef} className="recorder-wrapper" height={height} backgroundImage={!isRecording && !isStreaming && !hasRecorded ? backgroundImage : "none"}>
      {
        erroredState.isErrored ?
          <div className="place-self-center grid gap-12 md:max-w-[66vw] px-6">
            <p className="text-white text-3xl">Something went wrong, please refresh and try again.</p>
            {
              erroredState.error &&
              <div className="grid gap-6">
                <p className="text-white text-2xl">The error was:</p>
                <pre className="text-red-200 p-6 rounded-2xl bg-red-900 text-2xl whitespace-pre-wrap">{JSON.stringify(erroredState.error, null, 3)}</pre>
              </div>
            }
          </div> :
          <>
            <header data-has-text={headerText ? 'true' : 'false'}>
              <div className="wrapper">
                {!isStreaming && !hasRecorded && (
                  <button className={`border-2 border-solid border-secondary text-white py-4 px-6 rounded-lg ${!size.isMdUp && ' bg-secondary'} `}
                    type="button"
                    onClick={exit}
                  >
                    Come back later
                  </button>

                )
                }

                <h1>{headerText}</h1>
                {headerText ? (<div className="mobile-question-text" data-open={isMobileTextOpen ? 'true' : 'false'}>
                  <button onClick={() => { setIsMobileTextOpen(!isMobileTextOpen) }}><Icon icon={isMobileTextOpen ? faClose : faQuestionCircle} /></button>
                  <span>{headerText}</span>
                </div>) : <></>}
              </div>
            </header>

            <Recorder
              recId={janusRecordingId}
              ref={recorderRef}
              onStartRecording={onStartRecording}
              onStartStream={onStartStream}
              onStopRecording={onStopRecording}
              onCompleteRecording={onCompleteRecording}
              onStopStream={onStopStream}
            />

            {showGuide && (
              <div
                className="guide"
                style={{
                  display: isStreaming ? 'grid' : 'none',
                  height: guideSize.height || 'auto',
                  width: guideSize.width || 'auto'
                }}
              />
            )}
            {isOverlayOpen && overlayText &&
              <div className="overlay">
                <button onClick={() => setIsOverlayOpen(false)}><Icon icon={faClose} /></button>
                <div className="content thin-scrollbar">
                  {overlayText.map((t, i) => <p key={i}>{t}</p>)}
                </div>

              </div>
            }

            {videoComponents}

            <RecorderFooter
              allowUpload={allowUpload}
              hasRecorded={hasRecorded}
              isRecording={isRecording}
              isStreaming={isStreaming}
              onConfirmRecording={confirmRecording}
              onExit={exit}
              onDiscardRecording={discardRecording}
              onFileUploaded={onUpload}
              onStartRecording={() => {
                recorderRef.current?.startRecording()
              }}
              onStartStream={() => {
                recorderRef.current?.startStream()
              }}
              onStopRecording={() => {
                recorderRef.current?.stopRecording()
              }}
              onStopStream={() => {
                recorderRef.current?.stopStream();
              }}
              recordedDuration={recordedDuration}
              recordingStartTime={recordingStartTime}
              requiredVideoLength={requiredVideoLength < MIN_VIDEO_LENGTH ? MIN_VIDEO_LENGTH : requiredVideoLength}
            />

            <div
              className="thumbnail-selection-wrapper"
              style={{
                display:
                  thumbnailSelection && isConfirmed && !hasChosenThumbnail
                    ? 'grid'
                    : 'none',
              }}
            >
              <section>
                <h2>Choose a thumbnail</h2>
                <div className="thin-scrollbar">
                  <p
                    style={{
                      display:
                        thumbnailCount < thumbnailRefs.current.length
                          ? 'block'
                          : 'none',
                    }}
                  >
                    Generating thumbnails, please wait...
                  </p>
                  {new Array(5).fill(0).map((n, i) => (
                    <canvas
                      ref={(ref) =>
                        (thumbnailRefs.current[i] = ref)
                      }
                      key={`thumbnail_${i} `}
                      onClick={() => selectThumbnail(i)}
                      style={{
                        display:
                          thumbnailCount < thumbnailRefs.current.length
                            ? 'none'
                            : 'grid',
                      }}
                    ></canvas>
                  ))}
                </div>
                {allowThumbnailUpload && (
                  <>
                    <button className={"bg-secondary border-2 border-solid border-secondary text-white py-4 px-6 rounded-lg"} type="button" onClick={() => uploadThumbRef.current?.click()} title="Upload file">
                      Upload your own thumbnail
                    </button>
                    <input
                      ref={uploadThumbRef}
                      type="file"
                      accept="image/jpg"
                      onChange={(e) =>
                        e.target.files ? onThumbnailUploaded(e.target.files[0]) : null
                      }
                      style={{ display: 'none' }}
                    /></>)}
              </section>
            </div>
          </>
      }
    </Wrapper>
  )
})

export default VideoRecorder

const Wrapper = styled.div(({ height, backgroundImage }) => `
  ${backgroundImage &&
  `
    background-image: url('${backgroundImage}');
    `
  }
  // Gross, but required. Would be better if whole site was in a grid with 'grid-template-rows: auto 1fr; '
  // Then, this component could simply be 'height: 100%' like in the CSS itself, but here we are.
  // If the main layout ever gets fixed, remove this style declaration, and the 'height' prop.
  ${height && `height: ${height};`}
}
`)