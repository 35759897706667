import { IndexedDB } from 'utils/indexedDb'
import { useGetCompanyQuery, useUpdateCompanyMutation } from 'store/userAPI'
import { useNavigate } from "react-router-dom";
import { VideoPlaceholder } from './VideoPlaceholder';
import React, { useEffect, useRef, useState } from "react";
import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";
/**
 * Extracted to its own component so we can delete on unmount - similar to `MyVideos`
 */
export const MyCompanyVideos = React.memo(({ videoId }) => {
    const navigate = useNavigate();
    const db = useRef(new IndexedDB())
    const [localVideo, setLocalVideo] = useState({})

    const { refetch } = useGetCompanyQuery({})
    const [updateCompany] = useUpdateCompanyMutation()

    /**
    * Check for locally cached video
    * Videos are stored as ArrayBuffers due to an issue with Safari storing Blobs in IndexedDB
    * https://stackoverflow.com/questions/68386273/error-loading-blob-to-img-in-safari-webkitblobresource-error-1
    */
    useEffect(() => {
        const work = async () => {
            let video = {}

            // Sometimes the DB may have "null" - handle it.
            if (!videoId) return

            // Keys don't have the file extension
            const key = videoId.split('.')[0]

            // Get all the blobs from IndexedDB
            const blobs = await db.current.get(key)
            if (blobs) {
                // Combine the individual blobs
                const combined = new Blob(blobs, { type: blobs[0].type })
                video[videoId] = { url: URL.createObjectURL(combined), type: combined.type }
            } else {
                // No local store
                video[videoId] = videoId
            }

            setLocalVideo(video)
        }
        work().finally()
    }, [videoId, db])

    /** 
     * Clear local video cache on leave
     */
    useEffect(() => {
        const ref = db.current
        return () => {
            const work = async () => {
                await ref.clear()
            }
            work().finally()
        }
    }, []);

    const removeVideo = async () => {
        const payload = {
            video: ""
        }

        try {
            let response = await updateCompany(payload)

            if (!response) return
            if (response.error) return alert(response.message)
            refetch()
        } catch (error) {
            console.log(error)
        }
    }

    return (<div className='grid' style={{ gridTemplateColumns: `repeat(auto-fill, minmax(340px, 1fr))` }}>
        <div>
            {!localVideo[videoId] ? <VideoPlaceholder>No videos available</VideoPlaceholder> :
                <VideoCardJobBoard
                    videoData={localVideo[videoId]}
                    width={'100%'}
                    height={'auto'}
                    action
                />
            }
            <div className="flex gap-4 relative z-50 mt-9">

                <button
                    className=" px-4 py-2 text-white font-bold rounded-lg p-0 border border-primary/50 hover:bg-primary hover:text-black transition-all duration-300"
                    onClick={() => navigate("/employer/company/uploadvideo")}
                >
                    Edit Video
                </button>
                {localVideo[videoId] &&
                    <button
                        className="px-4 py-2 text-white font-bold rounded-lg p-0 border border-primary/50 hover:bg-primary hover:text-black transition-all duration-300" onClick={() => removeVideo()}
                    >
                        Remove Video
                    </button>
                }
            </div>
        </div>
    </div>)
})