import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

import testImage from "images/Avatar.png";

import { useNavigate } from "react-router-dom";

import { useApplyForJobMutation } from "store/userAPI";

import {
  selectSelectedVacancy,
  selectVacancyResponse,
} from "store/reducers/vacancyReducer";
import { useSelector } from "react-redux";

import { Icon } from "components/Icon";

import { faCheck, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export const ProgressSavedMessage = React.memo(() => {
  const { isMdUp } = useResponsiveLayout();
  const navigate = useNavigate();
  const [applyForJob] = useApplyForJobMutation();

  const selectedVacancy = useSelector(selectSelectedVacancy);
  const vacancyResponse = useSelector(selectVacancyResponse);

  let navigateToApplication = () => {
    navigate("/candidate/application");
  };
  let navigateBack = async () => {
    //comeback later

    navigate("/job-board");
    window.location.reload();
  };

  const arr = [
    {
      image: "/images/intro/1.png",
      text: "The company can’t wait to see you. They’d like you to record a short video answering their question.",
    },
    {
      image: "/images/intro/2.png",
      text: "If now is not the right time, or you’re not in the right environment, come back later whenever you’re ready.",
    },
    {
      image: "/images/intro/3.png",
      text: "You have unlimited retakes...So relax, if you’re not happy, just click redo.",
    },
    {
      image: "/images/intro/4.png",
      text: "The question will appear on the next screen, take a look and record when you’re ready.",
    },
    {
      image: "/images/intro/5.png",
      text: "Try to keep your answers clear and concise.",
    },
    {
      image: "/images/intro/6.png",
      text: "Just be natural and just be you, because that’s what makes you unique.",
    },
  ];

  return (
    <>
      <Container isMdUp={isMdUp}>
        <h2 className="text-5xl sm:text-7xl max-w-5xl text-center">
          Your Application progress has been saved
          <br />
        </h2>

        <div className="flex justify-center text-center  w-full h-96 ">
          <Icon
            icon={faCheckCircle}
            className="text-5xl text-primary w-60 h-60 sm:w-96 sm:h-96"
          />
        </div>

        <div className="flex justify-center text-center  w-full ">
          <p>
            We’ve sent you an email to confirm your progress has been saved. You
            can come back anytime by clicking on the link.
          </p>
        </div>
      </Container>
      <div className="flex  justify-center space-x-8">
        <button
          className="border-secondary border text-white w-[28rem] px-12 rounded-lg mt-20 py-6 text-3xl"
          onClick={() => navigateBack()}
        >
          Back to Job Board
        </button>
      </div>
    </>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${isMdUp ? "50px" : "30px"};
  padding-left : ${isMdUp ? "122px" : "16px"};
  padding-right :${isMdUp ? "122px" : "16px"};
  text-align:left;
  & h2 {
    font-size: 26px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 36.56px;
    margin-bottom: ${isMdUp ? "69px" : "80px"};
  }
  & p {
    font-size: 20px;
    color: white;
    font-weight: 300;
    line-height: 28.8px;
    margin: 0px;
    margin-top: 15px;
    font-family: "Roboto";
    width: ${isMdUp ? "70%" : "100%"};
  }
`
);
