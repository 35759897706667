import styled from 'styled-components'

/**
 * Simple flex wrapper
 * Puts as many children on a row as possible, then wraps to a new row.
 */
export const FlexColumnWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-flow: row;
  flex-wrap: wrap;
`
