import { computed, signal } from "@preact/signals-react";

export const user = signal(JSON.parse(localStorage.getItem('currentUser'))?.user || {})

export const isCandidate = computed(() => {
  return user.value?.userType === "CANDIDATE"
});

export const hasCandidateProfile = computed(() => {
  const candidate = user.value.candidate
  return [
    Boolean(candidate),
    Boolean(candidate?.cv),
    Boolean(candidate?.phoneNo)
  ].every(Boolean)
})

export const canApply = computed(() => {
  return isCandidate.value && hasCandidateProfile.value
})

