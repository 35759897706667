import { Collapse } from "components/collapse/Collapse";
import { data } from "./data";
import { DropdownInput } from "components/form";
import { SectionHeading } from "components/Headings/SectionHeading";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";

import React from "react";
import styled from "styled-components";

export const Faqs = React.memo(() => {
  const options = ["Sort by date", "name"];
  const { isMdUp } = useResponsiveLayout();
  const action = () => (
    <DWrapper>
      <DropdownInput options={options} type="alt" />
    </DWrapper>
  );
  return (
    <Container isMdUp={isMdUp}>
      <div>
        <SectionHeading
          search
          companyName
          title="FAQ"
          placeholder={
            isMdUp ? "Search by title, location or candidate name" : "search"
          }
          actions={action()}
        />
      </div>
      <hr />
      <BodyRoot isMdUp={isMdUp}>
        <BodyWrapper isMdUp={isMdUp}>
          {data.faqs &&
            data.faqs.map((category) => (
              <BodyContent isMdUp={isMdUp}>
                <Collapse
                  defaultOpen={category.id === 1 && isMdUp ? true : false}
                  show
                  title={category.name}
                  fullWidth
                >
                  {category.questions
                    ? category.questions.map((i, index) => (
                        <>
                          <h4>
                            <span> Q{index + 1} . </span> {i.question}
                          </h4>
                          <p>{i.answer}</p>
                        </>
                      ))
                    : ""}
                </Collapse>
              </BodyContent>
            ))}
        </BodyWrapper>
      </BodyRoot>
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  padding-bottom: 80px;
  padding-left : ${isMdUp ? "122px" : "16px"};
  padding-right :${isMdUp ? "122px" : "16px"};
  & hr {
    display : ${isMdUp ? "block" : "none"};
    border: 2px solid #494A4A;
    margin-top: 30px;
}

`
);
const BodyRoot = styled.div(
  ({ isMdUp }) => `

display: flex;
justify-content: flex-end;
margin-top: ${isMdUp ? "85px" : "35px"};

`
);

const BodyWrapper = styled.div(
  ({ isMdUp }) => `

width: ${isMdUp ? "50%" : "100%"};

`
);

const BodyContent = styled.div(
  ({ isMdUp }) => `
margin-bottom: 20px;
background-color: #232424;
color: white;
& h4{
  font-size: 18px;
  font-weight: bold;
}
& p{
  font-size: 16px;
  line-height: 25.6px;
  font-weight: 400;
  margin-bottom: 50px;
  margin-left: ${isMdUp ? "38px" : "0px"};

}



`
);

const DWrapper = styled.div`
  position: relative;
`;
