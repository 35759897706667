import { computed, effect, signal } from "@preact/signals-react";
import { useAuthState } from "Context";
import { useEffect } from "react";

export const userDetails = signal()
export const headerVariant = signal("default")
export const isCandidate = computed(() => userDetails?.value?.userType === "CANDIDATE")

effect(() => {
  if (isCandidate.value) headerVariant.value = "default"
})

export const SetUserDetails = () => {
  const { userDetails: userContext } = useAuthState();
  useEffect(() => {
    userDetails.value = userContext
  }, [userContext])
  return null
}