import { Button } from 'components/Button';
import {
  clearVacancyResponse,
  setSelectedVacancy,
} from "store/reducers/vacancyReducer";
import { DropdownInput } from 'components/form'
import { LoadingComponent } from "components/LoadingComponent";
import { NewSectionHeading } from "components/Headings/NewSectionHeading";
import { resetSearch, selectSearchResults } from "store/reducers/searchReducer";
import { SearchPanel } from "components/SearchPanel";
import { Seeall } from "components/Seeall";
import { useDispatch } from "react-redux";
import { useGetJobsQuery } from "store/userAPI";
import { useNavigate } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import { useSelector } from "react-redux";
import heroImage from "images/hero.png";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

import { motion } from "framer-motion";

import { Icon } from "components/Icon";

import {
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { SlickSlider } from "components/VideoSlider/SlickSlider";
import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";

export const JobBoard = () => {
  const [sortedData, setSortedData] = useState([]);
  const [shuffledData, setShuffledData] = useState([]);
  const [industryList, setIndustryList] = useState([]);

  const options = ["Sort by date", "Sort by salary"];
  const [sortType, setSortType] = useState(options[0])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const action = () => (
    <DropDownWrapper>
      <DropdownInput
        useFirstOption={true}
        name="sort"
        fullWidth={size.isMdUp ? false : true}
        options={options}
        onClick={changeSortType}
        type="alt"
        bg={"rgba(0,0,0,0.4)"}
      />
    </DropDownWrapper>
  );

  const size = useResponsiveLayout();

  const { data: jobs = [], isLoading: jobsLoading } = useGetJobsQuery();

  /* Search */
  const searchItemsPerPage = 10
  const [currentSearchItems, setCurrentItems] = useState([]);
  const [searchPageCount, setSearchPageCount] = useState(0);
  const [searchItemOffset, setSearchItemOffset] = useState(0);
  const searchResultsStore = useSelector(selectSearchResults);
  const [searchResults, setSearchResults] = useState([])

  /** Sort search results in local state */
  useEffect(() => {
    if (!searchResultsStore) return
    let sorted = []
    if (sortType === "Sort by salary") {
      sorted = [...searchResultsStore].sort((a, b) => b?.salary?.end - a?.salary?.end);
    } else if (sortType === "Sort by date") {
      sorted = [...searchResultsStore].sort((a, b) => {
        return a.updatedAt > b.updatedAt
          ? -1
          : a.updatedAt < b.updatedAt
            ? 1
            : 0
      });
    }
    setSearchResults(sorted)
  }, [searchResultsStore, sortType])

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      // Fetch items from another resources.
      const endOffset = searchItemOffset + searchItemsPerPage;
      console.log(`Loading items from ${searchItemOffset} to ${endOffset}`);
      setCurrentItems(searchResults.slice(searchItemOffset, endOffset));
      setSearchPageCount(Math.ceil(searchResults.length / searchItemsPerPage));
    }
  }, [searchItemOffset, searchItemsPerPage, searchResults]);

  /* /Search */

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * searchItemsPerPage) % searchResults.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setSearchItemOffset(newOffset);
  };

  const data = Array.isArray(jobs)
    ? jobs?.filter(
      (vacancy) =>
        vacancy?.videoURL &&
        vacancy?.title &&
        vacancy?.industry
    )
    : [];

  const changeSortType = (value) => {
    setSortType(value)
    if (value === "Sort by salary") {
      setSortedData([...data].sort((a, b) => b.salary?.end - a.salary?.end));
    } else if (value === "Sort by date") {
      setSortedData([...data].sort((a, b) => {
        // Not all vacancies have createdAt. If it doesn't,get it from the ID
        const aCreatedAt = a.createdAt ? new Date(a.createdAt) : new Date(parseInt(a.id.toString().substring(0, 8), 16) * 1000)
        const bCreatedAt = b.createdAt ? new Date(b.createdAt) : new Date(parseInt(b.id.toString().substring(0, 8), 16) * 1000)
        return aCreatedAt === bCreatedAt ? 0 : aCreatedAt > bCreatedAt ? -1 : 1
      }));

    }
  };

  const viewJobPost = (item) => {
    dispatch(clearVacancyResponse());
    dispatch(setSelectedVacancy(item));
    navigate(`/job-board/post/${item?.id}`);
  };

  const seeJobs = (type, path) => {
    if (path) {
      navigate(`/job-board/category/${path}`);
    } else {
      navigate(`/job-board/category/${type ? type : "general"}`);
    }
  };

  const arrayGrouper = (array) =>
    array.reduce((result, obj) => {
      (result[obj["industry"]] = result[obj["industry"]] || []).push(obj);
      return result;
    }, {});

  useEffect(() => {
    !!data.length && changeSortType("Sort by date");
    setIndustryList([...Object.keys(arrayGrouper(data))].sort());
  }, [jobsLoading]);

  const initialProperty = { scale: 1, zIndex: 0 };
  const transitProperty = {
    type: "spring",
    stiffness: 700,
    damping: 30,
    duration: 0.2,
  };
  const animateProperty = { rotate: 0, scale: 1, zIndex: 1, opacity: 1 };
  const whileHoverProperty = {
    scale: size.isMdUp ? 1.2 : 1,
    translateY: size.isMdUp ? '-50px' : '-10px',
    zIndex: 1,
    rotate: 0,
    transition: transitProperty,
  };

  const Card = (data) => {
    return (
      <motion.div
        key={data.id}
        className="rounded-3xl relative  group  hover:bg-gradient-to-b  hover:from-main/50  hover:via-main/75 hover:shadow-2xl hover:shadow-main/50 hover:to-main  to transition-colors duration-700   z-10 hover:z-20 shadow-2xl shadow-transparent h-auto  "
        initial={initialProperty}
        animate={animateProperty}
        whileHover={whileHoverProperty}
        transition={transitProperty}
      >
        <VideoCardJobBoard
          action
          type="play"
          width={'100%'}
          height={'auto'}
          aspectRatio={'4/3'}
          thumbnail={data.thumbnail}
          videoData={data.videoURL}
        />
        <div className="overflow-hidden flex basis-3/4 justify-between px-6 py-5 sm:rounded-lg">
          <div className="bg-transparent text-left">
            <h3
              onClick={() => viewJobPost(data)}
              className="text-2xl font-medium leading-6 text-gray-100
          underline underline-offset-1"
            >
              {data.title}
            </h3>
            <p className="mt-1 max-w-2xl text-xl text-gray-300">
              {data.companyName}
            </p>

            <p className="text-primary font-light text-xl">
              {`£ ${data.salary.start}`}
              {' - '}
              {`£ ${data.salary.end}`}
            </p>
            <p className="text-primary text-xl">
              <Icon icon={faGlobe} className="mr-2 text-primary text-sm" />
              {data.location || data.address || "N/A"}
            </p>
          </div>
          <div className="basis-1/4 text-right">
            <button
              onClick={() => viewJobPost(data)}
              className=" border px-2 py-1 whitespace-nowrap hover:bg-white hover:text-gray-800 transition-all duration-200  text-white  m-auto rounded-lg inline  text-lg font-medium"
            >
              View Details
            </button>
          </div>
        </div>
      </motion.div>)
  }

  const PostCard = ({ data, path }) => {
    // Slice the array to the desired size, then map over the contents. Don't map over everytthing and only render `if index < 0` since you're still iterating the entire list
    return [...data?.slice(0, 9).map((i, index) => {
      if (i?.videoURL)
        return Card(i);
      return null

    }), <Seeall key="seeAll" onClick={() => seeJobs("latest%20vacancies", path)} />];
  };


  /** UseEffect to shuffle for top picks */
  useEffect(() => {
    let array = [...sortedData]
    let currentIndex = sortedData.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    setShuffledData(array);
  }, [sortedData])


  if (jobsLoading) return <LoadingComponent />;

  return (
    <>
      <Container size={size}>
        <Wrapper size={size}>
          <Headers className="group transition-transform duration-300">
            <NewSectionHeading
              title="Job Board"
              actions={action()}
              className=" flex items-center justify-between"
            />
          </Headers>
          <hr />
          <SearchPanel />
        </Wrapper>
        {
          searchResults && searchResults.length ? (
            <div>
              <Name size={size}>{searchResults.length} search result{searchResults.length > 1 ? 's' : ''}</Name>
              <SearchResults>
                {
                  currentSearchItems?.map(result => {
                    return Card(result)
                  })
                }
              </SearchResults>
              <SearchFooter size={size}>
                <ButtonGroup size={size}>
                  <Button
                    button
                    fullWidth={!size.isMdUp}
                    type="alt"
                    size="medium"
                    onClick={() => {
                      dispatch(resetSearch())
                    }}
                  >
                    Clear search
                  </Button>
                </ButtonGroup>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={searchPageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  containerClassName={
                    "pagination large"
                  } /* as this work same as bootstrap class */
                  subContainerClassName={
                    "pages pagination large"
                  } /* as this work same as bootstrap class */
                  activeClassName={"active"} /* as this work same as bootstrap class */

                />

              </SearchFooter>
            </div>
          ) : (
            <>
              <Name size={size}>Latest Vacancies</Name>
              <SlickSlider
                data={sortedData}
                Card={() => PostCard({ data: sortedData, path: "latest vacancies" })}
                path={"latest vacancies"}
              />
              <Name size={size} top>
                Top Picks
              </Name>
              <SlickSlider data={shuffledData} Card={PostCard} />
              {
                industryList.map((industry, index) => {
                  const jobsOfIndustry = [...sortedData].filter(
                    (job) => industry === job?.industry
                  );

                  if (jobsOfIndustry.length <= 4) {
                    return null;
                  } else {
                    return (
                      <div key={index + industry}>
                        <Name size={size}>{industry}</Name>
                        <SlickSlider
                          data={jobsOfIndustry}
                          Card={PostCard}
                        />
                      </div >
                    );
                  }
                })}
            </>
          )}
      </Container >
    </>
  );
};

const Container = styled.div(
  ({ size }) => `
  overflow-x: hidden;
  max-width: 100vw;
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100vw;
  background-image: linear-gradient(180.5deg, transparent, rgba(196, 196, 196, 0) 60%), url(${heroImage});
& hr {
    display : ${size.isMdUp ? "block" : "none"};
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 30px;
}
`
);

const Wrapper = styled.div(
  ({ size }) => `
  // padding-right: ${size.isMdUp ? "122px" : "20px"};
`
);

const Headers = styled.div`
  padding-top: 40px;
  & input {
    background-color: rgba(0, 0, 0, 0.44);
  }
`;
const Name = styled.h2(
  ({ size, top }) => `
  font-size: ${size.isMdUp ? "40px" : "26px"};
  margin-top: 60px;
  font-weight: 800;
  color: white;
  text-transform: uppercase;
  margin-bottom: ${top ? "0px" : "15px"};
`
);
const DropDownWrapper = styled.div`
  width: 220px;
`;


const SearchResults = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-bottom: 32px;
`

const SearchFooter = styled.div(({ size }) => `
  display: grid;
  grid-template-columns: ${size.isLgUp ? "auto auto" : "1fr"};
  align-items: center;
  justify-content: space-between;
  justify-items: center;
`)

const ButtonGroup = styled.div(({ size }) => `
  display: flex;
  gap: 10px;
  flex-flow: ${size.isSmUp ? 'row' : 'column'};
  justify-content: center;
  width: 100%;
`)