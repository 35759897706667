import * as Yup from 'yup'
import {
  addTeamMember,
  getCompanyMembers,
  updateTeamMember,
  useAuthDispatch,
  useAuthState,
} from 'Context'
import { FormikProvider } from 'formik'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import { Button } from 'components/Button'
import { Colors } from 'styles/Colors'
import { FormikTextField, RadioComponent } from 'components/form'
import { SectionHeading } from 'components/Headings/SectionHeading'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout'

export const AddTeamMemberScreen = React.memo(() => {
  const navigate = useNavigate()

  const size = useResponsiveLayout()
  const [status, setStatus] = useState('')
  const [member, setMember] = useState({})

  const { userId } = useParams()
  const dispatch = useAuthDispatch()

  const { companyMembers } = useAuthState() || {}

  useEffect(() => {
    if (!companyMembers.length) {
      getCompanyMembers(dispatch)
    }
    console.log(companyMembers)
    if (userId) {
      const member = companyMembers.filter(
        (member) => member?.user?.id === userId,
      )[0]
      setMember(member)
      setStatus(member?.role)
    }
  }, [userId, dispatch, companyMembers])

  const validationSchema = Yup.object().shape({
    user: Yup.object().shape({
      email: Yup.string()
        .required('Email required')
        .email('Please enter a valid email'),
      position: Yup.string().required('Position required'),
      department: Yup.string().required('Department required'),
    }),
  })

  const skipAddTeamMember = () => {
    navigate('/employer/company/addteammembersuccess')
  }

  const onSubmit = async () => {
    validateForm()

    if (!isValid) return alert('Please complete form')
    const payload = {
      email: values.user.email,
      position: values.user.position,
      department: values.user.department,
      role: status,
    }

    if (!member?.user) {
      try {
        let response = await addTeamMember(dispatch, payload)
        if (!response) return
        if (response.error) {
          return alert(response.message)
        } else {
          navigate('/employer/company/addteammembersuccess')
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        let response = await updateTeamMember(
          dispatch,
          member?.user?.id,
          payload,
        )

        if (!response) return
        if (response.error) {
          return alert(response.message)
        } else {
          navigate('/employer/company/addteammembersuccess')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const formikbag = useFormik({
    initialValues: {
      user: {
        email: member?.user?.email || '',
        position: member?.position || '',
        department: member?.department || '',
      },
    },
    onSubmit,
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
  })

  const { values, validateForm, isValid } = formikbag

  return (
    <Container size={size}>
      {size.isMdUp ? (
        <>
          <SectionHeading companyName />
          <hr />
        </>
      ) : null}

      <Wrapper size={size}>
        <FormContainer size={size}>
          <Header>{!member?.user ? 'ADD' : 'EDIT'} TEAM MEMBER</Header>
          <FormikProvider value={formikbag}>
            <FormikTextField
              placeholder="Email Address"
              fullWidth
              Container={TextInputContainer}
              type="email"
              name="user.email"
              disabled={!!member?.id}
            />
            <FormikTextField
              placeholder="Position"
              fullWidth
              Container={TextInputContainer}
              name="user.position"
            />
            <FormikTextField
              placeholder="Department"
              fullWidth
              Container={TextInputContainer}
              name="user.department"
            />
            <Spacer />
            <RadiosContainer size={size}>
              <RadioComponent
                size={size}
                item="Admin"
                value="ADMIN"
                onClick={setStatus}
                selected={status === 'ADMIN'}
                data-tip
                data-for="Admin"
                data-event="hover"
              />
              <RadioComponent
                size={size}
                item="Hiring Manager"
                value="HRM"
                onClick={setStatus}
                selected={status === 'HRM'}
                data-tip
                data-for="Hiring Manager"
                data-event="hover"
              />
              <RadioComponent
                size={size}
                item="Team Manager"
                value="TM"
                onClick={setStatus}
                selected={status === 'TM'}
                data-tip
                data-for="Team Manager"
                data-event="hover"
              />
            </RadiosContainer>
            <Spacer />
            <Button button fullWidth onClick={onSubmit} disabled={!isValid}>
              {!member?.user ? 'Add Team Member' : 'Save'}
            </Button>
            <Spacer />
            <Button button fullWidth type="alt" onClick={skipAddTeamMember}>
              Skip
            </Button>
          </FormikProvider>
        </FormContainer>
        <ReactTooltip id="Admin" place="top" effect="solid">
          Full Access
        </ReactTooltip>
        <ReactTooltip id="Hiring Manager" place="top" effect="solid">
          No access to billing or to change company information
        </ReactTooltip>
        <ReactTooltip id="Team Manager" place="top" effect="solid">
          Can only view applicants to roles/the team they are in
        </ReactTooltip>
      </Wrapper>
    </Container>
  )
})

const Container = styled.div(
  ({ size }) => `
  padding-bottom: 80px;
 & > div{
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
 }
 & hr {
   border: 1px solid #333333;
 }
  `,
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: auto;
  width: 100%;
  & button {
    font-weight: 600;
    font-size: 18px;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
`

const Header = styled.h1`
  line-height: 26px;
  font-weight: 700;
  font-size: 18px;
  color: ${Colors.white};
  margin-top: 50px;
  margin-bottom: 50px;
`

const TextInputContainer = styled.div`
  width: 100%;
`

const RadiosContainer = styled.div(
  ({ size }) => `
  display: flex;
  width: 100%;
  gap: 16px;
  flex-direction: ${size.isSmUp ? 'row' : 'column'};
  align-items:${size.isSmUp ? 'center' : 'flex-start'};
  justify-content: space-between;
  & div{
    margin-right: 10px;
    margin-bottom: 0;
    align-items: center;
 
    & input {
     margin: 0px;
    }
   }
`,
)

const Spacer = styled.div`
  height: 26px;
`
