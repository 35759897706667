import { createVideoView, updateVideoView } from 'Context/actions';
import { ReadMore } from "components/ReadMore";
import { useAuthState } from 'Context'
import { VideoPlaceholder } from 'components/VideoPlaceholder'
import { VideoReducer } from './VideoReducer'
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import styled from 'styled-components'

// How often the timed stats are recalculated
const ACTIVITY_TICK = 1000
// How often to throw stats at the backend (replace with websockets later)
const DB_UPDATE_TICK = 5000
// Debounce seek events
const SEEK_DEBOUNCE_TICK = 2000

function VideoPlayer(
  {
    crossOrigin,
    debug,
    gatherStats,
    poster,
    preload,
    style,
    trackSrc,
    type,
    userData,
    videoData,
    videoId,
    width,
  }
) {

  const auth = useAuthState()

  // Ref for the skip debounce timer
  const skipDebounceTimer = useRef()
  // Used to prevent duplicate backend requests due to rerenders and recreation of the interval
  const backendUpdateInterval = useRef()

  const [error, setError] = useState()
  const [hasPlayed, setHasPlayed] = useState(false)
  const [viewId, setViewId] = useState(false)
  const [uData, setUData] = useState({})

  const player = useRef();

  // Stats
  const [stats, dispatch] = useReducer(VideoReducer, {
    activeTime: 0,
    blurTime: 0,
    duration: 0,
    focusChangeTime: new Date().valueOf(),
    focusChanges: [],
    hasFocus: true,
    lastActivity: new Date().valueOf(),
    position: 0,
    skips: [],
    skippedSeconds: 0,
    state: 'inactive',
    volume: 1,
    volumeTime: 0,
    playTime: 0,
    watchNumber: 1,
  })

  const statsRef = useRef(stats)

  /**
   * Video loaded event handler.
   * Fires when video stream is loaded and updates state with video metadata (duration, created time etc).
   */
  const handleLoaded = (e) => {
    console.log('%cLoaded', 'background: lightGreen')
    dispatch({
      type: 'LOADED',
      payload: {
        duration: e.target.duration,
        volume: e.target.volume
      },
    })
  }

  /**
   * Video playing event handler.
   * Fires when video is played.
   */
  const handlePlaying = async (_) => {
    console.log('%cPlaying', 'background: lightGreen')
    setHasPlayed(true)
    dispatch({
      type: 'PLAYING',
    })
  }

  /**
   * Video paused event handler.
   * Fires when video is paused].
   */
  const handlePaused = (_) => {
    console.log('%cPaused', 'background: lightGreen')
    dispatch({
      type: 'PAUSED',
    })
  }

  /**
   * Video seek event handler.
   * Fires when user seeks the video.
   * Debounced to prevent hundreds of events firing when you drag the seek bar.
   * This is an imperfect solution - ideally we could listen to mouse events to figure out when the seek starts and ends.
   * Although this worked beautifully for Ziggeo, it seems at least Chromium based browsers do not fire mouse events when the seek bar is interacted with.
   * We would have to build a custom seek bar instead, which is out of scope at this time.
   * Perhaps later we can build a video player UI ourselves and then we can listen to whatever we want.
   */
  const handleSeek = (e) => {

    // Some browsers fire a seek event on initial play, which is infuriating
    // Filter these out by checking for playTime and number of skips.
    // If we've not played and the seek target is 0, then we can infer this is an initial seek
    // We need to take the debounce timer in to account here rather than "0"
    // We need to remember most if not all videos preload at 0.1s rather than 0.
    // Again. Imperfect solution.
    if (stats.playTime <= SEEK_DEBOUNCE_TICK && e.target.currentTime <= 0.1) {
      console.log('%cFiltering initial skip event', 'background: yellow')
      return
    }
    const startTime = stats.position

    // Debounce
    clearTimeout(skipDebounceTimer.current);
    skipDebounceTimer.current = setTimeout(() => {
      // If we're playing, subtract the debounce period from the timestamp we send over for a more accurate position.
      // As mentioned this is an imperfect solution - this can sometimes be false when it should be true, but there's little we can do since its a race condition issue.
      const isPlaying = stats.state === 'playing'
      // Remember currentTime is in seconds, debounce timer is in ms.
      const actualTime = isPlaying ? e.target.currentTime - (SEEK_DEBOUNCE_TICK / 1000) : e.target.currentTime
      // Clamp this value to min 0, max duration. Due to various reasons, this value could be:
      // - Negative if skipped back to 0
      // - Greater than the duration if skipped to less than debounce period before the end
      // This does mean that all skip events that are close to the end will report as "the end" but its the best we've got for now.
      const clampedTime = Math.min(Math.max(actualTime, 0), stats.duration)
      console.log(`%cSeek ${clampedTime}`, 'background: lightGreen')
      dispatch({
        type: 'SEEK',
        payload: { from: startTime, to: clampedTime },
      });
    }, SEEK_DEBOUNCE_TICK);
  }

  /**
   * Video volume event handler.
   * Fires when user changed volume.
   */
  const handleVolumeChange = (e) => {
    console.log(`%cVolume ${e.target.muted ? 'Muted' : e.target.volume}`, 'background: lightGreen')
    dispatch({
      type: 'VOLUME',
      payload: { value: e.target.muted ? 0 : e.target.volume },
    })
  }

  /**
   * Video ended event handler.
   * Fires when video ends.
   */
  const handleEnded = async (_) => {
    console.log('%cEnded', 'background: lightGreen')
    // Update the backend
    await updateBackend()
    // Clear `viewId` and `hasPlayed` so that a new view is created
    setHasPlayed(false)
    setViewId(null)
    dispatch({ type: 'ENDED' })
  }

  /**
   * Error handler for loading a video
   * Used to provide a more graceful message on failure
   * If the video resides on our own recordings bucket but we get a 404, we can infer that we're waiting on a transcode
   * Any errors from another source should show a generic error
   * 
   * This hanlder also fires if the thumb fails to load - which is entirely possible.
   * React isn't providing the error detail in `e.target.error`, which is infuriating.
   * We can instead attempt to infer based on the `event.target.nodeType`, if it's `SOURCE` then we can assume the actual video failed
   * If its `VIDEO` (not `SOURCE`) then can assume it was the thumbnail and strip it out
  */
  const onError = async (e) => {
    if (e.target.nodeName === "SOURCE") {
      console.error(`Failed to load video ${videoData}`)
      // If `videoData` includes "http" this is a 3rd party video. If it doesn't, this is one of ours.
      const isRecording = videoData.toString().toLowerCase().indexOf('http') ===
        -1
      setError(isRecording ? `This video is still being processed. Please check back soon` : `Something went wrong with this video`)
    } else {
      console.warn("Non-source error, stripping thumbnail")
      player.current.removeAttribute('poster')
      player.current.preload = 'metadata'
    }
  }

  /**
   * Call to update the backend with the current stats
   * Will create a new view or update existing based on whether `viewId` is set
   */
  const updateBackend = useCallback(
    async () => {

      // Prepare the payload for the backend - we don't want all props as we track more locally here
      const sanitizedStats = {
        activeTime: statsRef.current.activeTime,
        blurTime: statsRef.current.blurTime,
        duration: statsRef.current.duration,
        focusChanges: statsRef.current.focusChanges,
        playTime: statsRef.current.playTime,
        skips: statsRef.current.skips,
        skippedSeconds: statsRef.current.skippedSeconds,
        volumeTime: statsRef.current.volumeTime,
        watchNumber: statsRef.current.watchNumber,
      }
      if (viewId) {
        console.log(`%cUpdating existing view ${viewId}`, 'background: pink')
        await updateVideoView({ id: viewId, ...sanitizedStats })
      } else {
        console.log(`%cCreating new view`, 'background: hotpink')
        const sanitizedUData = {
          vacancyId: uData.vacancyId,
          applicationId: uData.id,
          candidateId: uData.candidateId,
          companyId: uData.companyId,
          userId: uData.userId,
          videoId: videoId,
        }
        const res = await createVideoView({ ...sanitizedUData, ...sanitizedStats });
        const viewId = res.id
        console.log(`%cCreated view ${res.id}`, 'background: hotpink')
        setViewId(viewId)
      }
    },
    [viewId, uData, videoId],
  )

  /**
   * If present, set user data (company id, vacancy id etc) on load
   */
  useEffect(() => {
    if (gatherStats) {
      console.log("%cSetting user data", 'background: aqua')
      console.log(userData)
      setUData({ userId: auth.userDetails.id, ...userData })
    }
  }, [auth.userDetails.id, gatherStats, userData])


  /**
   * Init timer and bind event listeners for focus and mouse events
   */
  useEffect(() => {
    if (gatherStats) {
      /**
       * Start a timer so we can track time-based metrics (via the reducer), such as:
       * - Active time (how long we're on this screen for).
       * - Play time.
       * - Time spent out of focus.
       * - Time spent below minimum volume threshold.
       *
       * Ensure `clearInterval(activityIntervalId)` is returned from this `useEffect(...)` hook.
       */
      const activityIntervalId = setInterval(() => {
        dispatch({
          type: 'TIMED_STATS_TICK',
          payload: { value: ACTIVITY_TICK },
        })
      }, ACTIVITY_TICK)

      /**
       * Focus handler for when window regains focus.
       */
      const handleFocus = () => {
        const now = new Date()
        console.log(`%cFocus in at ${now}`, 'background: lightGreen')
        dispatch({ type: 'FOCUS' })
      }

      /**
       * Blur handler for when window loses focus.
       */
      const handleBlur = () => {
        const now = new Date()
        console.log(`%cFocus out at ${now}`, 'background: lightGreen')
        dispatch({ type: 'BLUR' })
      }

      // Bind event listeners
      window.addEventListener('focus', handleFocus)
      window.addEventListener('blur', handleBlur)

      // Kill event listeners and timers on unmount
      return () => {
        window.removeEventListener('focus', handleFocus)
        window.removeEventListener('blur', handleBlur)
        clearInterval(activityIntervalId)
      }
    }
  }, [gatherStats])


  /**
   * We require the current playback position in real (ish) time rather than updating it on specific events.
   */
  useEffect(() => {
    if (gatherStats && player.current) {
      const intervalId = setInterval(() => {
        const position = player.current.currentTime
        dispatch({
          type: 'POSITION',
          payload: { value: Number(position) },
        })
      }, 500)
      return () => clearInterval(intervalId)
    }
  }, [gatherStats, player])

  /**
   * Throw our data at the server every `DB_UPDATE_TICK` seconds
  */
  useEffect(() => {
    // Kill and recreate the timer each time this useEffect hook fires
    clearTimeout(backendUpdateInterval.current);
    if (gatherStats && hasPlayed) {
      backendUpdateInterval.current = setInterval(() => {
        updateBackend()
      }, DB_UPDATE_TICK);
    }

    return () => {
      clearInterval(backendUpdateInterval.current);
    }
  }, [gatherStats, hasPlayed, updateBackend])

  /**
   * Update stats ref on each stat update
   * We use the ref to avoid rerenders
   */
  useEffect(() => {
    statsRef.current = stats
  }, [stats])


  return (
    error ? <VideoPlaceholder style={style}>{error}</VideoPlaceholder> :
      <div className="App">
        <video
          ref={player}
          onEnded={!gatherStats ? null : handleEnded}
          onError={onError}
          onLoadedData={!gatherStats ? null : handleLoaded}
          onPause={!gatherStats ? null : handlePaused}
          onPlay={!gatherStats ? null : handlePlaying}
          onSeeked={!gatherStats ? null : handleSeek}
          onVolumeChange={!gatherStats ? null : handleVolumeChange}

          crossOrigin={crossOrigin}
          width={width}
          style={style}
          poster={poster}

          controls

          playsInline
          preload={preload}
          autoPlay={false}
        >
          <source
            src={videoData}
            type={type}
          >
          </source>

          {
            trackSrc && (
              <track
                label="English"
                kind="subtitles"
                srcLang="en"
                src={trackSrc}
                default
              />
            )
          }

          Your browser does not support the video tag.
        </video>

        {debug && gatherStats &&
          <ReadMore labelClosed="Show video stats (unavailable in production)" labelOpen="Hide video stats (unavailable in production)" maxHeight="0">
            <StatsWrapper>
              <div className="stats">
                <div className="stat">
                  <p>Activity time</p>
                  <p>{stats.activeTime}s</p>
                </div>
                <div className="stat">
                  <p>Last activity</p>
                  <p className="grid row space-between">
                    <span>{new Date(stats.lastActivity).toLocaleTimeString()}</span>
                    {stats.lastActivityDelta &&
                      <span>
                        𝚫 {stats.lastActivityDelta}s
                      </span>
                    }
                  </p>
                </div>
                <div className="stat">
                  <p>Player state</p>
                  <p style={{ textTransform: 'capitalize' }}>{stats.state ?? 'Unknown'}</p>
                </div>
                <div className="stat">
                  <p>Time playing</p>
                  <p className="grid row space-between">
                    <span>{`${stats.playTime}`}s </span>
                    <span>
                      (
                      {`${stats.playTime && stats.activeTime
                        ? ((stats.playTime / stats.activeTime) * 100).toFixed(2)
                        : '0'
                        }`}
                      % of {stats.activeTime}s)
                    </span>
                  </p>
                </div>
                <div className="stat">
                  <p>Playback position</p>
                  <p className="grid row space-between">
                    <span>{`${stats.position}`}s</span>
                    {stats.duration !== 0 &&
                      <span>
                        {`${(Math.round((stats.position / stats.duration) * 100))}`}% of{' '}
                        {stats.duration}s
                      </span>
                    }
                  </p>
                </div>
                <div className="stat">
                  <p>Last position change</p>
                  <p className="grid row space-between">
                    <span>
                      {stats.lastPositionChange
                        ? `${stats.lastPositionChange.from}s - ${stats.lastPositionChange.to}s`
                        : 'N/A'}
                    </span>
                    <span>
                      (𝚫 {(stats.lastPositionChangeDelta ?? 0)}s)
                    </span>
                  </p>
                </div>
                <div className="stat">
                  <p>Skipped</p>
                  <p className="grid row space-between">
                    <span>{stats.skippedSeconds}s</span>
                    <span>
                      ({stats.duration ? ((stats.skippedSeconds / stats.duration) * 100).toFixed(2) : 0}%
                      of {stats.duration}s - {stats.skips.length} skips)
                    </span>
                  </p>
                </div>
                <div className="stat">
                  <p>Volume</p>
                  <p>{(stats.volume * 100)}%</p>
                </div>
                <div className="stat">
                  <p>
                    Time volume {'<='}{' '}
                    {process.env.REACT_APP_VOLUME_THRESHOLD_PERCENT}%
                  </p>
                  <p className="grid row space-between">
                    <span>{stats.volumeTime}s</span>
                    <span>
                      (
                      {stats.volumeTime && stats.duration
                        ? ((stats.volumeTime / stats.playTime) * 100).toFixed(2)
                        : 0}
                      % of {stats.playTime}s)
                    </span>
                  </p>
                </div>
                <div className="stat">
                  <p>Time out of focus</p>
                  <p className="grid row space-between">
                    <span>{stats.blurTime}s</span>
                    <span>
                      (
                      {stats.blurTime && stats.activeTime
                        ? ((stats.blurTime / stats.playTime) * 100).toFixed(2)
                        : 0}
                      % of {stats.playTime}s)
                    </span>
                  </p>
                </div>
                <div className="stat">
                  <p>Last focus change</p>
                  <p className="grid row space-between">
                    <span>
                      {new Date(stats.focusChangeTime).toLocaleTimeString()}
                    </span>
                    <span>
                      ({stats.hasFocus ? 'in' : 'out'} - {stats.focusChanges.length}{' '}
                      changes)
                    </span>
                  </p>
                </div>
                <div className="stat">
                  <p>Duration</p>
                  <p>
                    {stats.duration ? `${stats.duration}s` : 'Unknown'}
                  </p>
                </div>
                <div className="stat">
                  <p>Watch number</p>
                  <p>{stats.watchNumber}</p>
                </div>
              </div>
            </StatsWrapper>
          </ReadMore>
        }
      </div >
  )
}



export default VideoPlayer;


const StatsWrapper = styled.div`
// The use of !important is gross - but not much we can do due to existing !important that we need to override
--base-spacing: 8px;
--border-radius: 16px;
--color-bg: rgba(4, 5, 5, 1);
--color-bg-alt: rgba(16, 16, 17, 1);
--color-text: #FFF;

margin-top: var(--base-spacing)!important;

div, span, p {
  margin: 0!important;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  color: var(--color-text)!important;
  font-weight: normal!important;
  font-size: 14px!important;
}

.stats {
  display: grid;
  gap: var(--base-spacing);
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.stat {
  display: grid;
  grid-auto-flow: column;
  gap: 0;
  grid-template-columns: 180px 1fr;
  background: var(--color-bg);
  border-radius: var(--border-radius);
  overflow: hidden;

  > :not(:first-child) {
    background: var(--color-bg-alt);
    font-weight: bold;
    height: 100%;
  }
  > * {
    display: grid;
    gap: var(--base-spacing);
    padding: calc(var(--base-spacing) * 2);
    align-items: center;
  }
}`