import 'react-toastify/dist/ReactToastify.css'

import { AboutCompany } from 'pages/Employer/Company/AboutCompany'
import { AccessDenied } from 'pages/AccessDenied'
import { AddTeamMemberScreen } from 'pages/Employer/Company/AddTeamMember'
import { AddTeamMemberSuccess } from 'pages/Employer/Company/AddTeamMemberSuccess'
import { ApplicationCongratulations } from 'pages/JobBoard/ApplicationPreview/ApplicationCongratulations'
import { ApplicationPreview } from 'pages/JobBoard/ApplicationPreview/ApplicationPreview'
import { ApplicationVideo } from 'pages/Candidate/ApplicationVideo/ApplicationVideo'
import { AuthProvider, useAuthState } from 'Context'
import { AuthScreen } from 'pages/Employer/Account/AuthPage'
import { AuthScreenCandidate } from 'pages/Candidate/Account/Auth'
import { BillingInformation } from 'pages/Employer/Company/BillingInformation'
import { CandidateDashboard } from 'pages/Candidate/Dashboard'
import { CandidateProfileManage } from 'pages/Candidate/CandidateProfileManage/CandidateProfileManage'
import { CandidateProfilePage } from 'pages/Employer/Dashboard/CandidateProfile/CandidateProfile'
import { Congrats } from 'components/Step/Congrats'
import { CreateVacancy } from 'pages/Employer/CreateRole/CreateVacancy'
import { Dashboard } from 'pages/Employer/Dashboard/Dashboard'
import { EmailVerification } from 'pages/Employer/Account/Verification/EmailVerification'
import { EmailVerified } from 'pages/Employer/Account/Verification/EmailVerified'
import { Faqs } from 'pages/Employer/Dashboard/Faqs'
import { FirstJob } from 'pages/Employer/CreateRole/FirstJob'
import { ForgotPassword } from 'pages/Employer/Account/ForgotPassword'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { HotjarProvider } from 'Context/hotjar'
import { JobBoard } from 'pages/JobBoard/JobBoard'
import { JobPost } from 'pages/JobBoard/JobPost/JobPost'
import { Journey } from 'pages/Candidate/Journey/Journey'
import { LoadingComponent } from 'components/LoadingComponent'
import { ManageProfile } from 'pages/Employer/Account/ManageProfile'
import { MessagePage } from 'pages/Employer/Dashboard/Messages'
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom'
import { PageLayout } from 'components/PageLayout'
import { PasswordReset } from 'pages/Employer/Account/PasswordReset'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from './store/store'
import { Provider } from 'react-redux'
import { RecorderTest } from 'pages/RecorderTest'
import { RecordVacancyVideo } from 'pages/Employer/CreateRole/RecordVacancyVideo'
import { ScrollToTop } from 'components/ScrollToTop'
import { SeeAllJobs } from 'pages/JobBoard/SeeAllJobs'
import { ShortlistPage } from 'pages/Employer/Dashboard/Shortlist/Shortlist'
import { SignupStep2 } from 'pages/Employer/Account/SignupStep2'
import { store } from './store/store'
import { TawkGateway } from 'Context/tawk'
import { UploadVideo } from 'pages/Employer/Company/UploadVideo'
import { VacancyPage } from 'pages/Employer/Dashboard/VacancyCandidates'
import { VacancyPreview } from 'pages/Employer/CreateRole/VacancyPreview/VacancyPreview'
import { VideoChat } from 'pages/Employer/Dashboard/VideoChat/VideoChat'
import { WindowDimensionsProvider } from 'components/WindowDimensionsProvider'
import en from 'javascript-time-ago/locale/en.json'
import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import TimeAgo from 'javascript-time-ago'

import { UploadCandidateVideo } from 'pages/Candidate/MyVideos/UploadCandidateVideo'

import { IntroMessage } from 'pages/Candidate/Account/introMessage'
import { RecordCandidateVideo } from 'pages/Candidate/MyVideos/RecordCandidateVideo'

import { ProgressSavedMessage } from 'pages/Candidate/Account/progressSavedMessage'
import { SelectVideoSource } from 'pages/Employer/CreateRole/SelectVideoSource'
TimeAgo.addDefaultLocale(en)

const RequireAuth = ({ candidate }) => {
  let auth = useAuthState()
  let location = useLocation()

  const to = candidate ? '/candidate/account' : '/employer/account'

  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={to} state={{ from: location.pathname }} replace />
  }

  return <Outlet />
}

const AppRoutes = () => {
  // const dispatch = useAuthDispatch();
  // const { token } = useAuthState();

  // useEffect(() => {
  //   !!token && getUser(dispatch );
  // }, [token, dispatch]);

  return (
    <>
      <Routes>
        <Route element={<PageLayout />}>
        <Route path="/recorder" element={<RecorderTest />} />
          {/* Employer */}
          {/* account */}
          <Route path="/employer/account" element={<AuthScreen />} />
          <Route path="/verify" element={<EmailVerification />} />
          <Route path="/verified" element={<EmailVerified />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route element={<RequireAuth candidate={false} />}>
            <Route
              path="/employer/manage-profile"
              element={<ManageProfile />}
            />
            <Route
              path="/employer/company/billing-information"
              element={<BillingInformation />}
            />
            {/* Role */}
            <Route
              path="/employer/company/vacancy/create"
              element={<CreateVacancy />}
            />
            <Route
              path="/employer/company/vacancy/edit/:vacancyId"
              element={<CreateVacancy />}
            />
            <Route
              path="/employer/vacancy/create/videorecorder/:vacancyId"
              element={<RecordVacancyVideo />}
            />
            <Route
              path="/employer/company/preview-vacancy/:vacancyId"
              element={<VacancyPreview />}
            />
            <Route
              path="/employer/company/vacancy/edit/:vacancyId/select-video-source/"
              element={<SelectVideoSource />}
            />
            <Route path="/employer/company/first-job" element={<FirstJob />} />

            {/* employer dashboard */}
            <Route path="/employer/dashboard" element={<Dashboard />} />
            <Route
              path="/employer/dashboard/vacancy/:vacancyId"
              element={<VacancyPage />}
            />
            <Route
              path="/employer/dashboard/vacancy/shortlist"
              element={<ShortlistPage />}
            />
            <Route
              path="/employer/dashboard/vacancy/candidate/:applicationId"
              element={<CandidateProfilePage />}
            />
            <Route path="/employer/dashboard/vacancy/faqs" element={<Faqs />} />
          </Route>

          <Route element={<PageLayout variant="fullWidth" />}>
            <Route
              path="/employer/dashboard/vacancy/message"
              element={<MessagePage />}
            />
          </Route>
        
        </Route>

        {/* Pages in Employer flow with no header */}
        <Route element={<PageLayout noHeader />}>
          <Route
            path="/employer/company/addteammember"
            element={<AddTeamMemberScreen />}
          />
          <Route
            path="/employer/company/addteammember/:userId"
            element={<AddTeamMemberScreen />}
          />
          <Route
            path="/employer/company/addteammembersuccess"
            element={<AddTeamMemberSuccess />}
          />
          <Route path="/employer/company/" element={<AboutCompany />} />
          <Route
            path="/employer/company/uploadvideo"
            element={<UploadVideo />}
          />
          <Route
            path="/employer/company/record-video"
            element={<RecordVacancyVideo />}
          />
        </Route>

        {/* Candidate */}
        <Route element={<PageLayout candidate />}>
          <Route path="/candidate/account" element={<AuthScreenCandidate />} />
          <Route element={<RequireAuth candidate={true} />}>
            <Route path="/candidate/journey" element={<Journey />} />
            <Route
              path="/job-board/candidate/application-preview"
              element={<ApplicationPreview />}
            />
            <Route
              path="/job-board/candidate/congratulations"
              element={<ApplicationCongratulations />}
            />
            <Route path="/candidate/congrats" element={<Congrats />} />
            <Route
              path="/candidate/application"
              element={<ApplicationVideo />}
            />
            <Route
              path="/candidate/application/:index"
              element={<ApplicationVideo />}
            />
            <Route
              path="/candidate/dashboard"
              element={<CandidateDashboard />}
            />

            <Route
              path="/candidate/manage-profile"
              element={<CandidateProfileManage />}
            />
            <Route
              path="/candidate/uploadvideo"
              element={<UploadCandidateVideo />}
            />
            <Route
              path="/candidate/record-video"
              element={<RecordCandidateVideo />}
            />
            <Route
              path="/candidate/application/view/:applicationId"
              element={<CandidateProfilePage />}
            />
          </Route>
          {/* </Route> */}
          {/* Job Board */}
          <Route path="/job-board/" element={<JobBoard />} />
          <Route path="/job-board/post/:jobId" element={<JobPost />} />
          <Route path="/job-board/category/:param" element={<SeeAllJobs />} />
          <Route path="/candidate/intro-message" element={<IntroMessage />} />
          <Route path="/employer/account/step-2" element={<SignupStep2 />} />
          <Route
            path="/candidate/progress-saved"
            element={<ProgressSavedMessage />}
          />
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="*" element={<Navigate to="/job-board" />} />
        </Route>
      </Routes>
    </>
  )
}

const TITLE = 'Seesy'
const App = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.assert = () => {}
    console.warn = () => {}
  }

  /**
   * TagManager init
   */
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-KR4JM34' })
  }, [])

  return (
    <HelmetProvider>
      <PersistGate loading={<LoadingComponent />} persistor={persistor}>
        <AuthProvider>
          <HotjarProvider>
            <Provider store={store}>
              <Router>
                <ScrollToTop />
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>{TITLE}</title>
                  <meta property="og:title" content={TITLE} />
                  <meta property="og:type" content="article" />
                </Helmet>
                <TawkGateway>
                  <WindowDimensionsProvider>
                    <AppRoutes />
                  </WindowDimensionsProvider>
                </TawkGateway>
              </Router>
            </Provider>
          </HotjarProvider>
        </AuthProvider>
      </PersistGate>
    </HelmetProvider>
  )
}

export default App
