import { AuthGuard } from "components/AuthGuard";
import { Button } from "components/Button";
import { Colors } from "styles/Colors";
import { faUpload, faVideo } from "@fortawesome/free-solid-svg-icons";
import {
    faVimeo, faYoutube
} from "@fortawesome/free-brands-svg-icons";
import { Icon } from "components/Icon";
import { Modal } from "components/Modal";
import { SectionHeading } from "components/Headings/SectionHeading";
import { TextField } from "components/form";
import {
    useGetCompanyQuery,
    useGetVacancyByIdQuery,
    useUpdateVacancyMutation,
} from "store/userAPI";
import { useLogger } from "hooks/useLogger";
import { useNavigate, useParams } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import { VideoPlaceholder } from "components/VideoPlaceholder";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";

export const SelectVideoSource = React.memo(() => {

    const navigate = useNavigate();
    const size = useResponsiveLayout();
    const { logger } = useLogger()

    const { vacancyId } = useParams();

    const [updateVacancy] = useUpdateVacancyMutation();
    const { data: company } = useGetCompanyQuery({});
    const { data: vacancy } = useGetVacancyByIdQuery(
        { id: vacancyId },
        { skip: !vacancyId }
    );

    // Company unable to create vacancies flag
    const [isLocked, setIsLocked] = useState(false);

    // Method selection
    const [selectedMethod, setSelectedMethod] = useState('record');

    // Value as the user types, used to validate etc
    const [videoUrl, setVideoUrl] = useState();

    // Validated value used by the iframe
    const [checkUrl, setCheckUrl] = useState();

    // Validation
    const [isValid, setIsValid] = useState(false);

    const requiresUserInput = useMemo(() =>
        ["youtube", "vimeo"].indexOf(selectedMethod) > -1, [selectedMethod])

    // Available methods
    const METHODS = {
        record: {
            label: "Record a video",
            icon: faVideo
        },
        upload: {
            label: "Upload a video",
            icon: faUpload
        },
        youtube: {
            label: "YouTube",
            icon: faYoutube
        },
        vimeo: {
            label: "Vimeo",
            icon: faVimeo
        },
        // tiktok: {
        //     label: "TikTok",
        //     icon: faTiktok
        // }
    }

    // Submit handler
    const onSubmit = useCallback(async () => {

        // YouTube, vimeo etc have a form input that we will now save against the DB
        if (requiresUserInput) {
            const payload = {
                videoURL: checkUrl,
            };

            try {
                const response = await updateVacancy({
                    vacancyId: { vacancyId },
                    payload,
                });
                if (!response.data) return;
                navigate(
                    `/employer/company/preview-vacancy/${response?.data?.id}`
                );
            } catch (error) {
                const res = `Something went wrong when updating the vacancy video URL`
                logger.error(res, { type: "CreateVacancy", error })
            }
        } else {
            // Record/upload should navigate to recorder
            navigate(
                `/employer/vacancy/create/videorecorder/${vacancyId}?upload=${selectedMethod === 'upload' ? 1 : 0}`
            );
        }
    }, [checkUrl, logger, navigate, requiresUserInput, selectedMethod, updateVacancy, vacancyId]);

    /**
     * Guard to disable ability to create vacancies
     * Don't use AuthGuard - the user would have to log out/in to get company updates and Seesy's JWTs are indefinite
     */
    useEffect(() => {
        const work = async () => {
            if (company && !company.canCreateVacancies) {
                setIsLocked(true);
            }
        };
        work().finally();
    }, [company]);

    /** 
     * Fires when selectedMethod changes, resets validation and input
     */
    useEffect(() => {
        setCheckUrl("")
        switch (selectedMethod) {
            case 'upload':
                setIsValid(true)
                break;
            case 'youtube':
                setIsValid(false)
                setVideoUrl("")
                break;
            case 'vimeo':
                setIsValid(false)
                setVideoUrl("")
                break;
            // case 'tiktok':
            //     setIsValid(false)
            //     setVideoUrl("")
            //     break;
            case 'record':
            default:
                setIsValid(true)
                break;
        }
    }, [selectedMethod])

    /**
     * Fires when video input changes
     * Validates input and sets state
     */
    useEffect(() => {
        setCheckUrl("")
        switch (selectedMethod) {
            case 'youtube':
                setIsValid(/^(http(s)?:\/\/)?(www\.)?youtu.be\/[A-Za-z0-9_.-]+$/i.test(videoUrl))
                break;
            case 'vimeo':
                setIsValid(/^(http(s)?:\/\/)?(www\.)?vimeo.com\/\d+$/i.test(videoUrl))
                break;
            // case 'tiktok':
            //     // TikTok usernames have a 24-character limit. Your handle will automatically be preceded by an “@” symbol. Usernames can only contain letters, numbers, periods and underscores.
            //     // TikTok videos are numeric
            //     setIsValid(/^(http(s)?:\/\/)?(www.)?tiktok.com\/@[A-Za-z0-9_.-]{1,24}\/video\/\d+$/i.test(videoUrl))
            //     break;
            default:
                setIsValid(true)
        }
    }, [videoUrl, selectedMethod])

    /**
     * 
     */
    const onCheck = () => {
        setCheckUrl(videoUrl)
    }

    return (
        <AuthGuard
            criteria={[
                { value: "EMPLOYER", check: "userDetails.userType" },
                vacancy && {
                    value: vacancy.companyId,
                    check: "membership.companyId",
                },
            ]}
        >
            <div>
                {isLocked && (
                    <Modal
                        actions={[{ text: 'Ok', onClick: () => navigate('/') }]}
                        title="You can't create new Vacancies"
                    >
                        <p>
                            {company.name} does not have the ability to create Vacancies.
                        </p>
                        <p className="font-light">Customer Support have been notified and will be in touch shortly to enable vacancies for your company.</p>
                    </Modal>
                )}
                <Container size={size}>
                    <SectionHeading
                        companyName
                        title={`${vacancy?.title} - Manage Video`}
                    />
                    <hr />

                    <section
                        className="grid gap-16"
                    >
                        <MethodList className="grid w-full gap-6 items-stretch">

                            {Object.keys(METHODS).map((methodKey, i) =>
                                <li key={i}>
                                    <input
                                        type="radio"
                                        id={`method-${methodKey}`}
                                        name="method"
                                        value={methodKey}
                                        className="hidden peer"
                                        checked={
                                            selectedMethod ===
                                            methodKey
                                        }
                                        onChange={
                                            (e) => {
                                                setSelectedMethod(
                                                    e.target
                                                        .value
                                                )
                                            }
                                        }
                                    />
                                    <label
                                        htmlFor={`method-${methodKey}`}
                                        className="grid h-full items-center w-full p-8 text-gray-300 bg-[#232424] border border-[#232424] rounded-lg cursor-pointer peer-checked:border-primary peer-checked:bg-primary peer-checked:text-black hover:text-white hover:border-primary transition-all"
                                    >
                                        <div className="flex gap-6 items-center">
                                            <Icon
                                                icon={METHODS[methodKey]?.icon}
                                                className="w-12 h-12"
                                                color={methodKey === selectedMethod ? 'black' : ''}
                                            />
                                            <p className="text-2xl font-semibold">
                                                {METHODS[methodKey]?.label}
                                            </p>
                                        </div>
                                    </label>
                                </li>
                            )}
                        </MethodList>

                        <div className={`grid gap-16 lg:grid-cols-[1fr_2fr] items-start`}>
                            {selectedMethod === 'record' &&
                                <div className="grid gap-4 text-white">
                                    <Title>Record with your device</Title>
                                    <p className="text-2xl">You can record a video in-browser using your device's camera.</p>
                                    <ul className="text-2xl list-disc pl-12">
                                        <li className="my-4">You can re-record as many times as you like.</li>
                                        <li className="my-4">You'll be given the chance to choose a thumbnail for the video.</li>
                                        <li className="my-4">We'll attempt to add captions to your video after you've confirmed all details.</li>
                                    </ul>
                                    <p className="text-2xl">Ready to go? Click next!</p>
                                </div>
                            }
                            {selectedMethod === 'upload' &&
                                <div className="grid gap-4 text-white">
                                    <Title>Upload an existing video</Title>
                                    <p className="text-2xl">You can upload a video from your device. Pay attention to the following points:</p>
                                    <ul className="text-2xl list-disc pl-12">
                                        <li className="my-4">Videos must be in <code className="p-2 bg-black rounded-lg mx-2">.mp4</code> format</li>
                                        <li className="my-4">There is a file size restriction of {process.env.REACT_APP_MAX_UPLOAD_FILESIZE_MB}MB. Tools like <a className="text-white underline" href="htttps://handbrake.fr" target="_blank" rel="noreferrer">Handbrake</a> can be used to compress larger videos.</li>
                                        <li className="my-4">You'll be given the chance to choose a thumbnail for the video.</li>
                                        <li className="my-4">We'll attempt to add captions to your video after you've confirmed all details.</li>
                                    </ul>
                                    <p className="text-2xl">Ready to go? Click next!</p>
                                </div>
                            }
                            {selectedMethod === 'youtube' &&
                                <div className="grid gap-4 text-white">
                                    <Title>Use a YouTube Video</Title>
                                    <p className="text-2xl">You can use a YouTube video for your vacancy.</p>
                                    <ul className="text-2xl list-disc pl-12">
                                        <li className="my-4">We can't pick a thumbnail for your video, this needs to be done directly on YouTube by the video owner.</li>
                                        <li className="my-4">We can't add captions to your video, this needs to be done directly on YouTube by the video owner.</li>
                                    </ul>

                                    <label htmlFor="youtube" className="text-white">YouTube Video URL</label>
                                    <div className="grid gap-4 grid-cols-[1fr_auto] items-stretch">
                                        <TextField
                                            id="youtube"
                                            placeholder="https://youtu.be/VIDEO_ID"
                                            fullWidth
                                            name="videoURL"
                                            onChange={(e) => setVideoUrl(e.target.value)}
                                            spacer={false}
                                        />
                                        <button size="medium" className="bg-secondary border-2 border-solid border-secondary text-white py-2 px-6 rounded-lg" onClick={onCheck}>Check</button>
                                    </div>
                                    {!isValid && <p className="text-red-500 text-xl">You can get this via YouTube's "share" function. It should be in the format https://youtu.be/VIDEO_ID</p>}
                                </div>
                            }
                            {selectedMethod === 'vimeo' &&
                                <div className="grid gap-4 text-white">
                                    <Title>Use a Vimeo Video</Title>
                                    <p className="text-2xl">You can use a Vimeo video for your vacancy.</p>
                                    <ul className="text-2xl list-disc pl-12">
                                        <li className="my-4">We can't pick a thumbnail for your video, this needs to be done directly on Vimeo by the video owner.</li>
                                        <li className="my-4">We can't add captions to your video, this needs to be done directly on Vimeo by the video owner.</li>
                                    </ul>

                                    <label htmlFor="vimeo" className="text-white">Vimeo Video URL</label>
                                    <div className="grid gap-4 grid-cols-[1fr_auto] items-stretch">
                                        <TextField
                                            id='vimeo'
                                            placeholder="https://vimeo.com/VIDEO_ID"
                                            fullWidth
                                            name="videoURL"
                                            onChange={(e) => setVideoUrl(e.target.value)}
                                            spacer={false}
                                        />
                                        <button size="medium" className="bg-secondary border-2 border-solid border-secondary text-white py-2 px-6 rounded-lg" onClick={onCheck}>Check</button>
                                    </div>
                                    {!isValid && <p className="text-red-500 text-xl">You can get this via Vimeo's "share" function. It should be in the format https://vimeo.com/VIDEO_ID</p>}
                                </div>
                            }
                            {/* {selectedMethod === 'tiktok' &&
                                <div className="grid gap-4 text-white">
                                    <Title>Use a TikTok Video</Title>
                                    <p className="text-2xl">You can use a TikTok video for your vacancy.</p>
                                    <ul className="text-2xl list-disc pl-12">
                                        <li className="my-4">We can't pick a thumbnail for your video, this needs to be done directly on TikTok by the video owner.</li>
                                        <li className="my-4">We can't add captions to your video, this needs to be done directly on TikTok by the video owner.</li>
                                    </ul>

                                    <label htmlFor="tiktok" className="text-white">TikTok Video URL</label>
                                    <div className="grid gap-4 grid-cols-[1fr_auto] items-stretch">
                                        <TextField
                                            id="tiktok"
                                            placeholder="https://tiktok.com/@CHANNEL/VIDEO_ID"
                                            fullWidth
                                            name="videoURL"
                                            onChange={(e) => setVideoUrl(e.target.value)}
                                            spacer={false}
                                        />
                                        <button size="medium" className="bg-secondary border-2 border-solid border-secondary text-white py-2 px-6 rounded-lg" onClick={onCheck}>Check</button>
                                    </div>
                                    {!isValid && <p className="text-red-500 text-xl">You can get this via TikTok's "share" function. It should be in the format https://tiktok.com/@CHANNEL/video/VIDEO_ID</p>}
                                </div>
                            } */}

                            <div className="lg:row-start-1 lg:row-end-1">
                                {!checkUrl && <VideoPlaceholder width="100%" height="auto">
                                    <Icon
                                        icon={METHODS[selectedMethod].icon}
                                        className="w-24 h-24"
                                    /></VideoPlaceholder>}
                                {checkUrl && <VideoCardJobBoard action={<></>} videoData={checkUrl} width="100%" height="auto" />}
                            </div>
                        </div>
                    </section>
                </Container>
                <BottomContainer size={size}>
                    <ButtonsGroup size={size}>
                        <Button
                            button
                            size="large"
                            fullWidth={!size.isSmUp}
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </Button>

                        <Button
                            button
                            size="large"
                            fullWidth={!size.isSmUp}
                            disabled={!isValid || (requiresUserInput && !checkUrl)}
                            onClick={() => onSubmit()}
                        >
                            {requiresUserInput ? "Save" : "Next"}
                        </Button>
                    </ButtonsGroup>
                </BottomContainer>
            </div>
        </AuthGuard >
    );
});

const Container = styled.div(
    ({ size }) => `
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  max-width: var(--max-width);
  margin: 0 auto;
  padding-left: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
  padding-right: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
  padding-bottom: ${size.isMdUp ? "250px" : "150px"};

  & button {
    padding: 16.5px 25px;
  }
  & hr{
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 20px;
    margin-bottom: 60px;
  }
  `
);

const BottomContainer = styled.div(
    ({ size }) => `
  position: fixed;
  bottom:0;
  padding: 20px;
  padding-left: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
  padding-right: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
  background-color: ${Colors.ghostAsh};
  flex-direction:${size.isMdUp ? "row" : "column"};
  align-items: center;
  width: 100%;
  display: flex;
  flex: 1;
  z-index: 3;
  `
);

const ButtonsGroup = styled.div(
    ({ size }) => `
  display: flex;
  flex-direction: ${size.isSmUp ? "row" : "column"};
  gap: 16px;
  width: 100%;
  max-width: var(--max-width);
  justify-content: ${size.isSmUp ? "flex-end" : "stretch"};
  `
);

const Title = styled.h2`
    font-size: 24px;
    text-transform: uppercase;
`

const MethodList = styled.ul`
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
`