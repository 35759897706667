import { analytics, trackingTypes } from "utils/analytics";
import { ApplicationPreviewFooter } from "./ApplicationPreviewFooter";
import { AuthGuard } from "components/AuthGuard";
import { Button } from "components/Button";
import { Colors } from "styles/Colors";
import { IndexedDB } from 'utils/indexedDb'
import {
  reset,
  selectSelectedVacancy,
  selectVacancyResponse,
} from "store/reducers/vacancyReducer";
import { SectionHeading } from "components/Headings/SectionHeading";
import { useApplyForJobMutation, useGetUserQuery, useUpdateApplicationMutation, useUpdateCandidateMutation } from "store/userAPI";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";


const InfoRow = React.memo(({ label, value, showbdr }) =>
  value ? (
    <InfoRowContainer showbdr={showbdr}>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </InfoRowContainer>
  ) : null
);

export const ApplicationPreview = React.memo(() => {
  const vacancyResponse = useSelector(selectVacancyResponse);
  const vacancy = useSelector(selectSelectedVacancy);

  const dispatch = useDispatch();
  const size = useResponsiveLayout();
  const [rightToWork, setRightToWork] = React.useState(null);
  const [phoneNo, setPhoneNo] = React.useState("");
  const navigate = useNavigate();

  const [updateJob] = useUpdateApplicationMutation();
  const [applyForJob] = useApplyForJobMutation();
  const [updateCandidate] = useUpdateCandidateMutation();

  const [formattedAnswers, setFormattedAnswers] = useState({})
  const [phoneError, setPhoneError] = useState(false);

  const {
    data: { user: { candidate = {} } = {} } = {},
  } = useGetUserQuery({});

  const db = useRef(new IndexedDB())

  const navigateToJobPost = () => {
    // navigate(-2);
    alert("navigate to job post");
    navigate(`/job-board/post/${vacancy.id}`);
  };

  const rerecordVideo = (index) => {
    // Remove the answer before navigating, else we'll be pushed back since all recordings are complete
    navigate(`/candidate/application/${index}`);
  };

  const action = () => (
    <div>
      {window.location.pathname ===
        "/job-board/candidate/application-preview" ? (
        <></>
      ) : (
        <Button button size="large" onClick={navigateToJobPost}>
          Back to Job Post
        </Button>
      )}
    </div>
  );

  const updatePhoneNo = (e) => {
    const phoneNo = e.target.value;
    const reg = /^\+?\d{0,3}(\(\+?\d{1,3}\))?\d{3,5}(\ |\-)?\d{3}(\ |\-)?\d{3}$/gim;

    if (
      phoneNo.length > 20 ||
      phoneNo.length === 0 ||
      !phoneNo.match(reg)
    ) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }

    setPhoneNo(phoneNo);
  }

  const submitForm = async () => {
    if (rightToWork == null) {
      alert("Please select if you have right to work in the UK");

      return;
    }

    if ((phoneNo === "" && !candidate?.phoneNo) || phoneError) {
      alert("Please enter a valid phone number");

      return;
    }

    const payload = {
      application: {
        vacancyId: vacancy.id,
        rightToWork,
        status: "APPLIED",
      }
    };

    if (!candidate?.phoneNo) {
      const accountPayload = {
        phoneNo: phoneNo
      };

      try {
        let response;
        response = await updateCandidate(accountPayload);
        if (!response) {
          return;
        }
        if (response.error) {
          return alert(JSON.stringify(response.error));
        }
      } catch (error) {
        console.log(error);
      };
    };

    console.log("paload", payload);

    try {
      let response;

      if (!vacancy.applicationId) {
        response = await applyForJob(payload);
      } else {
        payload.application.id = vacancy.applicationId;
        response = await updateJob(payload);
      }

      if (response?.error) {
        alert(response?.error?.data?.message);
        console.log(response.error);
        return;
      }
      // if (!response?.data?.vacancyId) {
      if (!response?.data) {
        console.log("response", response);
        alert("Something went wrong");
        return;
      } else {
        analytics.track(trackingTypes.APPLICATION_COMPLETED, {
          vacancy: vacancy.id,
          application: vacancy.applicationId || response.data?.application?.id,
          user: candidate.id
        })
        dispatch(reset());

        // Clear locally cached videos
        await db.current.clear()

        navigate("/job-board/candidate/congratulations");
        setTimeout(() => {
          document.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
  * Check for locally cached videos in IndexedDB
  * Videos are stored as ArrayBuffers due to an issue with Safari storing Blobs in IndexedDB
  * https://stackoverflow.com/questions/68386273/error-loading-blob-to-img-in-safari-webkitblobresource-error-1
  */
  useEffect(() => {
    const work = async () => {
      let answers = {}
      for (let i = 0; i < vacancyResponse.length; i++) {

        // Sometimes the DB may have "null" - handle it.
        if (!vacancyResponse[i].answer) continue

        // Keys don't have the file extension
        const key = vacancyResponse[i].answer.split('.')[0]

        // Get all the blobs from IndexedDB
        const blobs = await db.current.get(key)
        if (blobs) {
          // Combine the individual blobs
          const combined = new Blob(blobs, { type: blobs[0].type })
          answers[vacancyResponse[i].answer] = { url: URL.createObjectURL(combined), type: combined.type }
        } else {
          // No local store
          answers[vacancyResponse[i].answer] = vacancyResponse[i].answer
        }
      }
      setFormattedAnswers(answers)
    }


    work().finally()
  }, [vacancyResponse, db])


  return (
    <AuthGuard criteria={[
      { value: 'CANDIDATE', check: 'userDetails.userType' },
    ]}>
      {Object.keys(formattedAnswers).length === 0 ? <></> :
        <>
          <Container size={size}>
            <SectionHeading
              title={
                <>
                  <span>Apply: </span> {vacancy?.title}
                </>
              }
              actions={action()}
              style={{ gridArea: 'header' }}
            />
            <hr style={{ gridArea: 'hr' }} />

            <InfoWrapper>
              <Name>Details</Name>
              {/* <InfoRow showbdr label="Job reference" value={vacancy?.id} /> */}
              <InfoRow
                showbdr
                label="Contract type"
                value={vacancy?.contractType}
              />
              <InfoRow showbdr label="Location" value={vacancy?.location} />
              <InfoRow label="Remote working" value={vacancy?.remoteWorking} />
              <div className="flex flex-col space-x-2 ">
                <div className="text-gray-200 text-2xl">
                  Do you have the right to work in{" "}
                  {vacancy?.country || "United Kingdom"}?
                </div>

                <div className=" inline-flex  mt-4  space-x-4">
                  <div className="block">
                    <input
                      type="radio"
                      className="mt-2"
                      name="rightToWork"
                      value={true}
                      checked={rightToWork === true}
                      onChange={(e) => setRightToWork(true)}
                      id="rightToWork"
                    />
                    <span className=" normal-case"> Yes</span>
                  </div>
                  <div className="block">
                    <input
                      type="radio"
                      className="mt-2"
                      name="rightToWork"
                      checked={rightToWork === false}
                      value={false}
                      onChange={(e) => setRightToWork(false)}
                      id="rightToWork"
                    />
                    <span className=" normal-case"> No</span>
                  </div>
                </div>
                {rightToWork == null ? (
                  <div className="text-red-500 text-2xl">
                    Please select if you have right to work in the UK
                  </div>
                ) : null}
              </div>

              {!candidate?.phoneNo &&
                <>
                  <Name>Account Details</Name>
                  <div className=" inline-flex  mt-4  space-x-4">
                    <PhoneNo className="text-gray-200 text-2xl">
                      Phone Number:
                      <div className="block">
                        <input
                          name="phoneNo"
                          id="phoneNo"
                          type="text"
                          onChange={updatePhoneNo}
                        />
                      </div>
                    </PhoneNo>
                  </div>
                  <div className="text-red-500 text-2xl">
                    Please enter your phone number. This will be saved to your account!
                  </div>
                  {phoneError &&
                    <div className="text-red-500 text-2xl">
                      Please enter a valid phone number, in e164 format. eg: [+][country code][area code][local phone number]
                    </div>
                  }
                </>
              }

            </InfoWrapper>

            <Video>
              {vacancyResponse &&
                vacancyResponse?.map((i, index) =>
                  <Videowrapper key={i.answer}>
                    <Name>Q{index + 1}). {i?.question}</Name>
                    <VideoCardJobBoard
                      isPlayIcon
                      type={true}
                      width={'100%'}
                      height={'auto'}
                      action
                      videoData={formattedAnswers[i.answer]}
                      thumbnail={i?.thumbnail}
                    />
                    <Button
                      button
                      type="alt"
                      size="large"
                      onClick={() => rerecordVideo(index)}
                    >
                      Record New Video
                    </Button>
                  </Videowrapper>
                )}
            </Video>

          </Container>

          <ApplicationPreviewFooter submit={submitForm} />
        </>
      }
    </AuthGuard>
  );
});

const Container = styled.div(
  ({ size }) => `
  max-width: min(var(--max-width), 100vw);
  margin: 0 auto;
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  display: grid;
  gap: ${size.isLgUp ? '24px 64px' : '32px'};
  grid-template-columns: ${size.isLgUp ? 'minmax(320px, 1fr) 3fr' : '1fr'};
  grid-template-areas: ${size.isLgUp
      ? `'header header'
          'hr hr'
          'info videos'
          'info videos'`
      : `'header'
          'hr'
          'info'
          'videos'`
    };
  padding-bottom: 150px;
  & hr {
    margin-top: 30px;
    margin-bottom: 20px;
    border: 2px Solid rgba(255, 255, 255, 0.2);
  }
  & span{
    text-transform: uppercase;
    color: #787679;
  }
`
);

const InfoWrapper = styled.div`
  width: 100%;
  grid-area: info;
`;

const InfoRowContainer = styled.div(
  ({ showbdr }) => `
    display: flex;

    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: ${showbdr ? "2px solid #232424" : ""};
    padding-bottom: 15px;
  `
);
const Label = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.2);
`;
const Value = styled(Label)`
  color: ${Colors.white};
  margin-left: 60px;
`;
const Videowrapper = styled.div`
  
  & button {
    margin-top: 15px;
  }
  width: 100%;
  & > div:nth-child(2) > div {
    margin-top: 0px;
    width: 100%;
  }
  & > div:nth-child(2) > div img {
    width: 100%;
  }
  & hr {
    margin-top: 40px;
    margin-bottom: 40px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
`;
const Name = styled.h2`
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 18px;
  color: ${Colors.white};
`;

const Video = styled.div`
  grid-area: videos;
  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 40px 24px;
`;

const PhoneNo = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  & input {
    padding: 5px;
    border-radius: 4px;
    color: black;
  }  
`;