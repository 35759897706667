import { TextField } from "./TextField"
import { useResponsiveLayout } from "hooks/useResponsiveLayout"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components";

export const AddressLookup = React.memo(({ className, id, onResult, onError, label, value, ...rest }) => {

    const size = useResponsiveLayout()

    const [searchString, setSearchString] = useState()
    const [isInitialised, setIsInitialised] = useState()

    const inputRef = useRef()

    const _id = `${new Date().valueOf()}${Math.floor(Math.random())}`

    /**
     * Set `searchString` based on `value` prop
     * Only do this once and only if the value is not blank
     */
    useEffect(() => {
        if (!isInitialised && !searchString && value) {
            inputRef.current.value = value
            setSearchString(value)
            setIsInitialised(true)
        }
    }, [isInitialised, value, searchString])

    /**
     * Set `searchString` and fire `onResult`
     * Used to do an address lookup and extract props, now we're just using whatever they type.
     * @param {string} v - value from form input 
     */
    const onSearch = (v) => {
        setSearchString(v)
        onResult(v)
    }

    return <div className={`grid gap-6 ${className ? className : ''}`} {...rest}>
        {label && <label className="text-white" htmlFor='addressLookup'>{label}</label>}
        <InputButtonGroup size={size}>
            <TextField
                id={id ?? _id}
                fullWidth
                placeholder="Enter an address"
                ref={inputRef}
                spacer={false}
            />
            <button
                className="bg-secondary text-white py-6 px-8 rounded-lg hover:bg-secondary/80 h-full"
                onClick={() => {
                    onSearch(inputRef.current.value);
                }}
            >
                Search
            </button>
        </InputButtonGroup>
        {searchString &&
            <iframe
                className="border-0 rounded-lg"
                title="Primary location map"
                width="100%"
                height="350"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${searchString}`}>
            </iframe>}
    </div>
})

const InputButtonGroup = styled.div(({ size }) => `
  display: grid;
  gap: 16px;
  align-items: center;
  grid-template-columns: ${size.isSmUp ? '1fr auto' : '1fr'};
`)