import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fromSignUp } from './state'
import { headerHeight } from 'styles/Layout'
import {
  selectSelectedVacancy,
  selectVacancyResponse,
} from 'store/reducers/vacancyReducer'
import { useApplyForJobMutation } from 'store/userAPI'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout'
import { useSelector } from 'react-redux'
import React from 'react'
import styled from 'styled-components'

export const IntroMessage = React.memo(() => {
  const size = useResponsiveLayout()
  const navigate = useNavigate()
  const [applyForJob] = useApplyForJobMutation()
  useEffect(() => {
    fromSignUp.value = false
  }, [])

  const selectedVacancy = useSelector(selectSelectedVacancy)
  const vacancyResponse = useSelector(selectVacancyResponse)

  let navigateToApplication = () => {
    navigate('/candidate/application')
  }
  let navigateBack = async () => {
    //comeback later
    await applyForJob({
      vacancyId: selectedVacancy.id,
      answers: vacancyResponse || [],
      status: 'INCOMPLETED',
    })

    setTimeout(() => {
      navigate('/candidate/progress-saved')
    }, 100)
  }

  return (
    <>
      <Container size={size} headerHeight={headerHeight}>
        <header>
          <h1>What happens next?</h1>
          <h2>Seesy is different... But better</h2>
        </header>
        <IntroVideo controls autoplay preload="metadata">
          <source
            // Video could be a URL in which case use it exactly as-is, or it might be a video token in which case we should look in S3
            src={
              'https://d2khiudbypa5a9.cloudfront.net/candidate-welcome.mp4#t=0.1'
            }
            type={`video/mp4`}
          />
        </IntroVideo>
        <Buttons size={size}>
          <button
            className="bg-secondary text-white px-12 rounded-lg py-6 text-3xl"
            onClick={navigateToApplication}
          >
            Apply For the Job
            <FontAwesomeIcon
              icon={faCaretRight}
              className="h-5 w-5 ml-1"
              aria-hidden="true"
            />
          </button>
          <button
            className="border-secondary border text-white px-12 rounded-lg py-6 text-3xl"
            onClick={navigateBack}
          >
            Come Back Later
          </button>
        </Buttons>
      </Container>
    </>
  )
})

const Container = styled.div(
  ({ size, headerHeight }) => `
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 20px;
  align-items: space-between;
  justify-items: center;
  max-width: 550px;
  margin: 0 auto;
  padding: 20px;
  height: calc(100vh - ${headerHeight}px);
  height: calc(100svh - ${headerHeight}px);
  overflow-y: auto;
  header {
    display: grid;
    text-align: center;
    text-transform: uppercase;
    
    h1 {
      font-size: ${size.isSmUp ? '38px' : '30px'};
      font-weight: bold;
      color: white;
    }
    h2 {
      font-size: ${size.isSmUp ? '20px' : '16px'};
      color: #aeaeae;
    }
  }
`,
)

const IntroVideo = styled.video`
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  min-height: 100%;
`

const Buttons = styled.div(
  ({ size }) => `
  display: grid;
  width: ${size.isSmUp ? 'fit-content' : '100%'};
  align-items: center;
  justify-items: center;
  gap: 16px;
  grid-template-columns: ${size.isSmUp ? 'auto auto' : '1fr'};
  button {
    width: ${size.isSmUp ? 'fit-content' : '100%'};
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
  }
`,
)
