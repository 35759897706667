import styled from "styled-components";

export const AvatarPicker = styled.div(
    ({ imageUrl }) => `
   position: relative;
   height: 200px;
   width: 200px;
   aspect-ratio: 1/1;
   align-items: center;
   cursor: pointer;
   overflow: hidden;
   border-radius: 50%;
   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
   // Safari fix
   -webkit-transform: translate3d(0, 0, 0); 
  
   ::after {
    content: 'Change';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 33%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1) 100%);
    color: white;
    text-align: center;
    display: grid;
    align-items: center;
    padding-bottom: 5%;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 1);
    // backdrop-filter: blur(15px);
    backface-visibility: hidden;
    transform: translateY(20px);
    opacity: 0;
    transition: all 200ms ease;
   }
   
   figure {
    width: 100%;
    height: 100%;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${imageUrl ? imageUrl : "https://seesyfiles.s3.eu-west-2.amazonaws.com/74484575-1b28-42cc-a9d6-e29187495a35-1655727601387..png"});
    transform: scale(1);
    transition: all 200ms ease;
   }
   
   
   &:hover {
    ::after {
     opacity: 1;
     transform: translateY(0);
    }
    
    figure {
     transform: scale(1.1);
    }
   }
  `
);
