import { useAuthState } from 'Context'
import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'

/**
 * Basic access check.
 * @param criteria - Array of checks to perform
 * @param criteria.value - The value that the result of the check must equal. E.g. a companyId, a userId, or role. Pass a `*` for a null check where as long as the prop exists, we pass.
 * @param criteria.check - Dot notation selector for accessing the user's `auth` Context. E.g. `company.id` to access the user's company ID, or `userDetails.userType` for their role type.
 * @param criteria.not - If true, applies "not" to the given check. Useful for things like "not an employer" which allows anonymous and candidates.
 * @param redirect - Route to navigate to. If not specified will redirect to Access Denied.
 * }
 */
export const AuthGuard = React.memo(({ criteria, redirect, children }) => {
  const navigate = useNavigate()
  const auth = useAuthState()
  let hasAccess = true

  // If we're an admin, we're allowed in
  if (auth.userDetails.userType !== "ADMIN") {
    const checks = criteria.map(c => {
      // If c is `undefined` go to the next loop 
      // React will pass in `undefined` if the JSX used was a truthy insert on the criteria array, 
      // e.g. `(existingVacancy && { value: existingVacancy.companyId, check: 'membership.companyId' })`
      if (!c) return true

      // Deconstruct dot notation in the check to target the correct object
      const checkValue = c.check.split('.').reduce((o, i) => o[i], auth)
      // value may be a `*`. 
      // This is a "not null" check, act accordingly
      if (c.value === '*') {
        console.log(`Auth: Checking ${c.check} exists`)
        if (!checkValue) {
          console.log(`${checkValue} does not exist - access denied`)
          return false
        }
      } else {
        // Not a `*` null check - check the actual values

        console.log(`Auth: Checking ${c.value} ${c.not ? 'does not match' : 'matches'} ${c.check}`)
        if (c.not ? c.value === checkValue : c.value !== checkValue) {
          console.log(`${c.value} ${c.not ? 'matches' : 'does not match'} ${checkValue} - access denied`)
          return false
        }
        return true
      }
    })
    hasAccess = checks.every(Boolean)
  }

  if (hasAccess) {
    return <>{children}</>
  }

  if (!hasAccess) {
    return navigate(redirect ? redirect : '/access-denied', { replace: true })
  }
})
