import { DropdownMenu } from "components/DropdownMenu";
import { faBars, faGear, faSearch, faUniversalAccess } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { HeaderContent } from "./HeaderContent";
import { headerHeight } from "styles/Layout";
import { Icon } from "components/Icon";
import { NavLink, useLocation } from "react-router-dom";
import { Title } from "./Title";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useState } from "react";
import styled from "styled-components";

import { candidateMenuItems, candidateMobileItems, employerMenuItems, employerMobileItems } from "./menuItems";
import { headerVariant, isCandidate, SetUserDetails, userDetails } from './state'
import { useEffect } from "react";
import { useSignals } from "@preact/signals-react/runtime";
import { VerifyEmailBanner } from "./VerifyEmailBanner";

const FullWidthHeaderContent = () => {
  return (
    <>
      <FullWidthVariant>
        <div>
          <input placeholder="Search"></input>
          <Icon icon={faSearch} />
        </div>
        <span> Digital Environment Architect</span>
      </FullWidthVariant>
    </>
  )
}

const DefaultHeaderContent = ({ noHeader }) => {
  const size = useResponsiveLayout();
  if (noHeader || !userDetails.value) return <Title noHeader={noHeader} />

  return (
    <>
      <Title noHeader={noHeader} />
      <div className={`${!size.isMdUp ? 'hidden' : ''}`}>
        <HeaderContent isCandidate={isCandidate.value} />
      </div>
    </>
  )
}

const LoggedInMenu = () => {
  const location = useLocation();
  return (
    <DropdownMenu
      variant="filled"
      trigger={({ toggle, isOpen }) => (
        <IconStyle icon={faGear} onClick={() => toggle()} open={isOpen} className={`${!isOpen && 'hover:text-violet-300'}`} />
      )}
      items={isCandidate.value ? candidateMenuItems : employerMenuItems}
      path={location}
    />
  )
}

const MobileMenu = () => {
  return (
    <div>
      <DropdownMenu
        trigger={({ toggle }) => <Icon icon={faBars} onClick={toggle} />}
        items={isCandidate.value ? candidateMobileItems : employerMobileItems}
      />
    </div>
  )
}

const GuestMenu = () => {
  return (
    <DropdownMenu
      trigger={({ toggle }) => <Icon icon={faUser} onClick={toggle} />}
      className="block sm:hidden"
      items={[
        { text: "Employer Sign In / Sign Up", to: "/employer/account" },
        {
          text: "Candidate Sign in / Sign Up",
          to: "/candidate/account",
        },
      ]}
    />
  )
}

const GuestHeader = () => {
  const size = useResponsiveLayout();
  if (userDetails.value) return null
  return (
    <div className="flex gap-12">
      <Icon className={`text-[#87CCFF] accessibilityWidget cursor-pointer hover:text-violet-300 ${size.isSmUp ? "hidden" : ""}`} icon={faUniversalAccess} />
      <GuestMenu />
      <div className="hidden sm:flex space-x-8 ">
        <NavLink
          to="/employer/account"
          className="mr-2 cursor-pointer text-white"
        >
          Employer Sign In / Sign Up
        </NavLink>
        <NavLink
          to="/candidate/account"
          className="mr-2 cursor-pointer text-white"
        >
          Candidate Sign in / Sign Up
        </NavLink>
      </div>
      <Icon className={`text-[#87CCFF] accessibilityWidget cursor-pointer hover:text-violet-300 ${!size.isSmUp ? "hidden" : ""}`} icon={faUniversalAccess} />
    </div>
  )
}

const MobileHeader = ({ noHeader }) => {
  const size = useResponsiveLayout();
  if (!userDetails.value && noHeader) return null
  return (
    <div className={`flex gap-12 ${size.isMdUp ? 'hidden' : ''}`}>
      <Icon className="text-[#87CCFF] accessibilityWidget cursor-pointer hover:text-violet-300" icon={faUniversalAccess} />
      <MobileMenu />
    </div>
  )
}

const DesktopHeader = ({ noHeader }) => {
  const size = useResponsiveLayout();
  return (
    <Icons className={`${!size.isMdUp ? 'hidden' : ''}`}>
      {userDetails.value && <Icon className="text-[#87CCFF] accessibilityWidget cursor-pointer hover:text-violet-300" icon={faUniversalAccess} />}
      {userDetails.value && !noHeader && <LoggedInMenu />}
    </Icons>
  )
}

export const Header = React.memo(({ noHeader }) => {
  useSignals()
  const size = useResponsiveLayout();


  const [isUserWayInitialised, setIsUserWayInitialised] = useState(false)

  /**
   * This is a spicy one
   * We cannot just init UserWay via the `index.html` page
   * If we do this, the DOM will not be fully hydrated and UserWay will (sometimes) fail to bind to the custom button
   * When this happens, we get a busted custom button *and* the userway-managed floating widget button
   * This is because UserWay looks for a CSS class on init, and if it doesn't find one it falls back to its own widget button
   * Since this is a React App - and a poorly written one at that - the navbar might not have rendered by the time UserWay initialises
   * We could put an arbitrary `setTimeout` on the init on `index.html`, but that's a really janky option
   * Instead, manually init UserWay here.
   */
  useEffect(() => {
    if (isUserWayInitialised || process.env.REACT_APP_ENV !== 'production') return

    // Standard UserWay Init code
    // Give it 200ms juuuuust in case
    setTimeout(() => {
      var s = document.createElement("script"); s.setAttribute("data-account", "k02P9kescr"); s.setAttribute("src", "https://cdn.userway.org/widget.js"); document.body.appendChild(s);
    }, 200);

    setIsUserWayInitialised(true)
  }, [isUserWayInitialised])

  return (
    <div>
      <SetUserDetails />
      <Container size={size} variant={headerVariant.value} isCandidate={isCandidate.value}>
        {headerVariant.value === "fullWidth" && <FullWidthHeaderContent />}
        {headerVariant.value !== "fullWidth" && <DefaultHeaderContent />}

        <DesktopHeader />
        <MobileHeader />
        <GuestHeader />

      </Container>
      <VerifyEmailBanner />
    </div>
  );
});

const isFullWidth = `
padding-left: 0px;
margin-bottom: 0px;
height: 80px;
`;
const Container = styled.div(
  ({ size, variant, isCandidate }) => `
display: flex;
height: ${headerHeight}px;
padding-left: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
padding-right: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
align-items: center;
${variant ? isFullWidth : null};
background-color: ${isCandidate && "transparent"};
max-width: var(--max-width);
margin: 0 auto;
`
);

const IconFilled = `
background-color: #9e82fb;`;

const IconStyle = styled(Icon)`
  ${(props) => (props.open ? IconFilled : "")}
`;

const Icons = styled.div`
  margin-left: auto;
  display: inherit;
  & svg {
    padding: 10px;
    margin-left: 10px;
    border-radius: 5px;
  }
`;

const FullWidthVariant = styled.div`
  display: flex;
  width: 70%;
 height: 100%;
 & h2{
  color: white;
  margin: 0px;
  margin-top: 37px;
  font-size: 18px;
}
  > div {
    width: 360px;
    
    position: relative;
    & input{
    margin-top: 30px;
    width: 100%;
    padding-left: 15px !important;
    padding: 8px 0px;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 16px;
    color: white;
    font-weight: 500;
}

    & input::placeholder {
        color: white;
        opacity: 1px;
        
      }
  }
  
    }
    & svg{
      position: absolute;
      top: 35px;
      right: 34px;
      color: white;
      cursor: pointer;
  }
    }

  }
  > span {
    color: white;
    margin-top: 35px;
    font-size: 18px;
    
}
  }
`;
