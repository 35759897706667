const currentUser = localStorage.getItem("currentUser")
? JSON.parse(localStorage.getItem("currentUser")) : {}

const user = currentUser.user || "";

const token = currentUser.jwt || "";

const company = currentUser.company || "";

const membership = currentUser.membership || "";

export const initialState = {
  userDetails: user,
  token: token,
  loading: false,
  errorMessage: null,
  company: company,
  membership: membership,
  companyMembers: [],
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_SIGNUP":
    case "REQUEST_ADD_TEAM_MEMBER":
    case "REQUEST_LOGIN":
    case "REQUEST_UPDATE_COMPANY":
    case "REQUEST_COMPANY_MEMBERS":
    case "REQUEST_GET_USER":
    case "REQUEST_UPDATE_TEAM_MEMBER":
      return {
        ...initialState,
        loading: true,
      };
    case "GET_USER_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.jwt,
        loading: false,
      };
    case "SIGNUP_SUCCESS":
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        userDetails: action.payload.user,
        token: action.payload.jwt,
        company: action.payload.company,
        loading: false,
      };
    case "UPDATE_COMPANY_SUCCESS":
      return {
        ...initialState,
        company: action.payload,
      };
    case "COMPANY_MEMBERS_SUCCESS":
      return {
        ...initialState,
        companyMembers: action.payload,
      };
    case "ADD_TEAM_MEMBER_SUCCESS":
    case "UPDATE_TEAM_MEMBER_SUCCESS":
      return {
        ...initialState,
        loading: false,
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: "",
        token: "",
      };
    case "SIGNUP_ERROR":
    case "ADD_TEAM_MEMBER_ERROR":
    case "LOGIN_ERROR":
    case "UPDATE_COMPANY_ERROR":
    case "COMPANY_MEMBERS_ERROR":
    case "GET_USER_ERROR":
    case "UPDATE_TEAM_MEMBER_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
