import { headerHeight } from 'styles/Layout';
import bgImage from 'images/recorder-bg-1.jpg'
import VideoRecorder from 'components/VideoRecorder/VideoRecorder';

export const RecorderTest = () => {
    return (
        <VideoRecorder
            allowThumbnailUpload={true}
            allowUpload={true}
            disableJanus={true}
            height={`calc(100svh - ${headerHeight}px)`}
            janusServer={process.env.REACT_APP_JANUS_URL ?? 'https://videos-graviton.seezyhire.com/janus'}
            onComplete={(e) => console.log("onComplete", e)}
            onExit={() => console.log("onExit")}
            onRecording={() => console.log("onRecording")}
            requiredVideoLength={1}
            thumbnailSelection={true}
            showGuide={true}
            headerText="This is a local test of recorder. Your video will NOT be uploaded and is only being recorded locally on your device. Thumbnails are also NOT uploaded."
            overlayText={["Local-only test of recorder.", "Your recording will not be uploaded.", "Thumbnails will not be uploaded."]}
            backgroundImage={bgImage}
        />
    );
};
