import { ChatArea } from "./LocalComponents/ChatArea";
import { data } from "./data";
import { ProfileArea } from "./LocalComponents/ProfileArea";
import { useState } from "react";
import styled from "styled-components";

export const MessagePage = () => {
  const [activeUser, setActiveUser] = useState(0);

  const currentUser = (index) => {
    setActiveUser(index);
  };
  return (
    <>
      <Container>
        <Wrapper>
          <ChatArea
            chatData={data}
            currentUser={currentUser}
            activeUser={activeUser}
          />
          <ProfileArea data={data[activeUser].user} />
        </Wrapper>
      </Container>
    </>
  );
};

const Container = styled.div(
  () => `
display: flex;
overflow: hidden;
height: calc(100vh - 80px);
height: calc(100svh - 80px);
padding: 0px;
`
);
const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;
