import { Button } from "components/Button";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useState } from "react";
import styled from "styled-components";
export const DropDown = React.memo(
  ({ options, action, color, handleChange, active, placeholder, bg, icon }) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(
      active ? active : !placeholder ? options[0] : ""
    );
    const { isMdUp } = useResponsiveLayout();
    const onClick = (option) => {
      setSelected(option);
      setOpen(false);
      handleChange && handleChange(option);
    };
    return (
      <>
        <DropDownRoot
          isMdUp={isMdUp}
          color={color}
          active={active}
          selected={selected}
          placeholder={placeholder}
        >
          {action ? (
            action
          ) : (
            <div>
              <Button
                size="medium"
                rightIcon={icon ? icon : faCaretDown}
                button
                onClick={() => setOpen(!open)}
              >
                {selected || placeholder}
              </Button>
            </div>
          )}
          {open ? (
            <DropDownContent bg={bg} color selected={selected}>
              {options
                ? options.map((option, index) => {
                    if (index <= 3) {
                      return (
                        <DropDownList onClick={() => onClick(option)} bg={bg}>
                          <p>{option}</p>
                          {bg && option !== "Other +" ? <hr></hr> : null}
                        </DropDownList>
                      );
                    } else {
                      return null;
                    }
                  })
                : null}
            </DropDownContent>
          ) : null}
        </DropDownRoot>
      </>
    );
  }
);

const DropDownRoot = styled.div(
  ({ isMdUp, active }) => `
  position: relative;
  button {
    color: ${active ? "#6BFF00" : "#C4C4C4"};
    border-bottom: ${active ? "3px solid #6BFF00" : "3px solid #c4c4c4"};
    padding: 17px;
    border-radius: 5px;
    margin: 0px;
    text-transform: capitalize;
    width: ${isMdUp ? "170px" : "100% !important"};
    min-width: max-content;
    text-align: left;
    margin-left: 0 !important;
    background-color: #262428;
    position: relative;
  
  }
`
);
const DropDownContent = styled.div(
  ({ bg }) => `

text-align: center;
color: ${bg ? "#6BFF00" : "white"};
text-transform: capitalize;
background-color: ${bg ? "#87848A" : "rgb(38 36 40)"};
width: 100%;
max-width: max-content;
position: absolute;
padding: 4px 20px;
padding-right: 60px;
text-align: left;
top: 110%;
left: 0;
border-radius: 5px;
z-index: 22;
& > div:nth-child(4)  {
border-bottom: none;}
}
`
);
const DropDownList = styled.div(
  () => `
  cursor: pointer;
  & hr {
    margin: 0px -20px;
    margin-right: -60px;
    border: 0.4px solid #69656D;
  }
  & p{
    font-size: 16px;
    font-weight: 400;
  }


`
);
