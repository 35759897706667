import { StepEight } from "./StepEight";
import { StepFive } from "./StepFive";
import { StepFour } from "./StepFour";
import { StepOne } from "./StepOne";
import { StepSeven } from "./StepSeven";
import { StepSix } from "./StepSix";
import { StepThree } from "./StepThree";
import { StepTwo } from "./StepTwo";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

export const Review = React.memo(({ state, handleChange, setArrayValue }) => {
  const { isMdUp } = useResponsiveLayout();

  return (
    <Container isMdUp={isMdUp}>
      <StepOne children state={state} handleChange={handleChange}>
        <Heading>Hi, My Name is</Heading>
      </StepOne>
      <ReviewWrapper>
        <StepTwo setArrayValue={setArrayValue} state={state}>
          <Heading>The most important things to me in my new job are</Heading>
        </StepTwo>
      </ReviewWrapper>
      <ReviewWrapper>
        <StepThree state={state} handleChange={handleChange}>
          <Heading>I would most like to work in </Heading>
        </StepThree>
      </ReviewWrapper>
      <ReviewWrapper>
        <StepFour state={state} setArrayValue={setArrayValue}>
          <Heading>The Industries i am interested in are</Heading>
        </StepFour>
      </ReviewWrapper>
      <ReviewWrapper>
        <StepFive setArrayValue={setArrayValue} state={state}>
          <Heading>The roles i am interested in are </Heading>
        </StepFive>
      </ReviewWrapper>
      <ReviewWrapper>
        <StepSix handleChange={handleChange} state={state}>
          <Heading>I want to work for a </Heading>
        </StepSix>
      </ReviewWrapper>
      <ReviewWrapper>
        <StepSeven handleChange={handleChange} state={state}>
          <Heading>I’m thinking to start working at a new company </Heading>
        </StepSeven>
      </ReviewWrapper>
      <ReviewWrapper>
        <StepEight handleChange={handleChange} state={state}>
          <Heading> My salary is</Heading>
        </StepEight>
      </ReviewWrapper>
    </Container>
  );
});
const Container = styled.div(
  ({ isMdUp }) => `
  `
);
const Heading = styled.h4`
  text-transform: uppercase;
  color: #fff;
  margin: 0px;
  margin-right: 18px;
  font-size: 26px;
  font-weight: 700;
`;
const ReviewWrapper = styled.div`
  margin-top: 75px;
`;
