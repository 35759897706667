import { Colors } from 'styles/Colors'
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTiktok,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { faFileLines, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FlexColumnWrapper } from 'components/FlexColumnWrapper'
import { Icon } from 'components/Icon'
import { NewTagComponent } from 'components/NewTagComponent'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout'
import React from 'react'
import styled from 'styled-components'

const InfoRow = React.memo(({ label, value, style }) =>
  value ? (
    <InfoRowContainer>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </InfoRowContainer>
  ) : null,
)

export const JobInfo = React.memo(
  ({
    data: {
      tags,
      address,
      location,
      remoteWorking,
      bonus,
      equityAvailable,
      id,
      contractType,
      directToCompany,
      portfolio,
    },
    companyDetails,
  }) => {
    const size = useResponsiveLayout()

    return (
      <Container size={size}>
        <LogoWrapper size={size} imageUrl={companyDetails?.avatar}>
          <p>{companyDetails?.name}</p>
          <div />
        </LogoWrapper>
        <InfoContainer>
          <Title style={{ display: size.isLgUp ? 'block' : 'none', gridColumn: '1/-1' }}>Details</Title>
          <InfoRow

            label="Company name"
            value={companyDetails?.name}
            style={{ display: size.isLgUp ? 'flex' : 'none' }}
          ></InfoRow>
          <InfoRow label="Contract type" value={contractType}></InfoRow>
          <InfoRow label="Direct or Agency" value={directToCompany}></InfoRow>
          {(address || location) && (<div className="grid gap-4">
            <InfoRow label="Location" value={address ?? location}></InfoRow>
            {address &&
              <iframe
                className="border-0 rounded-lg"
                title="Primary location map"
                width="100%"
                height="150"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${address}`}>
              </iframe>
            }
          </div>)}
          <InfoRow label="Remote working" value={remoteWorking}></InfoRow>
          <InfoRow

            label="Bonus"
            value={bonus && bonus.start > 0 && bonus.end > 0 ? '£ ' + bonus.start + ' - £ ' + bonus.end : 'None'}
          ></InfoRow>
          <InfoRow
            label="Equity Available"
            value={
              equityAvailable === false
                ? 'No'
                : 'Yes'

            }
          ></InfoRow>
        </InfoContainer>
        {portfolio &&
          <Section>
            <Title>Company Portfolio</Title>
            <Files>
              <a href={portfolio} target="_blank" rel="noreferrer">
                <Icon icon={faFileLines} /> <h6 className="text-white">Download Company Portfolio</h6>
              </a>
            </Files>
          </Section>
        }

        <TagsContainer>
          <Title>Tags</Title>
          <FlexColumnWrapper>
            {!!tags?.length ? tags.map((i, index) => (
              <NewTagComponent key={index}>{i}</NewTagComponent>
            )) : <p className="text-gray-400">No tags</p>}
          </FlexColumnWrapper>
        </TagsContainer>


        <SocialsWrapper>
          <Title>Socials</Title>
          <SocialIcons size={size}>
            {companyDetails?.facebook && (
              <Icon icon={faFacebookF} href={companyDetails.facebook.indexOf('http') > -1 ? companyDetails.facebook : `https://fb.me/${companyDetails.facebook}`} />
            )}
            {companyDetails?.instagram && (
              <Icon icon={faInstagram} href={companyDetails.instagram.indexOf('http') > -1 ? companyDetails.instagram : `https://instagram.com/${companyDetails?.instagram}`} />
            )}
            {companyDetails?.linkedIn && (
              <Icon icon={faLinkedinIn} href={companyDetails.linkedIn.indexOf('http') > -1 ? companyDetails.linkedIn : `https://linkedin.com/companyDetails/${companyDetails?.linkedIn}`} />
            )}
            {companyDetails?.youtube && (
              <Icon icon={faYoutube} href={companyDetails.youtube.indexOf('http') > -1 ? companyDetails.youtube : `https://youtube.com/@${companyDetails?.youtube}`} />
            )}
            {companyDetails?.tiktok && (
              <Icon icon={faTiktok} href={companyDetails.tiktok.indexOf('http') > -1 ? companyDetails.tiktok : `https://tiktok.com/@${companyDetails?.tiktok}`} />
            )}
            {companyDetails?.website && (
              <Icon icon={faGlobe} href={`${companyDetails?.website}`} />
            )}
          </SocialIcons></SocialsWrapper>
      </Container>
    )
  },
)

const Container = styled.div(
  ({ size }) => `
  grid-area: info;
  
  display: grid;
  grid-template-areas: ${size.isLgUp
      ? `
  'logo-title'
  'stats'
  'tags'
  'socials'
  `
      : `
  'socials'
  'logo-title'
  'stats'
  'tags'
  `
    };
  position: relative;
  align-content: flex-start;
  gap: 50px;
  margin-top: ${size.isLgUp ? 0 : '20px'};

  * {
    word-break: break-word;
  }
`,
)

const Section = styled.section`
  display: grid;
  gap: 24px;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
`

const InfoContainer = styled(Section)`
  grid-area: stats;
`

const TagsContainer = styled(Section)`
  grid-area: tags;
`

const LogoWrapper = styled(Section)(
  ({ size, imageUrl }) => `
  grid-area: logo-title;
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: ${size.isLgUp ? '1fr' : '1fr auto'};
  align-items: center;
  p {
    display: ${size.isLgUp ? 'none' : 'block'};
    font-size: ${size.isMdUp ? '32px' : '26px'};;
    color: white;
    text-transform: uppercase;
  }
  div {
    height: ${size.isMdUp ? size.isLgUp ? '200px' : '150px' : '100px'};
    width: ${size.isMdUp ? size.isLgUp ? '200px' : '150px' : '100px'};
    border-radius: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${imageUrl ? imageUrl : null});
    background-color: #372545;
  }
  `,
)

const SocialsWrapper = styled(Section)`
  grid-area: socials;
`

const SocialIcons = styled.div(
  ({ size }) => `
  display: grid;
  grid-template-columns: repeat(auto-fill, ${size.isMdUp ? '56px' : '36px'
    } ); // (Width + padding*2)
  gap: 12px;

  & svg {
    background: white;
    padding: ${size.isMdUp ? '14px' : '8px'};
    border-radius: 50%;
    width: ${size.isMdUp ? '28px' : '20px'};
    height: ${size.isMdUp ? '28px' : '20px'};
    color: black;
  }
  & svg:hover {
    background: #d6d6d6;
  }
`,
)

const InfoRowContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  justify-items: space-between;
`

const Label = styled.div`
  line-height: 26px;
  color: ${Colors.greyText};
`

const Value = styled(Label)`
  color: ${Colors.white};
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  justify-self: flex-end;
`;

const Title = styled.h2`
  margin
  line-height: 26px;
  font-weight: 700;
  font-size: 18px;
  color: ${Colors.white};
`

const Files = styled.div`
  display: flex;
  & svg {
    font-size: 80px;
    margin-right: 30px;
    margin-bottom: 30px;
  }
`;
