import { useLocation } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import TawkReact from '@tawk.to/tawk-messenger-react'

/**
 * List of routes that the Tawk instance will be hidden when active
 * Could be updated to use the DB instead, but this will do for now
 */
export const HIDDEN_ROUTES = [
  '/candidate/account',
  '/employer/account',
  '/candidate/application',
  '/record-video',
  '/videorecorder/',
  '/recorder',
]

/**
 * Tawk Context
 * `useContext(TawkContext)` in (functional) components to access
 */

export const TawkContext = React.createContext(null)

/**
 * Tawk Gateway component
 * Initialises Tawk context etc
 */
export const TawkGateway = ({ children }) => {
  let location = useLocation()
  // Track if Tawk has completed loading
  const [isTawkLoaded, setIsTawkLoaded] = useState(false)
  // Ref to Tawk client
  const tawkRef = useRef(null)
  /**
   * Fires when Tawk loads
   * Set state to signal to child components the ref is ready to use. Do this on a slight delay since the client isn't immediately available
   * Without this, the ref is set and components attempt to iteract before the client has actually loaded
   *
   * Crucial to be able to interact with Tawk anywhere in the app at a component level:
   *```
   * const tawkInstance = useContext(TawkContext)
   * ...
   * useEffect(() => {
   *   if (tawkInstance.ref.current && tawkInstance.isLoaded) {
   *     // Do stuff with Tawk, e.g. force hide it
   *     tawkInstance.ref.current.minimize();
   *     tawkInstance.ref.current.hideWidget();
   *   }
   * }, [tawkInstance.ref, tawkInstance.isLoaded])
   * ```
   */
  const onTawkLoad = () => {
    setIsTawkLoaded(true)
    // Do stuff with Tawk on app init.
  }

  /**
   * Check whether the client should be hidden based on route
   */
  useEffect(() => {
    if (isTawkLoaded) {
      if (
        HIDDEN_ROUTES.some((x) =>
          location.pathname.toLowerCase().includes(x.toLowerCase()),
        )
      ) {
        tawkRef.current.minimize()
        tawkRef.current.hideWidget()
      } else {
        // Re-open, it might have been hidden by a previous route change
        tawkRef.current.showWidget()
      }
    }
  }, [location, isTawkLoaded])

  return (
    <TawkContext.Provider value={{ ref: tawkRef, isLoaded: isTawkLoaded }}>
      {children}
      {/* Don't use Tawk locally, it's infuriating */}
      {process.env.REACT_APP_ENV !== 'local' && (
        <TawkReact
          propertyId="63985110daff0e1306dc68f9"
          widgetId="1gk5fpaeb"
          ref={tawkRef}
          onLoad={onTawkLoad}
        />
      )}
    </TawkContext.Provider>
  )
}
