import { AuthGuard } from "components/AuthGuard";
import { Button } from "components/Button";
import { Colors } from "styles/Colors";
import {
  faEye,
  faPencil,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import { SectionHeading } from "components/Headings/SectionHeading";
import {
  setSelectedCandidate,
} from 'store/reducers/vacancyReducer'
import { useAuthState } from 'Context'
import {
  useGetMyJobApplicationsQuery,
  useLazyGetVacancyByIdQuery,
  useLazyWithdrawApplicationQuery,
} from "store/userAPI";
import { useNavigate } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";

import { useDispatch } from "react-redux";

import {
  clearVacancyResponse,
  selectVacancyResponse,
  setSelectedVacancy,
  setVacancyResponse,
} from "store/reducers/vacancyReducer";
import { fromSignUp } from "../Account/state";
import { useSelector } from "react-redux";


export const CandidateDashboard = React.memo(() => {
  useEffect(() => {
    /* reset this when the user lands after completing signup steps.
    Same for the intro message page.
    Its a crap solution, but this whole app is a rats nest of shady state management. 
    This is the best of a bad bunch of options.
    */
    fromSignUp.value = false;
  }, [])
  const auth = useAuthState()

  const size = useResponsiveLayout();
  const [tabvalue, setTabvalue] = useState("APPLIED");
  const [jobs, setJobs] = useState();
  const vacancyResponse = useSelector(selectVacancyResponse);

  // const options = ["sort by date", "name"];

  const { data: jobApplications, refetch } = useGetMyJobApplicationsQuery();
  const [getVacancy] = useLazyGetVacancyByIdQuery();
  const [withdrawApplication] = useLazyWithdrawApplicationQuery();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getJobs = useCallback(async () => {
    try {
      let promises = jobApplications
        ?.filter((i) => i.status === tabvalue)
        ?.map((n) => getVacancy({ id: n?.vacancyId }, true).unwrap());
      const results = await Promise.all(promises);
      setJobs(results);
    } catch (e) {
      console.log(e);
    }
  }, [jobApplications, tabvalue, getVacancy]);

  const navigateToJob = (id) => {
    navigate(`/job-board/post/${id}`);
  };

  const completeApplication = (vacancy) => {
    dispatch(clearVacancyResponse());

    dispatch(setSelectedVacancy(vacancy));
    let application = jobApplications?.find((j) => j.vacancyId === vacancy.id);

    dispatch(setVacancyResponse(application?.answers));
    console.log("application-data", application);
    console.log("vacancy", vacancy);
    console.log("vacancyResponse", vacancyResponse);

    console.log("total questions in vacancy", vacancy.questions.length);
    console.log("current step of application", application.answers.length);

    if (vacancy.questions.length === application.answers.length) {
      return navigate(`/job-board/candidate/application-preview`);
    }
    return navigate(`/candidate/application/${application.answers.length}`);

    // we have the applicationId and vacanyId

    // if (application.answers.length < vacancy.questions.length) {
    //   return navigate(`/candidate/application/${num}`);
    // } else if (application.answers.length === vacancy.questions.length) {
    //   return navigate("/job-board/candidate/application-preview");
    // }
  };

  useEffect(() => {
    setTabvalue("ALL");
  }, []);

  useEffect(() => {
    const init = async () => {
      let promises;
      if (tabvalue === "ALL") {
        promises = jobApplications?.map((n) =>
          getVacancy({ id: n?.vacancyId }, true).unwrap()
        );
      } else {
        promises = jobApplications
          ?.filter((i) => i.status === tabvalue)
          ?.map((n) => getVacancy({ id: n?.vacancyId }, true).unwrap());
      }

      if (!promises) return
      const results = await Promise.all(promises)
      const jobs = results.map((i) => {
        return {
          ...i,
          applicationId: jobApplications?.find((j) => j.vacancyId === i.id)?.id,
          status: jobApplications?.find((j) => j.vacancyId === i.id)?.status,
        };
      });
      setJobs(jobs);
    }

    init().finally()
    dispatch(setSelectedCandidate(auth.userDetails))
  }, [jobApplications, getVacancy, getJobs, tabvalue, dispatch, auth.userDetails]);

  const handleTabChange = (value) => {
    setTabvalue(value);
  };

  const handleStatusStyle = (status) => {
    switch (status) {
      case "APPLIED":
        return "text-primary bg-primary/5 ";
      case "SHORTLISTED":
        return "text-secondary bg-secondary/5 ";
      case "REJECTED":
        return "text-red-500 bg-red-500/5 ";
      case "INCOMPLETED":
        return "text-yellow-500 bg-yellow-500/5";
      case "WITHDRAWN":
        return "text-gray-500 bg-gray-500/5";
      default:
        return "text-white bg-white/5";
    }
  };

  const VacancyComponent = ({ job }) => {
    const application = jobApplications.find(
      (a) => a.vacancyId === job.id
    )
    return (
      <div key={job.id} size={size}>
        <p className="text-white py-4">
          Status:{" "}
          <span
            className={`font-bold text-2xl tracking-normal  decoration-from-font  p-2 rounded-lg uppercase  ${handleStatusStyle(
              job.status
            )}`}
          >
            {job.status}
          </span>
        </p>
        <VideoCardJobBoard
          type={'play'}
          width={'100%'}
          height={"auto"}
          action={actions(job, application)}
          title={job.title}
          videoData={job.videoURL}
          thumbnail={job.thumbnail}
          onClickTitle={() => navigateToJob(job.id)}
        />
      </div>
    );
  };


  const removeApplication = async (id) => {
    try {
      const { error } = await withdrawApplication({
        id
      });
      if (error) {
        alert(error?.data?.message);
        console.log(error);
        return;
      } else {
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const actions = (vacancy, application) => (
    <ActionWrapper size={size}>
      {application?.status !== "INCOMPLETED" ? (
        <div>
          <Button
            button
            color="transparent"
            onClick={() => removeApplication(application.id)}
          >
            <Icon icon={faXmark} color={Colors.primaryGreen} size={22} />
            Withdraw
          </Button>
          {/* <Button button color="transparent">
        <Icon icon={faPencil} color={Colors.primaryGreen} size={16} />
        Edit
      </Button> */}
          <Button
            button
            color="transparent"
            onClick={() => {
              console.log('%cauth.userDetails', 'background: red')
              console.log(auth.userDetails)
              console.log(application)
              console.log('%cauth.userDetails', 'background: red')
              navigate(`/candidate/application/view/${application?.id}`)
            }}
          >
            <Icon icon={faEye} color={Colors.primaryGreen} size={16} />
            View
          </Button>
        </div>
      ) : (
        <Button
          button
          color="transparent"
          onClick={() => completeApplication(vacancy)}
        >
          <Icon icon={faPencil} color={Colors.primaryGreen} size={16} />
          Complete Application
        </Button>
      )}
    </ActionWrapper>
  );

  return (
    <AuthGuard
      redirect="/"
      criteria={[{ value: 'CANDIDATE', check: 'userDetails.userType' }]}
    >
      <Container size={size}>
        <SectionHeading
          title="Dashboard"
          // search
          placeholder={
            size.isMdUp ? "Search by title, location or employer name" : "Search"
          }
        />
        <hr />
        <TabBodyContainer size={size}>
          <TabHeadercontainer className="flex sm:flex-row flex-col w-full sm:w-auto text-center">
            <TabHeader
              tabvalue={tabvalue}
              value="ALL"
              onClick={() => handleTabChange("ALL")}
            >
              All Applications
            </TabHeader>
            <TabHeader
              tabvalue={tabvalue}
              value="ACCEPTED"
              onClick={() => handleTabChange("ACCEPTED")}
            >
              Shortlisted Applications
            </TabHeader>
            <TabHeader
              tabvalue={tabvalue}
              value="INCOMPLETED"
              onClick={() => handleTabChange("INCOMPLETED")}
            >
              Incomplete Applications
            </TabHeader>
            {/* <TabHeader
            tabvalue={tabvalue}
            value="Offers"
            onClick={() => setTabvalue("Offers")}
          >
            Offers
          </TabHeader> */}
            {/* <TotalCandidates>{jobs?.length}</TotalCandidates> */}
            {/* <TotalVideos size={size}>3</TotalVideos>
          <TotalOffers>1</TotalOffers> */}
          </TabHeadercontainer>
        </TabBodyContainer>
        <VideoContainer size={size}>
          {tabvalue === "ALL" ? (
            !!jobs?.length ? (
              jobs?.map((i) =>
                <VacancyComponent key={i.id} job={i} />
              )
            ) : (
              <div className="m-auto w-50 p-10" style={{ gridColumn: "1/-1" }}>
                <img
                  src="/icons/empty-doc.png"
                  className=" grayscale w-96 h-96 mt-20"
                  alt="File with a magnifying glass"
                />
                <p className="text-gray-400">No Applications Found</p>
              </div>
            )
          ) : null}
          {tabvalue === "ACCEPTED" ? (
            !!jobs?.length ? (
              jobs?.map((i) => {
                return <VacancyComponent key={i.id} job={i} />;
              })
            ) : (
              <div className=" m-auto w-50 p-10" style={{ gridColumn: "1/-1" }}>
                <img
                  src="/icons/empty-doc.png"
                  className=" grayscale w-96 h-96 mt-20"
                  alt="File with a magnifying glass"
                />
                <p className="text-gray-400">No Shortlisted Applications</p>
              </div>
            )
          ) : null}
          {tabvalue === "INCOMPLETED" ? (
            !!jobs?.length ? (
              jobs?.map((i) => {
                return <VacancyComponent key={i.id} job={i} />;
              })
            ) : (
              <div className=" m-auto w-50 p-10" style={{ gridColumn: "1/-1" }}>
                <img
                  src="/icons/empty-doc.png"
                  className=" grayscale w-96 h-96 mt-20"
                  alt="File with a magnifying glass"
                />
                <p className="text-gray-400">No Incomplete Applications</p>
              </div>
            )
          ) : null}
        </VideoContainer>
      </Container></AuthGuard>
  );
});

const Container = styled.div(
  ({ size }) => `{
        max - width: min(var(--max-width), 100vw);
      margin: 0 auto;
      margin-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
      padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
      & hr{
        margin - top: 35px;
      border: 2px solid rgba(255, 255, 255, 0.2);
    }
      & select{
        padding: 18px 64px;
      background: rgba(255, 255, 255, 0.04);
      border-radius: 5px;
      outline: none;
      border: none;
      color: white;
    }
}`
);
const TabHeadercontainer = styled.div(
  () => `
      display: flex;
      position: relative;
      margin-top: 20px;
}
      `
);

const TabHeader = styled.h2(
  ({ tabvalue, value }) => `
      font-family: Archivo;
      font-style: normal;
      cursor: pointer;
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
      padding: 5px 5px;
      border-radius: 5px;
      margin-right: 35px;
      color: ${tabvalue === value ? Colors.white : "rgba(255, 255, 255, 0.4)"} ;
      background: ${tabvalue === value ? Colors.velvet : "rgba(255, 255, 255, 0)"} ;
      `
);

const VideoContainer = styled.div(
  ({ size }) => `
      display: grid;
      margin-left: ${size.isMdUp ? "122px" : "0px"};
      grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
      gap: 32px;

      `
);

const TabBodyContainer = styled.div(
  ({ size }) => `
      display: flex;
      margin-top: 20px;
      margin-left: ${size.isMdUp ? "122px" : "0px"};
      padding-bottom: 40px;
      justify-content: ${size.isMdUp ? "space-between" : "center"};
      flex-wrap: wrap;
      align-items: center;
      & button {
        background: transparent;
      color: white;
      border: none;
      font-weight: 400;
      cursor: pointer;
      font-size: 16px;
      margin-left: 0;
      padding-right: 0;
      & svg {
        margin - right: 10px;
    }
  }
      `
);

const ActionWrapper = styled.div(
  ({ size }) => `
      margin-top: 10px;
      margin-bottom: ${!size.isMdUp ? "10px" : "0px"};
      & button{
        border:none;
  }
      `
);
