import { useLazyEmailVerificationQuery } from "store/userAPI";
import { useNavigate } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import envelope from "../../../../images/envelope.png";
import React, { useEffect } from "react";
import styled from "styled-components";

export const EmailVerification = React.memo(() => {
  const { isMdUp } = useResponsiveLayout();

  const navigate = useNavigate();

  const [verifyEmail] = useLazyEmailVerificationQuery();

  useEffect(() => {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const onSubmit = async () => {
      try {
        let response = await verifyEmail({
          token: urlParams.get("token"),
          id: urlParams.get("id"),
        });

        if (response.error)
          return alert(response?.message || response?.error?.data?.message);
        else {
          navigate(`/verified?userType=${urlParams.get("userType")}`);
        }
      } catch (error) {
        console.log(error);
      }
    };

    onSubmit();
  }, [navigate, verifyEmail]);

  return (
    <Container isMdUp={isMdUp}>
      {isMdUp ? <img src={envelope} height="180px" alt="envelope" /> : null}
      <h2>Verify your account</h2>
      <p>
        An account verification link has been sent to the email address you
        signed up with. Please check your inbox to continue.
      </p>
      {/* <Button button>Resend Verification Link</Button> */}
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${isMdUp ? "50px" : "30px"};
  padding-left : ${isMdUp ? "122px" : "16px"};
  padding-right :${isMdUp ? "122px" : "16px"};
  width: ${isMdUp ? "50%" : "100%"};
  margin:auto;
  text-align:center;
  & h2 {
    font-size: 26px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 36.56px;
    margin-bottom: ${isMdUp ? "40px" : "120px"};
  }
  & p {
    font-size: 16px;
    color: white;
    font-weight: 400;
    line-height: 28.8px;
    font-family: "Roboto";
  }
  & button {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 600;
    width:100%;
  }
`
);
