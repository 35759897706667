import { LikeButton } from "components/LikeButton";
import { ProfileCard } from "components/ProfileCard";
import { useAuthState } from "Context";
import React from "react";

let getStatusStyles = (status) => {
  switch (status) {
    case "NEW":
      return "text-yellow-500 bg-yellow-500/10";
    case "SHORTLISTED":
      return "text-green-500 bg-green-500/10";
    case "REJECTED":
      return "text-red-500 bg-red-500/10";
    case "WITHDRAWN":
      return "bg-gray-500 bg-gray-500/10";
    default:
      return "text-white bg-white/10";
  }
};
let handleStatusName = (status) => {
  switch (status) {
    case "APPLIED":
      return "NEW";
    case "ACCEPTED":
      return "SHORTLISTED";
    case "REJECTED":
      return "REJECTED";
    case "WITHDRAWN":
      return "WITHDRAWN";

    default:
      return status;
  }
};

export const Candidates = React.memo(
  ({ data, status, isCompare, deleteCandidate, viewProfile, views, likes, onToggleLike, disableLike }) => {

    const { userDetails } = useAuthState();
    const isActive = (likes && likes.filter(l => l.userId === userDetails.id).length > 0) ?? false

    return (
      <div className="group relative">
        <div className="absolute top-0 left-[50%] -translate-x-1/2 -translate-y-[100%] text-center text-sm">
          <span
            className="text-transparent duration-300 transition-all group-hover:text-primary bg-transparent
           group-hover:bg-green-500/20 px-5 py-2 rounded-lg"
          >
            Click To View Candidate
          </span>
        </div>
        <div className="absolute flex text-lg z-50 right-5 top-5">
          <span
            className={
              getStatusStyles(handleStatusName(status)) + " px-4 rounded-lg"
            }
          >
            {handleStatusName(status)}
          </span>
        </div>
        <div className="absolute z-50 left-5 top-5">
          <LikeButton active={isActive} disabled={disableLike} likes={likes} onToggle={(likeId) => onToggleLike && onToggleLike(likeId, !isActive)}></LikeButton>
        </div>

        <ProfileCard
          deleteUser={deleteCandidate}
          isCompare={isCompare}
          imageSource={data?.candidate?.avatar}
          name={data?.name}
          // rating={data?.rating}
          data={data}
          skills={data?.candidate?.skills}
          views={views}
          viewProfile={viewProfile}
        />
      </div>
    );
  }
);