import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

export const Congrats = React.memo(() => {
  const { isMdUp } = useResponsiveLayout();
  return (
    <Container isMdUp={isMdUp}>
      <h2>Congratulations</h2>
      <p>
        Congratulations. We’ve got your preferences and are preparing the top 10
        roles for you.
      </p>
      <p>Sit tight.</p>
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${isMdUp ? "50px" : "30px"};
  padding-left : ${isMdUp ? "122px" : "16px"};
  padding-right :${isMdUp ? "122px" : "16px"};
  text-align:center;
  & h2 {
    font-size: 26px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 36.56px;
    margin-bottom: ${isMdUp ? "69px" : "80px"};
  }
  & p {
    font-size: 18px;
    color: white;
    font-weight: 500;
    line-height: 28.8px;
    margin: 0px;
    margin-top: 15px;
    font-family: "Roboto";
    width: ${isMdUp ? "40%" : "100%"};
  }
`
);
