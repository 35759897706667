import { batch, computed, signal } from "@preact/signals-react";
import { Button } from "components/Button";
import { TextField } from "components/form";
import { useSignals } from "@preact/signals-react/runtime";

export const apiBase = process.env.REACT_APP_EX_API;

export const oldNumber = signal("");
export const newNumber = signal("");
export const verifyResult = signal();
export const hasPreviouslyUnverifiedNumber = signal(false);
export const shouldRevalidateNumber = computed(() => {
  return (
    verifyResult.value !== "success" && oldNumber.value !== newNumber.value
  );
});
export const verificationFailed = computed(
  () => verifyResult.value && verifyResult.value !== "success"
);
export const codeSent = signal(false);
export const callingApi = signal(false);
export const otpCode = signal("");

export const showOtpForm = computed(
  () =>
    (codeSent.value === true && callingApi.value === false) ||
    verificationFailed.value
);

export const verifyStatusMessage = computed(() => {
  if (verifyResult.value === "success")
    return ["success", "Verification complete, thank you!"];
  if (hasPreviouslyUnverifiedNumber.value && !verifyResult.value)
    return [
      "warn",
      "You currently have an unverified phone number. Please verify your phone number.",
    ];
  if (shouldRevalidateNumber.value && !verifyResult.value)
    return [
      "warn",
      "Please revalidate your number to recieve updates on your applications",
    ];

  if (verifyResult.value !== "success") return ["error", verifyResult.value];
  if (codeSent.value) return ["info", "Your code is on the way"];
  return null;
});

export const handleSuccessfulSave = (candidate) => {
  batch(() => {
    if (candidate.phoneVerified) {
      oldNumber.value = candidate?.phoneNo;
      newNumber.value = candidate?.phoneNo;
      hasPreviouslyUnverifiedNumber.value = false;
      verifyResult.value = null;
      codeSent.value = false;
      otpCode.value = "";
    }
  });
};

export const callElixirApi = async (endpoint, params) => {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  try {
    const res = await fetch(`${apiBase}${endpoint}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.jwt} `,
      },
      body: JSON.stringify({ user: user.user.id, ...params }),
    });

    return res.json();
  } catch (e) {
    console.error("Calling API failed", e);
    return { status: "Sorry, something went wrong." };
  }
};

export const sendCode = async (whatsapp = false) => {
  batch(() => {
    callingApi.value = true;
    verifyResult.value = null;
    otpCode.value = "";
  });
  const res = await callElixirApi("/verify/phone", {
    phone: newNumber.value,
    whatsapp,
  });
  if (res.status === "success") {
    return batch(() => {
      callingApi.value = false;
      codeSent.value = true;
    });
  } else {
    callingApi.value = false;
  }
};

export const verifyCode = async () => {
  const res = await callElixirApi("/verify/phone/confirm", {
    phone: newNumber.value,
    code: otpCode.value,
  });
  verifyResult.value = res?.status;
};

export const OtpForm = () => {
  useSignals();

  return (
    <div className="my-2">
      <TextField
        fullwidth
        value={otpCode.value || ""}
        onInput={(e) => (otpCode.value = e.currentTarget.value)}
        label="Enter your OTP Code"
      />
      <Button onClick={() => verifyCode()}>
        {callingApi.value ? "Verifying...." : "Verify Code"}
      </Button>
    </div>
  );
};

export const SendCodeButton = () => {
  useSignals();
  if (callingApi.value) return <p>Sending...</p>;
  return (
    <div className="flex flex-col sm:flex-row gap-2">
      <Button onClick={() => sendCode(true)}>Send OTP Code (WhatsApp)</Button>
      <Button onClick={() => sendCode()}>Send OTP Code (SMS)</Button>
    </div>
  );
};

export const ResendCodeButton = () => {
  useSignals();
  if (callingApi.value) return <p>Sending...</p>;
  return (
    <div className="flex flex-col sm:flex-row gap-2">
      <Button onClick={() => sendCode(true)}>Resend OTP Code (WhatsApp)</Button>
      <Button onClick={() => sendCode()}>"Resend OTP Code (SMS)"</Button>
    </div>
  );
};

export const FormSection = () => {
  useSignals();
  if (verifyResult.value === "success") return null;
  if (verifyResult.value === "Code expired or already used")
    return (
      <div>
        <ResendCodeButton />
      </div>
    );
  return (
    <div>{showOtpForm.value === true ? <OtpForm /> : <SendCodeButton />}</div>
  );
};

export const ValidationResult = () => {
  useSignals();
  if (!verifyStatusMessage.value) return null;
  let style = "";
  const [level, message] = verifyStatusMessage.value;
  switch (level) {
    case "info":
      style = "text-white";
      break;
    case "success":
      style = "text-green-600";
      break;
    case "warn":
      style = "text-orange-600";
      break;
    case "error":
      style = "text-red-600";
      break;
    default:
      style = "text-white";
  }

  return <p className={`${style}`}>{message}</p>;
};

export const PhoneNumberValidationForm = () => {
  const Form = () => {
    return (
      <div className="mb-2">
        <FormSection />
      </div>
    );
  };

  useSignals();
  if (verificationFailed.value) return <Form />;
  if (
    shouldRevalidateNumber.value === false &&
    hasPreviouslyUnverifiedNumber.value === false
  )
    return null;
  return <Form />;
};
