import { Button } from "components/Button";
import { data } from "../Messages/data";
import { UserProfile } from "components/UserProfile/UserProfile";
import React from "react";
import styled from "styled-components";
import videocall1 from "images/videocall1.png";
import videocall2 from "images/videocall2.png";
export const VideoChat = React.memo(() => {
  return (
    <Container>
      <SideBar>
        <Scroll>
          {data.map((chat, index) => (
            <UProfile>
              <UserProfile name={chat.user.name} userImage={chat.user.avatar} />
              {chat.user.active ? <p> {chat.user.duration}</p> : null}
            </UProfile>
          ))}
        </Scroll>
        <ExitButtonStyle>
          <Button button> Exit Messages</Button>
        </ExitButtonStyle>
      </SideBar>
      <VideoCall1>
        <h2>Phil Anselmo</h2>
      </VideoCall1>
      <VideoCall2>
        <h2>Jason Newstead</h2>
      </VideoCall2>
    </Container>
  );
});

const Container = styled.div(
  () => `
  display: flex;
  overflow: hidden;
  height: calc(100vh - 80px);
  height: calc(100svh - 80px);
  padding: 0px;
  `
);

const SideBar = styled.div(
  () => `
  background-color: black;
  min-width: 361px;
  position: relative;
  
  `
);

const Scroll = styled.div`
height: calc(100vh - 158px);
height: calc(100svh - 158px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #323232;
  }

  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #f1f1f1;
  }
`;

const UProfile = styled.div(
  ({ activeUser }) => `
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:${activeUser ? "white" : "#232424"};
  margin-bottom: 1px;
  & svg{
      margin-right: 15px;
      cursor: pointer;
  }
  & img{
      margin-left: 5px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
  }

  & p{
    color: rgba(255, 255, 255, 0.4);
    margin-right: 17px;
    font-size: 16px;
  }
  `
);

const ExitButtonStyle = styled.div`
  bottom: 0;
  left: 0px;
  width: 100%;
  & button {
    width: 100%;
    padding: 30px 0px;
    border-radius: 0px;
    font-size: 16px;
  }
`;
const VideoCall1 = styled.div`
  position: relative;
  margin-right: -8px;
  width: 100%;
  background-image: url(${videocall1});
  background-repeat: no-repeat;
  background-size: cover;
  & h2 {
    background: rgba(0, 0, 0, 0.3);
    font-size: 16px;
    padding: 10px 10px;
    color: white;
    top: 5%;
    border-radius: 5px;
    width: fit-content;
    position: absolute;
    right: 5%;
  }
`;
const VideoCall2 = styled.div`
  position: relative;
  width: 100%;
  background-image: url(${videocall2});
  background-repeat: no-repeat;
  background-size: cover;
  & h2 {
    background: rgba(0, 0, 0, 0.3);
    font-size: 16px;
    padding: 10px 10px;
    color: white;
    top: 5%;
    border-radius: 5px;
    width: fit-content;
    position: absolute;
    right: 5%;
  }
`;
