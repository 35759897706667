import { faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";

import { Colors } from "styles/Colors";
import { Icon } from "components/Icon";

export const Rating = ({
  rating,
  style = {},
  onMouseEnter = null,
  onMouseLeave = null,
  onClick = null,
  iconSize = 24,
}) => {
  let ratingAdder = Math.round(rating * 2) / 2; // round to nearest 0.5

  const icons = [];

  while (icons.length < 5) {
    if (ratingAdder >= 1) {
      icons.push(faStar);
      ratingAdder -= 1;
    } else if (ratingAdder >= 0.5) {
      icons.push(faStarHalf);
      ratingAdder -= 0.5;
    } else {
      icons.push(null);
    }
  }

  return (
    <Container style={style}>
      {icons.map((icon, i) => (
        <IconContainer
          className="fa-layers"
          key={i}
          onMouseEnter={() => onMouseEnter && onMouseEnter(i + 1)}
          onMouseLeave={() => onMouseLeave && onMouseLeave()}
          onClick={() => onClick && onClick(i + 1)}
          iconSize={iconSize}
        >
          <Icon
            icon={faStar}
            color={"rgba(255, 255, 255, 0.2)"}
            size={iconSize}
          />
          {icon && <Icon icon={icon} color={Colors.green} size={iconSize} />}
        </IconContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div(({ onClick, iconSize }) => ({
  width: iconSize + 2,
  height: iconSize,
  cursor: onClick ? "pointer" : undefined,

  svg: {
    width: `${iconSize}px !important`,
  },
}));
