import { analytics, trackingTypes } from "utils/analytics";
import {
  clearVacancyResponse,
  selectVacancyResponse,
  setSelectedVacancy,
  updateVacancyResponse,
} from "store/reducers/vacancyReducer";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { headerHeight } from "styles/Layout";
import { Icon } from "components/Icon";
import { Modal } from "components/Modal";
import { performFacialRecognition } from 'Context'
import { selectSelectedVacancy } from "store/reducers/vacancyReducer";
import { UploadingFader } from "components/UploadingFader";
import { useApplyForJobMutation, useGetVideoUploadUrlsMutation, useUpdateApplicationMutation } from "store/userAPI";
import { useAuthState } from "Context";
import { useDispatch } from "react-redux";
import { useLogger } from "hooks/useLogger";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import bgImage from 'images/recorder-bg-1.jpg'
import React, { useCallback, useEffect, useState } from "react";
import VideoRecorder from "components/VideoRecorder/VideoRecorder";


export const ApplicationVideo = React.memo(() => {
  const { logger } = useLogger()

  const BASE_THUMBNAIL_URL = process.env.REACT_APP_BASE_THUMBNAIL_URL

  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails } = useAuthState()

  // Selectors / stores
  const selectedVacancy = useSelector(selectSelectedVacancy);
  const vacancyResponse = useSelector(selectVacancyResponse);


  // Mutations
  const [updateApplication] = useUpdateApplicationMutation();
  const [applyForJob] = useApplyForJobMutation();
  const [getVideoUploadUrls] = useGetVideoUploadUrlsMutation();

  // Query param to control which index we're updating - used by the "rerecord" or "resume" functionality
  const { index: updateIndexParam } = useParams();
  // Current active index - set in useEffects later and increments on each answer
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showNextQuestionAlert, setShowNextQuestionAlert] = useState(false);
  const [isUploading, setIsUploading] = useState(false)
  const [killRecording, setKillRecording] = useState(false)

  /**
   * Sets index to update from params on load
   * Checks that it's a number
   */
  useEffect(() => {
    if (!updateIndexParam || isNaN(updateIndexParam)) return
    setCurrentQuestionIndex(Number(updateIndexParam))
  }, [selectedVacancy.id, updateIndexParam])

  /**
   * Logs the vacancy responses to the database
   * Refactored from `comeBackLater` since its shared code
   * Now takes answer and answerIndex due to Redux Toolkit not letting us await a dispatch to get a state update on `VacancyResponse`
   */
  const logApplicationToDb = async (answer, answerIndex) => {
    let response;

    // New application - create
    if (!selectedVacancy.applicationId) {
      logger.info(`Logging new application`, { type: "ApplicationVideo", vacancyId: selectedVacancy.id, answer });
      // New application
      analytics.track(trackingTypes.APPLICATION_STARTED, {
        vacancy: selectedVacancy.id,
        company: selectedVacancy.companyId,
        userId: userDetails.id
      })
      try {
        response = await applyForJob({
          vacancyId: selectedVacancy.id,
          answers: answer ? [answer] : [],
          status: "INCOMPLETED",
        });

        if (response?.error) {
          if (response?.error?.data?.message?.indexOf('already applied') > -1) {
            logger.error(`User already applied for vacancy: ${selectedVacancy.id}`)
            throw response?.error.data
          } else {
            throw response.error
          }
        }
      } catch (ex) {
        logger.error("Failed to log new application", { error: ex, type: "ApplicationVideo" })
        throw ex
      }
      // We have the ID - update local store so future runs update instead
      dispatch(setSelectedVacancy({ ...selectedVacancy, applicationId: response.data.id }))
    } else {
      console.log(`Updating existing application`,
        {
          type: "ApplicationVideo",
          vacancyId: selectedVacancy.id,
          answer,
          answerIndex,
          applicationId: selectedVacancy.applicationId
        });
      response = await updateApplication({
        application: {
          id: selectedVacancy.applicationId,
          vacancyId: selectedVacancy.id,
          status: "INCOMPLETED",
        },
        answer: answer,
        index: answerIndex
      });
    }

    if (response?.error) {
      logger.error(`Failed to log application attempt`, { error: response, type: "ApplicationVideo", vacancyId: selectedVacancy.id })
      throw response.error;
    } else {
      analytics.track(trackingTypes.APPLICATION_UPDATED, {
        vacancy: selectedVacancy.id,
        application: selectedVacancy.applicationId,
        company: selectedVacancy.companyId,
        userId: userDetails.id,
        answer
      })
    }
  }

  /**
   * Submits a video
   */
  const submitVideo = async (e) => {
    try {
      setIsUploading(true)
      logger.info(`Submitting answer`, { recordingId: e.recordingId, currentQuestionIndex })

      // Get signed URLs for S3, and chuck our files over
      const res = await getVideoUploadUrls({ name: e.recordingId })
      const videoUrl = res.data.videoUrl
      const duration = e.duration
      const thumbnailUrl = res.data.thumbnailUrl
      logger.info(`URLS for uploads`, { videoUrl, thumbnailUrl, duration })


      // Upload video to S3 directly if this was an upload and not a recording
      if (!e.wasRecorded) {
        const videoRes = await fetch(videoUrl, {
          method: 'PUT',
          headers: { 'Content-Type': 'video/mp4' },
          body: e.video
        });
        if (!videoRes.ok) {
          const res = "There was an issue uploading your video, please refresh and try again."
          logger.error(res, { error: videoRes })
          throw Error(res)
        }
      }

      // Upload thumbnail if it was selected
      if (e.thumbnail) {
        const thumbnailRes = await fetch(thumbnailUrl, {
          method: 'PUT',
          headers: { 'Content-Type': 'image/jpeg' },
          body: e.thumbnail
        });
        if (!thumbnailRes.ok) {
          const res = "There was an issue uploading the thumbnail, we'll try to auto generate one instead."
          logger.error(res, { error: thumbnailRes })
          alert(res)
        }
        // Check to see whether we should perform facial recognition. 
        await checkAndPerformFacialRecognition(e.recordingId)
      }

      // Set the answer in local state
      logger.info(`Setting answer index ${currentQuestionIndex} in local state`, { type: "ApplicationVideo", vacancyId: selectedVacancy.id })
      const answer = {
        question: selectedVacancy.questions[currentQuestionIndex],
        answer: `${e.recordingId}.mp4`,
        thumbnail: `${e.recordingId}.jpg`,
        videoDuration: duration
      }

      // We should be able to await this since we're using RTK. We can in IncludR.
      // But here we are.
      dispatch(
        updateVacancyResponse({
          index: currentQuestionIndex,
          answer,
        })
      )

      // Since we can't await the above state update, we need to pass the actual data to th `logApplicationToDb` to handle it
      // Log the state changes to the DB
      await logApplicationToDb(answer, currentQuestionIndex)
      analytics.track(trackingTypes.ANSWER_SUBMITTED, {
        vacancy: selectedVacancy.id,
        company: selectedVacancy.companyId,
        application: selectedVacancy.applicationId,
        userId: userDetails.id,
        answer
      })
      // If we've answered all questions, move to Application Preview by passing `true` to `handleNext`
      if (vacancyResponse.length >= selectedVacancy.questions.length) {
        return handleNext(true);
      } else {
        // Next question
        return handleNext(false);
      }
    } catch (ex) {
      const res = "An error occurred when submitting your video, please refresh and try again."
      analytics.track(trackingTypes.ANSWER_FAILED, {
        userId: userDetails.id,
        error: ex
      })
      logger.error(res, { error: ex })
      alert(ex.message ? ex.message : res)
    }
    finally {
      setIsUploading(false)
    }
  };

  /**
   * "Come back later" button handler.
   * Saves current progress, clears local state, and navigates to a "progress saved" screen
   */
  const comeBackLater = async (e) => {
    // come back later logic
    try {
      await logApplicationToDb()
      analytics.track(trackingTypes.APPLICATION_COME_BACK_LATER, {
        vacancy: selectedVacancy.id,
        application: selectedVacancy.applicationId,
        company: selectedVacancy.companyId,
        userId: userDetails.id
      })
      dispatch(clearVacancyResponse());
      navigate(`/candidate/progress-saved`);
    } catch (ex) {
      // Handled by `logApplicationToDb()`, we just want to ensure we don't leave the page and clear our data unless it succeeded
    }
  };

  /**
  * Fires when the candidate starts recording
  * Used to flag them as in-progress and report errors
  */
  const handleRecording = async () => {
    try {
      // Only do this if this is the 1st question - we do this again each time the user saves a video and moves to the next step
      if (vacancyResponse.length === 0) {
        await logApplicationToDb()
      }
    } catch (ex) {
      // If this failed then send a kill signal to the recorder
      // Use a number not just a boolean = this lets us repeatedly throw a kill event and the child component will always react to it 
      // Otherwise, setting false when its already false will do nothing
      setKillRecording(Math.round(Math.random() * 1000))
      if (ex.statusCode === 403) {
        alert(ex.message)
        navigate('/candidate/dashboard')
      }
    }
  }

  /**
   * Checks whether facial recognition needs performing, running it if needed.
   */
  const checkAndPerformFacialRecognition = async (filename) => {
    try {
      console.log(`Checking whether facial recognition needs processing...`)
      // Originally tried dispatch and authContect to get tbe user, but it doesn't update and I'm past caring about this React mess.
      // Use whatever is in localStorage.
      const storageUser = localStorage.getItem('currentUser')
      const currentUser = JSON.parse(storageUser)

      let requiresProcessing = false
      // Check for an existing candidate object. Newly registered users have nothing
      if (!currentUser.user.candidate) {
        requiresProcessing = true
      } else {
        // Check each known property. If any are null, we need to process this candidate
        const properties = [
          { actual: 'age', estimated: 'estimatedAge' },
          { actual: 'ethnicity', estimated: 'estimatedEthnicity' },
          { actual: 'gender', estimated: 'estimatedGender' }
        ]
        properties.forEach(property => {
          if (!currentUser.user.candidate[property.actual] && !currentUser.user.candidate[property.estimated]) requiresProcessing = true
        })
      }

      if (requiresProcessing) {
        try {
          console.log(`Performing facial recognition on ${BASE_THUMBNAIL_URL}/${filename}.jpg`)
          await performFacialRecognition(currentUser.user.id, `${BASE_THUMBNAIL_URL}/${filename}.jpg`)
        } catch (ex) {
          console.error("Failed to perform facial recognition", { error: ex })
        }
      }
    } catch (ex) {
      console.error(`Something went wrong during facial recognition checks`, { error: ex })
    }
  }

  /**
   * Next question handler
   */
  const handleNext = useCallback((skip) => {
    if (currentQuestionIndex + 1 < selectedVacancy.questions.length && !skip) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setShowNextQuestionAlert(true)
    } else {
      // Wth do they *think* this is doing? We're doing a truthy check on a Redux query?!
      // Surely this should be `selectedVacancy` but even then.... why?
      // if (selectSelectedVacancy) {
      if (userDetails?.userType) {
        setTimeout(() => {
          return navigate("/job-board/candidate/application-preview");
        }, 300);
      } else {
        return navigate(`/candidate/manage-profile`, {
          state: { from: "/job-board/candidate/application-preview" },
        });
      }
      // } else {
      //   setTimeout(() => {
      //     return navigate("/job-board/candidate/application-preview");
      //   }, 300);
      // }
    }
  }, [currentQuestionIndex, navigate, selectedVacancy.questions.length, userDetails]);


  return <>
    {isUploading && <UploadingFader>
      <div><Icon spin icon={faSpinner} /><span>Uploading, please wait...</span></div></UploadingFader>
    }
    <VideoRecorder
      allowUpload={true}
      headerText={`Q${currentQuestionIndex + 1} ${selectedVacancy.questions[currentQuestionIndex]}`}
      height={`calc(100svh - ${headerHeight}px)`}
      janusServer={process.env.REACT_APP_JANUS_URL ?? 'https://videos-graviton.seezyhire.com/janus'}
      killRecording={killRecording}
      onComplete={(e) => submitVideo(e, currentQuestionIndex)}
      onExit={comeBackLater}
      onRecording={handleRecording}
      requiredVideoLength={process.env.REACT_APP_MIN_APPLICATION_RECORDING_LENGTH}
      showGuide={true}
      thumbnailSelection={true}
      backgroundImage={bgImage}
      maxUploadFilesize={process.env.REACT_APP_MAX_UPLOAD_FILESIZE_MB}
    />
    <Modal
      title="Video submitted"
      actions={[
        { text: "Continue", onClick: () => { setShowNextQuestionAlert(false) } },
      ]}
      isVisible={showNextQuestionAlert}
    >
      <p className="font-medium">Your video has been submitted and your progress has been saved. We'll now take you to the next question.</p>
    </Modal>
  </>
})
