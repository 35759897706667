import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }

    // We have a load of nested divs, any of which could scroll. Scroll them all up on route change.
    window.scrollTo(0, 0)
    document.querySelector('body').scrollTo(0, 0)
    document.querySelector('#root').scrollTo(0, 0)
    document.querySelector('#root > div').scrollTo(0, 0)
  }, [pathname])

  return children || null
}
