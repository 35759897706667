import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import { useGetCompanyQuery } from "store/userAPI";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useState } from "react";
import styled from "styled-components";

/**
 * Replacement for SectionHeading on the JobPost page. Improved layout in terms of responsive-ness, via Grid.
 * Do not have scope to replace every instance of SectionHeading, so will leave those in place and we should aim to replace over time for consistent placement.
 */
export const NewSectionHeading = React.memo(
  ({
    companyName,
    title,
    actions,
    search,
    placeholder,
    dropdown,
    date,
    caption,
    style,
  }) => {
    const [searchText, setSearchText] = useState("");

    const size = useResponsiveLayout();

    const { data: company } = useGetCompanyQuery(1, {
      skip: !Boolean(companyName),
    });

    return (
      <SectionHeadingStyle
        size={size}
        hasSearch={!!search}
        hasActions={!!actions}
        style={{ ...style }}
      >
        <TitleContainer size={size}>
          {companyName && <h6>{company?.name}</h6>}
          {dropdown && dropdown}
          {date && <DateWrapper>{date}</DateWrapper>}
          {title && <h3>{title}</h3>}
          {caption && <h6>{caption}</h6>}
        </TitleContainer>

        {search ? (
          <SearchBar size={size}>
            <input
              placeholder={placeholder}
              type="text"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
            <Icon icon={faSearch} onClick={() => search(searchText)} />
          </SearchBar>
        ) : null}
        {actions ? (
          <ActionsContainer size={size}>{actions}</ActionsContainer>
        ) : null}
      </SectionHeadingStyle>
    );
  }
);

const actionOnMobile = `
  padding: 20px;
  color: white;
  margin-bottom: 3px;
  margin-top: 10px;
  marign-left: 0;
`;
const SectionHeadingStyle = styled.div(
  ({ size, hasSearch, hasActions }) => `
  display: grid;
  // This is a little bonkers, but we have a lot of re-arranging to do for each viewport size, based on Figma.
  // This is the best way of doing this without excessive show/hide. Would be far simpler if the caption did not separate from the title.
  // Shoud this ever change, this entire block can be dropped for a simple column-wrap arrangement.
  ${
    size.isXl
      ? hasSearch
        ? hasActions
          ? // XLarge+ viewports with search + actions
            `
            grid-template-areas:    'title search actions';
            grid-template-columns: 1fr 3fr 1fr;
            `
          : // XLarge+ viewports with search, no actions
            `
            grid-template-areas:    'title search';
            grid-template-columns: auto 1fr;
            `
        : hasActions
        ? // XLarge+ viewports with actions, no search
          `
            grid-template-areas:    'title actions';
            grid-template-columns: 1fr auto;
                `
        : // Xlarge+ viewports with no search or actions
          `
            grid-template-areas:    'title';
            grid-template-columns: 1fr;
        `
      : size.isLgUp
      ? hasSearch
        ? hasActions
          ? // Large-Xlarge viewports with search + actions
            `
            grid-template-areas:    'title actions'
                                    'search search';
            grid-template-columns: 1fr auto;
        `
          : // Large-Xlarge viewports with search, no actions
            `
            grid-template-areas:    'title search';
            grid-template-columns: auto 1fr;
        `
        : hasActions
        ? // Large-Xlarge viewports with actions, no search
          `
            grid-template-areas:    'title actions';
            grid-template-columns: 1fr auto;
        `
        : // Large-Xlarge viewports with no search or actions
          `
            grid-template-areas: 'title';
            grid-template-columns: 1fr;
        `
      : size.isMdUp
      ? hasSearch
        ? hasActions
          ? // Medium-large viewports with search + actions
            `
            grid-template-areas:    'title actions'
                                    'search search';
            grid-template-columns: 1fr auto;
        `
          : // Medium-large viewports with search, no actions
            `
            grid-template-areas:    'title'
                                    'search';
            grid-template-columns: 1fr;
        `
        : hasActions
        ? // Medium-large viewports with actions, no search
          `
            grid-template-areas:    'title actions';
            grid-template-columns: 1fr;
            `
        : // Medium-large viewports with no search or actions
          `
            grid-template-areas:    'title';
            grid-template-columns: 1fr;
        `
      : size.isSmUp
      ? hasSearch
        ? hasActions
          ? // Small-medium viewports with search + actions
            `
              grid-template-areas:    'title actions'
                                      'search search';
              grid-template-columns: 1fr auto;
          `
          : // Small-medium viewports with search, no actions
            `
              grid-template-areas:    'title'
                                      'search';
              grid-template-columns: 1fr;
          `
        : hasActions
        ? // Small-medium viewports with actions, no search
          `
              grid-template-areas:    'title actions';
              grid-template-columns: 1fr auto;
          `
        : // Small-medium viewports with no search or actions
          `
              grid-template-areas:    'title';
              grid-template-columns: 1fr;
          `
      : hasSearch
      ? hasActions
        ? // Small viewports with search + actions
          `
            grid-template-areas:    'title'
                                    'search'
                                    'actions';
            grid-template-columns: 1fr;
        `
        : // Small viewports with search, no actions
          `
            grid-template-areas:    'title'
                                    'search';
            grid-template-columns: 1fr;
        `
      : hasActions
      ? // Small viewports with actions, no search
        `
            grid-template-areas:    'title'
                                    'actions';
            grid-template-columns: 1fr;
        `
      : // Small viewports with no search or actions
        `
            grid-template-areas:    'title';
            grid-template-columns: 1fr;
        `
  }
  gap: 20px 32px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: ${size.isMdUp ? "20px" : "0px"};
  & h6 {
    color: #8E8C90;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }
  & h3 {
    color: white;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
  }
  & h2 {
    color: white;
    font-family: Archivo;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
  }
  & button {
    color: white;
    ${!size.isMdUp ? actionOnMobile : null}
    
  }
`
);

const TitleContainer = styled.div(
  ({ size }) => `
    grid-area: title;
    display: grid;
    gap: 10px;

    // Dropdown
    & > div:nth-child(1) > div {
        font-size: ${size.isMdUp ? "26px" : "16px"};
        font-weight: 700;
        text-transform: uppercase;
      }
`
);

const SearchBar = styled.div(
  ({ size }) => `
  grid-area: search;
  position: relative;

  & input {
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(30px);
    border-radius: 105px;
    padding: 15px 20px;
    outline: none;
    border: none;
    color: white;
    ::placeholder{
        font-weight: 500;
        font-size: 16px;
        color: white;
   }
    
  }
   svg {
    cursor: pointer;
    position: absolute;
    right: 26px;
    top: 16px;
`
);

const ActionsContainer = styled.div(
  ({ size }) => `
      grid-area: actions;
      display:flex;
      gap: 10px;
      align-items: center;
      justify-content: ${size.isMdUp ? "flex-end" : "flex-start"};
      flex-wrap: wrap;
      button {
        min-width: 80px;
      }
  `
);

const DateWrapper = styled.h2`
  color: #878787 !important;
  font-size: 18px;
`;
