import { useResponsiveLayout } from 'hooks/useResponsiveLayout'
import { useSearchParams } from 'react-router-dom'
import React, { useCallback, useEffect, useRef, useState } from 'react'

export const RecorderFooter = React.memo(({
  allowUpload,
  hasRecorded,
  isRecording,
  isStreaming,
  onConfirmRecording,
  onExit,
  onDiscardRecording,
  onFileUploaded,
  onStartRecording,
  onStartStream,
  onStopRecording,
  onStopStream,
  recordedDuration,
  recordingStartTime,
  requiredVideoLength,
}) => {
  const size = useResponsiveLayout()
  const [searchParams] = useSearchParams()
  const uploadRef = useRef(null)
  const [recordingTime, setRecordingTime] = useState(0)

  // Check for URL key on load
  useEffect(() => {
    const isUpload = searchParams.get("upload")
    if (`${isUpload}` === '1') {
      uploadRef?.current?.click()
    }
  }, [searchParams])



  // Calculate elapsed recording time whilst actively recording
  // This is just a counter in local state and may not accurately reflect the actual duration
  // `recordingTime` should be considered as an indicator, use actual duration for anything important via `recordedDuration`
  useEffect(() => {
    if (!isRecording) {
      setRecordingTime(0)
    } else {
      const intervalId = setInterval(() => {
        const diff = new Date().valueOf() - recordingStartTime
        setRecordingTime(Math.floor(diff / 1000))
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }, [isRecording, recordingStartTime])

  /**
   * Formats number of seconds to HH:MM:SS
   * @returns formatted timestamp
   */
  const formatRecordedDuration = useCallback(() => {
    // If we're recording, use `recordingTime` from our local state counter
    // If we're not, use the actual recorded duration
    // Sometimes this is stuck as Infinity because of an annoying webkit glitch - if so use the `recordingTime` after all.
    const _recordedDuration = isRecording || recordedDuration === Infinity ? recordingTime : recordedDuration

    var h = Math.floor(_recordedDuration / 3600)
    var m = Math.floor((_recordedDuration % 3600) / 60)
    var s = Math.floor((_recordedDuration % 3600) % 60)
    return `${h > 9 ? h : `0${h}`}:${m > 9 ? m : `0${m}`}:${s > 9 ? s : `0${s}`
      }`
  }, [isRecording, recordedDuration, recordingTime])

  return (
    <footer className={isStreaming || isRecording || hasRecorded ? 'docked' : 'float'}>
      <div className="wrapper">
        <div className="grid row centered">
          {(isRecording || hasRecorded) &&
            <div className="grid row centered recording-info">
              {isRecording && <span className="recording-icon"></span>}
              <span className="recording-timestamp">
                {formatRecordedDuration()}
              </span>
            </div>
          }
        </div>
        <div className="grid buttons">
          {!isStreaming && !hasRecorded && (
            <>
              <button className={buttonStyle}
                type="button"
                onClick={
                  onStartStream}
              >
                Start camera
              </button>
              {allowUpload && (
                <>
                  <button className={buttonStyle} type="button" onClick={() => uploadRef.current?.click()} title="Upload file">
                    Upload
                  </button>
                  <input
                    ref={uploadRef}
                    type="file"
                    accept="video/mp4"
                    onChange={(e) =>
                      e.target.files ? onFileUploaded(e.target.files[0]) : null
                    }
                    style={{ display: 'none' }}
                  /></>)}
              {
                size.isMdUp && <button className={outlineButtonStyle}
                  type="button"
                  onClick={
                    onExit}
                >
                  Come back later
                </button>
              }
            </>
          )}

          {isStreaming && !hasRecorded && !isRecording && (
            <button className={buttonStyle} type="button" onClick={() => onStopStream()}>
              Stop camera
            </button>
          )}

          {!isRecording && isStreaming && (
            <button className={buttonStyle} type="button" onClick={() => onStartRecording()} title="Start recording">
              Record
            </button>
          )}
          {isRecording && (
            // Tailwind is a PitA. 
            // <button className={`${buttonStyle} bg-red-800 border-red-800`}
            <button className={`border-2 border-solid text-white py-4 px-6 rounded-lg bg-red-800 border-red-800`}
              type="button"
              onClick={onStopRecording}
              disabled={recordingTime <= requiredVideoLength}
              title="Stop recording"
            >
              Stop
            </button>
          )}
          {!isRecording && hasRecorded && (
            <button className={buttonStyle} type="button" onClick={onDiscardRecording}>
              Start Again
            </button>
          )}
          {!isRecording && hasRecorded && (
            <button className={buttonStyle} type="button" onClick={onConfirmRecording} title="Save video">
              Save
            </button>
          )}
        </div>
      </div>
    </footer>
  )
})

const outlineButtonStyle = `border-2 border-solid border-secondary text-white py-4 px-6 rounded-lg`
const buttonStyle = `bg-secondary ${outlineButtonStyle}`