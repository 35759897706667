import React from "react";
import styled from "styled-components";

import { Colors } from "styles/Colors";

export const ErrorMessage = React.memo(({ error, Container = null }) => {
  const ContainerComponent = Container || ErrorContainer;

  if (!error) return null;

  return (
    <ContainerComponent>
      <ErrorMessageText>{error}</ErrorMessageText>
    </ContainerComponent>
  );
});

const ErrorContainer = styled.div`
  margin-top: 10px;
`;

const ErrorMessageText = styled.span`
  color: ${Colors.red};
`;
