import { Outlet } from "react-router-dom";
import React, { useEffect } from "react";
import styled from "styled-components";

import { Colors } from "styles/Colors";
import { Header } from "./Header/Header";
import { headerVariant } from "./Header/state";
import { useSignals } from "@preact/signals-react/runtime";

export const PageLayout = ({ candidate, variant, noHeader }) => {
  useSignals()
  useEffect(() => {
    headerVariant.value = variant
  }, [variant])
  return (
    <Container candidate={candidate}>
      <Header
        candidate={candidate}
        noHeader={noHeader}
      />
      <Outlet />
    </Container>
  )
};

const Container = styled.div(
  ({ candidate }) => `
  width: 100%;
  height: 100%;
  background: ${candidate ? `linear-gradient(180deg, #1C1D1D 0%, #250140 100%)` : Colors.ash
    };
  padding-bottom: 100px;
`
);
