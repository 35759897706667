import * as Yup from 'yup'
import { AuthGuard } from 'components/AuthGuard'
import { Button } from 'components/Button'
import { Colors } from 'styles/Colors'
import { faClose, faGlobe } from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTiktok,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { FileUpload } from 'components/FileUpload'
import { FormikProvider } from 'formik'
import { FormikTextField, RadioComponent } from 'components/form'
import { Icon } from 'components/Icon'
import { SectionHeading } from 'components/Headings/SectionHeading'
import { updateCompany, uploadMedia, useAuthDispatch } from 'Context'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import VideoCardJobBoard from 'components/Cards/VideoCardJobBoard'

const COMPANY_SIZES = [
  '1-10',
  '11-50',
  '51-200',
  '201-500',
  '501-1000',
  '1001-5000',
  '5001-10000',
  '10000+',
]

// Regex checks for socials. Not bullet proof, but best we can do with given requirements
// "Loose" regex checks against channel names
// Needs to NOT be a link, and not contain certain characterrs
const urlRegex = /^https?:\/\/.*\.{1}.?/i;

// "Strict" regex checks where the user has inputted a full URL
// Tries to checks the provided link is at least from the right domain etc
const socialRegex = {
  instagram: {
    // An Instagram username is limited to 30 characters and must contain only letters, numbers, periods, and underscores.
    loose: [
      /^[A-Za-z0-9_.-]{1,30}$/
    ],
    strict: [
      /^http(s)?:\/\/(www\.)?instagram.com\/[A-Za-z0-9_.-]{1,30}$/i,
    ],
  },
  facebook: {
    // Facebook usernames can only contain alphanumeric characters (A–Z, 0–9) and periods (".")
    loose: [
      /^[A-Za-z0-9.]+$/
    ],
    strict: [
      /^http(s)?:\/\/(www\.)?fb.me\/[A-Za-z0-9.]+$/i,
      /^http(s)?:\/\/(www\.)?facebook.com\/[A-Za-z0-9.]+$/i
    ],
  },
  linkedIn: {
    // LinkedIn custom URL must contain 3-100 letters or numbers. Please do not use spaces, symbols, or special characters
    loose: [
      /^[A-Za-z0-9]{3,100}$/
    ],
    strict: [
      /^http(s)?:\/\/(www\.)?linkedin.com\/company\/[A-Za-z0-9]{3,100}$/i
    ],
  },
  youtube: {
    // YouTube appears to have little to no restrictions. Wild.
    // Just check whatever is given doesn't start with @ since YouTube adds that itself.
    loose: [
      /^(?!@).+$/i
    ],
    strict: [
      /^http(s)?:\/\/(www\.)?youtube.com\/@.+$/i,
    ],
  },
  tiktok: {
    // TikTok usernames are limited to 24 characters. Usernames can only contain letters, numbers, periods, and underscores.
    loose: [
      /^[A-Za-z0-9_.]{1,24}$/
    ],
    strict: [
      /^http(s)?:\/\/(www\.)?tiktok.com\/@[A-Za-z0-9_.]{1,24}$/i,
    ],
  }
}
export const AboutCompany = React.memo(() => {
  const [selectedImage, setSelectedImage] = useState()
  const [addresses, setAddresses] = useState([])
  const [sizes, setSizes] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  // ******** Tips - intersection observers
  const [showInfoVideo, setShowInfoVideo] = useState(false)
  const [currentTip, setCurrentTip] = useState('Tell me about your job')

  const intersectionObserverRef = useRef()
  const companyDetailsIntersectionRef = useRef()
  const socialsIntersectionRef = useRef()

  useEffect(() => {
    intersectionObserverRef.current = new IntersectionObserver(
      (e) => {
        console.log(e)
        e.forEach((entry, i) => {
          if (entry.isIntersecting) {
            const tip = entry.target.attributes['data-tip'].value
            console.log(tip)
            setCurrentTip(tip)
          }
        })
      },
      {
        rootMargin: '0px 0px -50% 0px',
        threshold: 0,
      },
    )
  }, [])

  useEffect(() => {
    if (intersectionObserverRef.current) {
      intersectionObserverRef.current.observe(
        companyDetailsIntersectionRef.current,
      )
      intersectionObserverRef.current.observe(socialsIntersectionRef.current)

      return () => {
        intersectionObserverRef.current.disconnect()
      }
    }
  }, [intersectionObserverRef])
  // ********  End of Tips - intersection observers

  const navigate = useNavigate()

  const size = useResponsiveLayout()
  const dispatch = useAuthDispatch()

  /**
   * @param {keyof typeof socialRegex} checks - regex check group (youtube/facebook/instagram)
   * @param {string} value - value to check
   * @returns {boolean} - pass/fail
   */
  const multiRegexCheck = (checks, value) => {
    if (!value) return true
    // If the value provided contains "http" we're going to check against `checks.strict` array
    // If it doesn't, we're going to check against `checks.loose` array
    const checkGroup = value.toLowerCase().indexOf('http') > -1 ? 'strict' : 'loose'
    let isValid = false;
    checks[checkGroup].forEach(check => {
      let res = check.test(value.toLowerCase())
      if (res) isValid = true;
    });
    return isValid
  }
  
  const validationSchema = Yup.object().shape({
    company: Yup.object().shape({
      description: Yup.string().required('description required'),
      size: Yup.array(),
      instagram: Yup.string().test('instagram', 'Enter a valid Instagram page.', (value) => multiRegexCheck(socialRegex.instagram, value)),
      facebook: Yup.string().test('facebook', 'Enter a valid Facebook page.', (value) => multiRegexCheck(socialRegex.facebook, value)),
      linkedIn: Yup.string().test('linkedin', 'Enter a valid LinkedIn company.', (value) => multiRegexCheck(socialRegex.linkedIn, value)),
      youtube: Yup.string().test('youtube', 'Enter a valid YouTube channel.', (value) => multiRegexCheck(socialRegex.youtube, value)),
      tiktok: Yup.string().test('tiktok', 'Enter a valid TikTok channel.', (value) => multiRegexCheck(socialRegex.tiktok, value)),
      website: Yup.string().matches(urlRegex, "Enter a valid url"),
    }),
  })

  const onSubmit = async () => {
    // validateForm();
    // if (!isValid) return alert("Please complete form.");
    if (!addresses) return alert('Please add an address.')
    if (!sizes) return alert('Please select a size.')
    if (!values.company.description) return alert('Please add a description.')

    const avatarUrl = await uploadAvatar()

    const payload = {
      description: values.company.description,
      avatar: avatarUrl,
      address: addresses,
      size: sizes,
      instagram: values.company.instagram || '',
      facebook: values.company.facebook || '',
      linkedIn: values.company.linkedIn || '',
      youtube: values.company.youtube || '',
      tiktok: values.company.tiktok || '',
      website: values.company.website || '',
    }

    try {
      let response = await updateCompany(dispatch, payload)
      if (!response) return
      if (response.error) return alert(response.message)
      else {
        navigate('/employer/company/uploadvideo')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const formikbag = useFormik({
    initialValues: {
      company: {
        description: '',
        instagram: '',
        facebook: '',
        linkedIn: '',
        youtube: '',
        tiktok: '',
        website: '',
        address: '',
        size: null,
      },
    },
    validationSchema,
    onSubmit,
  })

  const uploadAvatar = async () => {
    let data = new FormData()
    data.append('file', selectedImage)

    try {
      let response = await uploadMedia(data)
      if (!response) return alert('An error has occured.')
      if (response.error) return alert(response.message)

      return response?.url
    } catch (error) {
      console.log(error)
    }
  }

  const { values, setFieldValue } = formikbag

  const {
    company: { address },
  } = values

  /** Address handler  */
  useEffect(() => {
    if(!addresses) return
    if (values?.company?.address) {
      const _addresses = addresses
      _addresses[0] = values?.company?.address
      setAddresses(addresses)
    } else {
      const _addresses = addresses
      _addresses[0] = undefined
      setAddresses(addresses)
    }
  }, [addresses, values?.company?.address])

  const deleteAddress = (index) => {
    setAddresses(addresses.filter((_, i) => i !== index))
  }

  return (
    <AuthGuard criteria={[{ value: 'ADMIN', check: 'membership.role' }]}>
      <div>
        <Container size={size}>
          <SectionHeading companyName title="about your company" />
          {size.isMdUp ? (
            <>
              <hr />
            </>
          ) : (
            <p>Introduce your company to candidates</p>
          )}
          <ContentWrapper size={size} className="mt-[64px]">
            <div className="grid gap-[32px]">
              <FormikProvider value={formikbag}>
                <section
                  className="grid gap-[16px]"
                  ref={companyDetailsIntersectionRef}
                  data-tip="Get spicy & stand out"
                >
                  <FormikTextField
                    size={size}
                    placeholder="Tell us about your company"
                    fullWidth
                    Container={TextAreaContainer}
                    type="textArea"
                    name="company.description"
                    spacer={false}
                  />
                </section>
                <hr className="m-0" />
                <section className="grid gap-[16px]">
                  <Title>Your logo</Title>
                  <SubTitle>
                    Upload your company logo (PNG, JPG or SVG)
                  </SubTitle>
                  <FileUpload
                    type={'image'}
                    setSelectedFile={setSelectedImage}
                  />
                </section>
                <hr className="m-0" />
                <section className="max-w-screen-md grid gap-[16px]">
                  <h2 className="text-white">Primary Location</h2>
                  <SubTitle>Your main office / headquarters</SubTitle>
                  <FormikTextField
                    placeholder="Location"
                    fullWidth
                    name="company.address"
                    value={values?.company?.address}
                    spacer={false}
                  />
                </section>
                {(values?.company.address?.length > 0 ||
                  (addresses[0] && addresses[0].length > 0)) && (
                  <>
                    <hr className="m-0" />
                    <section className="max-w-screen-md grid gap-[16px]">
                      <h2 className="text-white">Other locations</h2>
                      <InputButtonGroup size={size}>
                        <FormikTextField
                          placeholder="New location"
                          fullWidth
                          name="company.address_other"
                          value={values?.company?.address_other}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setAddresses([
                                ...addresses,
                                values?.company?.address_other,
                              ])
                              setFieldValue('company.address_other', '')
                            }
                          }}
                          spacer={false}
                        />
                        <button
                          className="bg-secondary text-white py-6 px-8 rounded-lg hover:bg-secondary/80 h-full"
                          disabled={!values?.company?.address_other}
                          onClick={() => {
                            setAddresses([
                              ...addresses,
                              values?.company?.address_other,
                            ])
                            setFieldValue('company.address_other', '')
                          }}
                        >
                          Add Location
                        </button>
                      </InputButtonGroup>
                      {addresses.length > 1 && (
                        <>
                          {addresses?.slice(1).map((address, index) => (
                            <ListItem key={index}>
                              <div className="flex flex-row gap-4">
                                <span>{index + 1}.</span>
                                <span>{address}</span>
                              </div>
                              <button
                                type="text"
                                onClick={() => {
                                  deleteAddress(index + 1)
                                }}
                              >
                                <Icon size={size} icon={faTrashCan} />
                              </button>
                            </ListItem>
                          ))}
                        </>
                      )}
                    </section>
                  </>
                )}

                <hr className="m-0" />
                <section className="grid gap-[16px]">
                  <Title>Company Size</Title>
                  <SubTitle>How many people work at your company?</SubTitle>
                  <RadiosContainer size={size}>
                    {COMPANY_SIZES.map((size, index) => {
                      return (
                        <RadioComponent
                          size={size}
                          item={size}
                          onClick={() => {
                            if (sizes.indexOf(size) === -1) {
                              sizes.push(size)
                              setSizes([...sizes])
                              console.log('sizes', sizes)
                            } else {
                              sizes.splice(sizes.indexOf(size), 1)
                              setSizes([...sizes])
                              console.log('sizes', sizes)
                            }
                          }}
                          selected={sizes.indexOf(size) > -1}
                        />
                      )
                    })}
                    <RadioComponent
                      size={size}
                      item={selectAll === false ? 'Select All' : 'Deselect All'}
                      onClick={() => {
                        if (selectAll === true) {
                          setSelectAll(false)
                          setSizes([])
                        } else {
                          setSelectAll(true)
                          setSizes([...COMPANY_SIZES])
                        }
                      }}
                      selected={sizes.length === COMPANY_SIZES.length}
                    />
                  </RadiosContainer>
                </section>
                <hr className="m-0" />
                <section
                  className="grid gap-[16px]"
                  ref={socialsIntersectionRef}
                  data-tip="Get your socials involved"
                >
                  <Title>Social accounts</Title>
                  <SubTitle>
                    Share more with your applicants through social media
                  </SubTitle>
                  <Socials>
                    <div>
                      <Icon
                        icon={faInstagram}
                        className="bg-white text-black rounded-full w-12 h-12 p-5"
                        style={{
                          color: '#1C1D1D',
                        }}
                      />

                      <FormikTextField
                        placeholder="instagram"
                        fullWidth
                        type="text"
                        name="company.instagram"
                        spacer={false}
                      />
                    </div>
                    <div>
                      <Icon
                        icon={faFacebookF}
                        className="bg-white text-black rounded-full w-12 h-12 p-5"
                        style={{
                          color: '#1C1D1D',
                        }}
                      />{' '}
                      <FormikTextField
                        placeholder="facebook"
                        fullWidth
                        type="text"
                        name="company.facebook"
                        spacer={false}
                      />
                    </div>
                    <div>
                      <Icon
                        icon={faLinkedinIn}
                        className="bg-white text-black rounded-full w-12 h-12 p-5"
                        style={{
                          color: '#1C1D1D',
                        }}
                      />{' '}
                      <FormikTextField
                        placeholder="linkedIn"
                        fullWidth
                        type="text"
                        name="company.linkedIn"
                        spacer={false}
                      />
                    </div>
                    <div>
                      <Icon
                        icon={faYoutube}
                        className="bg-white text-black rounded-full w-12 h-12 p-5"
                        style={{
                          color: '#1C1D1D',
                        }}
                      />{' '}
                      <FormikTextField
                        placeholder="youtube"
                        fullWidth
                        type="text"
                        name="company.youtube"
                        spacer={false}
                      />
                    </div>
                    <div>
                      <Icon
                        icon={faTiktok}
                        className="bg-white text-black rounded-full w-12 h-12 p-5"
                        style={{
                          color: '#1C1D1D',
                        }}
                      />{' '}
                      <FormikTextField
                        placeholder="tiktok"
                        fullWidth
                        type="text"
                        name="company.tiktok"
                        spacer={false}
                      />
                    </div>
                    <div>
                      <Icon
                        icon={faGlobe}
                        className="bg-white text-black rounded-full w-12 h-12 p-5"
                        style={{
                          color: '#1C1D1D',
                        }}
                      />{' '}
                      <FormikTextField
                        placeholder="your website"
                        fullWidth
                        type="text"
                        name="company.website"
                        spacer={false}
                      />
                    </div>
                  </Socials>
                </section>
              </FormikProvider>
            </div>
            <InfoSidebar
              size={size}
              style={{ display: size.isMdUp ? 'block' : 'none' }}
            >
              <div className="wrapper">
                <VideoCardJobBoard
                  videoData="https://d2khiudbypa5a9.cloudfront.net/employer-welcome.mp4"
                  width={'100%'}
                  height={'auto'}
                  action={<></>}
                  preload="metadata"
                />
                <div className="content">
                  <h2>“{currentTip}”</h2>
                </div>
              </div>
            </InfoSidebar>
          </ContentWrapper>
        </Container>
        <BottomContainer size={size}>
          <ButtonsGroup size={size}>
            {!size.isMdUp && (
              <Button
                button
                size="large"
                fullWidth={!size.isSmUp}
                onClick={() => setShowInfoVideo(true)}
                type={'alt'}
              >
                Show Guide Video
              </Button>
            )}
            <Button
              button
              onClick={onSubmit}
              size="large"
              fullWidth={!size.isSmUp}
            >
              Next
            </Button>
          </ButtonsGroup>
        </BottomContainer>
      </div>
      {showInfoVideo && !size.isMdUp && (
        <InfoVideo>
          <h1>Company Guide Video</h1>
          <button onClick={() => setShowInfoVideo(false)}>
            <Icon icon={faClose}></Icon>
          </button>
          <VideoCardJobBoard
            videoData="https://d2khiudbypa5a9.cloudfront.net/employer-welcome.mp4"
            width={'100%'}
            height={'80vh'}
            action={<></>}
            aspectRatio="auto"
            preload="metadata"
          />
        </InfoVideo>
      )}
    </AuthGuard>
  )
})

const Container = styled.div(
  ({ size }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  margin: auto;
  padding: 20px;
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  max-width: var(--max-width);
  & ul {
    position: relative;
  }
  & hr{
    margin-top: 30px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  & p{
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-weight: 400;
  }
  `,
)

const Title = styled.h2`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${Colors.white};
`

const SubTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  font-size: 18px;
  color: ${Colors.white};
  opacity: 0.5;
`
const TextAreaContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  textarea {
    height: 200px;
  }
`

const RadiosContainer = styled.div(
  ({ size }) => `
 display: flex;
 width: 100%;
 flex-direction: ${size.isMdUp ? 'row' : 'row'};
 align-items:${size.isMdUp ? 'center' : 'flex-start'};
 flex-wrap: wrap;
  & div{
   margin-right: 10px;
   align-items: center;

   & input {
    margin: 0px;
   }
  }
`,
)

const BottomContainer = styled.div(
  ({ size }) => `
  position: fixed;
  bottom:0;
  padding: 20px;
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  background-color: ${Colors.ghostAsh};
  flex-direction:${size.isMdUp ? 'row' : 'column'};
  align-items: center;
  width: 100%;
  display: flex;
  flex: 1;
  z-index: 2;
  `,
)

const ButtonsGroup = styled.div(
  ({ size }) => `
  display: flex;
  flex-direction: ${size.isSmUp ? 'row' : 'column'};
  width: 100%;
  margin: 0 auto;
  max-width: var(--max-width);
  justify-content: flex-end;
  text-align: center;
  gap: 16px;
  `,
)

const ListItem = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  padding: 17px 30px;
  color: ${Colors.white};
  background-color: ${Colors.darkBorder};
  border-radius: 5px;
`

const InfoSidebar = styled.div`
  --avatar-size: 60px;
  z-index: 2;
  opacity: 1;
  transition: opacity 200ms ease;
  &[data-hide='true'] {
    opacity: 0;
  }
  .wrapper {
    position: sticky;
    top: 20px;
    height: fit-content;
    display: grid;
    gap: 20px;
    right: 0;
    video {
      max-height: 40vh;
    }
    .content {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      align-items: flex-start;
      img {
        aspect-ratio: 1;
        width: var(--avatar-size);
        height: var(--avatar-size);
        border-radius: var(--avatar-size);
      }
      h2 {
        color: white;
        font-weight: 800;
        font-size: 32px;
        text-transform: uppercase;
        word-break: break-word;
      }
    }
  }
`

const InfoVideo = styled.div`
  position: fixed;
  display: grid;
  gap: 16px;
  align-content: center;
  justify-items: center;
  width: 100%;
  height: 100vh;
  height: 100svh;
  left: 0;
  top: 0;
  padding: 20px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(30px);
  z-index: 9999;
  h1 {
    font-size: 24px;
    color: white;
    text-transform: uppercase;
  }
  button {
    color: white;
    position: absolute;
    top: 60px;
    right: 60px;
    font-size: 28px;
  }
`

const ContentWrapper = styled.div(
  ({ size }) => `
  display: grid;
  gap: 64px;
  grid-template-columns: ${size.isMdUp ? '1fr 400px' : '1fr'};
  padding-bottom: 300px; /* Need to push content up for intersection observers */
}`,
)

const Socials = styled.div`
  display: grid;
  gap: 16px 32px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  > div {
    display: grid;
    grid-template-columns: 55px 1fr;
    gap: 16px;
    align-items: center;
  }
`

const InputButtonGroup = styled.div(
  ({ size }) => `
  display: grid;
  gap: 16px;
  align-items: center;
  grid-template-columns: ${size.isSmUp ? '1fr auto' : '1fr'};
`,
)
