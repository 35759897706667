import * as Yup from "yup";
import { Button } from "components/Button";
import { Colors } from "styles/Colors";
import { FormikProvider } from "formik";
import { FormikTextField } from "components/form/FormikTextField";
import { fromSignUp, selectedVacancy as selectedVacancyState } from "./state";
import { loginUser, registerCandidate, useAuthDispatch } from "Context";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useState } from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";

import { selectSelectedVacancy } from "store/reducers/vacancyReducer";

export const AuthScreenCandidate = React.memo(() => {
  const navigate = useNavigate();
  let validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  let validPass = /^(?=.*[A-Z])(?=.*?[a-z])(?=.*\d).{7,}$/;
  const location = useLocation();
  const navigatingFrom = location?.state?.from || "/candidate/manage-profile";

  const [authType, setAuthType] = useState("signIn");
  const [errors, setErrors] = useState({});
  const [iserror, setIserror] = useState("");
  const [validationInfo, setValidationinfo] = useState(false);
  const { isMdUp } = useResponsiveLayout();

  const selectedVacancy = useSelector(selectSelectedVacancy);

  const signInValidationSchema = Yup.object().shape({
    user: Yup.object().shape({
      email: Yup.string()
        .required("required")
        .email("Please input a valid email"),
      password: Yup.string().required("required"),
    }),
  });

  const signUpValidationSchema = Yup.object().shape({
    user: Yup.object().shape({
      name: Yup.string().required("required"),
      email: Yup.string()
        .required("required")
        .email("Please input a valid email"),
      password: Yup.string()
        .required("required")
        .min(8, "Password should be at least 6 characters")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d){8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      confirmPassword: Yup.string()
        .required("required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
  });

  const dispatch = useAuthDispatch();

  const validateConfirmPassword = (pass, value) => {
    let error = "";
    if (pass && value) {
      if (pass !== value) {
        error = "Password not matched";
      }
    }
    return error;
  };

  const onSubmitSignUp = async () => {
    const istrue = errors && Object.keys(errors).length;
    if (istrue) {
      setValidationinfo(true);
    } else {
      setValidationinfo(false);
      validateForm();
      let payload = {
        name: values.user.name,
        email: values.user.email.toLowerCase(),
        password: values.user.password,
        userStatus: "contact",
        refering_vacancyId: null,
      };
      if (
        selectedVacancy &&
        typeof selectedVacancy === "object" &&
        !Array.isArray(selectedVacancy) &&
        selectedVacancy !== null
      ) {
        payload = {
          ...payload,
          refering_vacancyId: selectedVacancy.id,
        };
      }

      try {
        let response = await registerCandidate(dispatch, payload);
        setIserror(response.message);
        if (!response.user) return;
        console.log("selectedVacancy", selectedVacancy);
        fromSignUp.value = true
        if (
          selectedVacancy &&
          typeof selectedVacancy === "object" &&
          !Array.isArray(selectedVacancy) &&
          selectedVacancy !== null
        ) {
          selectedVacancyState.value = selectedVacancy;
          console.log("selected vacancy found", selectedVacancy);
          selectedVacancyState.value = selectedVacancy;
          
          return navigate("/candidate/manage-profile", {
            state: { from: navigatingFrom },
          });
          // return navigate(`/candidate/intro-message/`);
          // return setTimeout(() => {
          //   document.location.pathname = `/candidate/application/`;
          // }, 1500);
        }
        navigate("/candidate/manage-profile", {
          state: { from: navigatingFrom },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onSubmitSignIn = async () => {
    const istrue = errors && Object.keys(errors).length;
    if (istrue) {
      setValidationinfo(true);
    } else {
      const payload = {
        email: signInValues.user.email.toLowerCase(),
        password: signInValues.user.password,
      };

      try {
        let response = await loginUser(dispatch, payload);
        setIserror(response.message);
        if (!response.user) return;
        navigate(navigatingFrom, { replace: true });
        setTimeout(() => {
          document.location.reload()
        }, 1500)
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formikbagSignin = useFormik({
    initialValues: {
      user: {
        email: "",
        password: "",
      },
    },
    validate: (values) => {
      const errors = {};

      if (!values.user.password || !values.user.password.match(validPass)) {
        errors.password =
          "Password must be, atleast 8 Characters with Lowercase ,Uppercase & Numbers";
      }
      if (!values.user.email || !values.user.email.match(validRegex)) {
        errors.email = "Please Enter a Valid Email";
      }

      setErrors(errors);
      return errors;
    },
    onSubmit: onSubmitSignIn,
    signInValidationSchema,
  });

  const formikbagSignup = useFormik({
    initialValues: {
      user: {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
    },
    validate: (values) => {
      const errors = {};
      if (!values.user.name) {
        errors.name = "Enter name";
      }
      if (!values.user.password || !values.user.password.match(validPass)) {
        errors.password =
          "Password must be, atleast 8 Characters with Lowercase ,Uppercase & Numbers";
      }
      if (values.user.password !== values.user.confirmPassword) {
        errors.confirmPassword = "Password does not match";
      }
      if (!values.user.email || !values.user.email.match(validRegex)) {
        errors.email = "Please Enter a Valid Email";
      }

      setErrors(errors);
      return errors;
    },
    onSubmit: onSubmitSignUp,
    signUpValidationSchema,
  });

  const { values, validateForm } = formikbagSignup;
  const { values: signInValues, validateForm: validateSignInForm } =
    formikbagSignin;

  const resetPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <Container isMdUp={isMdUp}>
      <FormContainer isMdUp={isMdUp}>
        <Header>WELCOME {isMdUp && "TO SEESY"}</Header>
        <sub className="text-gray-100 font-light">
          {authType === "signIn"
            ? "Sign In to your account to continue"
            : "Create your account to continue"}
        </sub>
        <TabContainer>
          <TabHeader
            authType={authType}
            value="signIn"
            onClick={() => setAuthType("signIn")}
          >
            Sign In
          </TabHeader>
          <TabHeader
            authType={authType}
            value="signUp"
            onClick={() => setAuthType("signUp")}
          >
            Sign Up
          </TabHeader>
        </TabContainer>
        {authType === "signIn" ? (
          <>
            {iserror && (
              <ErrorMessageText className="bg-red-500/5 text-red-500 rounded-lg px-4 mb-4">
                {iserror}
              </ErrorMessageText>
            )}
            <FormikProvider value={formikbagSignin}>
              <FormikTextField
                placeholder="Email "
                fullWidth
                Container={TextInputContainer}
                type="email"
                name="user.email"
              />
              {errors.email && validationInfo ? (
                <p className="bg-red-500/5 text-red-500 rounded-lg px-4 mb-4">
                  {errors.email}
                </p>
              ) : null}
              <FormikTextField
                placeholder="Password"
                fullWidth
                Container={TextInputContainer}
                type="password"
                name="user.password"
              />
              {errors.password && validationInfo ? (
                <p
                  style={{ color: "red", fontSize: "16px" }}
                  className="bg-red-500/5 text-red-500 rounded-lg px-4 mb-4"
                >
                  {errors.password}
                </p>
              ) : null}
              <Button button onClick={onSubmitSignIn} fullWidth>
                Sign In
              </Button>
            </FormikProvider>
          </>
        ) : (
          <>
            {iserror && (
              <p className="bg-red-500/5 text-red-500 rounded-lg px-4 mb-4">
                {iserror}
              </p>
            )}
            <FormikProvider value={formikbagSignup}>
              <FormikTextField
                placeholder="Name"
                fullWidth
                Container={TextInputContainer}
                type="text"
                name="user.name"
              />
              {errors.name && validationInfo ? (
                <p
                  style={{ color: "red", fontSize: "16px" }}
                  className="bg-red-500/5 text-red-500 rounded-lg px-4 mb-4"
                >
                  {errors.name}
                </p>
              ) : null}
              <FormikTextField
                placeholder="Email"
                fullWidth
                Container={TextInputContainer}
                type="email"
                name="user.email"
              />
              {errors.email && validationInfo ? (
                <p className="bg-red-500/5 text-red-500 rounded-lg px-4 mb-4">
                  {errors.email}
                </p>
              ) : null}
              <FormikTextField
                placeholder="Password"
                fullWidth
                Container={TextInputContainer}
                type="password"
                name="user.password"
              />
              {errors.password && validationInfo ? (
                <p
                  style={{ color: "red", fontSize: "16px" }}
                  className="bg-red-500/5 text-red-500 rounded-lg px-4 mb-4"
                >
                  {errors.password}
                </p>
              ) : null}
              <FormikTextField
                placeholder="Confirm Password"
                fullWidth
                Container={TextInputContainer}
                type="password"
                name="user.confirmPassword"
                validate={(value) =>
                  validateConfirmPassword(values.password, value)
                }
              />
              {errors.confirmPassword && validationInfo ? (
                <p
                  style={{ color: "red", fontSize: "16px" }}
                  className="bg-red-500/5 text-red-500 rounded-lg px-4 mb-4"
                >
                  {errors.confirmPassword}
                </p>
              ) : null}
              <Policy>
                <p>
                  <a href="https://seesy.co.uk/privacy-policy/">
                    Choosing Sign Up means that you agree to the Seesy Services
                    Agreement and privacy and cookies statement.
                  </a>
                </p>
              </Policy>
              <Button button onClick={onSubmitSignUp} fullWidth>
                Sign Up
              </Button>
            </FormikProvider>
          </>
        )}
        <Spacer isMdUp={isMdUp} />
        {/* <>
          <SocialLogin isMdUp={isMdUp}>
            <Icon icon={faGoogle} style={{ marginRight: 30 }} /> Sign in with
            Google
          </SocialLogin>
          <SocialLogin isMdUp={isMdUp}>
            <Icon icon={faLinkedinIn} style={{ marginRight: 30 }} /> Sign in
            with LinkedIn
          </SocialLogin>
        </> */}
      </FormContainer>
      {authType === "signIn" ? (
        <ForgotPassword isMdUp={isMdUp} onClick={resetPassword}>
          Forgot password?
        </ForgotPassword>
      ) : null}
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  justify-content: center;
  padding-bottom: 80px;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 0px;
  padding-left : ${isMdUp ? "122px" : "16px"};
  padding-right :${isMdUp ? "122px" : "16px"};
  & button{
    font-weight: 600;
    font-size: 18px;
  }
  & input{
    padding: 20px 25px;
  }

  `
);

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 360px;
`;
const Header = styled.h1`
  margin-top: 80px;
  margin-bottom: 15px;
  line-height: 26px;
  font-weight: 700;
  font-size: 26px;
  color: ${Colors.white};
`;

const TabContainer = styled.div(
  ({ isMdUp }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top:${isMdUp ? "63px" : "49px"};
  margin-bottom:${isMdUp ? "77px" : "60px"};
`
);

const TabHeader = styled.div(
  ({ authType, value }) => `
display: flex;
cursor: pointer;
border-bottom: 1px solid ${authType === value ? Colors.green : "rgba(255, 255, 255, 0.03)"
    };
font-family: Archivo;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 26px;
padding-bottom: 18px;
width: 50%;
justify-content: center;
align-items: center;
color: ${authType === value ? Colors.white : "rgba(255, 255, 255, 0.4)"} ;
`
);

const TextInputContainer = styled.div`
  width: 100%;
`;

/*
const SocialLogin = styled.div(
  ({ isMdUp }) => `
  border-radius: 5px;
  cursor: pointer;
  color: ${Colors.white};
  width: 100%;
  padding: 18px 30px;
  border: double 1px transparent;
  background-image: linear-gradient(#251838, #251838),
    linear-gradient(to bottom, ${Colors.green}, ${Colors.velvet});
  background-clip: padding-box, border-box;
  background-origin: border-box;
  margin-bottom:${isMdUp ? "8px" : "20px"};
`
);
*/

const Spacer = styled.div(
  ({ isMdUp }) => `
    height:${isMdUp ? "50px" : "62px"};
  `
);

const ForgotPassword = styled.div(
  ({ isMdUp }) => `
  display: flex;
  cursor: pointer;
  justify-content: ${isMdUp ? "end" : "center"};
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin-top:${isMdUp ? "20px" : "57px"};
  font-size: 16px;
  line-height: 26px;
  text-align: right;
  color: ${Colors.white};
  `
);
const Policy = styled.div`
  & p {
    margin-top: 7px;
    margin-bottom: 25px;
  }
  & a {
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
  }
`;

const ErrorMessageText = styled.p({
  color: "white",
  fontSize: "18px",
  fontWeight: "500",
});
