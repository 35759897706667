import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import { useGetCompanyQuery } from "store/userAPI";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useState } from "react";
import styled from "styled-components";

export const SectionHeading = React.memo(
  ({
    companyName,
    title,
    actions,
    search,
    placeholder,
    dropdown,
    date,
    caption,
    style,
  }) => {
    const [searchText, setSearchText] = useState("");

    const { isMdUp } = useResponsiveLayout();

    const { data: company } = useGetCompanyQuery(1, {
      skip: !Boolean(companyName),
    });

    return (
      <SectionHeadingStyle isMdUp={isMdUp} style={{ ...style }}>
        <SectionHeadingWrapper>
          <SectionHeadingWrapper>
            <DropDownWapper isMdUp={isMdUp}>
              {companyName ? <h6>{company?.name || ""}</h6> : null}
              {dropdown ? dropdown : null}
              {date ? <Datewrapper>{date}</Datewrapper> : null}
              <MemberRating isMdUp={isMdUp}>
                {title ? <h3>{title}</h3> : null}

                {/* {member ? <Rating rating={2} /> : null} */}
              </MemberRating>
              {caption ? <h6>{caption}</h6> : null}
            </DropDownWapper>
          </SectionHeadingWrapper>
        </SectionHeadingWrapper>
        {search ? (
          <SearchBar isMdUp={isMdUp}>
            <input
              placeholder={placeholder}
              type="text"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  search(searchText);
                }
              }}
            />
            <SearchIcon icon={faSearch} onClick={() => search(searchText)} />
          </SearchBar>
        ) : null}
        {actions && actions}
      </SectionHeadingStyle>
    );
  }
);

const actionOnMobile = `
  padding: 20px;
  color: white;
  margin-bottom: 3px;
  margin-top: 10px;
  marign-left: 0;
`;
const SectionHeadingStyle = styled.div(
  ({ isMdUp }) => `
  display: ${!isMdUp ? "block" : "flex"};
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: ${isMdUp ? "20px" : "0px"};
  grid-area: header;
  & h6 {
    color: #8E8C90;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  & h3 {
    color: white;
    margin: 0;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
  }
  & h2 {
    color: white;
    font-family: Archivo;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    margin-top: ${!isMdUp && "48px"};
  }
  & button {
    color: white;
    margin-left: 15px;
    ${!isMdUp ? actionOnMobile : null}
    
  }
`
);

const SectionHeadingWrapper = styled.div(
  () => `
display:flex;
align-items: center;
`
);

const MemberRating = styled.div(
  ({ isMdUp }) => `
display: ${isMdUp ? "flex" : "block"};
> div{
  margin-left: ${isMdUp ? "20px" : "0px"};
  margin-top: ${!isMdUp ? "20px" : "0px"};
  margin-bottom: ${!isMdUp ? "20px" : "0px"};
}
`
);

const searchBarIconMobile = `
position: absolute;
right: 4px;
top: 0;
padding: 12px;
bottom: 0;
border-radius: 50%;
margin: auto;`;

const searchBarMobile = `
  margin-left: 0;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const SearchIcon = styled(Icon)`
  cursor: pointer;
`;

const SearchBar = styled.div(
  ({ isMdUp }) => `
  margin-right: 50px;
  margin-left: 50px;
  position: relative;
  width: 60%;
  ${!isMdUp ? searchBarMobile : ""}

  & input {
    background: rgba(255, 255, 255, 0.04);
    border-radius: 105px;
    padding: 15px 20px;
    width: 100%;
    outline: none;
    border: none;
    color: white;
    
  }
  & ::placeholder{
    color: green;
    font-weight: 500;
    font-size: 16px;
    color: white;
  }
  & svg {
    position: absolute;
    right: 26px;
    top: 12px;
    ${!isMdUp ? searchBarIconMobile : ""}
`
);
const DropDownWapper = styled.div(
  ({ isMdUp }) => `
  width: 100%;
  position: relative;
  & > div:nth-child(2) > div {
    font-size: ${isMdUp ? "26px" : "16px"};
    font-weight: 700;
    text-transform: uppercase;
  }
`
);
const Datewrapper = styled.h2`
  color: #878787 !important;
  font-size: 18px;
`;
