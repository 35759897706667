import { AuthGuard } from 'components/AuthGuard'
import { Button } from 'components/Button'
import { Candidates } from '../../Commons/Candidates/Candidates'
import { Colors } from 'styles/Colors'
import { DropdownInput } from 'components/form/DropdownInput'
import { InterviewVideos } from '../../Commons/InterviewVideos/InterviewVideos'
import { LoadingComponent } from 'components/LoadingComponent'
import { SectionHeading } from 'components/Headings/SectionHeading'
import {
  selectSelectedVacancy,
  setSelectedCandidate,
} from 'store/reducers/vacancyReducer'
import { Toggle } from 'components/form/Toggle'
import {
  useAddApplicationLikeMutation,
  useGetJobApplicationsByVacancyIdQuery,
  useGetVacancyByIdQuery,
  useLazyGetApplicationLikesByVacancyIdQuery,
  useLazyGetApplicationViewsByVacancyIdQuery,
  useLazyGetCandidateByIdQuery,
  useRemoveApplicationLikeMutation,
  useUpdateVacancyMutation,
} from 'store/userAPI'
import { useAuthState } from 'Context'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const TabItemComponent = ({ tabvalue, value, setTabvalue, label, data }) => (
  <TabItem>
    <TabHeader
      className="shadow-xl shadow-white/5 border text-2xl hover:bg-primary hover:text-gray-700 duration-200 transition-all group border-white/5 px-4 py-3 rounded-xl cursor-pointer"
      tabvalue={tabvalue}
      value={value}
      onClick={() => setTabvalue(value)}
    >
      {label}
      <TotalCandidates className="text-white -top-2 -right-2 rounded-full w-6 h-6 group-hover:bg-white group-hover:text-black font-extrabold text-base bg-green-500/10 ">
        {data?.length >= 0 ? data?.length : 0}
      </TotalCandidates>
    </TabHeader>
  </TabItem>
)

export const VacancyPage = React.memo(() => {
  const { userDetails } = useAuthState()
  const scrollContainer = useRef()
  const [tabvalue, setTabvalue] = useState('candidates')
  const [candidate, setCandidate] = useState()
  const [applicationViews, setApplicationViews] = useState([]);
  const [applicationLikes, setApplicationLikes] = useState([]);
  const [filterByLiked, setFilterByLiked] = useState(false)
  const [isLikeLoading, setIsLikeLoading] = useState(false)

  const size = useResponsiveLayout()
  const [updateVacancy] = useUpdateVacancyMutation()
  const [addApplicationLike] = useAddApplicationLikeMutation()
  const [removeApplicationLike] = useRemoveApplicationLikeMutation()

  const { vacancyId } = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [scrollPos, setScrollPos] = useState(0)

  const vacancyFromStore = useSelector(selectSelectedVacancy)

  // Actual data displayed
  const [viewData, setViewData] = useState([])
  const [orderBy, setOrderBy] = useState({name: "descending", value:"Descending"})
  const [orderByProp, setOrderByProp] = useState({name: "createdAt", value:"Date created"})

  const {
    data: vacancyDetails,
    refetch: refetchVacancy,
  } = useGetVacancyByIdQuery(
    {
      id: vacancyId,
    },
    { skip: vacancyFromStore?.id === vacancyId },
  )

  const selectedVacancy = vacancyDetails || vacancyFromStore

  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    setIsDisabled(selectedVacancy?.disabled || false)
  }, [selectedVacancy])

  const {
    data: jobApplications,
    refetch,
  } = useGetJobApplicationsByVacancyIdQuery({
    id: vacancyId,
  })

  const [getCandidate] = useLazyGetCandidateByIdQuery({
    id: vacancyId,
  })
  const [getApplicationViews] = useLazyGetApplicationViewsByVacancyIdQuery();
  const [getApplicationLikes] = useLazyGetApplicationLikesByVacancyIdQuery();

  const refreshLikes = useCallback(async() => {
    try {
      const allApplicationLikes = await getApplicationLikes({
        id: vacancyId,
      }).unwrap();
      setApplicationLikes(allApplicationLikes);
    } catch (e) {
      console.log(`Failed to get Application Likes`, e);
      setApplicationLikes([]);
    }
  },[getApplicationLikes, vacancyId])

  useEffect(() => {
    const getCandidates = async () => {
      try {
        if(!jobApplications || jobApplications.length === 0) return
        const promises = jobApplications?.map((n, i) => {
          console.log(n)
          return getCandidate({ id: n?.candidateId }, true).unwrap()
        })

        let results = await Promise.all(promises.map((p) => p.catch((e) => console.error(e))))
        setCandidate(results)
      } catch (e) {
        console.log(e)
        setCandidate([])
      }
    }

    getCandidates()
  }, [getCandidate, jobApplications])

  useEffect(() => {
    console.log(`Getting all applications views for vacancy ${vacancyId}`);
    const work = async () => {
      try {
        const allViews = await getApplicationViews({
          id: vacancyId,
        }).unwrap();
        setApplicationViews(allViews);
      } catch (e) {
        console.log(`Failed to get application views`, e);
        setApplicationViews([]);
      }
    };

    work();
  }, [getApplicationViews, jobApplications, vacancyId]);

  useEffect(() => {
    console.log(`Getting all Application Likes for vacancy ${vacancyId}`);
    const work = async () => {
      await refreshLikes()
    };

    work();
  }, [getApplicationLikes, jobApplications, refreshLikes, vacancyId]);

  useEffect(() => {
    refetch()
  }, [refetch])

  const viewCandidateProfile = (e) => {
    dispatch(setSelectedCandidate(e))
    navigate(
      `/employer/dashboard/vacancy/candidate/${e?.application?.id}?bucket=${tabvalue}&y=${scrollPos}`,
    )
  }

  useEffect(() => {
    // Init ref
    // This is bonkers. The DOM element actually being scrolled it html > body > #root > div
    // The div is the first and only child of #root. If this ever changes, this will go pop
    // Yes this is bad but would require significant refactoring that is out of scope
    const root = document.getElementById('root')
    const target = root.children[0]
    scrollContainer.current = target

    const onScroll = (e) => {
      setScrollPos(e.target.scrollTop)
    }

    scrollContainer.current.addEventListener('scroll', onScroll)

    return () => {
      scrollContainer.current.removeEventListener('scroll', onScroll)
    }
  }, [])

  const viewVacancy = () => {
    navigate(`/employer/company/preview-vacancy/${vacancyId}`)
  }
  const closeVacancy = async () => {
    if (!!vacancyId) {
      let response = await updateVacancy({
        vacancyId: { vacancyId },
        payload: {
          disabled: !isDisabled,
        },
      })
      if (response.data) {
        console.log('res', response.data)
        refetchVacancy()
        setIsDisabled(response?.data?.disabled)

        alert(
          isDisabled === true
            ? 'Vacancy Re-opened Successfully'
            : 'Vacancy Closed Successfully.',
        )
      }
      if (!response.data) return
      if (response.error) return alert(response.message)
    } else {
      alert('No vacancy id found')
    }
  }

  const deleteCandidate = (id) => {
    // const selectedCandidate = candidate.filter(
    //   (item) => item.candidate.id !== id
    // );
    // setCandidate(selectedCandidate);
  }

    /**
     * Set view based on filters and order
     */
    useEffect(() => {
      if (
          !jobApplications ||
          jobApplications.length < 1 ||
          !candidate ||
          candidate.length < 1
      )
          return;

      let data = [];

      // Filter
      if (filterByLiked) {
          console.log("Filtering by liked");
          data = jobApplications.filter(
              (a) =>
                  applicationLikes.findIndex(
                      (l) =>
                          l.userId === userDetails.id &&
                          l.applicationId === a.id
                  ) > -1
          );
      } else {
          console.log("Showing all");
          data = [...jobApplications];
      }

      // Order
      switch (orderByProp.name) {
          case "createdAt":
              orderBy.name === "asc"
                  ? data.sort((a, b) =>
                        new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1
                    )
                  : data.sort((a, b) =>
                        new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
                    );
              break;
          case "updatedAt":
              orderBy.name === "asc"
                  ? data.sort((a, b) =>
                        new Date(a.updatedAt) < new Date(b.updatedAt) ? -1 : 1
                    )
                  : data.sort((a, b) =>
                        new Date(a.updatedAt) < new Date(b.updatedAt) ? 1 : -1
                    );
              break;
          case "likes":
              orderBy.name === "asc"
                  ? data.sort((a, b) =>
                        applicationLikes.filter(
                            (l) => l.applicationId === a.id
                        ).length <
                        applicationLikes.filter(
                            (l) => l.applicationId === b.id
                        ).length
                            ? -1
                            : 1
                    )
                  : data.sort((a, b) =>
                        applicationLikes.filter(
                            (l) => l.applicationId === a.id
                        ).length <
                        applicationLikes.filter(
                            (l) => l.applicationId === b.id
                        ).length
                            ? 1
                            : -1
                    );
              break;
          case "candidateName":
            default:
                orderBy.name === "asc"
                    ? data.sort((a, b) => {
                          const candidateA = candidate.find(
                              (c) => c.id === a.candidateId
                          );
                          const candidateB = candidate.find(
                              (c) => c.id === b.candidateId
                          );
                          if (!candidateA || !candidateB) return -1;
                          return candidateA.name < candidateB.name ? -1 : 1;
                        })
                        : data.sort((a, b) => {
                          const candidateA = candidate.find(
                            (c) => c.id === a.candidateId
                            );
                            const candidateB = candidate.find(
                              (c) => c.id === b.candidateId
                              );
                          if (!candidateA || !candidateB) return -1;
                          return candidateA.name < candidateB.name ? 1 : -1;
                      });
                break;
      }
      setViewData(data);
  }, [
      jobApplications,
      filterByLiked,
      orderBy,
      orderByProp,
      applicationLikes,
      userDetails.id,
      candidate,
  ]);

  const actions = () => (
    <div className='flex gap-2 items-center'>
      <Button
        button
        type="alt"
        size={size ? 'large' : 'small'}
        onClick={viewVacancy}
      >
        View Vacancy
      </Button>
      <Button
        button
        color={isDisabled ? Colors.green : Colors.velvet}
        type="alt"
        size={size ? 'large' : 'small'}
        onClick={closeVacancy}
      >
        {isDisabled === true ? 'Re-open Vacancy' : 'Close Vacancy'}
      </Button>
      {/* <Button
        button
        type={!invited ? "alt" : ""}
        borderColor={invited ? "#9400FF" : "#9400FF"}
        color={invited ? "#9400FF" : "transparent"}
        leftIcon={faShareNodes}
        size={isMdUp ? "large" : "small"}
        onClick={() => setInvited(!invited)}
      />
      <Button
        button
        type="alt"
        leftIcon={faEyeSlash}
        size={isMdUp ? "large" : "small"}
      /> */}
    </div>
  )
  /**
   * Sets the active tab based on URL query string
   */
  useEffect(() => {
    const targetBucket = searchParams.get('bucket')
    if (
      targetBucket &&
      [
        'candidates',
        'interviewVideos',
        'inReview',
        'shortlisted',
        'rejected',
        'inProgress',
      ].indexOf(targetBucket) > -1
    ) {
      // Only set if we have a known bucket
      setTabvalue(targetBucket)
      setSearchParams({bucket: targetBucket})
    }
  }, [searchParams, setSearchParams, tabvalue])

  /** Sets scroll position based on URL query string */
  useEffect(() => {
    // Check for scroll instructions
    const scrollPos = searchParams.get('y')
    if (scrollPos && scrollContainer.current) {
      // If we have a scroll, scroll the target container down
      // Arbitrary timeouts suck, but there's not much else we can do for now
      // This'll work _most_ of the time and thus will have to do.
      setTimeout(() => {
        scrollContainer.current.scrollTo(0, scrollPos)
      }, 500)
    }
  }, [searchParams, scrollContainer])

  const onToggleLike = async(likeId, applicationId, newState) => {
    setIsLikeLoading(true)
    if(newState) {
      await addApplicationLike({applicationId})
    } else {
      await removeApplicationLike({id: likeId})
    }
    await refreshLikes()
    setTimeout(() => {
      setIsLikeLoading(false)
    }, 500);
  }

  
  return !selectedVacancy || !selectedVacancy.companyId ? (
    <LoadingComponent />
  ) : (
    <AuthGuard
      criteria={[
        { value: selectedVacancy.companyId, check: "membership.companyId" },
        { value: "EMPLOYER", check: "userDetails.userType" },
      ]}
    >
      <Container size={size}>
        <SectionHeading
          actions={tabvalue === "candidates" && actions()}
          companyName
          title={selectedVacancy?.title}
        />
        <TabHeadercontainer className="flex flex-wrap flex-auto">
          <TabItemComponent
            tabvalue={tabvalue}
            value={"candidates"}
            setTabvalue={(value) => {
              setTabvalue(value);
              searchParams.set("bucket", value);
            }}
            label="Candidates"
            data={viewData?.filter(
              (j) =>
                j.status === "APPLIED" &&
                j.answers.length >= selectedVacancy.questions.length &&
                candidate?.find((c) => c.id === j.candidateId)
            )}
          />
          <TabItemComponent
            tabvalue={tabvalue}
            value={"interviewVideos"}
            setTabvalue={(value) => {
              setTabvalue(value);
              searchParams.set("bucket", value);
            }}
            label="Interview Videos"
            data={viewData?.filter((j) => {
              return (
                j.answers.length > 0 &&
                j.answers.filter(a => a && a.answer).length > 0 &&
                candidate?.find((c) => c.id === j.candidateId)
              );
            })}
          />
          <TabItemComponent
            tabvalue={tabvalue}
            value={"inReview"}
            setTabvalue={(value) => {
              setTabvalue(value);
              searchParams.set("bucket", value);
            }}
            label="Applicants In-review"
            data={viewData?.filter(
              (j) =>
                j.status === "IN-REVIEW" &&
                candidate?.find((c) => c.id === j.candidateId)
            )}
          />
          <TabItemComponent
            tabvalue={tabvalue}
            value={"shortlisted"}
            setTabvalue={(value) => {
              setTabvalue(value);
              searchParams.set("bucket", value);
            }}
            label="Shortlisted Applicants"
            data={viewData?.filter(
              (j) =>
                j.status === "ACCEPTED" &&
                candidate?.find((c) => c.id === j.candidateId)
            )}
          />
          <TabItemComponent
            tabvalue={tabvalue}
            value={"rejected"}
            setTabvalue={(value) => {
              setTabvalue(value);
              searchParams.set("bucket", value);
            }}
            label="Rejected Applicants"
            data={viewData?.filter(
              (j) =>
                j.status === "REJECTED" &&
                candidate?.find((c) => c.id === j.candidateId)
            )}
          />
          <TabItemComponent
            tabvalue={tabvalue}
            value={"inProgress"}
            setTabvalue={(value) => {
              setTabvalue(value);
              searchParams.set("bucket", value);
            }}
            label="In-progress Applications"
            data={viewData?.filter(
              (j) =>
                j.status === "INCOMPLETED" &&
                candidate?.find((c) => c.id === j.candidateId)
            )}
          />
        </TabHeadercontainer>
        <div className={`${size.isSmUp ? 'flex justify-between my-6' : 'grid my-6 mb-16'} w-full gap-4 items-center`}>
            <div className={`${!size.isSmUp ? 'w-full' : ''} flex flex-wrap gap-4`}>
              <DropdownInput
                  className={size.isSmUp ? 'w-[200px]' : 'w-full'}
                  type="alt"
                  bg={"rgba(0,0,0,0.4)"}
                  useFirstOption={false}
                  name="order"
                  options={[
                      { name: "createdAt", label: "Date created" },
                      { name: "updatedAt", label: "Date updated" },
                      { name: "candidateName", label: "Candidate name" },
                      { name: "likes", label: "Likes" },
                  ]}
                  placeholder="Order by"
                  onClick={(selectedValue) => setOrderByProp(selectedValue)}
                  value={orderByProp}
              />
              <DropdownInput
                  className={size.isSmUp ? 'w-[180px]' : 'w-full'}
                  type="alt"
                  bg={"rgba(0,0,0,0.4)"}
                  useFirstOption={false}
                  name="order"
                  options={[
                      { name: "asc", label: "Ascending" },
                      { name: "desc", label: "Descending" },
                  ]}
                  placeholder="Order by"
                  onClick={(selectedValue) => setOrderBy(selectedValue)}
                  value={orderBy}
              />
            </div>
            <div onClick={() => setFilterByLiked(!filterByLiked)} className={`${size.isSmUp ? 'grid justify-items-end gap-2' : 'flex gap-4 bg-black/40 px-[25px] py-6 rounded-lg backdrop-blur-md justify-between'} items-center`}>
                <span className={`${size.isSmUp ? 'text-xl font-light' : 'text-2xl'} text-white`}>
                    Only my liked
                </span>
                <Toggle
                    checked={filterByLiked}
                    onChange={() => setFilterByLiked(!filterByLiked)}
                    name="Filter to my liked"
                    inactiveBackground={size.isSmUp ? 'black' : null}
                />
            </div>
        </div>
        {tabvalue === "candidates" ? (
          <CandidatesList>
            {viewData
              ?.filter(
                (j) =>
                  j.status === "APPLIED" &&
                  j.answers.length >= selectedVacancy.questions.length
              )
              .map((j) => {
                return {
                  jobApplication: j,
                  candidate: candidate?.find((c) => c.id === j.candidateId),
                };
              })
              ?.map(({ jobApplication, candidate }, i) =>
                !candidate || !candidate.id ? null : (
                  <Candidates
                    key={i}
                    data={candidate}
                    status={jobApplication.status}
                    deleteCandidate={() => deleteCandidate(candidate?.id)}
                    views={applicationViews.filter(
                      (x) => x.applicationId === jobApplication.id
                    )}
                    likes={applicationLikes.filter(
                      (x) => x.applicationId === jobApplication.id
                    )}
                    disableLike={isLikeLoading}
                    onToggleLike={(likeId, newState) => {console.log(likeId, newState); onToggleLike(likeId, jobApplication.id, newState)}}
                    viewProfile={() =>
                      viewCandidateProfile({
                        ...candidate,
                        application: viewData?.find(
                          (j) => j.candidateId === candidate?.id
                        ),
                      })
                    }
                  />
                )
              )}
          </CandidatesList>
        ) : tabvalue === "interviewVideos" ? (
          <InterviewVideos
            jobApplications={viewData?.filter(
              (j) =>
                j.answers.length > 0 &&
                j.answers.filter(a => a && a.answer).length > 0 &&
                candidate?.find((c) => c.id === j.candidateId)
            )}
            candidates={candidate}
            viewProfile={viewCandidateProfile}
          />
        ) : tabvalue === "inReview" ? (
          <CandidatesList>
            {viewData
              ?.filter((j) => j.status === "IN-REVIEW")
              .map((j) => {return {jobApplication: j, candidate: candidate?.find((c) => c.id === j.candidateId)}})
              ?.map(({jobApplication, candidate}, i) =>
                !candidate || !candidate.id ? null : (
                  <Candidates
                    key={i}
                    data={candidate}
                    deleteCandidate={() => deleteCandidate(candidate?.id)}
                    likes={applicationLikes.filter(
                      (x) => x.applicationId === jobApplication.id
                    )}
                    disableLike={isLikeLoading}
                    onToggleLike={(likeId, newState) => {console.log(likeId, newState); onToggleLike(likeId, jobApplication.id, newState)}}
                    viewProfile={() =>
                      viewCandidateProfile({
                        ...candidate,
                        application: viewData?.find(
                          (j) => j.candidateId === candidate?.id
                        ),
                      })
                    }
                  />
                )
              )}
          </CandidatesList>
        ) : tabvalue === "shortlisted" ? (
          <CandidatesList>
            {viewData
              ?.filter((j) => j.status === "ACCEPTED")
              .map((j) => {return {jobApplication: j, candidate: candidate?.find((c) => c.id === j.candidateId)}})
              ?.map(({jobApplication, candidate}, i) =>
                !candidate || !candidate.id ? null : (
                  <Candidates
                    key={i}
                    data={candidate}
                    deleteCandidate={() => deleteCandidate(candidate?.id)}
                    likes={applicationLikes.filter(
                      (x) => x.applicationId === jobApplication.id
                    )}
                    disableLike={isLikeLoading}
                    onToggleLike={(likeId, newState) => {console.log(likeId, newState); onToggleLike(likeId, jobApplication.id, newState)}}
                    viewProfile={() =>
                      viewCandidateProfile({
                        ...candidate,
                        application: viewData?.find(
                          (j) => j.candidateId === candidate?.id
                        ),
                      })
                    }
                  />
                )
              )}
          </CandidatesList>
        ) : tabvalue === "rejected" ? (
          <CandidatesList>
            {viewData
              ?.filter((j) => j.status === "REJECTED")
              .map((j) => {return {jobApplication: j, candidate: candidate?.find((c) => c.id === j.candidateId)}})
              ?.map(({jobApplication, candidate}, i) =>
                !candidate || !candidate.id ? null : (
                  <Candidates
                    key={i}
                    data={candidate}
                    deleteCandidate={() => deleteCandidate(candidate?.id)}
                    likes={applicationLikes.filter(
                      (x) => x.applicationId === jobApplication.id
                    )}
                    disableLike={isLikeLoading}
                    onToggleLike={(likeId, newState) => {console.log(likeId, newState); onToggleLike(likeId, jobApplication.id, newState)}}
                    viewProfile={() =>
                      viewCandidateProfile({
                        ...candidate,
                        application: viewData?.find(
                          (j) => j.candidateId === candidate?.id
                        ),
                      })
                    }
                  />
                )
              )}
          </CandidatesList>
        ) : tabvalue === "inProgress" ? (
          <CandidatesList>
            {viewData
              ?.filter((j) => j.status === "INCOMPLETED")
              .map((j) => {return {jobApplication: j, candidate: candidate?.find((c) => c.id === j.candidateId)}})
              ?.map(({jobApplication, candidate}, i) =>
                !candidate || !candidate.id ? null : (
                  <Candidates
                    key={i}
                    data={candidate}
                    deleteCandidate={() => deleteCandidate(candidate?.id)}
                    likes={applicationLikes.filter(
                      (x) => x.applicationId === jobApplication.id
                    )}
                    disableLike={isLikeLoading}
                    onToggleLike={(likeId, newState) => {console.log(likeId, newState); onToggleLike(likeId, jobApplication.id, newState)}}
                    viewProfile={() =>
                      viewCandidateProfile({
                        ...candidate,
                        application: viewData?.find(
                          (j) => j.candidateId === candidate?.id
                        ),
                      })
                    }
                  />
                )
              )}
          </CandidatesList>
        ) : null}
        {/* <Comparebtn isMdUp={isMdUp}>
        <Button button>Compare Cƒandidates</Button>
      </Comparebtn> */}
      </Container>
    </AuthGuard>
  );
});

const Container = styled.div(
  ({ size }) => `
  max-width: var(--max-width);
  margin: 0 auto;
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-bottom: 80px;
  `,
)

const TabHeadercontainer = styled.div`
  display: flex;
  align-items: center;
  wrap: wrap;
  margin-top: 20px;
  margin-bottom: 40px;
  justify-items: center;
  align-items: center;
  padding-top: 5px;
  text-align: center;
  gap: 16px 24px;
`

const TabItem = styled.div`
  position: relative;
`

const TabHeader = styled.h2(
  ({ tabvalue, value }) => `
font-family: Archivo;
font-style: normal;
cursor: pointer;
font-weight: bold;
color: ${tabvalue === value ? Colors.white : 'rgba(255, 255, 255, 0.4)'} ;
`,
)

const TotalCandidates = styled.p`
  position: absolute;
  color: #66e12c;
  font-weight: 400;

  right: 27px;

  bottom: 6px;
`

const CandidatesList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
`
