import { createSlice } from "@reduxjs/toolkit";
import { selectedVacancy } from "pages/Candidate/Account/state";

const initialState = {
  selectedVacancy: [],
  vacancyResponse: [],
  selectedCandidate: {},
};

const vacancySlice = createSlice({
  name: "vacancy",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setSelectedVacancy: (state, action) => {
      state.selectedVacancy = action.payload;
      selectedVacancy.value = action.payload
    },
    setSelectedCandidate: (state, action) => {
      state.selectedCandidate = action.payload;
    },
    setVacancyResponse: (state, action) => {
      state.vacancyResponse = action.payload;
    },
    addVacancyResponse: (state, action) => {
      state.vacancyResponse.push(action.payload);
    },
    updateVacancyResponse: (state, action) => {
      const { index, answer } = action.payload;

      state.vacancyResponse[index] = answer;
    },
    clearVacancyResponse: (state, action) => {
      state.vacancyResponse = [];
    },
  },
});

export const {
  reset,
  setSelectedVacancy,
  setSelectedCandidate,
  setVacancyResponse,
  addVacancyResponse,
  updateVacancyResponse,
  clearVacancyResponse,
} = vacancySlice.actions;

export const vacancyReducer = vacancySlice.reducer;

export const selectSelectedVacancy = (state) => state.vacancy.selectedVacancy;
export const selectSelectedCandidate = (state) =>
  state.vacancy.selectedCandidate;
export const selectVacancyResponse = (state) => state.vacancy.vacancyResponse;
