import { Review } from "components/Step/Review";
import { StepEight } from "components/Step/StepEight";
import { StepFive } from "components/Step/StepFive";
import { StepFour } from "components/Step/StepFour";
import { StepOne } from "components/Step/StepOne";
import { StepSeven } from "components/Step/StepSeven";
import { StepSix } from "components/Step/StepSix";
import { StepThree } from "components/Step/StepThree";
import { StepTwo } from "components/Step/StepTwo";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useState } from "react";
import Stepper from "components/Stepper/Stepper";
import styled from "styled-components";

const Heading = styled.h4`
  text-transform: uppercase;
  color: #fff;
  margin: 0px;
  margin-right: 18px;
  font-size: 26px;
  font-weight: 700;
`;

export const Journey = React.memo(() => {
  const [state, setState] = useState({
    name: "",
    preferredCity: "",
    importantThings: [],
    industries: [],
    preferredRoles: [],
    companySize: {
      start: 0,
      end: 0,
    },
    preferredJobStartDate: undefined,
    salaryExpectation: undefined,
  });

  const handleChange = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const setArrayValue = (key, value, index) => {
    const newArray = state[key];
    newArray[index] = value;
    handleChange(key, newArray);
  };

  const steps = [
    {
      component: ({ handleNext }) => (
        <StepOne
          handleNext={handleNext}
          handleChange={handleChange}
          state={state}
        >
          <Heading>Hi! my name is</Heading>
        </StepOne>
      ),
    },
    {
      component: () => (
        <StepTwo setArrayValue={setArrayValue} state={state}>
          <Heading>The most important things to me in my new job are</Heading>
        </StepTwo>
      ),
    },
    {
      component: () => (
        <StepThree handleChange={handleChange} state={state}>
          <Heading>I would like to work in </Heading>
        </StepThree>
      ),
    },
    {
      component: () => (
        <StepFour setArrayValue={setArrayValue} state={state}>
          <Heading>The Industries i am interested in are </Heading>
        </StepFour>
      ),
    },
    {
      component: () => (
        <StepFive setArrayValue={setArrayValue} state={state}>
          <Heading>The roles i am interested in are </Heading>
        </StepFive>
      ),
    },
    {
      component: () => (
        <StepSix handleChange={handleChange} state={state}>
          <Heading>I want to work for a </Heading>
        </StepSix>
      ),
    },
    {
      component: () => (
        <StepSeven handleChange={handleChange} state={state}>
          <Heading>i want to start my new job </Heading>
        </StepSeven>
      ),
    },
    {
      component: ({ handleNext }) => (
        <StepEight
          handleChange={handleChange}
          state={state}
          handleNext={handleNext}
        >
          <Heading>My salary Expectaions are </Heading>
        </StepEight>
      ),
    },
    {
      component: () => (
        <Review
          handleChange={handleChange}
          setArrayValue={setArrayValue}
          state={state}
        />
      ),
    },
  ];

  const { isMdUp } = useResponsiveLayout();

  return (
    <Container isMdUp={isMdUp}>
      <Stepper steps={steps} state={state} />
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
    display: flex;
    margin-top: ${isMdUp ? "50px" : "30px"};
    padding-left : ${isMdUp ? "122px" : "16px"};
    padding-right :${isMdUp ? "122px" : "16px"};
  `
);
