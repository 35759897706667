export class IndexedDB {
  defaultGetStoreFunc

  promisifyRequest(request) {
    return new Promise((resolve, reject) => {
      request.oncomplete = request.onsuccess = () => resolve(request.result)
      request.onabort = request.onerror = () => reject(request.error)
    })
  }

  createStore(dbName, storeName) {
    const request = indexedDB.open(dbName)
    request.onupgradeneeded = () => request.result.createObjectStore(storeName)
    const dbp = this.promisifyRequest(request)

    return (txMode, callback) =>
      dbp.then((db) =>
        callback(db.transaction(storeName, txMode).objectStore(storeName)),
      )
  }

  defaultGetStore() {
    if (!this.defaultGetStoreFunc) {
      this.defaultGetStoreFunc = this.createStore(
        'recording-store',
        'recordings',
      )
    }
    return this.defaultGetStoreFunc
  }

  set(key, value, customStore = this.defaultGetStore()) {
    return customStore('readwrite', (store) => {
      store.put(value, key)
      return this.promisifyRequest(store.transaction)
    })
  }

  delete(key, customStore = this.defaultGetStore()) {
    return customStore('readwrite', (store) => {
      console.log(`Deleting key ${key} from video recording store`)
      store.delete(key)
      return this.promisifyRequest(store.transaction)
    })
  }

  get(key, customStore = this.defaultGetStore()) {
    return customStore('readonly', (store) =>
      this.promisifyRequest(store.get(key)),
    )
  }

  clear(customStore = this.defaultGetStore()) {
    return customStore('readwrite', (store) => {
      console.log(`Clearing video recording store`)
      this.promisifyRequest(store.clear())
    })
  }
}