import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'
export { trackingTypes } from './trackingTypes';

const twilioAppNameSuffix = process.env.REACT_APP_ENV === 'production' ? 'Prod' : 'Dev'

export const analytics = Analytics({
  app: `Seesy React ${twilioAppNameSuffix}`,
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_TWILIO_SEGMENT_ID
    })
  ]
});

