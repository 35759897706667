import { Button } from 'components/Button'
import { Colors } from 'styles/Colors'
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getCompanyMembers, useAuthDispatch, useAuthState } from 'Context'
import { Icon } from 'components/Icon'
import { SectionHeading } from 'components/Headings/SectionHeading'
import { useCallback, useEffect } from 'react'
import {
  useDeleteInviteMutation,
  useGetCompanyInvitesQuery,
  useGetCompanyQuery,
  userAPI,
} from 'store/userAPI'
import { useNavigate } from 'react-router-dom'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout'
import React from 'react'
import styled from 'styled-components'

const InformationItem = ({ edit, label, value, size, id, icon }) => (
  <InformationRow key={id}>
    <InformationText size={size}>
      <InformationLabel>{label}</InformationLabel>
      <InformationValue>{value}</InformationValue>
    </InformationText>
    <Icon
      icon={icon}
      color={Colors.velvet}
      onClick={() => edit()}
      style={{
        marginLeft: 28,
        width: 20,
        height: 20,
        marginRight: size.isMdUp ? 20 : 80,
        marginTop: 20,
      }}
    />
  </InformationRow>
)

export const AddTeamMemberSuccess = React.memo(() => {
  const { data: company } = useGetCompanyQuery({})
  const navigate = useNavigate()

  const size = useResponsiveLayout()

  const dispatch = useAuthDispatch()

  const { companyMembers } = useAuthState() || {}

  const { refetch, data } = useGetCompanyInvitesQuery()
  const [deleteInviteMutation] = useDeleteInviteMutation()

  const roleConverter = (role) =>
    role === 'ADMIN'
      ? 'Admin'
      : role === 'TM'
      ? 'Team Manager'
      : 'Hiring Manager'

  const fetchCompanyMembers = useCallback(() => {
    getCompanyMembers(dispatch)
  }, [dispatch])

  useEffect(() => {
    fetchCompanyMembers()
  }, [fetchCompanyMembers])

  useEffect(() => {
    console.log('USE EFFECT')
    refetch()
  }, [refetch])

  const addTeamMember = () => {
    navigate('/employer/company/addteammember')
  }

  const saveAndContinue = () => {
    if (company?.description) {
      navigate('/employer/manage-profile')
    } else {
      navigate('/employer/company/')
    }
  }

  const editTeamMember = (id) => {
    navigate(`/employer/company/addteammember/${id}`)
  }

  const deleteInvite = async (email) => {
    const res = window.confirm('Are you sure you want to delete this invite?')
    if (res) {
      await deleteInviteMutation({
        email,
      })
    }
    refetch()
  }

  return (
    <Container size={size}>
      {size.isMdUp ? (
        <>
          <SectionHeading companyName />
          <hr />
        </>
      ) : null}
      <Wrapper>
        <DetailsContainer>
          <Header size={size}>TEAM MEMBERS</Header>
          <InformationContainer>
            {!!companyMembers.length &&
              companyMembers?.map((member) => (
                <InformationItem
                  label={member.user?.name || member.user.id}
                  value={roleConverter(member?.role)}
                  size={size}
                  edit={() => editTeamMember(member.user.id)}
                  id={member.user.id}
                  key={member.user.id}
                  icon={faPen}
                />
              ))}
            {!!data?.length &&
              data?.map((user) => (
                <InformationItem
                  label={user?.name || user?.email}
                  value={roleConverter(user?.role) + ' (Invited)'}
                  size={size}
                  edit={() => deleteInvite(user.email)}
                  id={user?.id}
                  key={user?.id}
                  icon={faTrash}
                />
              ))}
          </InformationContainer>
          <AddNewMember size={size} onClick={addTeamMember}>
            <Icon
              icon={faPlus}
              color={Colors.green}
              style={{ marginRight: 20 }}
            />
            Add another team member
          </AddNewMember>
          <Button button fullWidth onClick={saveAndContinue}>
            Save & Continue
          </Button>
        </DetailsContainer>
      </Wrapper>
    </Container>
  )
})

const Container = styled.div(
  ({ size }) => `
  padding-bottom: 80px;
 & > div{
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
 }
 & hr {
   border: 1px solid #333333;
 }
 & svg{
  cursor:pointer;
 }
  `,
)
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: auto;
  & button {
    font-weight: 600;
    font-size: 18px;
  }
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
`

const Header = styled.h1`
  line-height: 26px;
  font-weight: 700;
  font-size: 18px;
  color: ${Colors.white};
  margin-top: 50px;
  margin-bottom: 50px;
`

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  width: 100%;
`

const InformationRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25;
  width: 100%;
  justify-content: space-between;
`

const InformationLabel = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: ${Colors.white};
`

const InformationValue = styled(InformationLabel)`
  color: rgba(255, 255, 255, 0.5);
`

const InformationText = styled(InformationLabel)(
  ({ size }) => `
display: flex;
margin-top: 20px;
flex-direction: ${size.isMdUp ? 'row' : 'column'}; 
justify-content: space-between;
flex: 1;
`,
)

const AddNewMember = styled(InformationLabel)(
  ({ size }) => `
display; flex;
cursor:pointer;
flex-direction: row;
align-self: center;
margin-bottom: 50px;
`,
)
