import logo from "images/logo.png";
import logoWithText from "images/logo-with-text.png";
import React from "react";
import styled from "styled-components";

import { headerHeight } from "styles/Layout";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";

export const Title = ({ noHeader }) => {
  const { isMdUp } = useResponsiveLayout();

  return (
    <Container>
      <a href="/">
        {isMdUp ? <LogoWithText src={logoWithText} /> : <Logo src={logo} />}
      </a>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-right: auto;
  align-items: center;
  height: ${headerHeight}px;
`;


const LogoWithText = styled.img`
  height: 40px;
  width: 85px;
  margin-right: 10px;
`;

const Logo = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 10px;
`;