import { AuthGuard } from 'components/AuthGuard'
import { Button } from 'components/Button'
import { Colors } from 'styles/Colors'
import {
  faApplePay,
  faBitcoin,
  faPaypal,
  faStripe,
} from '@fortawesome/free-brands-svg-icons'
import { FormikProvider } from 'formik'
import { FormikTextField } from 'components/form'
import { Icon } from 'components/Icon'
import { SectionHeading } from 'components/Headings/SectionHeading'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout'
import React from 'react'
import styled from 'styled-components'


export const BillingInformation = React.memo(() => {
  const size = useResponsiveLayout()

  const navigate = useNavigate()

  const onSubmit = () => {
    navigate('/employer/dashboard')
  }

  const formikbagSignin = useFormik({
    initialValues: {
      card: {
        cardNumber: '',
        start: '',
        end: '',
        ccv: '',
      },
    },
    onSubmit,
  })

  return (
    <AuthGuard
      criteria={[{ value: 'ADMIN', check: 'membership.role' }]}
    >
      <Container size={size}>
        <SectionHeading companyName title="billing information" />
        {size.isMdUp ? <hr /> : <Spacer />}
        <Spacer mt />
        <FormikProvider value={formikbagSignin}>
          <FormikTextField
            placeholder="Card Number"
            fullWidth
            Container={TextInputContainer}
            name="card.cardNumber"
          />
          <FormikTextField
            placeholder="Start"
            fullWidth
            Container={TextInputContainer}
            name="card.start"
            type="counter"
          />
          <FormikTextField
            placeholder="End"
            fullWidth
            Container={TextInputContainer}
            name="card.end"
            type="counter"
          />
          <FormikTextField
            placeholder="CCV"
            fullWidth
            Container={TextInputContainer}
            name="card.ccv"
            symbol="123"
          />
          <Spacer />
          <Button button size="large" fullWidth={size.isXs ? true : false}>
            Add Card
          </Button>
        </FormikProvider>
        <Title>Choose another payment method</Title>
        <PaymentOptions>
          <PaymentOption backgroundColor={'#111111'}>
            <Icon icon={faApplePay} />
          </PaymentOption>
          <PaymentOption backgroundColor={'#6461FC'}>
            <Icon icon={faStripe} />
          </PaymentOption>
          <PaymentOption backgroundColor={'#CCECFF'}>
            <Icon icon={faPaypal} />
          </PaymentOption>
          <PaymentOption backgroundColor={'#FF7E00'}>
            <Icon icon={faBitcoin} />
          </PaymentOption>
        </PaymentOptions>
      </Container>
      <BottomContainer size={size}>
        <Wrapper size={size}>
          <Button
            button
            size="large"
            fullWidth={!size.isSmUp}
            onClick={onSubmit}
          >
            Save & View Your Dashboard
          </Button>
        </Wrapper>
      </BottomContainer>
    </AuthGuard>
  )
})

const Container = styled.div(
  ({ size }) => `
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  margin: auto;
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  margin-bottom: 250px;

  & hr{
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 20px;
    margin-bottom: 80px;
  }
  & input {
    padding: 20px 25px;
  }
  `,
)

const Title = styled.h2`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${Colors.white};
  margin-bottom: 20px;
  margin-top: 66px;
`

const TextInputContainer = styled.div`
  max-width: 360px;
`

const BottomContainer = styled.div(
  ({ size }) => `
  position: fixed;
  bottom:0;
  padding: 20px;
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  background-color: ${Colors.ghostAsh};
  flex-direction:${size.isMdUp ? 'row' : 'column'};
  align-items: center;
  width: 100%;
  display: flex;
  `,
)

const Wrapper = styled.div(
  ({ size }) => `
display: flex;
flex-direction: ${size.isSmUp ? 'row' : 'column'};
width: 100%;
margin: 0 auto;
max-width: var(--max-width);
justify-content: flex-end;
text-align: center;
gap: 16px;
`,
)

const PaymentOption = styled.div(
  ({ backgroundColor }) => `
    display: flex;
    background-color:${backgroundColor};
    width: 78px;
    height: 40px;
    border-radius: 6px;
    margin-right: 8px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;`,
)

const PaymentOptions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Spacer = styled.div(
  ({ mt }) => `
  height: ${mt ? '50px' : '10px'};
`,
)
