import * as Yup from 'yup'
import { AutoComplete } from './form/AutoComplete';
import { Colors } from 'styles/Colors';
import { COUNTRIES_LIST } from 'pages/Employer/CreateRole/countries-list';
import { DropdownInput, FormikTextField } from "./form";
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FormikProvider } from "formik";
import { Icon } from './Icon';
import { Modal } from './Modal';
import { selectActiveSearchOptions, selectAvailableSearchOptions, setActiveOptions, setAvailableOptions, setRequestedOptions, setResults } from "store/reducers/searchReducer";
import { useDispatch } from "react-redux";
import { useFormik } from 'formik'
import { useGetSearchOptionsMutation, useSearchVacancyMutation } from "store/searchAPI";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SALARY_LIST = [
    { start: '10000', end: '19999' },
    { start: '20000', end: '29999' },
    { start: '30000', end: '34999' },
    { start: '35000', end: '39999' },
    { start: '40000', end: '44999' },
    { start: '45000', end: '49999' },
    { start: '50000', end: '59999' },
    { start: '60000', end: '69999' },
    { start: '70000', end: '79999' },
    { start: '80000', end: '89999' },
    { start: '90000', end: '99999' },
    { start: '100000', end: '+' }
]

/**
 * A simple modal element with a title, body, and list of actions
 * If no actions are provided, defaults to an "Ok" button that hides the modal
 * Does not use the <dialog> DOM element due to React Styled Components not working correctly with ::backdrop and I cannot be bothered to figure out why I don't even want to use Styled components.
*/
export const SearchPanel = ({ textSearchValue, onSearch }) => {

    // Hooks
    const size = useResponsiveLayout();
    const dispatch = useDispatch();

    // Stores and mutations
    const availableOptions = useSelector(selectAvailableSearchOptions);
    const activeOptions = useSelector(selectActiveSearchOptions);
    const [getSearchOptions] = useGetSearchOptionsMutation();
    const [getVacancySearchResults] = useSearchVacancyMutation();

    // Local state
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingOptions, setIsLoadingOptions] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedInput, setSelectedInput] = useState()


    /** 
     * Main submit handler
     * I'm unfamiliar with Formik, and I don't like what's having to be done here, but it works.
     */
    const onSubmit = async (_) => {
        console.log("Searching...")
        validateForm()
        if (!isValid) return alert("Please check your input")

        // Store our selections in state for use elsewhere if required
        dispatch(setActiveOptions(values))

        // Strip out the text search - this needs to be mutated
        const { text, ...rest } = values

        // If we have a text search, construct the text object
        const textSearch = text ? {
            textSearch: {
                search: text,
                caseSensitive: false,
                diacriticSensitive: false
            }
        } : undefined

        // Santize certain values before throwing at the API
        // Change some entries to be numbers. Despite inputs and validation on the forms, Formik is setting as string
        // For now this is just salary
        const sanitized = {
            ...rest, salary: { start: rest.salary.start ? Number(rest.salary.start) : "", end: rest.salary.end ? Number(rest.salary.end) : "" },
            ...textSearch
        }

        // Now we need to strip out empty arrays and blank strings from all props no matter how deep
        // We can do something wild here where we use JSON stringify/parse to strip out all nested nulls/empty strings
        const stripped = JSON.parse(JSON.stringify(sanitized, (key, value) => {
            return (value === null || (Array.isArray(value) ? value.length === 0 : value === '') ? undefined : value);
        }));

        // The above will leave completely empty objects if their props were empty, but it doesn't remove the actual object itself, which is what we need.
        // This is a recursive function
        function clearEmpties(o) {
            for (var k in o) {
                if (!o[k] || typeof o[k] !== "object") {
                    continue // If null or not an object, skip to the next iteration
                }

                // The property is an object, check for empty keys
                clearEmpties(o[k]);
                if (Object.keys(o[k]).length === 0) {
                    delete o[k];
                }
            }
            return o;
        }

        const reqData = clearEmpties(stripped)
        console.log(reqData)
        setIsLoading(true)
        const res = await getVacancySearchResults(reqData)
        console.log("Search results", res.data)
        dispatch(setRequestedOptions(reqData))
        dispatch(setResults(res.data))
        setIsLoading(false)
        if (!res.data || res.data.length === 0) {
            alert("No results match your criteria, please alter your search and try again.")
        } else {
            onSearch && onSearch(true)
        }
    }

    const validationSchema = Yup.object().shape({
        text: Yup.string(),
        location: Yup.string(),
        salary: Yup.object().shape({
            start: Yup.number().moreThan(0, "Must be a positive number"),
        })
    })

    const formikbag = useFormik({
        initialValues: {
            salary: {
                start: "",
                end: "",
            }
        },
        onSubmit,
        validationSchema,
    })

    const { values, setFieldValue, validateForm, isValid, resetForm } = formikbag

    const clearSearch = () => {
        resetForm();
        // Why do we have to do this? Why doesn't Formik handle it?
        setFieldValue("text", "");
        setFieldValue("location", "");
        dispatch(setResults([]));
        dispatch(setActiveOptions([]))
        dispatch(setRequestedOptions([]))
    }

    /**
     * Handler for showing options via the mobile modal
     */
    const handleOptionClick = (propName, input, options) => {
        setShowModal(true)
        setSelectedInput({ propName, input, options })
    }

    /**
     * Handler for picking an option via the mobile modal
    */
    const handleSelectOption = (input, value) => {
        /* 
            Once again the requirements have changed after the entire system has been built
            We now want Salary bands instead of "x+", and the UI just isn't built to handle that anymore.
            It used to be, you could even select multiple options (you'll see remnants of this with arrays being passed to the API) 
            Alas, it was changed to be more like Indeed, which was done. Now it's changed again.
            Do something disgusting here where we check the prop being set, and if it's salary we'll mutate 
            Good luck doing this in a typesafe language
        */
        if (input === 'salary') {
            setFieldValue('salary.start', value.start)
            // The last option will have `end` as a "+", so check for a number
            if (!isNaN(value.end)) setFieldValue('salary.end', value.end)
        } else {
            setFieldValue(input, value)
        }
        setShowModal(false)
    }

    /**
     * Get available search inputs on load, if required
     */
    useEffect(() => {
        const work = async () => {
            if (!availableOptions || availableOptions.length === 0) {
                console.log("Refreshing search options")
                setIsLoadingOptions(true)
                const res = await getSearchOptions()
                setIsLoadingOptions(false)
                dispatch(setAvailableOptions(res.data))
            }
        }

        work().finally()
    }, [availableOptions, dispatch, getSearchOptions])

    /**
     * Set values based on store on load
     */
    useEffect(() => {
        console.log("Setting values from store")
        console.log(activeOptions)
        for (const [key, value] of Object.entries(activeOptions)) {
            setFieldValue(key, value)
        }
    }, [activeOptions, setFieldValue])


    return <>
        <FormikProvider value={formikbag}>
            <Wrapper>
                <TopBar size={size}>
                    <FormikTextField
                        fullWidth
                        spacer={false}
                        placeholder="Keywords found in the Job Title, Skills, Description etc"
                        type="text"
                        name="text"
                    />
                    <FormikTextField
                        fullWidth
                        spacer={false}
                        placeholder="Location"
                        type="text"
                        name="location"
                    />
                    <Controls size={size}>
                        <button
                            className="bg-secondary border-2 border-solid border-secondary text-white py-5 px-6 rounded-lg"
                            type="submit"
                            title="Search"
                            onClick={onSubmit}
                            disabled={isLoading || !isValid}
                        >
                            Search
                        </button>
                        <button
                            className="border-2 border-solid border-secondary text-white py-5 px-6 rounded-lg"
                            type="button"
                            title="Reset"
                            onClick={clearSearch}
                            disabled={isLoading}
                        >
                            Reset
                        </button>
                    </Controls>
                </TopBar>
                <InputGrid size={size} className='thin-scrollbar'>
                    <InputWrapper size={size}>
                        {values.contractType ? <SelectedOption onClick={() => setFieldValue('contractType', null)}>{values.contractType}<Icon icon={faClose} /></SelectedOption> : size.isMdUp ?
                            <DropdownInput
                                type="alt"
                                bg={"rgba(0,0,0,0.4)"}
                                isLoading={isLoadingOptions}
                                useFirstOption={false}
                                resetOnSelected={true}
                                name="contractType"
                                options={availableOptions?.contractType?.map(o => o.name)}
                                placeholder='Contract Type'
                                onClick={(selectedValue) =>
                                    setFieldValue('contractType', [selectedValue])
                                }
                            /> : <SelectedOption isSelected={false} onClick={(o) => handleOptionClick('Contract Type', 'contractType', availableOptions?.contractType?.map(x => { return [x.name] }))}>Contract Type</SelectedOption>
                        }
                    </InputWrapper>
                    <InputWrapper size={size}>
                        {values.remoteWorking ? <SelectedOption onClick={() => setFieldValue('remoteWorking', null)}>{values.remoteWorking}<Icon icon={faClose} /></SelectedOption> : size.isMdUp ?
                            <DropdownInput
                                type="alt"
                                bg={"rgba(0,0,0,0.4)"}
                                isLoading={isLoadingOptions}
                                useFirstOption={false}
                                resetOnSelected={true}
                                name="remoteWorking"
                                options={availableOptions?.remoteWorking?.map(o => o.name)}
                                placeholder='Working Environment'
                                onClick={(selectedValue) =>
                                    setFieldValue('remoteWorking', [selectedValue])
                                }
                            /> : <SelectedOption isSelected={false} onClick={(o) => handleOptionClick('Working Environment', 'remoteWorking', availableOptions?.remoteWorking?.map(x => { return [x.name] }))}>Working Environment</SelectedOption>
                        }
                    </InputWrapper>
                    <InputWrapper size={size}>
                        {values.directToCompany ? <SelectedOption onClick={() => setFieldValue('directToCompany', null)}>{values.directToCompany}<Icon icon={faClose} /></SelectedOption> : size.isMdUp ?
                            <DropdownInput
                                type="alt"
                                bg={"rgba(0,0,0,0.4)"}
                                isLoading={isLoadingOptions}
                                useFirstOption={false}
                                resetOnSelected={true}
                                name="directToCompany"
                                options={availableOptions?.directToCompany?.map(o => o.name)}
                                placeholder='Posted By'
                                onClick={(selectedValue) =>
                                    setFieldValue('directToCompany', [selectedValue])
                                }
                            /> : <SelectedOption isSelected={false} onClick={(o) => handleOptionClick('Posted By', 'directToCompany', availableOptions?.directToCompany?.map(x => { return [x.name] }))}>Posted By</SelectedOption>
                        }
                    </InputWrapper>
                    <InputWrapper size={size}>
                        {values.industry ? <SelectedOption onClick={() => setFieldValue('industry', null)}>{values.industry}<Icon icon={faClose} /></SelectedOption> : size.isMdUp ?
                            <DropdownInput
                                type="alt"
                                bg={"rgba(0,0,0,0.4)"}
                                isLoading={isLoadingOptions}
                                useFirstOption={false}
                                resetOnSelected={true}
                                name="industry"
                                options={availableOptions?.industry?.map(o => o.name)}
                                placeholder="Industry"
                                onClick={(selectedValue) =>
                                    setFieldValue('industry', [selectedValue])
                                }
                            /> : <SelectedOption isSelected={false} onClick={(o) => handleOptionClick('Industry', 'industry', availableOptions?.industry?.map(x => { return [x.name] }))}>Industry</SelectedOption>
                        }
                    </InputWrapper>
                    <InputWrapper size={size}>
                        {values.country ? <SelectedOption onClick={() => setFieldValue('country', null)}>{values.country}<Icon icon={faClose} /></SelectedOption> :
                            size.isMdUp ? <AutoComplete
                                type="alt"
                                isDark={true}
                                name="country"
                                data={COUNTRIES_LIST?.map(o => o.name)}
                                placeholder='Country'
                                onChange={(value) =>
                                    value !== "" && setFieldValue('country', [value])
                                }
                            /> : <SelectedOption isSelected={false} onClick={(o) => handleOptionClick('Country', 'country', COUNTRIES_LIST.map(x => { return [x.name] }))}>Country</SelectedOption>
                        }
                    </InputWrapper>
                    <InputWrapper size={size}>
                        {values.salary.start ? <SelectedOption onClick={() => {
                            setFieldValue('salary.start', "")
                            setFieldValue('salary.end', "")
                        }
                        }>{values.salary.end === "+" ? "£" + values.salary.start + "+" : "£" + values.salary.start + " - £" + values.salary.end}<Icon icon={faClose} /></SelectedOption> : size.isMdUp ?
                            <DropdownInput
                                type="alt"
                                bg={"rgba(0,0,0,0.4)"}
                                useFirstOption={false}
                                resetOnSelected={true}
                                name="salary.start"
                                options={SALARY_LIST.map(x => { return { label: `${x.end === "+" ? "£" + x.start + "+" : "£" + x.start + " - £" + x.end}`, value: x } })}
                                placeholder='Salary'
                                onClick={
                                    (selectedValue) => {
                                        setFieldValue('salary.start', selectedValue.value.start);
                                        setFieldValue('salary.end', selectedValue.value.end);
                                    }
                                } /> : <SelectedOption
                                    isSelected={false}
                                    onClick={
                                        (o) => handleOptionClick('Salary', 'salary',
                                            SALARY_LIST.map(x => {
                                                return {
                                                    label: `${x.end === "+" ? "£" + x.start + "+" : "£" + x.start + " - £" + x.end}`,
                                                    value: { start: x.start, end: x.end }
                                                }
                                            })
                                        )
                                    }>Salary</SelectedOption>}
                    </InputWrapper>
                </InputGrid>
            </Wrapper>
        </FormikProvider>
        <Modal
            title={`Select ${selectedInput?.propName}`}
            actions={[
                { text: "Cancel", onClick: () => { setShowModal(false) } },
            ]}
            isVisible={showModal}
        >
            <div className="grid gap-[12px]">
                {selectedInput?.options?.map((o, i) => <Option key={i} className='w-full' onClick={(e) => handleSelectOption(selectedInput?.input, o.value ?? o)}>{o.label ?? o}</Option>)}
            </div>
        </Modal>
    </>

}
const Wrapper = styled.div`
    display: grid;
    margin-top: 32px;
    gap: 32px;
    align-content: flex-start;
    color: white;
    z-index: 9999;
`

const TopBar = styled.div(({ size }) => `
    display: grid;
    grid-template-columns: ${size.isLgUp ? '2fr 1fr auto' : size.isMdUp ? '1fr 1fr auto' : size.isSmUp ? '1fr 1fr' : '1fr'};
    gap: 12px;
    * {
        background: transparent;
        border: 0;
    }
    input {
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(30px);
        color: white;
            ::placeholder {
            color: white;
        }
    }
`)


const InputGrid = styled.div(({ size }) => `
    display: flex;
    flex-wrap: ${size.isMdUp ? 'wrap' : 'no-wrap'} ;
    gap: 12px;
    ${!size.isMdUp ? 'overflow-x: scroll;' : ''}
    padding-bottom: 10px;
`)

const InputWrapper = styled.div(({ size }) => `
    width: fit-content;
    min-width: ${size.isMdUp ? '200px' : 'fit-content'};
`)

const Controls = styled.div(({ size }) => `
    display: inline-flex;
    flex-flow: row;
    flex-grow: 1;
    grid-column: ${size.isMdUp ? '3' : '1/-1'};
    gap: 10px;
    *{
        flex-grow: 1;
    }
`)

const SelectedOption = styled.div(({ isSelected = true }) => `
    display: flex;
    gap: 10px;
    position: relative;
    padding: 12px 12px 12px 25px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    background-color: ${isSelected ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.4)'};
    backdrop-filter: blur(30px);
    align-items: center;
    height: 56px;
    cursor: pointer;
    justify-content: space-between;
`)

const Option = styled.button`
    min-width: 250px;
    width: 100%;
    text-align: left;
    padding: 10px 16px;
    border-radius: 5px;
    background: rgba(0,0,0,0.1);
    color: black;
    :hover {
        background: ${Colors.velvet};
        color: white;
    }
`