import React, { createContext, useContext, useEffect, useState } from 'react'

const WindowDimensionsContext = createContext(null)

export const WindowDimensionsProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    width: Math.min(window.screen.width, window.innerWidth), 
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <WindowDimensionsContext.Provider value={dimensions}>
      {children}
    </WindowDimensionsContext.Provider>
  )
}

export const useWindowDimensions = () => {
  return useContext(WindowDimensionsContext)
}
