import { Button } from "./Button";
import { Colors } from "styles/Colors";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "./Icon";
import { NewTagComponent } from "./NewTagComponent";
import { ReadMore } from "./ReadMore";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const InfoRow = React.memo(({ label, value, showbdr }) =>
  value ? (
    <InfoRowContainer>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </InfoRowContainer>
  ) : null
);

export const ProfileCard = React.memo(
  ({
    imageSource,
    name,
    skills,
    isCompare,
    data,
    viewProfile,
    status,
    views,
    likes,
  }) => {
    const VIEWER_DISPLAY_COUNT = 5;
    const [viewers, setViewers] = useState([]);

    useEffect(() => {
      if (!views) return;
      // Sort the views by `updatedAt`
      const sorted = [...views].sort(
        (a, b) =>
          new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf()
      );
      // Set to just names + count of views for this user
      const mapped = sorted.map((v) => {
        return {
          avatar: v.user.avatar,
          name: v.user.name,
          count: views.filter((view) => view.userId === v.userId).length,
        };
      });

      // Remove dupes
      const unique = mapped.reduce(function (p, c) {
        if (
          !p.some(function (el) {
            return el.name === c.name;
          })
        )
          p.push(c);
        return p;
      }, []);

      setViewers(unique);
    }, [views]);

    return (
      <Container
        // style={{maxHeight: 'fit-content'}}
        className={`flex shadow-secondary/10 border border-transparent hover:border 
       hover:border-secondary/60 duration-200 transition-border relative flex-col px-10
        py-8 justify-start items-center rounded-md bg-[#232424] shadow-xl h-[55rem]`}
      >
        {isCompare !== undefined ? (
          <CompareInfo>
            <Button button>
              <Icon
                icon={faCircle}
                color={data?.compare ? Colors.primaryGreen : "#625f5f"}
                size="lg"
              />
              Compare
            </Button>
          </CompareInfo>
        ) : null}
        {/* <CloseBtn>
          <Button button leftIcon={faXmark} onClick={deleteUser}></Button>
        </CloseBtn> */}
        <div
          className="mt-12 w-full rounded-lg flex flex-col justify-center items-center cursor-pointer"
          onClick={viewProfile}
        >
          <Image
            src={imageSource || "/dummy-profile-picture.png"}
            className="bg-white w-60 h-60"
            style={{
              backgroundColor: "white",
            }}
          />
          <h4 className="py-2 text-white font-extrabold">{name}</h4>
          {/* <Rating rating={rating} /> */}
          <div className=" flex flex-col" onClick={viewProfile}>
            {/* <InfoRow showbdr label="Candidate ref" value={data?.id} /> */}
            <InfoRow showbdr label="Contract type" value={data?.ContractType} />
            <InfoRow
              showbdr
              label="Location"
              value={data?.candidate?.preferredCities}
            />
            <InfoRow showbdr label="Tel / Mob" value={data?.tel} />
            <p className="text-white  text-xl"> {data?.email}</p>
          </div>
        </div>
        {viewers && viewers.length > 0 && (
          <>
            <h3 className="text-white mt-12 mb-2 text-left self-start">
              Viewers
            </h3>

            <div className="flex gap-2 self-start text-white text-xl items-center">
              {viewers.slice(0, VIEWER_DISPLAY_COUNT).map((viewer, index) => (
                <img
                  title={viewer.name}
                  key={index}
                  className="rounded-full inline bg-white"
                  src={viewer.avatar}
                  alt={`${viewer.name} avatar`}
                  style={{
                    width: 28,
                    height: 28,
                    objectFit: "cover",
                  }}
                />
              ))}
              {viewers.length > VIEWER_DISPLAY_COUNT && <span>+ {viewers.length - VIEWER_DISPLAY_COUNT} more</span>}  
            </div>
          </>
        )}
        <h3 className="text-white mt-12 mb-2 text-left self-start">Skills</h3>
        <div className="relative max-h-[30rem] overflow-y-auto overflow-x-hidden thin-scrollbar w-full">
          {skills && skills.length > 0 && (
            <ReadMore labelClosed={"Show more"} maxHeight={70} color="white">
              <div className="flex w-full flex-wrap justify-start gap-4 transition-all duration-300">
                {skills
                  ? skills.map((skill, index) => (
                      <NewTagComponent key={index} className="px-4 py-1">
                        {skill}
                      </NewTagComponent>
                    ))
                  : "No Skills"}
              </div>
            </ReadMore>
          )}
        </div>
      </Container>
    );
  }
);

const Container = styled.div`
  & button {
    background: transparent;
    color: #625f5f;
    border: none;
    font-weight: 400;
    padding-right: 30px !important;
    cursor: pointer;
    font-size: 16px;
    padding-left: 0;

    & svg {
      margin-right: 10px;
      font-size: 19px;
    }
  }
`;

const Image = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 80px;
  margin-bottom: 20px;
  cursor: pointer;
  object-fit: cover;
`;

const InfoRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: ${Colors.greyText};
`;

const Value = styled(Label)`
  color: ${Colors.white};
`;

const CompareInfo = styled.div`
  position: absolute;
  top: 22px;
  left: 26px;
`;
