import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const apiUrl = process.env.REACT_APP_API_URL

export const searchAPI = createApi({
  reducerPath: 'searchAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      let token = localStorage.getItem('currentUser')
        ? JSON.parse(localStorage.getItem('currentUser')).jwt
        : ''

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['results', 'options'],
  endpoints: (builder) => ({
    getSearchOptions: builder.mutation({
      invalidatesTags: ['options'],
      query: () => {
        return {
          url: `search/options/all`,
          method: 'GET',
        }
      },
    }),
    searchVacancy: builder.mutation({
      invalidatesTags: ['results'],
      query: (payload) => {
        return {
          url: `search/vacancy`,
          method: 'POST',
          body: payload,
        }
      },
    }),
  }),
})

export const {
  useGetSearchOptionsMutation,
  useSearchVacancyMutation,
} = searchAPI
