import { Colors } from "styles/Colors";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

/**
 * Form input component
 * - Can be prefixed with an icon
 * - Generates a unique ID if not provided
 * - Sets name to ID if not provided
 * - Fires handleChange when input changes
 */
export const AutoComplete = React.memo(
    ({
        allowCustomInput = false,
        data,
        disabled,
        field,
        onChange,
        icon,
        isDark,
        isLoading,
        label,
        name,
        placeholder,
        required = false,
        type,
        value,
        ...rest
    }) => {
        const inputRef = React.createRef();
        const [options, setOptions] = useState([]);
        const [hasSelection, setHasSelection] = useState(false);
        const [isInitialised, setIsInitialised] = useState(false);

        /**
         * Select handler - fires on change even to parent and sets input
         * @param value
         */
        const onSelect = (v) => {
            // Set text input
            if (!inputRef.current) return;
            inputRef.current.value = v;

            // Fire change handler
            onChange && onChange(v);

            // Mark that we have a selection 
            setHasSelection(true)

            // Close the panel via setting options to 0
            setOptions([]);
        };

        const handleChange = useCallback(
            (v) => {
                // As soon as we manually type, we've lost the selection
                setHasSelection(false)

                // If custom input is not enabled, we should fire a the change handler with `""` to tell the parent this field is incomplete
                // If custom input is enabled, fire the change handler our current value
                if (onChange) onChange(allowCustomInput ? v : "");

                // Do nothing until we've typed at least 3 characters
                if (v.length < 3) {
                    setOptions([]);
                } else {
                    const options = data.filter(
                        (x) => x.toLowerCase().indexOf(v.toLowerCase()) > -1
                    );
                    setOptions(options);
                }
            },
            [data, onChange, allowCustomInput]
        );

        /**
         * Handle initial value set from parent
         * Track local initialisation to prevent rerenders
         */
        useEffect(() => {
            // Only perform this action once, and only if there's an initial value
            if (!isInitialised && data.length > 0) {
                if (value) {
                    handleChange(value)
                    // Is our value stored in the options?
                    // If so, set it and emit the change handler
                    inputRef.current.value = value;
                    if (data.indexOf(value) > -1) {
                        setHasSelection(true)
                        onChange && onChange(value)
                    } else if (!allowCustomInput) {
                        // If custom input is also disabled, set as unselected and emit a blank selection
                        setHasSelection(false)
                        onChange && onChange("")
                    }
                }
                setIsInitialised(true)
            }
        }, [])

        return (
            <Container isDark={isDark} optionsLength={options?.length ?? 0} {...rest}>
                {label && <label className="text-white" htmlFor={inputRef}>{label}</label>}
                <div>
                    <StyledInput
                        allowCustomInput={allowCustomInput}
                        className="transition-all duration-100 focus:shadow-2xl drop-shadow-lg focus:shadow-secondary/25"
                        disabled={isLoading ?? disabled}
                        fullWidth
                        hasSelection={hasSelection}
                        isDark={isDark}
                        onChange={(e) => { handleChange(e.target.value) }}
                        ref={inputRef}
                        spacer={false}
                        type="text"
                        placeholder={placeholder}
                    />
                    {options.length > 0 && (
                        <Options isDark={isDark} className={`thin-scrollbar ${!isDark ? 'light' : ''}`}>
                            {options?.map((option, i) => {
                                return (
                                    <li key={i} tabIndex={i}>
                                        <button
                                            type="button"
                                            onClick={() => onSelect(option)}
                                            className={`text-sm-semibold ${isDark ? 'hover:bg-black/30' : 'hover:bg-secondary/10'}`}
                                        >{option}</button>

                                    </li>
                                );
                            })}
                        </Options>
                    )}</div>
            </Container>
        );
    }
);

const Container = styled.div(
    ({ isDark, optionsLength }) => `
  position: relative;
  gap: 10px;
  display: grid;

  :focus-within {
    // Set bottom border radii to 0
    ${optionsLength > 0
            ? `input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }`
            : ""
        }

    // Show the panel
    ul {
      display: grid;
    }

    // Change borders of panel and input to stay active
    ul,
    input {
        border-color: ${isDark ? 'transparent' : '#9400FF'};
    }
  }

  input {
    outline: 0;
  }
`
);

const Options = styled.ul(({ isDark }) => `
width: 100%;
list-style: none;
grid-template-columns: 1fr;

position: absolute;
top: 100%;
left: 0;
min-width: 150px;
max-height: min(300px, 33vh);
overflow-y: auto;

padding: 8px;
background-color: ${isDark ? 'rgba(0, 0, 0, 0.4)' : Colors.white}!important; // Hate having to use !important, but the parent has incorrectly overridden 
backdrop-filter: ${isDark ? 'blur(30px)' : 'none'};
border: ${isDark ? '0' : '1px solid #9400FF'}!important;
border-top: ${isDark ? '1px solid rgba(255, 255, 255, 0.2)' : '0'}!important;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
color: ${isDark ? Colors.white : '#667085'};
z-index: 999;

display: none;

button {
    color: ${isDark ? Colors.white : Colors.ash};
    background: transparent;
    border: 0;
    width: 100%;
    text-transform: capitalize;
    font-size: 16px;
    text-align: left;
    padding: 16px;
    border-radius: 5px;
}`)

const StyledInput = styled.input(
    ({ allowCustomInput, hasSelection, isDark }) => `
  padding: 16px 25px;
  border-radius: 4px;
  -webkit-appearance: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  -webkit-appearance: none;
  color: ${hasSelection || allowCustomInput ? isDark ? Colors.white : Colors.ash : Colors.greyText};
  width: 100%;
  background-color: ${isDark ? 'rgba(0, 0, 0, 0.4)' : Colors.white};
  backdrop-filter: ${isDark ? 'blur(30px)' : 'none'};
  border: ${isDark ? '0' : `1px solid ${Colors.ghostAsh}`};
  &::placeholder {
    color: ${isDark ? Colors.white : '#757676'};
  }
  `
);