import { Colors } from "styles/Colors";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faFileLines, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FlexColumnWrapper } from 'components/FlexColumnWrapper'
import { Icon } from "components/Icon";
import { NewTagComponent } from "components/NewTagComponent";
import { useGetCompanyByIdQuery } from "store/userAPI";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";


const InfoRow = React.memo(({ label, value, }) =>
  value ? (
    <InfoRowContainer>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </InfoRowContainer >
  ) : null
);

export const VacancyInfo = React.memo(
  ({
    data: {
      tags,
      location,
      address,
      equityAvailable,
      bonus,
      thumbnail,
      remoteWorking,
      id,
      contractType,
      directToCompany,
      portfolio,
      companyId
    },
  }) => {
    const size = useResponsiveLayout();


    const {
      data: company,
    } = useGetCompanyByIdQuery({
      id: companyId,
    })

    return (
      <Container>
        {company?.avatar && (
          <div className="flex justify-center">
            <LogoWrapper
              imageUrl={company?.avatar}
            />
          </div>
        )}
        <Section>
          <Title>Details</Title>
          <InfoRow label="Contract type" value={contractType} />
          <InfoRow label="Direct or Agency" value={directToCompany} />
          {(address || location) && (<div className="grid gap-4">
            <InfoRow label="Location" value={address ?? location}></InfoRow>
            {address &&
              <iframe
                className="border-0 rounded-lg"
                title="Primary location map"
                width="100%"
                height="150"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${address}`}>
              </iframe>
            }
          </div>)}
          <InfoRow
            label="Bonus"
            value={bonus ? `£ ${bonus.start} - ${bonus.end}` : null}
          />
          <InfoRow
            label="Equity Available"
            value={equityAvailable === true ? "Yes" : "No"}
          />
          <InfoRow label="Remote working" value={remoteWorking} />
        </Section>
        <Section>
          <Title>Company Portfolio</Title>
          <Files>
            <a href={portfolio} target="_blank" rel="noreferrer">
              <Icon icon={faFileLines} /> <h6 className="text-white">Download Company Portfolio</h6>
            </a>
          </Files>
        </Section>
        {!!tags?.length && (
          <>
            <hr className="m-0 border-0 border-t" />
            <Section>
              <Title>Tags</Title>
              <FlexColumnWrapper>
                {tags?.map((i, index) => (
                  <NewTagComponent key={index}>{i}</NewTagComponent>
                ))}
              </FlexColumnWrapper>
            </Section></>
        )}
        <hr className="m-0 border-0 border-t" />

        <Section>
          <Title>Socials</Title>
          <SocialIcons size={size}>
            {company?.facebook && (
              <Icon icon={faFacebookF} href={company.facebook.indexOf('http') > -1 ? company.facebook : `https://fb.me/${company.facebook}`} />
            )}
            {company?.instagram && (
              <Icon icon={faInstagram} href={company.instagram.indexOf('http') > -1 ? company.instagram : `https://instagram.com/${company?.instagram}`} />
            )}
            {company?.linkedIn && (
              <Icon icon={faLinkedinIn} href={company.linkedIn.indexOf('http') > -1 ? company.linkedIn : `https://linkedin.com/company/${company?.linkedIn}`} />
            )}
            {company?.youtube && (
              <Icon icon={faYoutube} href={company.youtube.indexOf('http') > -1 ? company.youtube : `https://youtube.com/@${company?.youtube}`} />
            )}
            {company?.tiktok && (
              <Icon icon={faTiktok} href={company.tiktok.indexOf('http') > -1 ? company.tiktok : `https://tiktok.com/@${company?.tiktok}`} />
            )}
            {company?.website && (
              <Icon icon={faGlobe} href={`${company?.website}`} />
            )}
          </SocialIcons>
        </Section>
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
  display: grid;
  align-content: flex-start;
  gap: 50px;

  * {
    word-break: break-word;
  }
`

const Section = styled.section`
  display: grid;
  gap: 24px;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
`

const LogoWrapper = styled.div(
  ({ imageUrl }) => `
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${imageUrl ? imageUrl : null});
  background-color: #232424;
`
);

const SocialIcons = styled.div(
  ({ size }) => `
  display: grid;
  grid-template-columns: repeat(auto-fill, ${size.isMdUp ? '56px' : '36px'
    } ); // (Width + padding*2)
  gap: 12px;

  & svg {
    background: white;
    padding: ${size.isMdUp ? '14px' : '8px'};
    border-radius: 50%;
    width: ${size.isMdUp ? '28px' : '20px'};
    height: ${size.isMdUp ? '28px' : '20px'};
    color: black;
  }
  & svg:hover {
    background: #d6d6d6;
  }
`,
)

const InfoRowContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  justify-items: space-between;
`

const Label = styled.div`
  line-height: 26px;
  color: ${Colors.greyText};
`

const Value = styled(Label)`
  color: ${Colors.white};
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  justify-self: flex-end;
`;

const Title = styled.h2`
  margin
  line-height: 26px;
  font-weight: 700;
  font-size: 18px;
  color: ${Colors.white};
`

const Files = styled.div`
  display: flex;
  & svg {
    font-size: 80px;
    margin-bottom: 30px;
    margin-right: 30px;
  }
`;
