import { Button } from "components/Button";
import { useResetPasswordRequestMutation } from "store/userAPI";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useState } from "react";
import styled from "styled-components";

export const ForgotPassword = React.memo(() => {
  const [userEmail, setUserEmail] = useState("");
  const { isMdUp } = useResponsiveLayout();
  const handelChange = (e) => {
    setUserEmail(e.target.value);
  };

  const [sendResetRequest] = useResetPasswordRequestMutation();

  const onSubmit = async (email) => {
    try {
      let response = await sendResetRequest({
        email: email,
      });
      console.log(response);

      if (response.error)
        return alert(response?.message || response?.error?.data?.message);
      else {
        alert("Please check your inbox for reset email link.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateEmail = (email) => {
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    const result = pattern.test(email);
    if (result === true) {
      setUserEmail(userEmail);
      onSubmit(email);
    } else {
      alert("please enter a valid email");
    }
  };

  return (
    <Container isMdUp={isMdUp}>
      <h2>forgot your password?</h2>
      <p>
        No problem, just enter your registered email address below and we’ll
        send you a link to reset it.
      </p>
      <input onChange={handelChange} placeholder="Email address" type="text" style={{
        borderRadius: "15px",
      }} />
      <Button button  onClick={() => validateEmail(userEmail)}>
        Send The Link
      </Button>
      {userEmail}
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${isMdUp ? "50px" : "30px"};
  padding-left : ${isMdUp ? "122px" : "16px"};
  padding-right :${isMdUp ? "122px" : "16px"};
  text-align:center;
  & h2 {
    font-size: 26px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 36.56px;
    margin-bottom: ${isMdUp ? "69px" : "79px"};
  }
  & p {
    font-size: 16px;
    color: white;
    font-weight: 400;
    line-height: 28.8px;
    font-family: "Roboto";
    width: ${isMdUp ? "40%" : "80%"};
  }
  & button {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 600;
  }
  & input {
    padding: 15px 20.5px;
    max-width: 361px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    outline: none;
    ::placeholder {
      color: #1C1D1D;
      font-size: 16px;
    }
  }
`
);
