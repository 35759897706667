import { Button } from 'components/Button'
import { Colors } from 'styles/Colors'
import { Divider } from 'components/Divider'
import { FileUpload } from 'components/FileUpload'
import { FormikProvider } from 'formik'
import { FormikTextField } from 'components/form/FormikTextField'
import { SectionHeading } from 'components/Headings/SectionHeading'
import { uploadMedia } from 'Context'
import { useFormik } from 'formik'

import { useNavigate } from 'react-router-dom'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout'
import React, { useState } from 'react'

import styled from 'styled-components'

import { useGetUserQuery, useUpdateCandidateMutation } from 'store/userAPI'

export const UploadCandidateVideo = React.memo(() => {
  const [selectedVideo, setSelectedVideo] = useState()
  const [videoUrl, setVideoUrl] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [updateCandidate] = useUpdateCandidateMutation()

  const navigate = useNavigate()

  const { data: { user: { candidate = {} } = {} } = {} } = useGetUserQuery({})

  const size = useResponsiveLayout()

  const onSubmit = async (videoMethod) => {
    let uploadedUrl

    // I have no idea how Formik works and don't have the time to figure it out. Do some basic validation
    // If YouTube is included, MUST contain 'https://youtu.be/'
    if (
      values?.company?.youtube &&
      values?.company?.youtube.indexOf('https://youtu.be') === -1
    )
      return alert(
        "YouTube embeds must be in the format 'https://youtu.be/[VIDEO_ID]'",
      )

    // If Vimeo is included, MUST contain 'https://vimeo.com/'
    if (
      values?.company?.vimeo &&
      values?.company?.vimeo.indexOf('https://vimeo.com') === -1
    )
      return alert(
        "Vimeo embeds must be in the format 'https://vimeo.com/[VIDEO_ID]'",
      )

    validateForm()
    if (videoMethod === 'recording') {
      navigate('/candidate/record-video')
      return
    } else if (!!selectedVideo) {
      uploadedUrl = await uploadVideo()
    }

    const payload = {
      ...candidate,
      videos: [
        ...candidate.videos,
        values?.candidate?.youtube ||
        values?.candidate?.vimeo ||
        videoUrl ||
        uploadedUrl,
      ],
    }

    try {
      console.log('final-payload', payload)
      let response = await updateCandidate(payload)

      if (!response) return
      if (response.error) return alert(response.message)
      // refetch();
      navigate('/candidate/manage-profile')
    } catch (error) {
      console.log('error updating candidate', error)
    }
  }

  const skip = () => {
    navigate('/candidate/manage-profile')
  }

  const uploadVideo = async () => {
    setIsLoading(true)
    let data = new FormData()
    data.append('file', selectedVideo)

    try {
      let response = await uploadMedia(data)
      setIsLoading(false)
      if (!response) return
      if (response.error) return alert(response.message)
      else {
        setVideoUrl(response?.url)
        return response?.url
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const formikbag = useFormik({
    initialValues: {
      candidate: {
        youtube: '',
        vimeo: '',
      },
    },
    onSubmit,
  })

  const { validateForm, values } = formikbag

  return (
    <>
      <Container size={size}>
        <SectionHeading candidateName title="upload a candidate video" />
        {!size.isMdUp ? <p>From your device, Youtube or Vimeo</p> : null}
        {size.isMdUp ? <hr /> : <Spacer />}

        <FormikProvider value={formikbag}>
          <ContentWrapper size={size}>
            <SubWrapper>
              <Button button fullWidth onClick={() => onSubmit('recording')}>
                Record your candidate video from your camera
              </Button>
              <Title>From your device</Title>
              <SubTitle>Max file size 300MB</SubTitle>
              <FileUpload type={'video'} setSelectedFile={setSelectedVideo} />
            </SubWrapper>
            {/* {isMdUp ? (
              <>
                <Info
                  img={Avatar}
                  info="Upload upto 3 Me-V Videos?”"
                  fb="42%"
                />
              </>
            ) : null} */}
          </ContentWrapper>
          <Divider />
          <ContentWrapper size={size}>
            <SubWrapper>
              <Title>From Youtube</Title>
              <SubTitle>Embed a video from your Youtube account</SubTitle>
              <FormikTextField
                placeholder="https://youtu.be/[VIDEO_ID]"
                fullWidth
                Container={TextInputContainer}
                type="textArea"
                name="candidate.youtube"
              />
            </SubWrapper>
            {/* {isMdUp ? (
              <>
                <Info
                  img={Avatar}
                  info="“already got one? just upload or link to it here” "
                  fb="43%"
                  mt="91px;"
                />
              </>
            ) : null} */}
          </ContentWrapper>
          <Divider />
          <Title>From Vimeo</Title>
          <SubTitle>Embed a video from your Vimeo account</SubTitle>
          <FormikTextField
            placeholder="https://vimeo.com/[VIDEO_ID]"
            fullWidth
            Container={TextInputContainer}
            type="textArea"
            name="candidate.vimeo"
          />
        </FormikProvider>
      </Container>
      <BottomContainer size={size}>
        <Wrapper size={size}>
          <Button
            button
            size="large"
            type="alt"
            fullWidth={!size.isSmUp}
            onClick={skip}
          >
            Skip
          </Button>
          <Button
            button
            size="large"
            fullWidth={!size.isSmUp}
            onClick={onSubmit}
            loading={isLoading}
          >
            Continue
          </Button>
        </Wrapper>
      </BottomContainer>
    </>
  )
})

const Container = styled.div(
  ({ size }) => `
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  padding-bottom: 180px;
  margin: auto;
  padding-left : ${size.isMdUp ? '122px' : '16px'};
  padding-right : ${size.isMdUp ? '122px' : '16px'};
  margin-bottom: ${size.isMdUp ? '150px' : '100px'};
  & hr{
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 20px;
    margin-bottom: 80px;
  }
  & button {
    padding: 16px 26px;
  }

  & p{
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-weight: 400;
    margin: 0px;

  }
  `,
)

const Title = styled.h2`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: ${Colors.white};
  margin-bottom: 3px;
`

const SubTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  font-size: 18px;
  color: ${Colors.white};
  margin-bottom: 29px;
  opacity: 0.5;
`

const TextInputContainer = styled.div`
  height: 120px;
  max-width: 466px;
`

const BottomContainer = styled.div(
  ({ size }) => `
  position: fixed;
  bottom:0;
  padding: 20px;
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  background-color: ${Colors.ghostAsh};
  flex-direction:${size.isMdUp ? 'row' : 'column'};
  align-items: center;
  width: 100%;
  display: flex;
  `,
)

const Spacer = styled.div`
  margin-top: 50px;
`
const ContentWrapper = styled.div(
  ({ size }) => `
  display: ${size.isMdUp ? 'flex' : 'block'};
  justify-content: space-between;
  align-items: flex-start;

`,
)
const SubWrapper = styled.div`
  flex-basis: 34%;
  & button {
    font-size: 18px;
    margin-bottom: 40px;
  }
`

const Wrapper = styled.div(
  ({ size }) => `
display: flex;
flex-direction: ${size.isSmUp ? 'row' : 'column'};
width: 100%;
margin: 0 auto;
max-width: var(--max-width);
justify-content: flex-end;
text-align: center;
gap: 16px;
`,
)
