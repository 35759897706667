import { Collapse } from "components/collapse/Collapse";
import { IndexedDB } from 'utils/indexedDb'
import { Question } from "components/Question/Question";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";

const chunkSubstr = (str, size) => {
  const numChunks = str.split(" ").length;
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

export const VacancyContent = React.memo(
  ({
    data: {
      description,
      benefits,
      questions,
      videoURL,
    },
  }) => {
    const { isMdUp } = useResponsiveLayout();
    const db = useRef(new IndexedDB())
    const [localVideo, setLocalVideo] = useState({})

    /**
    * Check for locally cached video
    */
    useEffect(() => {
      const work = async () => {
        let video = {}
        console.log(videoURL)
        const videoId = videoURL

        // Sometimes the DB may have "null" - handle it.
        if (!videoId) return

        // Keys don't have the file extension
        const key = videoId.split('.')[0]

        // Get all the blobs from IndexedDB
        const blobs = await db.current.get(key)
        if (blobs) {
          // Combine the individual blobs
          const combined = new Blob(blobs, { type: blobs[0].type })
          video[videoId] = { url: URL.createObjectURL(combined), type: combined.type }
        } else {
          // No local store
          video[videoId] = videoId
        }
        setLocalVideo(video)
      }
      work().finally()
    }, [videoURL, db])

    /** 
     * Clear local video cache on leave
     */
    useEffect(() => {
      const ref = db.current
      return () => {
        const work = async () => {
          await ref.clear()
        }
        work().finally()
      }
    }, []);

    return (
      <Container isMdUp={isMdUp}>
        <VideoCardJobBoard action width="100%" height="auto" videoData={localVideo[videoURL]} preload="metadata" />
        <ContentWrapperBodyAbout className=" transition-all duration-700">
          <h2> Vacancy Description</h2>
          {chunkSubstr(description, 400).length > 1 ? (
            <>
              <Collapse
                fullWidth={false}
                title={`Read more`}
                titleAfter="Read less"
              >
                <h5>{description}</h5>
              </Collapse>
            </>
          ) : (
            <h5>
              {chunkSubstr(description, 400).length &&
                chunkSubstr(description, 400).map((item) => item)}
            </h5>
          )}
        </ContentWrapperBodyAbout>
        <ContentWrapperBody bg="#232424" space>
          <Collapse defaultOpen title={`Key Benefits`} fullWidth>
            <KeyBenefitsStyle>
              {benefits
                ? benefits.map((i, index) => (
                  <h6 key={index}>
                    <span> / </span>
                    {i}
                  </h6>
                ))
                : ""}
            </KeyBenefitsStyle>
          </Collapse>
        </ContentWrapperBody>
        {isMdUp ? <Space /> : ""}
        <ContentWrapperBody>
          <Collapse
            space
            defaultOpen
            title="Questions from this employer"
            fullWidth
          >
            {questions
              ? questions.map((i, index) => (
                <Question>
                  <p>
                    <span> Q{index + 1} . </span> {i}
                  </p>
                </Question>
              ))
              : ""}
          </Collapse>
        </ContentWrapperBody>
      </Container>
    );
  }
);

const Container = styled.div(
  ({ isMdUp }) => `
  position: relative;
  border-radius: 5px;
  color: white;
  grid-area: content;
  & h2{
    font-size: 18px;
    margin-top: 0px !important;
    font-weight: 400;
    margin-top: 22px;
    margin-bottom: 0px;
    text-transform: capitalize;;
  }
  & > div:nth-child(1) > div{
    width: 100%;
  }
  & p{
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400 !important;
      line-height: 25px;
  }
  & h5{
    line-height: 25px;
    font-size: 15px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 10px;
    white-space: pre-line;
  }
  & span{
    margin-right: 5px;
    color: #fff;
    font-size: 18px;
    font-weight: 400px;
  }
  * {
    word-break: break-word;
  }

  `
);
const ContentWrapperBody = styled.div(
  ({ bg, space }) => `
background-color: ${bg ? bg : ""};
margin-top: 30px; 
padding: ${space ? "4px 20px" : "0"};
border-radius: 5px;

`
);
const ContentWrapperBodyAbout = styled.div`
  background: #232424;
  margin-top: 30px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 30px 40px;
`;

const KeyBenefitsStyle = styled.div`
  display: block;
  margin-top: 50px;
  & span {
    margin-right: 30px;
    color: #6bff00;
    font-weight: bold;
  }
`;

const Space = styled.hr`
  margin-top: 48px !important;
  border-width: 1px !important;
`;