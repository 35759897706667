import React from "react";
import ReactTimeAgo from "react-time-ago";
import styled from "styled-components";

import { Colors } from "styles/Colors";

export const ChatCard = React.memo(({ date, title }) => {
  return (
    <Container>
      {date ? (
        <Time>
          <ReactTimeAgo date={date} locale="en-US" />
        </Time>
      ) : null}
      <Title>{title}</Title>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 130px;
  padding: 10px 20px;
  border-radius: 5px;
`;

const Time = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: ${Colors.white};
  text-align: center;
  line-height: 26px;
`;

const Title = styled.h2`
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: ${Colors.white};
  line-height: 26px;
`;
