import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import { useAuthState } from "Context";
import React, { useState } from "react";

export const LikeButton = React.memo(
    ({ active, likes = [], onToggle, disabled }) => {

        const { userDetails } = useAuthState();

        const [forceShowLikes, setForceShowLikes] = useState(null)
        const [showLikes, setShowLikes] = useState(false)

        const myLike = likes.find(x => x.userId === userDetails.id)

        return (
            <div className="relative"
                onMouseEnter={() => forceShowLikes !== false && setShowLikes(true)}
                onMouseLeave={() => setShowLikes(false)} >
                <div
                    className={`flex items-stretch rounded-md border border-gray-600 text-xl text-gray-400 overflow-hidden${active ? ' border-primary text-primary' : ''}`}>
                    {/*Requires padding and margin resets since these are often set by a parent on all child buttons*/}
                    <button className={`p-0 mb-0 mt-0 flex items-center border-r border-gray-600 pl-3 pr-3 border-gray-600${active ? ' border-primary bg-primary text-black hover:bg-transparent hover:text-primary' : ' hover:text-primary'}`}
                        onClick={() => !disabled && onToggle && onToggle(myLike?.id)}
                        title={myLike ? 'Remove your like' : 'Like this application'}>
                        <Icon color="text-gray-400" icon={faStar} size={12} />
                    </button>
                    <button className={`p-0 mb-0 mt-0 px-3 py-2${forceShowLikes && likes.length > 0 ? ' bg-primary text-black' : ''}`} onClick={() => setForceShowLikes(!forceShowLikes)} title={`${forceShowLikes ? 'Unpin' : 'Pin'} likes`}>
                        {likes.length}
                    </button>
                </div >
                {
                    (showLikes || forceShowLikes) && likes.length > 0 && <ul className="absolute top-[100%] left-0 translate-y-2 bg-black/80 backdrop-blur-md px-4 py-2 rounded-xl text-white w-[220px]">
                        {
                            likes.map((like, index) =>
                                <li className="py-3 flex items-center justify-between gap-4 w-full" key={index}>
                                    <span className="flex items-center gap-4 max-w-full">
                                        <img className="rounded-full inline bg-white" src={like.user.avatar} alt={`${like.user.name} avatar`} style={{
                                            width: 28,
                                            height: 28,
                                            objectFit: "cover",
                                        }} />
                                        <span className="text-xl truncate">{like.user.name}</span>
                                    </span>
                                </li>
                            )
                        }
                    </ul>
                }
            </div >
        )
    }
);