import { Button } from "components/Button";
import { useResetPasswordMutation } from "store/userAPI";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useState } from "react";
import styled from "styled-components";

export const PasswordReset = React.memo(() => {
  const [state, setState] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  const [resetPassword] = useResetPasswordMutation();

  const onSubmit = async () => {
    try {
      let response = await resetPassword({
        token: urlParams.get("token"),
        id: urlParams.get("id"),
        newPassword: state.newPassword,
      });

      if (response.error)
        return alert(response?.message || response?.error?.data?.message);
      else {
        alert("Passsword successfully reset.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { isMdUp } = useResponsiveLayout();
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const checkPassword = async () => {
    if (state.newPassword !== state.confirmPassword) {
      alert("Passwords must be equal");
      return;
    } else {
      onSubmit();
    }
  };

  return (
    <Container isMdUp={isMdUp}>
      <h2>Password reset</h2>
      <input
        onChange={handleChange}
        placeholder="New password"
        value={state.newPassword}
        type="password"
        name="newPassword"
      />
      <input
        onChange={handleChange}
        placeholder="Confirm password"
        type="password"
        value={state.confirmPassword}
        name="confirmPassword"
      />
      <Button button  onClick={checkPassword}>
        Reset Password
      </Button>
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${isMdUp ? "50px" : "30px"};
  padding-left : ${isMdUp ? "122px" : "16px"};
  padding-right :${isMdUp ? "122px" : "16px"};
  text-align:center;
  & h2 {
    font-size: 26px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 36.56px;
    margin-bottom: ${isMdUp ? "69px" : "79px"};
  }
  & p {
    font-size: 16px;
    color: white;
    font-weight: 400;
    line-height: 28.8px;
    font-family: "Roboto";
    width: ${isMdUp ? "40%" : "80%"};
  }
  & button {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 600;
  }
  & input {
    padding: 15px 20.5px;
    max-width: 361px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    outline: none;
    ::placeholder {
      color: #1C1D1D;
      font-size: 16px;
    }
  }
`
);
