import { DropDown } from "components/DropDown/DropDown";
import { TagComponent } from "components/TagComponent";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

export const StepThree = React.memo(({ children, handleChange, state }) => {
  const { isMdUp } = useResponsiveLayout();

  const option = ["My own home", "London", "Dubai", "Other +"];

  const cities = [
    {
      name: "Manchester",
      job: "17",
    },
    {
      name: "Liverpool",
      job: "21",
    },
    {
      name: "San Francisco",
      job: "1",
    },
    {
      name: "New York",
      job: "12",
    },
    {
      name: "Berlin",
      job: "14",
    },
  ];

  const setPrefferedCity = (value) => {
    handleChange("preferredCity", value);
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      setPrefferedCity(e.target.value);
    }
  };

  return (
    <Container isMdUp={isMdUp}>
      <Wrapper isMdUp={isMdUp}>
        {children}
        <DropDown
          placeholder="choose A city  +"
          bg
          icon
          active={state.preferredCity}
          options={option}
          handleChange={(option) => setPrefferedCity(option)}
        />
        <h5>.</h5>
      </Wrapper>
      {state.preferredCity === "Other +" ? (
        <Tag>
          <TagWrapper>
            {cities.map((city) => (
              <TagComponent onClick={() => setPrefferedCity(city.name)}>
                {city.name}
                {city.job !== "" ? <span>{`(${city.job})`}</span> : null}
              </TagComponent>
            ))}
          </TagWrapper>
        </Tag>
      ) : null}
      {state.preferredCity === "Other +" ? (
        <OtherCity isMdUp={isMdUp}>
          <Heading>Tell us about somewhere else</Heading>
          <input
            placeholder="Type city name"
            onKeyUp={onKeyUp}
            autoFocus
          ></input>
        </OtherCity>
      ) : null}
    </Container>
  );
});

const Container = styled.div`
  position: relative;
  min-height: fit-content;
  & button {
    background: transparent !important;
    font-size: 26px;
    font-weight: 700;
    padding: 0px !important;
    padding-left: 10px;
    border-radius: 0px !important;
    padding-left: 0px;
  }
  & h5 {
    font-size: 26px;
    font-weight: 700;
    color: white;
    margin: 0px 20px;
  }
`;
const Wrapper = styled.div(
  ({ isMdUp }) => `
  display: ${isMdUp ? "flex" : "block"};
`
);
const Tag = styled.div`
  width: 90%;
  padding-top: 10px;
`;
const OtherCity = styled.div(
  ({ isMdUp }) => `
  display: ${isMdUp ? "flex" : "block"};
  margin-top: 80px;
  align-items: center;
}
  & input {
    background-color: transparent;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 700;
    color: #b5b5b6;
    margin-left: ${isMdUp ? "15px" : "0px"};
    outline: none;
    border: none;
    max-width: 240px;
    border-bottom: 5px solid #9e9c9f;
    padding-bottom: 5px;
  }
`
);
const Heading = styled.h4`
  text-transform: capitalize;
  color: #fff;
  margin: 0px;
  margin-right: 18px;
  font-size: 16px;
  font-weight: 400;
`;
const TagWrapper = styled.div`
  margin-top: 258px;
`;
