import React from "react";
import styled from "styled-components";

/**
 * Styled toggle component
 * - Generates a unique ID if not provided
 * - Sets name to ID if not provided
 */
export const Toggle = React.memo(
    ({ name, style, checked, onChange, id, inactiveBackground, ...rest }) => {
        return (
            <Container background={inactiveBackground}>
                <input
                    type="checkbox"
                    className="toggle"
                    style={style}
                    onChange={onChange}
                    id={id}
                    name={name}
                    checked={checked}
                    {...rest}
                />
                <label htmlFor={name}></label>
            </Container>
        );
    }
);

const Container = styled.div(({ background }) => `
position: relative;

input.toggle[type="checkbox"] {
    position: absolute;
    width: 50px;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    isolation: isolate;
    + label {
        display: grid;
        width: 50px;
        padding: 2px;
        background-color: ${background ?? '#232424'};
        border-width: 2px;
        border-style: solid;
        border-color: ${background ?? '#232424'};
        border-radius: 9999px;
        transition: all 200ms ease-out;
        cursor: pointer;

        &:after {
            content: " ";
            width: 22px;
            height: 22px;
            left: 0;
            background-color: #A0A0A0;
            border-width: 1px;
            border-style: solid;
            border-color: ${background ?? '#232424'};
            border-radius: 9999px;
            transition: all 200ms ease-out;
            cursor: pointer;
            pointer-events: none;
        }
    }

    &:hover,
    &:focus,
    &:active {
        + label {
            border-color: #6BFF00;
        }
    }

    &:checked {
        + label {
            background-color: #6BFF00;
            border-color: #6BFF00;
            &:after {
                transform: translateX(calc(100% - 2px));
                border-color: #000;
                background-color: #000;
            }
        }
    }
}`)