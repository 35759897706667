import { Button } from 'components/Button'
import { Colors } from 'styles/Colors'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { formatFilePath } from 'utils/functions'
// import { Helmet } from 'react-helmet-async'
import { Icon } from 'components/Icon'
import { VideoPlaceholder } from 'components/VideoPlaceholder'
import React from 'react'
import styled from 'styled-components'
import VideoPlayer from 'components/VideoPlayer/VideoPlayer'

/** Declared props:
 * date | string or number
 * action | any
 * isPlayIcon | boolean
 * playIconClick | () => {}
 * actionClick | () => {}
 * videoData | string
 **/

const BASE_VIDEO_URL = 'https://d2khiudbypa5a9.cloudfront.net'
const BASE_SUBTITLE_URL = 'https://seesy-subtitles.s3.eu-west-2.amazonaws.com'
const BASE_THUMBNAIL_URL = process.env.REACT_APP_BASE_THUMBNAIL_URL

const VideoCardJobBoard = React.memo(
  ({
    title,
    date,
    time,
    action,
    actionClick,
    spreator,
    videoData,
    thumbnail,
    height,
    width,
    className,
    preload,
    aspectRatio,
    userData,
    gatherStats = false,
    borderRadius = '16px',
    onClickTitle
  }) => {

    const videoStyle = {
      width: width || 400,
      height: height || 300,
      borderRadius: borderRadius,
      backgroundColor: '#000000',
      aspectRatio: aspectRatio || '4/3'
    }

    return (
      <VideoCardStyle className={className}>
        {!videoData
          ? <VideoPlaceholder aspectRatio={aspectRatio} borderRadius={borderRadius}>Something isn't right with this video</VideoPlaceholder>
          :
          <div>
            {
              videoData.type ?
                // If a Type prop is present, this is a blob.
                <video
                  crossOrigin="anonymous"
                  controls={true}
                  width={width}
                  style={
                    videoStyle
                  }
                  playsInline
                  preload={'none'}
                  autoPlay={false}
                >
                  <source
                    src={`${videoData.url}`}
                    // Chrome reports `video/x-matroska;codecs=avc1,opus` - this does not work as a MIME type
                    // avc1 codec belongs to mp4 (or mkv/matroska) but the browser requires 'video/mp4' instead
                    type={videoData.type.indexOf('codecs=avc1') > -1 ? 'video/mp4' : videoData.type}
                  />
                  Your browser does not support the video tag.
                </video>
                :
                // No type specified, check for other known formats
                // Videos hosted on YouTube should use an embed
                videoData.toString().toLowerCase().indexOf('youtu.be/') > -1 ? (
                  <iframe
                    title="YouTube video player"
                    src={`https://www.youtube.com/embed/${videoData.split('https://youtu.be/')[1]
                      }?rel=0&modestbranding=1`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                    allowFullScreen
                    style={videoStyle}
                  ></iframe>
                ) : // Videos hosted on Vimeo should use an embed
                  videoData.toString().toLowerCase().indexOf('vimeo.com/') > -1 ? (
                    <>
                      <iframe
                        title="Vimeo Video player"
                        src={`https://player.vimeo.com/video/${videoData.split('https://vimeo.com/')[1]}`}
                        width={width || 400}
                        height={height || 300}
                        frameBorder="0"
                        allow="autoplay; fullscreen;"
                        allowFullScreen
                        style={videoStyle}
                      ></iframe>
                    </>
                  // ) : // Videos hosted on TikTok should use an embed script
                  //   videoData.toString().toLowerCase().indexOf('tiktok.com/@') > -1 ? (
                  //     <>
                  //       {[videoData].map((video, i) => {
                  //         const videoId = video.split('/video/')[1]
                  //         return <blockquote ket={i} className="tiktok-embed" cite={videoData} data-video-id={videoId} style={{ width: 'fit-content' }} >
                  //           <section />
                  //         </blockquote>
                  //       })}
                  //       <Helmet>
                  //         <script src="https://www.tiktok.com/embed.js"></script>
                  //       </Helmet>
                  //    </>
                    ) : (
                      // Anything else should use a standard <video>, this (should be) one of our recordings, or a direct URL of a video file
                      <VideoPlayer
                        autoPlay={false}
                        controls={true}
                        crossOrigin="anonymous"
                        debug={process.env.REACT_APP_ENV !== 'production'}
                        // This has gotten really messy with legacy IDs and moving away from Ziggeo.
                        playsInline
                        poster={
                          thumbnail
                            // Check for http. If not present this is a new format. Thumbnail is an ID and extension within the database
                            ? thumbnail.toString().toLowerCase().indexOf('http') === -1 ? `${BASE_THUMBNAIL_URL}/${formatFilePath(videoData, 'jpg', true).nameAndExtension}`
                              // Legacy format - thumbnail is a full URL within the database
                              : thumbnail
                            // Thumbnail not provided, use the video ID 
                            : `${BASE_THUMBNAIL_URL}/${formatFilePath(videoData, 'jpg', true).nameAndExtension}`
                        }
                        preload={preload ?? 'none'}
                        style={
                          videoStyle
                        }
                        // Subtitles are only available for videos that aren't linked to - ensure 'http' isn't present, else no subs
                        trackSrc={videoData.toString().toLowerCase().indexOf('http') === -1 && `${BASE_SUBTITLE_URL}/${videoData}.vtt`}
                        type={`video/${formatFilePath(videoData, 'mp4').extension}`}
                        userData={userData}
                        videoData={videoData
                          ? videoData.toString().toLowerCase().indexOf('http') ===
                            -1
                            ? `${BASE_VIDEO_URL}/${formatFilePath(videoData, 'mp4').nameAndExtension}#t=0.1`
                            : `${videoData}#t=0.1`
                          : ''}
                        videoId={videoData}
                        gatherStats={gatherStats}
                        width={width}
                      >
                      </VideoPlayer>
                    )
            }
          </div>
        }

        {(title || time || date || spreator) && <div className="flex flex-col flex-wrap pt-4">
          {title ? onClickTitle ? <h2><button onClick={onClickTitle}>{title}</button></h2> : <h2>{title}</h2> : null}
          {time ? <p>{time}</p> : null}
          {date ? <p>{date}</p> : null}
          {spreator ? <Break /> : null}
        </div>}
        {
          action ? (
            <div className="actions">
              {action}
            </div>
          ) : (
            <div className="actions">
              <Button button onClick={actionClick} color="transparent">
                <Icon icon={faEye} color={Colors.primaryGreen} size={16}></Icon>
                Transcript
              </Button>
            </div>
          )
        }
      </VideoCardStyle >
    )
  },
)

export default VideoCardJobBoard

const VideoCardStyle = styled.div`
  width: 100%;
  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
  & .ba-videoplayer-poster-container,
  .ba-videoplayer-poster-container img,
  .ba-videoplayer-video-container,
  .ba-videoplayer-video-container video {
    z-index: 0;
  }
  & video {
    object-fit: contain;
  }
  & p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: white;
    margin: 0px;
  }
  & h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: white;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .actions button {
    color: white;
    border: none;
    font-weight: 400;
    cursor: pointer;
    font-size: 16px;
    padding-left: 0;
    & svg {
      margin-right: 10px;
    }
  }
`

const Break = styled.div`
  background-color: #160b1f;
  position: relative;
  height: 2px;
  width: 100%;
  margin-top: 15px;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 2px;
    width: 50%;
    background: #66e12c;
  }
`