import "react-phone-number-input/style.css";
import { AuthGuard } from "components/AuthGuard";
import { AvatarPicker } from "components/AvatarPicker";
import { Button } from "components/Button";
import { checkImageFileSize } from "utils/functions";
import { Colors } from "styles/Colors";
import {
  DropdownInput,
  FormikTextField,
  RadioComponent,
  TextField,
} from "components/form";
import { faFilePdf, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Field, FormikProvider, useFormik } from "formik";
import { FileUpload } from "components/FileUpload";
import { fromSignUp, selectedVacancy } from "../Account/state";
import {
  handleSuccessfulSave,
  hasPreviouslyUnverifiedNumber,
  newNumber,
  oldNumber,
  PhoneNumberValidationForm,
  shouldRevalidateNumber,
  showOtpForm,
  ValidationResult,
  verifyResult,
} from "./PhoneVerification";
import { Icon } from "components/Icon";
import { LoadingComponent } from "components/LoadingComponent";
import { ManageProfileFooter } from "components/ProfileFooter/ManageProfileFooter";
import { MultiSelectDropdownInput } from "components/form/MultiSelectDropdown";
import { MyVideos } from "../MyVideos/MyVideos";
import { NewTagComponent } from "components/NewTagComponent";
import { SectionHeading } from "components/Headings/SectionHeading";
import {
  selectAvailableSearchOptions,
  setAvailableOptions,
} from "store/reducers/searchReducer";
import { toast, ToastContainer } from "react-toastify";
import { Toggle } from "components/form/Toggle";
import { uploadMedia } from "Context";
import { useDeleteUserMutation } from "store/userAPI";
import { useDispatch } from "react-redux";
import { useGetSearchOptionsMutation } from "store/searchAPI";
import { useGetUserQuery, useUpdateCandidateMutation } from "store/userAPI";
import { useLogger } from "hooks/useLogger";
import { useNavigate } from "react-router-dom";

import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import { useSelector } from "react-redux";
import { useSignals } from "@preact/signals-react/runtime";
import bgImage from "images/profile-bg.jpg";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const COMPANY_SIZES = [
  "1-10",
  "11-50",
  "51-200",
  "201-500",
  "501-1000",
  "1001-5000",
  "5001-10000",
  "10000+",
];

const GENDERS = ["Male", "Female", "Non Binary", "Other", "Prefer Not to Say"];

const NEURODIVERSITY = [
  "ADHD",
  "Autism",
  "Dyspraxia",
  "Dyslexia",
  "Dyscalculia",
  "Dysgraphia",
  "Tourette's",
];

export const CandidateProfileManage = () => {
  useSignals();
  const { logger } = useLogger();

  const dispatch = useDispatch();
  const focusInput = useRef();

  const {
    data: { user: { candidate = {} } = {} } = {},
    isLoading,
    error,
    isSuccess,
  } = useGetUserQuery({});
  const [updateCandidate, { isLoading: submitting }] =
    useUpdateCandidateMutation();
  const [errors, setErrors] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  const navigate = useNavigate();

  const [tabvalue, setTabvalue] = useState("Personal Details");
  const size = useResponsiveLayout();
  const [skills, setSkills] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [subtitle, setSubtitle] = useState("Save Changes");
  const [accepted, setAccepted] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [gender, setGender] = useState();
  const [neurodiverse, setNeurodiverse] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState();
  const [avatarUrl, setAvatarUrl] = useState();

  const [deleteUserMutation] = useDeleteUserMutation();
  const deleteCompany = async () => {
    try {
      let response = await deleteUserMutation();
      if (response?.iserror) return alert(response?.error?.data?.message);
      if (!response.data) return;
      if (response.iserror) return alert(response.message);
      localStorage.clear();
      navigate("/job-board");
    } catch (error) {
      console.log(error);
    }
  };

  /** Input options */
  const availableOptions = useSelector(selectAvailableSearchOptions);
  const [getSearchOptions] = useGetSearchOptionsMutation();
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  // Steps
  const [step, setStep] = useState(1);

  /**
   * Get available search inputs on load, if required
   */
  useEffect(() => {
    const work = async () => {
      if (
        (!availableOptions || availableOptions?.length === 0) &&
        isLoadingOptions === false
      ) {
        console.log("Refreshing search options");
        setIsLoadingOptions(true);
        const res = await getSearchOptions();
        setIsLoadingOptions(false);
        dispatch(setAvailableOptions(res.data));
      }
    };

    work().finally();
  }, [availableOptions, dispatch, getSearchOptions, isLoadingOptions]);
  /********** End of input options */

  useEffect(() => {
    setImageUrl(candidate?.avatar || null);
    setSkills(candidate?.skills || []);
    setSizes(candidate?.companySize || []);
    setSelectedFile(candidate?.cv);
    setGender(candidate?.gender);
    setNeurodiverse(candidate?.neurodiverse || []);
    if (candidate?.phoneVerified) {
      oldNumber.value = candidate?.phoneNo;
    }
    newNumber.value = candidate?.phoneNo;
  }, [
    candidate?.avatar,
    candidate?.companySize,
    candidate?.skills,
    isSuccess,
    candidate?.cv,
    candidate?.gender,
    candidate?.neurodiverse,
    candidate?.phoneNo,
    candidate.phoneVerified,
  ]);

  const formikbag = useFormik({
    initialValues: {
      candidate: {
        description: candidate?.description || "",
        salaryExpectation: candidate?.salaryExpectation || 0,
        companySize: candidate?.companySize || [],
        skills: "",
        phoneNo: candidate?.phoneNo || "",
        phoneVerified: candidate?.phoneVerified,
        gender: candidate?.gender || "",
        neurodiverse: candidate?.neurodiverse || [],
        smsConsent: candidate?.smsConsent || false,
        smsOnly: candidate.smsOnly || false,
      },
    },
    validate: (values) => {
      const errors = {};

      if (!values.candidate.description) {
        errors.description = "Please enter a profile description";
      }
      if (values.candidate.salaryExpectation < 5000) {
        errors.salaryExpectation =
          "Minimum salary expectation must be greater than 5000";
      }
      if (!values.candidate.salaryExpectation) {
        errors.salaryExpectation = "Your salary expectation";
      }
      if (isNaN(values.candidate.salaryExpectation)) {
        errors.salaryExpectation = "Please input a number";
      }

      if (sizes.length < 0) {
        errors.companySize = "Please select your desired company size";
      }
      if (!skills) {
        errors.skills = "Please add at least 1 skill";
      }
      if (!values.candidate.phoneNo) {
        errors.phoneNumber = "Please enter a phone number";
      }

      if (
        values.candidate.phoneNo &&
        !isValidPhoneNumber(values.candidate.phoneNo)
      ) {
        errors.phoneNo =
          "Please enter a valid phone number. For international numbers, please use e164 format. eg: [+][country code][area code][local phone number]";
      }
      setErrors(errors);
      return errors;
    },
    enableReinitialize: true,
  });

  const { values, validateForm, setFieldValue } = formikbag;

  useEffect(() => {
    if (verifyResult.value === "success")
      setFieldValue("candidate.phoneNo", newNumber.value);
  }, [verifyResult.value, setFieldValue]);

  useEffect(() => {
    // has unverified existing number
    if (candidate?.phoneNo && !candidate?.phoneVerified) {
      hasPreviouslyUnverifiedNumber.value = true;
    }
  });

  useEffect(() => {
    if (candidate) {
      if (candidate?.companySize?.length === COMPANY_SIZES.length) {
        setSelectAll(true);
      }
      if (candidate?.companySize === undefined) {
        return;
      }
      if (
        Array.isArray(candidate.companySize) &&
        candidate.companySize.length > 0
      ) {
        setSizes([...candidate?.companySize]);
      } else if (
        typeof candidate?.companySize === "object" &&
        candidate?.companySize !== null
      ) {
        if (candidate?.companySize?.start || candidate?.companySize?.end) {
          // now since we have obj size:{start:1,end:2} we need to convert it to array
          let oldSize = `${candidate.companySize.start}-${candidate.companySize.end}`;
          setSizes([oldSize]);
        }
      } else {
        console.log("error getting correct size", candidate.companySize);
      }
    }
  }, [candidate]);

  const notify = (para) => toast(para);

  const hiddenFileInput = React.useRef(null);

  const onClickAvatar = () => {
    hiddenFileInput.current.click();
  };

  const onAvatarChange = async (event) => {
    const file = event.target.files[0];

    // Check the image minimum size an aspect ratio
    const imageErrors = await checkImageFileSize(file, 100000000);
    if (imageErrors.length === 0) {
      setAvatarUrl(URL.createObjectURL(file));
      setSelectedAvatar(file);
    } else {
      const res = `Invalid image selected:\n${imageErrors.join("\n")}`;
      alert(res);
      logger.error(res, { type: "ImageUpload" });
    }
  };

  const uploadAvatar = async () => {
    setIsUploading(true);
    let data = new FormData();
    data.append("file", selectedAvatar);
    try {
      let response = await uploadMedia(data);
      setIsUploading(false);
      if (!response) return alert("An error has occured.");
      if (response.error) return alert(response?.message);

      return response?.url;
    } catch (error) {
      console.log(error);
      setIsUploading(false);
    }
  };

  const uploadFile = async () => {
    setIsUploading(true);
    let data = new FormData();
    data.append("file", selectedFile);
    try {
      let response = await uploadMedia(data);
      setIsUploading(false);
      if (!response) return alert("An error has occured.");
      if (response.error) return alert(response?.message);
      return response?.url;
    } catch (error) {
      console.log(error);
      setIsUploading(false);
    }
  };

  const onSubmit = async () => {
    let payload;
    // Step 1 specific actions
    // We can get away with not revalidating the entire form because step 2 only contains 2 optional inputs, both of which are select dropdowns
    // If this changes, move validateForm outside the if so that it always runs
    if (step === 1) {
      validateForm();
      const istrue =
        (!selectedFile && !candidate?.cv) ||
        !skills?.length ||
        (!selectedAvatar && !candidate?.avatar) ||
        (errors && Object.keys(errors).length);

      if (istrue) {
        if (Object.keys(errors).length) {
          for (const property in errors) {
            notify(errors[property]);
          }
        }
      } else {
        let fileURL;
        let imageURL;
        if (selectedFile) {
          fileURL = await uploadFile();
        }

        if (selectedAvatar) {
          imageURL = await uploadAvatar();
        }

        payload = {
          ...candidate,
          ...values.candidate,
          // Handle `{label: "", value: {}}` objects where we want to throw just the `value` at the API
          educationLevel: values.candidate?.educationLevel?.value,
          skills: skills,
          salaryExpectation: Number(values?.candidate?.salaryExpectation),
          cv: fileURL || candidate?.cv,
          avatar: imageURL || candidate?.avatar,
          companySize: sizes || [],
        };

        // If we're an existing user (not on the registration flow), include these inputs
        if (!fromSignUp.value) {
          payload = {
            ...payload,
            gender: gender || "",
            neurodiverse: neurodiverse || [],
          };
        }
      }
    }

    // Step 2 specific actions
    if (step === 2) {
      payload = {
        ...candidate,
        gender: gender || "",
        neurodiverse: neurodiverse || [],
      };
    }

    try {
      if (verifyResult.value === "success") {
        payload.phoneVerified = true;
      }
      if (shouldRevalidateNumber.value) {
        payload.phoneVerified = false;
      }
      let response = await updateCandidate(payload);
      if (!response) {
        return;
      }
      if (response.error) {
        return alert(JSON.stringify(response.error));
      }

      setSubtitle("Saved!");

      setTimeout(() => {
        handleSuccessfulSave(response.data.candidate);
        setSubtitle("Save Changes");
      }, 3000);
      if (fromSignUp.value) {
        console.log("New registrant");
        if (step === 1) {
          console.log("Moving to step 2");
          setStep(2);
          return;
        }
        if (
          selectedVacancy.value &&
          typeof selectedVacancy.value === "object" &&
          !Array.isArray(selectedVacancy.value) &&
          selectedVacancy.value !== null
        ) {
          return navigate(`/candidate/intro-message/`);
        }
        return navigate("/candidate/dashboard");
      }
      if (
        selectedVacancy.value &&
        typeof selectedVacancy.value === "object" &&
        !Array.isArray(selectedVacancy.value) &&
        selectedVacancy.value !== null
      ) {
        return navigate(`/candidate/intro-message/`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addSkill = (value) => {
    if (!!value) {
      setSkills([...skills, ...value.split(",").map((i) => i.trim())]);
    }
    setFieldValue("candidate.skills", "");
  };

  const deleteSKill = (index) => {
    setSkills(skills.filter((_, i) => i !== index));
  };

  const selectFileUploadCV = (file) => {
    setSelectedFile(file);
  };

  const setSmsOnly = (val) => {
    setFieldValue("candidate.smsOnly", val);
  };

  if (!error && isLoading) return <LoadingComponent />;

  const step2Inputs = () => (
    <>
      <h2 className="text-white text-4xl mb-8 mt-16">
        Optional information to help remove bias behaviour
      </h2>
      <p
        className={`bg-[${Colors.velvet}] rounded-lg p-6 max-w-screen-md mt-0`}
      >
        Inclusivity is the heart of Seesy. We are working with companies by
        providing them with their data to ensure they remove any bias when
        hiring. If you choose to tell us more about yourself, it enables us to
        more accurately monitor their behaviour and check if it’s in line with
        our inclusion policy.
      </p>

      <div className="max-w-screen-md">
        <DropdownInput
          label="Gender (Optional)"
          options={GENDERS}
          value={values?.candidate?.gender || null}
          onClick={(selectedValue) => {
            setGender(selectedValue);
          }}
        />
      </div>

      <div className="max-w-screen-md mt-16">
        <MultiSelectDropdownInput
          label="Neurodiversity (Optional)"
          options={NEURODIVERSITY || []}
          value={neurodiverse || []}
          onClick={(selectedValue) => {
            setNeurodiverse(selectedValue);
          }}
        />
      </div>
    </>
  );

  return (
    // We can't just check for "CANDIDATE" since just-registered users who do not yet have a profile can hit this page.
    // Instead, use "not employer". Anonymous users are handled by `RequireAuth` in `App.js`
    <AuthGuard
      redirect="/"
      criteria={[
        { value: "EMPLOYER", check: "userDetails.userType", not: true },
      ]}
    >
      <ToastContainer />
      <Container size={size} step={step}>
        <SectionHeading title="manage my account" />
        <div></div>
        <hr />
        <ProfileWrapper size={size}>
          {fromSignUp.value ? null : (
            <TabValues size={size}>
              <TabHeadercontainer>
                <TabHeader
                  tabvalue={tabvalue}
                  value="Personal Details"
                  onClick={() => setTabvalue("Personal Details")}
                >
                  Personal Details
                </TabHeader>

                <TabHeader
                  tabvalue={tabvalue || ""}
                  value="Me-V"
                  onClick={() => setTabvalue("Me-V")}
                >
                  Profile Videos
                </TabHeader>
              </TabHeadercontainer>
              {tabvalue === "My Videos" && (
                <div>
                  <Button button size="medium">
                    Add Video
                  </Button>
                </div>
              )}
            </TabValues>
          )}

          {tabvalue === "Personal Details" ? (
            <FormikProvider value={formikbag}>
              {step === 1 ? (
                <>
                  {fromSignUp.value && (
                    <h2 className="text-white text-4xl mb-8 mt-16">
                      Personal Details
                    </h2>
                  )}
                  <div className="max-w-screen-md">
                    <FormikTextField
                      placeholder="Tell us about yourself"
                      ref={focusInput}
                      type="textArea"
                      value={values?.candidate?.description || ""}
                      name="candidate.description"
                      label="About you"
                      style={{ height: "120px" }}
                    />
                    {
                      <p style={{ color: "red", fontSize: "16px" }}>
                        {errors.description}
                      </p>
                    }
                  </div>
                  <hr />
                  <Title>
                    Your profile image
                    <span className="text-xl text-gray-300 font-thin ml-4">
                      (Click to change)
                    </span>
                  </Title>
                  <p className="font-light">
                    <strong>Tip: </strong>The recommended image size is at least
                    256px * 256px and should have a 1:1 (square) aspect ratio.
                  </p>
                  <AvatarPicker
                    onClick={onClickAvatar}
                    imageUrl={avatarUrl || candidate?.avatar}
                  >
                    <figure />
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={hiddenFileInput}
                      onChange={onAvatarChange}
                    />
                  </AvatarPicker>
                  {!imageUrl && (
                    <p style={{ color: "red", fontSize: "16px" }}>
                      {errors.avatar || "Please upload a profile image"}
                    </p>
                  )}
                  <hr />
                  <div className="max-w-screen-md">
                    <PhoneWrapper>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={values?.candidate?.phoneNo || ""}
                        onChange={(e) => {
                          newNumber.value = e;
                          setFieldValue("candidate.phoneNo", e);
                        }}
                        defaultCountry="GB"
                        countries={["GB", "AE"]}
                        inputComponent={TextField}
                      />
                    </PhoneWrapper>
                    <p style={{ color: "red", fontSize: "16px" }}>
                      {errors.phoneNo}
                      {errors.unverifiedPhone}
                    </p>
                    {!errors.phoneNo && (
                      <PhoneNumberValidationForm
                        showOtpForm={showOtpForm.value}
                      />
                    )}
                    <ValidationResult />

                    <div className="grid gap-4">
                      <p className="m-0">
                        We can send you messages by phone to keep you informed
                        on your application progress. You can come back and
                        change this setting at any time.
                      </p>
                      <button
                        onClick={() =>
                          setFieldValue(
                            "candidate.smsConsent",
                            !values.candidate.smsConsent
                          )
                        }
                        className="flex gap-12 p-8 bg-[#372545] text-white rounded-xl justify-between items-center w-full"
                      >
                        <p className="m-0">
                          {values.candidate.smsConsent
                            ? "Recieve "
                            : "Do not recieve "}
                          phone messages
                        </p>
                        <Toggle
                          checked={values.candidate.smsConsent}
                          onChange={() =>
                            setFieldValue(
                              "candidate.smsConsent",
                              !values.candidate.smsConsent
                            )
                          }
                          name="Receive SMS alerts for applications, etc"
                        />
                      </button>
                      {values.candidate.smsConsent && (
                        <div>
                          <p className="m-0 mb-2">
                            We will use WhatsApp to send application updates. If
                            you wish to use SMS instead, you can select that
                            here. Please note, SMS is not available in all
                            countries and subject to provider terms and
                            conditions.
                          </p>
                          <button
                            onClick={() =>
                              setSmsOnly(!values.candidate.smsOnly)
                            }
                            className="flex gap-12 p-8 bg-[#372545] text-white rounded-xl justify-between items-center w-full"
                          >
                            <p className="m-0">
                              {values.candidate.smsOnly
                                ? "Receive updates by SMS only"
                                : "Receive updates by WhatsApp (recommended)"}
                            </p>
                            <Toggle
                              checked={!values.candidate.smsOnly}
                              onChange={() =>
                                setSmsOnly(!values.candidate.smsOnly)
                              }
                              name="Use WhatsApp or SMS"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="max-w-screen-md">
                    <FormikTextField
                      placeholder="Your minimum salary expectations"
                      fullWidth
                      data-type="number"
                      inputmode="decimal"
                      type="number"
                      value={values?.candidate?.salaryExpectation || 0}
                      name="candidate.salaryExpectation"
                      label="Minimum Salary (£)"
                    />
                    {
                      <p style={{ color: "red", fontSize: "16px" }}>
                        {errors.salaryExpectation}
                      </p>
                    }
                  </div>
                  <hr />

                  <div className="max-w-screen-md">
                    <DropdownInput
                      useFirstOption={false}
                      name="candidate.educationLevel"
                      fullWidth={true}
                      options={
                        availableOptions?.educationLevel?.map(
                          (availableOption) => {
                            return {
                              label: `${availableOption.name} - ${
                                availableOption.level.toLowerCase() === "entry"
                                  ? availableOption.level + " Level"
                                  : "Level " + availableOption.level
                              }`,
                              // Strip the ID out, we don't want it
                              value: (() => {
                                const { _id, ...rest } = availableOption;
                                return rest;
                              })(),
                            };
                          }
                        ) || []
                      }
                      isLoading={isLoadingOptions}
                      label="Education Level"
                      onClick={(selectedValue) =>
                        setFieldValue("candidate.educationLevel", selectedValue)
                      }
                      value={(() => {
                        // This is really, really bad. The dropdown has never supported setting a value, and now it's been shoe-horned in badly...
                        // This should be refactored and the Dropdown itself should simply handle a value being passed.
                        if (!candidate.educationLevel) return null;
                        const selected = availableOptions?.educationLevel?.find(
                          (e) =>
                            e.level === candidate.educationLevel.level &&
                            e.name === candidate.educationLevel.name
                        );
                        if (!selected) return null;
                        return {
                          label: `${selected.name} - ${
                            selected.level.toLowerCase() === "entry"
                              ? selected.level + " Level"
                              : "Level " + selected.level
                          }`,
                          // Strip the ID out, we don't want it
                          value: (() => {
                            const { _id, ...rest } = selected;
                            return rest;
                          })(),
                        };
                      })()}
                    />
                  </div>
                  <hr />
                  <Title className="group">
                    Desired Company Size
                    <Icon
                      icon={faInfoCircle}
                      color={Colors.primaryGreen}
                      size="sm"
                      style={{ cursor: "pointer" }}
                      className="ml-4"
                    />
                    <div className="text-lg hidden transition-all duration-500 group-hover:inline">
                      <span className="bg-secondary/50 border border-primary/5 px-2 py-0.5 ml-1  rounded-md font-light text-white">
                        You can select multiple sizes
                      </span>
                    </div>
                  </Title>
                  <Size className="mt-4" size={size}>
                    <RadiosContainer size={size}>
                      {COMPANY_SIZES.map((size, index) => {
                        return (
                          <RadioComponent
                            // disabled={currentlyEditing !== "size"}
                            key={`radio_${index}`}
                            size={size}
                            item={size}
                            onClick={() => {
                              const newSizes = [...sizes];
                              if (sizes.indexOf(size) === -1) {
                                newSizes.push(size);
                                setSizes([...newSizes]);
                              } else {
                                newSizes.splice(sizes.indexOf(size), 1);
                                setSizes([...newSizes]);
                              }
                            }}
                            selected={sizes.indexOf(size) > -1}
                          />
                        );
                      })}
                      <RadioComponent
                        size={size}
                        item={
                          selectAll === false ? "Select All" : "Deselect All"
                        }
                        onClick={() => {
                          if (selectAll === true) {
                            setSelectAll(false);
                            setSizes([]);
                          } else {
                            setSelectAll(true);
                            setSizes([...COMPANY_SIZES]);
                          }
                        }}
                        selected={sizes.length === COMPANY_SIZES.length}
                      />
                    </RadiosContainer>
                  </Size>
                  {
                    <p style={{ color: "red", fontSize: "16px" }}>
                      {errors.companySize}
                    </p>
                  }

                  <hr />

                  <Title>My Skills</Title>
                  <Row className="max-w-screen-md">
                    <FormikTextField
                      placeholder="Add skill"
                      fullWidth
                      name="candidate.skills"
                      value={values.candidate.skills || ""}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          addSkill(values.candidate.skills);
                        }
                      }}
                      spacer={false}
                    />
                    <Button
                      button
                      size="small"
                      children={"Add"}
                      onClick={() => {
                        addSkill(values.candidate.skills);
                      }}
                    />
                  </Row>
                  <SkillBody className="flex flex-wrap gap-4 mt-6" size={size}>
                    {skills?.map((i, index) => (
                      <NewTagComponent
                        key={index}
                        onClick={() => deleteSKill(index)}
                      >
                        {i}
                      </NewTagComponent>
                    ))}
                  </SkillBody>
                  {skills?.length ? null : (
                    <p style={{ color: "red", fontSize: "16px" }}>
                      Please add at least 1 skill
                    </p>
                  )}
                  <hr />
                  {!fromSignUp.value && (
                    <div className="mt-12">
                      {step2Inputs()}
                      <hr />
                    </div>
                  )}
                  <Title className="mb-8">Manage CV</Title>
                  {candidate?.cv && (
                    <a
                      href={candidate?.cv}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-primary text-gray-800 px-3 py-2 rounded-xl "
                    >
                      View current CV{" "}
                      <Icon
                        icon={faFilePdf}
                        size="sm"
                        className={" text-gray-800 stroke-2"}
                      />
                    </a>
                  )}

                  <FileUpload
                    type={"file"}
                    cloudFile={candidate?.cv || null}
                    setSelectedFile={selectFileUploadCV}
                  />

                  {!selectedFile ? (
                    <p style={{ color: "red", fontSize: "16px" }}>
                      Upload your CV
                    </p>
                  ) : null}
                  {!fromSignUp.value && (
                    <>
                      <hr />
                      <Title className="mb-4 pb-2">Delete my account</Title>
                      <AccountStyle size={size}>
                        <p>Permanently delete your account</p>
                        <Form size={size}>
                          <Field
                            type="checkbox"
                            id="deleteaccount"
                            name="user"
                            checked={accepted}
                            onClick={() => setAccepted(!accepted)}
                          />
                          <label htmlFor="deleteaccount">
                            I understand that by deleting my account all data,
                            including application data will be permanently
                            erased and cannot be recovered.
                          </label>
                        </Form>
                        <Button
                          type="alt"
                          button
                          size="large"
                          disabled={!accepted ? true : false}
                          onClick={deleteCompany}
                        >
                          Delete My Account
                        </Button>
                      </AccountStyle>
                    </>
                  )}
                </>
              ) : (
                step2Inputs()
              )}
            </FormikProvider>
          ) : (
            <MyVideos data={candidate}></MyVideos>
          )}
        </ProfileWrapper>
      </Container>
      <ManageProfileFooter
        subtitle={fromSignUp.value ? "Save & Continue" : subtitle}
        save={onSubmit}
        disabled={
          !selectedFile ||
          !skills?.length ||
          (!selectedAvatar && !candidate?.avatar) ||
          (errors && Object.keys(errors).length)
        }
        loading={submitting || isUploading}
      />
    </AuthGuard>
  );
};

const Container = styled.div(
  ({ size, step }) => `
  position: relative;
  isolation: isolate;
  padding-top: 20px;
  padding-left: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
  padding-right: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
  padding-bottom: 180px;

  ${
    step === 2
      ? `
    background-image: url(${bgImage});
    background-size: cover;
    background-position: center bottom;
  
    &::after {
      position: absolute;
      inset: 0;
      content: '';
      background-image: linear-gradient(180deg, rgba(28, 39, 29, 0.6) 0%, rgba(37, 1, 64, 0.8) 100%);
      // More readable IMO:
      // background-image: linear-gradient(180deg, rgba(28, 39, 29, 0.7) 0%, rgba(37, 1, 64, 1) 100%);
      // backdrop-filter: blur(3px);
      z-index: -1;
    }
  `
      : ""
  }
  
  & p {
      color: white;
      line-height: 25.6px;
      margin: 30px 0px;
      font-size: 16px;

  }
  & hr{
    border-color: #8d8e8e;
   margin-top: 30px;
   margin-bottom: 20px;
  }

  & input{
    font-size: 16px;
  }
`
);

const TabHeadercontainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  position: relative;
`;
const TabHeader = styled.h2(
  ({ tabvalue, value }) => `
  font-family: Archivo;
  font-style: normal;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 18px;
  margin-right: 35px;
  color: ${tabvalue === value ? Colors.white : "rgba(255, 255, 255, 0.4)"};
  `
);

const Title = styled.h2`
  color: white;
  font-family: "Archivo";
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const Form = styled.form(
  ({ size }) => `
  margin-bottom: 20px;
  display: flex;
  & label {
    color: white;
    font-weight: 400;
    font-family: Roboto;
    margin-right: 30px;
    margin-top: 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;

}
   & input{
        margin-top: 24px;
        cursor: pointer;
   }
  }
  
  & input[type="radio"]{
    display: none;
  }
  

  & input[type="radio"]+label:before {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 6px;
    margin-right: 3px;
    background-clip: content-box;
   
    background-color: #232424;
    border-radius: 50%;
  }


  & input[type="radio"]:checked + label:before {
    background-color: #66E12C;
  }

`
);

const Size = styled.div(
  ({ size }) => `
& form {

  display: ${size.isMdUp ? "flex" : "block"};
}

`
);

const SkillBody = styled.div(
  ({ size }) => `
  width:${size.isMdUp ? "70% !important" : "100%"};
`
);

const AccountStyle = styled.div(
  () => `
& from {
  display: flex;
}
  & button {
    margin-top: 25px;
    font-size: 18px;
   
  }
  & p {
    margin-top: -15px;
    color: #8d8e8e;
  }
`
);
const TabValues = styled.div(
  ({ size }) => `
  display: ${size.isMdUp ? "flex" : "block"};
  align-items: center;
  justify-content: space-between;
  & > div:nth-child(2) {
    & button {
      padding: 18px 30px;
      font-size: 18px;
    }
  }
`
);

const RadiosContainer = styled.div(
  ({ size }) => `
  display: ${size.isMdUp ? "flex" : "block"};
  width: 100%;
  flex-direction: ${size.isMdUp ? "row" : "row"};
  flex-wrap: wrap;
  align-items:${size.isMdUp ? "center" : "flex-start"};
   & div{
     margin-right: 10px;
     align-items: center;

     & input {
       margin: 0px;
     }
   }
`
);

const Row = styled.span`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: stretch;
`;

const ProfileWrapper = styled.div(
  ({ size }) => `
  margin-left: ${size.isLgUp ? "100px" : "0px"};
`
);

// A ridiculous workaround to get the nested input as 100% width
// This is what you get for using styled components with props instead of just CSS
const PhoneWrapper = styled.div`
  div:nth-child(2),
  input {
    width: 100%;
    margin: 0;
  }
`;
