import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { IndexedDB } from 'utils/indexedDb'
import { useLogger } from 'hooks/useLogger';

export const Recorder = forwardRef(
  (
    {
      recId,
      onStartStream,
      onStopStream,
      onStartRecording,
      onStopRecording,
      onCompleteRecording
    },
    ref,
  ) => {
    const { logger } = useLogger()

    const RECORDER_TICK = 1000;
    const db = useRef(new IndexedDB())

    const [stream, setStream] = useState(null)
    const [recorder, setRecorder] = useState(null)
    const [isRecording, setIsRecording] = useState(false)

    useImperativeHandle(ref, () => ({

      /**
       * Exposed method to start the camera stream - parent components can call this
       */
      async startStream() {

        const videoProps = {
          aspectRatio: 4 / 3,
          width: { min: 640, ideal: 960, max: 1440 },
          height: { min: 480, ideal: 720, max: 1080 },
        }

        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'user',
            ...videoProps
          },
          audio: true,
        })
        setStream(mediaStream)
        const settings = mediaStream.getVideoTracks()[0].getSettings()
        const width = settings.width
        const height = settings.height
        console.log(`mediaStream settings`, settings)
        if (onStartStream) onStartStream(mediaStream, width, height)
      },


      /**
       * Exposed method to stop the camera stream - parent components can call this
       */
      async stopStream() {
        if (!stream) return
        stream.getTracks().forEach((track) => track.stop())
        setStream(null)
        if (onStopStream) onStopStream()
      },

      /**
       * Exposed method to start recording - parent components can call this
       */
      async startRecording() {
        if (!isRecording) _startRecording()
      },

      /**
       * Exposed method to stop recording - parent components can call this
       */
      async stopRecording() {
        try {
          if (isRecording) recorder.stop()
        } catch (ex) {
          logger.error(`Failed to stop recorder`, { type: "Recorder", error: ex })
          // Don't throw just because the recorder failed to stop - it'll kill the whole UI. Chances are Stop was called twice 
          // We'll still call the onStop event so that parent UI can react to being stopped, even if it isn't.
          await handleStopRecording()
        }
      },
    }))

    /**
     * Start recording handler
     */
    const _startRecording = async () => {
      if (!stream) return

      // Create recorder
      const recorder = new MediaRecorder(stream, {
        videoBitsPerSecond: 2500000,
        audioBitsPerSecond: 128000,
      })

      // Bind recording started event - broadcast to parent
      recorder.onstart = (_) => {
        if (onStartRecording) onStartRecording({ startTime: new Date().valueOf() })
      };

      /**
       * Bind ondataavailable event
       * Event that fires on each recording tick, provides a chunk of the recording
       * Save each chunk to IndexedDB, add to the existing array of chunks
       */
      recorder.ondataavailable = async (e) => {
        if (e.data.size > 0) {
          const existingChunks = await db.current.get(`${recId}`)
          await db.current.set(`${recId}`, existingChunks ? [...existingChunks, e.data] : [e.data])
        }
      }

      // Bind recording stopped event
      recorder.onstop = async () => await handleStopRecording()

      // Start and set state
      recorder.start(RECORDER_TICK)
      setRecorder(recorder)
      setIsRecording(true)
    }

    const handleStopRecording = async () => {
      // Fire stop recording events immediately to update the UI before expensive compute operations
      onStopRecording()
      setIsRecording(false)

      // Only proceed with expensive blob operations if we have 'onCompleteRecording' set.
      // Why bother if nobody's listening?
      if (onCompleteRecording) {
        try {
          // Wait for the tick period before we get the data from blobs
          // We need onDataAvailable to tick for the last time, else we'll be missing up to a second of the recording.
          // We might then be under the required length, despite the user believing they're over
          await new Promise((resolve) => setTimeout(() => resolve(), RECORDER_TICK))

          // Righto, now get all the data from indexedDB
          const data = await db.current.get(`${recId}`)
          logger.info(`Blob count for recording: ${data.length}`, { type: "Recorder", recId, blobCount: data.length })

          // Combine the individual blobs
          const combined = new Blob(data, { type: data[0].type })


          // Emit the event
          onCompleteRecording({ data: combined })
        } catch (ex) {
          // Don't throw if this fails - it'll kill the whole UI. Result will be no playback etc
          // Return the error in the `onCompleteRecording` callback for the parent component to handle.
          logger.error(`Failed to create output video`, { type: "Recorder", error: ex })
          onCompleteRecording({ error: ex })
        }
      }
      return;
    }

    return <></>
  },
)
