import { Button } from "components/Button";
import { ChatBubble } from "components/ChatBubble";
import { ChatCard } from "components/ChatCard";
import {
  faAngleRight,
  faUpload,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import { useNavigate } from "react-router-dom";
import { UserProfile } from "components/UserProfile/UserProfile";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const chatGroup = (date, index, chatData) => {
  if (index === 0) {
    return date;
  } else if (index > 0 && chatData[index - 1].date !== date) {
    return date;
  } else {
    return false;
  }
};
export const ChatArea = ({ chatData, activeUser, currentUser }) => {
  const [input, setInput] = useState("");
  const [currentChat, setCurrentChat] = useState();

  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    const thread = {
      sender: false,
      avatar: chatData[activeUser].user.avatar,
      date: new Date(),
      message: input,
    };
    if (input) {
      setCurrentChat([...currentChat, thread]);
    }
    setInput("");
  };

  useEffect(() => {
    setCurrentChat(chatData[activeUser].threads);
  }, [activeUser, chatData]);

  const videoChat = () => {
    navigate("/employer/dashboard/vacancy/video-chat");
  };

  return (
    <>
      <Container>
        <SideBar>
          <Scroll>
            {chatData.map((chat, index) => (
              <UProfile
                activeUser={chat.user.id === activeUser}
                onClick={() => {
                  currentUser(index);
                }}
              >
                <UserProfile
                  name={chat.user.name}
                  userImage={chat.user.avatar}
                  active={chat.user.active}
                  selectedUser={chat.user.id === activeUser}
                />
                {chat.user.active ? (
                  <Icon icon={faVideo} color="#9E82FB" onClick={videoChat} />
                ) : null}
              </UProfile>
            ))}
          </Scroll>

          <ExitButtonStyle>
            <Button button> Exit Messages</Button>
          </ExitButtonStyle>
        </SideBar>
        <Chat>
          <Scroll>
            {currentChat &&
              currentChat.map((chat, index) => (
                <>
                  <ChatCard
                    date={
                      chat.date
                        ? chatGroup(chat.date, index, currentChat)
                        : null
                    }
                    title={
                      <ChatBubble avatar={chat.avatar} sender={chat.sender}>
                        {chat.message}
                      </ChatBubble>
                    }
                  />
                </>
              ))}
          </Scroll>
          <Form onSubmit={onSubmit}>
            <textarea
              rows="1"
              type="text"
              placeholder="type here..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <input type="file" name="myfile" />
            <Icon icon={faUpload} />
            <button onClick={onSubmit} type="submit" value="submit">
              <Icon icon={faAngleRight}></Icon>
            </button>
          </Form>
        </Chat>
      </Container>
    </>
  );
};

const Container = styled.div(
  () => `
display: flex;
width: 100%;
`
);

const SideBar = styled.div(
  () => `
background-color: black;
min-width: 361px;
position: relative;

`
);
const Chat = styled.div(
  () => `
  position: relative;
  width: 100%;
  background-color: #232424;
  
`
);

const Scroll = styled.div`
  height: calc(100vh - 158px);
  height: calc(100svh - 158px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #323232;
  }

  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #f1f1f1;
  }
`;
const UProfile = styled.div(
  ({ activeUser }) => `
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
background-color:${activeUser ? "white" : "#232424"};
margin-bottom: 1px;
& svg{
    margin-right: 15px;
    cursor: pointer;
}
& img{
    margin-left: 5px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
`
);

const ExitButtonStyle = styled.div`
  bottom: 0;
  left: 0px;
  width: 100%;
  & button {
    width: 100%;
    padding: 30px 0px;
    border-radius: 0px;
    font-size: 16px;
  }
`;

const Form = styled.form`
  position: relative;
  & textarea {
    bottom: 0;
    left: 0px;
    font-size: 16px;
    width: 100%;
    padding: 30px 0px;
    outline: none;
    padding-left: 30px;
    border: none;
    color: black;
    ::placeholder {
      color: black;
      opacity: 2;
    }
  }
  & svg {
    z-index: 0;
    position: absolute;
    top: 35px;
    color: black;
    right: 105px;
    cursor: pointer;
    font-size: 18px;
  }
  & button {
    cursor: pointer;
    z-index: 22;
    position: absolute;
    right: 63px;
    top: 36px;
    background-color: transparent;
    border: none;
    & svg {
      color: black;
      position: relative;
      right: 0;
      top: 0;
      margin: auto;
      cursor: pointer;
    }
  }
  & input {
    position: absolute;
    top: 35px;
    z-index: 11;
    right: -20px;
    opacity: 0;
    cursor: pointer;
    font-size: 10px;
    cursor: pointer;
    ::-webkit-file-upload-button {
      visibility: hidden;
      width: 0px;
    }
  }
`;
