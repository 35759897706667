import React from "react";
import styled from "styled-components";

import { Colors } from "styles/Colors";

export const Divider = React.memo(() => <Line />);

const Line = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: ${Colors.white};
  opacity: 0.03;
  width: 100%;
  margin: 30px 0px;
`;
