import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  availableOptions: [],
  activeOptions: [],
  requestedOptions: [],
  results: [],
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setAvailableOptions: (state, action) => {
      const newState = { ...state }
      newState.availableOptions = action.payload
      return newState
    },
    setActiveOptions: (state, action) => {
      const newState = { ...state }
      newState.activeOptions = action.payload
      return newState
    },
    setRequestedOptions: (state, action) => {
      const newState = { ...state }
      newState.requestedOptions = action.payload
      return newState
    },
    setResults: (state, action) => {
      const newState = { ...state }
      newState.results = action.payload
      return newState
    },
    resetAvailableOptions: (state) => {
      const newState = { ...state }
      newState.availableOptions = []
      return newState
    },
    resetActiveOptions: (state) => {
      const newState = { ...state }
      newState.activeOptions = []
      return newState
    },
    resetRequestedOptions: (state) => {
      const newState = { ...state }
      newState.requestedOptions = []
      return newState
    },
    resetResults: (state) => {
      const newState = { ...state }
      newState.results = []
      return newState
    },
    resetSearch: (state) => {
      const newState = { ...state }
      newState.results = []
      newState.activeOptions = []
      newState.requestedOptions = []
      return newState
    },
    resetAll: () => {
      return initialState
    },
  },
})

export const {
  setAvailableOptions,
  setActiveOptions,
  setRequestedOptions,
  setResults,
  resetAvailableOptions,
  resetActiveOptions,
  resetRequestedOptions,
  resetResults,
  resetSearch,
  resetAll,
} = searchSlice.actions

export const searchReducer = searchSlice.reducer

export const selectAvailableSearchOptions = (state) =>
  state.search.availableOptions
export const selectActiveSearchOptions = (state) => state.search.activeOptions
export const selectRequestedSearchOptions = (state) => state.search.requestedOptions
export const selectSearchResults = (state) => state.search.results
