import { Button } from "components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import { useUpdateCandidateMutation } from "store/userAPI";
import Progress from "react-progressbar";
import React, { useState } from "react";
import styled from "styled-components";

const Stepper = React.memo(({ state, steps }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const { isMdUp } = useResponsiveLayout();

  const navigate = useNavigate();
  const location = useLocation();
  const navigatingFrom = location?.state?.from || "/";

  const [updateCandidate] = useUpdateCandidateMutation();

  const handleNext = () => {
    if (currentStep <= steps?.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const current = (Array.isArray(steps) && steps[currentStep - 1]) || {
    component: () => "",
  };

  const submitForm = async () => {
    try {
      let response = await updateCandidate(state);
      console.log(response);
      if (!response?.data) return;
      navigate(navigatingFrom, { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StepperWrapper>
      <current.component handleNext={handleNext}></current.component>
      <Spacer />
      <TotalQuestions isMdUp={isMdUp}>
        Question {currentStep} of {steps?.length}
      </TotalQuestions>
      <Action isMdUp={isMdUp}>
        <ProgressWrapper>
          <Progress
            color="#9400FF"
            completed={(currentStep / steps?.length) * 100}
          />
        </ProgressWrapper>
        <ActionWrapper>
          {currentStep > 1 && (
            <Button button type="alt" onClick={() => handlePrev()}>
              {steps?.length === currentStep ? "Cancel" : "Back"}
            </Button>
          )}
          {currentStep !== steps?.length ? (
            <Button button onClick={() => handleNext()}>
              Next
            </Button>
          ) : (
            <Button button onClick={submitForm}>
              Complete & see jobs
            </Button>
          )}
        </ActionWrapper>
      </Action>
    </StepperWrapper>
  );
});

export default Stepper;

const StepperWrapper = styled.div`
  border-radius: 4px;
  width: 100%;
  padding: 43px 20px;
  border-color: rgba(0, 0, 0, 0.25);
  background-color: #4b4651;
  & .progressbar-container {
    background-color: #fff;
    border-radius: 30px;
    & .progressbar-progress {
      border-radius: 30px;
    }
`;
const Action = styled.div(
  ({ isMdUp }) => `
  display: ${isMdUp ? "flex" : "block"};
  justify-content: space-between;
  align-items: center;
  & button {
    margin-left: ${!isMdUp ? "0px" : "20px"};
    margin-top: ${!isMdUp ? "30px" : ""};
    font-weight: 500;
    font-size: 18px;
    width: ${!isMdUp ? "100%" : ""}
  }
`
);
const ProgressWrapper = styled.div`
  flex: 9;
`;
const ActionWrapper = styled.div`
  flex: 5;
  text-align: right;
`;
const TotalQuestions = styled.h3(
  ({ isMdUp }) => `
  color: white;
  margin: 0px;
  font-size: 18px;
  margin-left: ${!isMdUp ? "0px" : "25px"};
  margin-bottom: ${!isMdUp ? "10px" : "0px"};
`
);
const Spacer = styled.div`
  margin-top: 100px;
`;
