export const VideoReducer = (state, action) => {
  const now = new Date().valueOf()
  switch (action.type) {
    case 'BLUR': {
      return {
        ...state,
        focusChanges: [
          ...state.focusChanges,
          { wasLost: true, timestamp: now },
        ],
        focusChangeTime: now,
        hasFocus: false,
        lastActivity: now,
        lastActivityDelta: state.lastActivity
          ? (now - state.lastActivity) / 1000
          : 0,
      }
    }

    case 'ENDED': {
      return {
        ...state,
        position: 0,
        state: 'ended',
        watchNumber: state.watchNumber++,
      }
    }
    case 'FOCUS': {
      return {
        ...state,
        hasFocus: true,
        focusChanges: [
          ...state.focusChanges,
          { wasLost: false, timestamp: now },
        ],
        focusChangeTime: now,
        lastActivity: now,
        lastActivityDelta: state.lastActivity
          ? (now - state.lastActivity) / 1000
          : 0,
      }
    }
    case 'LOADED': {
      return {
        ...state,
        duration: action.payload.duration,
      }
    }
    case 'PAUSED': {
      return {
        ...state,
        lastActivity: now,
        lastActivityDelta: state.lastActivity
          ? (now - state.lastActivity) / 1000
          : 0,
        state: 'paused',
      }
    }
    case 'PLAYING': {
      // If the current state is 'ended', reset blur time, play time, skipped seconds, and volume time - we're doing a re-watch
      return {
        ...state,
        blurTime: state.state === 'ended' ? 0 : state.blurTime,
        focusChanges: state.state === 'ended' ? [] : state.focusChanges,
        lastActivity: now,
        lastActivityDelta: state.lastActivity
          ? (now - state.lastActivity) / 1000
          : 0,
        playTime: state.state === 'ended' ? 0 : state.playTime,
        skips: state.state === 'ended' ? [] : state.skips,
        skippedSeconds: state.state === 'ended' ? 0 : state.skippedSeconds,
        state: 'playing',
        volumeTime: state.state === 'ended' ? 0 : state.volumeTime,
      }
    }
    case 'POSITION': {
      return { ...state, position: Number(action.payload.value.toFixed(2)) }
    }
    case 'SEEK': {
      const lastPositionChangeDelta = Number(
        (action.payload.to - action.payload.from).toFixed(2),
      )
      return {
        ...state,
        lastActivity: now,
        lastActivityDelta: state.lastActivity
          ? (now - state.lastActivity) / 1000
          : 0,
        lastPositionChange: {
          from: Number(action.payload.from.toFixed(2)),
          to: Number(action.payload.to.toFixed(2)),
        },
        lastPositionChangeDelta: lastPositionChangeDelta,
        position: Number(action.payload.to.toFixed(2)),
        skippedSeconds: Number(
          (state.skippedSeconds + lastPositionChangeDelta).toFixed(2),
        ),
        skips: [
          ...state.skips,
          {
            from: action.payload.from,
            to: action.payload.to,
            delta: lastPositionChangeDelta,
            timestamp: now,
          },
        ],
      }
    }
    case 'TIMED_STATS_TICK': {
      return {
        ...state,
        activeTime:
          state.activeTime + Number((action.payload.value / 1000).toFixed(2)),
        // Only increment blurTime if we're playing and not in focus
        blurTime:
          state.state === 'playing' && !state.hasFocus
            ? state.blurTime + Number((action.payload.value / 1000).toFixed(2))
            : state.blurTime,
        playTime:
          // Only increment playTime if we're playing
          state.state === 'playing'
            ? state.playTime + Number((action.payload.value / 1000).toFixed(2))
            : state.playTime,
        // Only increment volumeTime if we're playing and below the volume threshold
        volumeTime:
          state.state === 'playing' &&
          state.volume * 100 <=
            Number(process.env.REACT_APP_VOLUME_THRESHOLD_PERCENT ?? 10)
            ? state.volumeTime +
              Number((action.payload.value / 1000).toFixed(2))
            : state.volumeTime,
      }
    }
    case 'VOLUME': {
      return {
        ...state,
        lastActivity: now,
        lastActivityDelta: state.lastActivity
          ? (now - state.lastActivity) / 1000
          : 0,
        volume: action.payload.value,
      }
    }
    default:
      throw new Error()
  }
}
