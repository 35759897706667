import React from "react";
import styled from "styled-components";
export const Seeall = React.memo(({ onClick }) => {
  return (
    <Container onClick={onClick} className="h-[26rem] mt-2">
      <p> See all</p>
    </Container>
  );
});

const Container = styled.div`
  height: 260px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.44);
  hover: {
    background-color: #fece1a;
  }
  display: flex;
  border-radius: 8px;

  & p {
    margin: auto;
    align-items: center;
    font-size: 20px;
    letter-spacing: 0.3px;
    color: white;
    cursor: pointer;
    text-decoration: underline;
  }
`;
