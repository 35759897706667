import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Collapse } from 'components/collapse/Collapse'
import { Question } from 'components/Question/Question'
import { ReadMore } from 'components/ReadMore'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout'
import React from 'react'
import styled from 'styled-components'

export const JobContent = React.memo(
  ({
    data: { description, benefits, questions },
    companyDetails,
  }) => {
    const size = useResponsiveLayout()
    // description = "Seesy is exciting and new disruptive Software business that is changing how people hire or will be hired by using visceral short form video. They have created a Netflix style job board which allows job seeker to see jobs, not just read. The candidate would need to have clear experience and strengths in building and managing scalable systems and processes, including management of Product / Marketing / Data & Operations. You must have start up experience, ideally a tech startup but certainly be happy to be in the office in Shoreditch, in the trenches with us everyday. This opportunity gives someone the ability to join us in a very senior capacity, but at a point with a live product and clients.  We now would like to have another senior officer in the wheel house our CEO, helping to navigate this ship. This can come with equity, so there’s a real opportunity to jump in and help us scale having saved yourself of the pain and torture of the early foundation build!!!If you are or know of such an amazing human that wishes to join our quest to build the next generation of hiring and hiring built for diverse talent and people, please apply with your video.  ";
    return (
      <Container size={size}>
        <ContentWrapperBodyAbout
          size={size}
          style={{ gridArea: 'vacancy-description' }}
        >
          <h2> Vacancy Description</h2>
          <ReadMore defaultOpen={!size.isMdUp}><p>{description}</p></ReadMore>
        </ContentWrapperBodyAbout>
        {size.isMdUp ? <Space style={{ gridArea: 'company-space' }} /> : ''}
        <ContentWrapperBodyAbout
          size={size}
          style={{ gridArea: 'company-description' }}
        >
          <h2> About Company</h2>
          <ReadMore defaultOpen={!size.isMdUp}><p>{companyDetails.description}</p></ReadMore>
        </ContentWrapperBodyAbout>
        <div style={{ gridArea: 'questions' }}>
          <Collapse
            bg="#372545"
            space
            defaultOpen
            title="Questions from this employer"
            fullWidth
            viewportOverride
          >
            {questions
              ? questions.map((i, index) => (
                <Question color="#9400FF" key={index}>
                  <p>
                    <span> Q{index + 1} . </span> {i}
                  </p>
                </Question>
              ))
              : ''}
          </Collapse>
        </div>
        {size.isMdUp ? <Space style={{ gridArea: 'questions-space' }} /> : ''}
        <div style={{ gridArea: 'benefits' }}>
          <Collapse bg="#372545" space defaultOpen title="Key Benefits" fullWidth viewportOverride>
            {benefits
              ? benefits.map((i, index) => (
                <Question color="#9400FF" key={index}>
                  <span> / </span>
                  {i}
                </Question>
              ))
              : ''}
          </Collapse>
        </div>
      </Container>
    )
  },
)

const Container = styled.div(
  ({ size }) => `
  grid-area: content;
  display: grid;
  gap: ${size.isMdUp ? '20px' : '50px'};
  grid-template-areas: ${size.isMdUp
      ? `
    'vacancy-description'
    'company-space'
    'company-description'
    'questions'
    'questions-space'
    'benefits'
    `
      : `
    'questions'
    'vacancy-description'
    'company-description'
    'benefits'
  `
    };
  // Fix no padding on bottom due to collapse element - could fix that but don't want to change that component
  padding-bottom: ${size.isMdUp ? '0' : '20px'};
  position: relative;
  border-radius: 5px;
  color: white;
  & h2{
    font-size: 18px;
    margin-top: 0px !important;
    font-weight: 400;
    margin-top: 22px;
    margin-bottom: 0px;
    text-transform: capitalize;;
  }
  & h5{
    white-space: pre-line;
  }
  
  // & > div:nth-child(1) > div {
  //   width: 100%;
  //   img{
  //     width: 100%;
  //   }
   
  // }
  & p{
      font-size: 16px;
      font-weight: 400 !important;
      line-height: 25px;
  }
  & h5{
    line-height: 25px;
    font-size: 15px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  & span{
    margin-right: 5px;
    color: #fff;
    font-size: 18px;
    font-weight: 400px;
  }

  * {
    word-break: break-word;
  }

  `,
)
const ContentWrapperBodyAbout = styled.div(
  ({ size }) => `
  background:  ${size.isMdUp ? '#372545' : 'transparent'};
  box-sizing: border-box;
  border-radius: 5px;
  padding: ${size.isMdUp ? '16px 26px' : '0'};
  display: grid;
  gap: 16px;
`,
)

const Space = styled.hr`
  border-width: 1px !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
`
