import { Button } from "components/Button";
import { data } from "./data";
import {
  faPrint,
  faShareNodes,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import { SectionHeading } from "components/Headings/SectionHeading";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import { UserProfile } from "components/UserProfile/UserProfile";
import React from "react";
import styled from "styled-components";

export const Transcript = React.memo(({ setOpen, open, selectedTranscipt }) => {
  const { isMdUp } = useResponsiveLayout();
  const action = () => (
    <Buttonstyle>
      <Button button onClick={setOpen}>
        <Icon icon={faXmark}></Icon>
      </Button>
    </Buttonstyle>
  );
  const actionbtn = () => (
    <div>
      <Button button>
        <Icon icon={faShareNodes}></Icon>
      </Button>
      <Button button>
        <Icon icon={faPrint}></Icon>
      </Button>
    </div>
  );
  return (
    <>
      <Blur />
      <Container open={open} isMdUp={isMdUp}>
        <ContentWrapper isMdUp={isMdUp}>
          <UserProfile
            userImage={selectedTranscipt.user.avatar}
            name={selectedTranscipt.user.name}
            action={action()}
          />
        </ContentWrapper>
        <SectionBody isMdUp={isMdUp}>
          <SectionHeading
            date={selectedTranscipt.video.date}
            title="transcript"
            actions={actionbtn()}
          />
          <Card isMdUp={isMdUp}>
            {data.transcript &&
              data.transcript.map((i) => (
                <UserProfile userImage={i.avatar} para={i.detail} />
              ))}
          </Card>
        </SectionBody>
      </Container>
    </>
  );
});

const Container = styled.div(
  ({ open }) => `
position: fixed;
    top: 0;
  padding-right: 0px;
  display: ${open ? "flex" : "none"};
  justify-content: flex-end;
  transition: 1s all ease-in-out;
  left: 0;

    align-items: flex-end;
    flex-direction: column;
  }
  & h6 {
    color: black;
  }
  & h4 {
    color: black;
  }
  & h3{
    color: black;
  }
`
);
const Buttonstyle = styled.div`
  & button {
    background-color: transparent;
  }
  & svg {
    color: black;
  }
`;
const SectionBody = styled.div(
  ({ isMdUp }) => `
  background-color: #f2f2f2;
  width: ${isMdUp ? "70%" : "100%"};
  padding-bottom: 100% !important;
  padding: 30px;
  padding-right: 20px;
  padding-left: 20px;
  & > div h3 {
    font-size: 19px;
  }
  & button{
    margin-top: ${isMdUp ? "0px" : "10px"};
  }
`
);
const Card = styled.div(
  ({ isMdUp }) => `
  height: 500px;
  padding-right: ${isMdUp ? "0px" : "18px"};
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #e5e5e5;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #9400ff;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #9400ff;
  }

  & img {
    width: 40px;
    height: 40px;
    boder-radius: 50%;
  }
  & p {
    margin-left: 50px;
  }
`
);
const ContentWrapper = styled.div(
  ({ isMdUp }) => `
  width: ${isMdUp ? "70%" : "100%"};
  padding: 0px 20px;
  background-color: white;
  & img {
    width: 60px;
    height: 60px;
  }
 
`
);

const Blur = styled.div`
  background: #000000b8;
  position: absolute;
  left: 0;
  right: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
