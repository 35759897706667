import * as Yup from 'yup'
import { Button } from 'components/Button'
import { Colors } from 'styles/Colors'
import { FormikProvider } from 'formik'
import { FormikTextField } from 'components/form/FormikTextField'
import { loginUser, registerCompany, useAuthDispatch } from 'Context'
import { useFormik } from 'formik'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export const AuthScreen = React.memo(() => {
  const navigate = useNavigate()
  let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  let validPass = /^(?=.*[A-Z])(?=.*?[a-z])(?=.*\d).{7,}$/
  const [authType, setAuthType] = useState('signIn')
  const [errors, setErrors] = useState({})
  const [iserror, setIserror] = useState('')
  const [validationInfo, setValidationinfo] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const [token, setToken] = useState()

  const { isMdUp } = useResponsiveLayout()

  const signInValidationSchema = Yup.object().shape({
    user: Yup.object().shape({
      email: Yup.string()
        .required('required')
        .email('Please input a valid email'),
      password: Yup.string().required('required'),
    }),
  })

  const signUpValidationSchema = Yup.object().shape({
    user: Yup.object().shape({
      companyName: Yup.string().required('required'),
      email: Yup.string()
        .required('required')
        .email('Please input a valid email'),
      password: Yup.string()
        .required('required')
        .min(8, 'Password should be at least 8 characters')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
        ),
      confirmPassword: Yup.string()
        .required('required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
  })

  const resetPassword = () => {
    navigate('/forgot-password')
  }

  const dispatch = useAuthDispatch()

  const validateConfirmPassword = (pass, value) => {
    let error = ''
    if (pass && value) {
      if (pass !== value) {
        error = 'Password not matched'
      }
    }
    return error
  }

  const onSubmitSignUp = async () => {
    const istrue = errors && Object.keys(errors).length
    if (istrue) {
      setValidationinfo(true)
    } else {
      setValidationinfo(false)
      validateForm()
      const payload = {
        companyName: !token ? values.user.companyName : undefined,
        email: values.user.email.toLowerCase(),
        password: values.user.password,
        token: token,
      }
      try {
        let response = await registerCompany(dispatch, payload)
        setIserror(response.message)
        if (!response.user) return
        if (token) {
          navigate('/employer/account/step-2?token=' + token)
        } else {
          navigate('/employer/account/step-2')
        }
        setTimeout(() => {
          document.location.reload()
        }, 1500)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const onSubmitSignIn = async () => {
    const istrue = errors && Object.keys(errors).length

    if (istrue) {
      setValidationinfo(true)
    } else {
      const payload = {
        email: signInValues.user.email.toLowerCase(),
        password: signInValues.user.password,
      }

      try {
        let response = await loginUser(dispatch, payload)
        setIserror(response.message)
        if (!response.user) return
        navigate('/employer/dashboard', { replace: true })
        setTimeout(() => {
          document.location.reload()
        }, 1500)
      } catch (error) {
        console.log('e', error)
      }
    }
  }

  const formikbagSignin = useFormik({
    initialValues: {
      user: {
        email: '',
        password: '',
      },
    },
    validate: (values) => {
      const errors = {}
      if (!values.user.email || !values.user.email.match(validRegex)) {
        errors.email = 'Please Enter a Valid Email'
      }
      if (!values.user.password || !values.user.password.match(validPass)) {
        errors.password =
          'Password must be, atleast 8 Characters with Lowercase ,Uppercase & Numbers'
      }

      setErrors(errors)
      return errors
    },
    onSubmit: onSubmitSignIn,
    signInValidationSchema,
  })

  const formikbagSignup = useFormik({
    initialValues: {
      user: {
        companyName: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
    },
    validate: (values) => {
      const errors = {}
      if (!values.user.companyName && !token) {
        errors.companyName = 'Enter companyName'
      }
      if (!values.user.password || !values.user.password.match(validPass)) {
        errors.password =
          'Password must be, atleast 8 Characters with Lowercase ,Uppercase & Numbers'
      }
      if (values.user.password !== values.user.confirmPassword) {
        errors.confirmPassword = 'Password does not match'
      }
      if (!values.user.email || !values.user.email.match(validRegex)) {
        errors.email = 'Please Enter a Valid Email'
      }

      setErrors(errors)
      return errors
    },
    onSubmit: onSubmitSignUp,
    signUpValidationSchema,
  })

  const { values, validateForm } = formikbagSignup
  const {
    values: signInValues,
    validateForm: validateSignInForm,
  } = formikbagSignin

  /** Set token on load */
  useEffect(() => {
    const _token = searchParams.get('token')
    if (_token) {
      setToken(_token)
      setAuthType('signUp')
    }
  }, [searchParams])

  return (
    <Container isMdUp={isMdUp}>
      <FormContainer isMdUp={isMdUp}>
        <Header>WELCOME {isMdUp && 'TO SEESY'}</Header>
        <sub className="text-gray-100 font-light">
          {authType === 'signIn'
            ? 'Login to your account to continue'
            : 'Create your account to hire talent'}
          {!isMdUp && 'Sign in'}
        </sub>
        <TabContainer>
          <TabHeader
            authType={authType}
            value="signIn"
            onClick={() => setAuthType('signIn')}
          >
            Sign In
          </TabHeader>
          <TabHeader
            authType={authType}
            value="signUp"
            onClick={() => setAuthType('signUp')}
          >
            Sign Up
          </TabHeader>
        </TabContainer>
        {authType === 'signIn' ? (
          <>
            {iserror && (
              <p className="bg-red-500/5 text-red-500 rounded-lg px-4 mb-4">
                {iserror}
              </p>
            )}{' '}
            <FormikProvider value={formikbagSignin}>
              <FormikTextField
                placeholder="Company Email"
                fullWidth
                Container={TextInputContainer}
                type="email"
                name="user.email"
              />
              {errors.email && validationInfo ? (
                <p style={{ color: 'red', fontSize: '16px' }}>{errors.email}</p>
              ) : null}
              <FormikTextField
                placeholder="Password"
                fullWidth
                Container={TextInputContainer}
                type="password"
                name="user.password"
              />
              {errors.password && validationInfo ? (
                <p style={{ color: 'red', fontSize: '16px' }}>
                  {errors.password}
                </p>
              ) : null}
              <Button style={{margin: '0'}} button onClick={onSubmitSignIn} fullWidth>
                Sign In
              </Button>
            </FormikProvider>
          </>
        ) : (
          <>
            {iserror && authType == 'signUp' ? (
              <p
                style={{
                  color: 'red',
                  fontSize: '18px',
                  fontWeight: '700',
                  marginBottom: '16px',
                }}
              >
                {iserror}
              </p>
            ) : null}
            <FormikProvider value={formikbagSignup}>
              {!token && (
                <FormikTextField
                  placeholder="Company Name"
                  type="text"
                  fullWidth
                  Container={TextInputContainer}
                  name="user.companyName"
                />
              )}
              {errors.companyName && validationInfo ? (
                <p style={{ color: 'red', fontSize: '16px' }}>
                  {errors.companyName}
                </p>
              ) : null}
              <FormikTextField
                placeholder="Email"
                type="email"
                fullWidth
                Container={TextInputContainer}
                name="user.email"
              />
              {errors.email && validationInfo ? (
                <p style={{ color: 'red', fontSize: '16px' }}>{errors.email}</p>
              ) : null}
              <FormikTextField
                placeholder="Password"
                type="password"
                fullWidth
                Container={TextInputContainer}
                name="user.password"
              />
              {errors.password && validationInfo ? (
                <p style={{ color: 'red', fontSize: '16px' }}>
                  {errors.password}
                </p>
              ) : null}
              <FormikTextField
                placeholder="Confirm Password"
                type="password"
                fullWidth
                Container={TextInputContainer}
                name="user.confirmPassword"
                validate={(value) =>
                  validateConfirmPassword(values.password, value)
                }
              />
              {errors.confirmPassword && validationInfo ? (
                <p style={{ color: 'red', fontSize: '16px' }}>
                  {errors.confirmPassword}
                </p>
              ) : null}
              <Policy>
                <p>
                  Choosing Sign Up means that you agree to the{' '}
                  <a href="https://seesy.co.uk/privacy-policy/" target="_blank" rel="noreferrer">
                    Seesy Services Agreement and privacy and cookies statement.
                  </a>
                </p>
              </Policy>

              <Button style={{margin: '10px 0'}} button onClick={onSubmitSignUp} fullWidth>
                Sign Up
              </Button>
              <Policy>
                <p>
                  <a href="https://seesy.co.uk/book-a-call/" target="_blank" rel="noreferrer">
                    Click here to talk to the client team
                  </a>
                </p>
              </Policy>
            </FormikProvider>
          </>
        )}
      </FormContainer>
      {authType === 'signIn' ? (
        <ForgotPassword onClick={resetPassword} isMdUp={isMdUp}>
          Forgot password?
        </ForgotPassword>
      ) : null}
    </Container>
  )
})

const Container = styled.div(
  ({ isMdUp }) => `display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  justify-content: center;
  padding-bottom: 80px;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 0px;
  padding-left : ${isMdUp ? '122px' : '16px'};
  padding-right :${isMdUp ? '122px' : '16px'};
  & button{
    margin-top: -11px;
    font-weight: 600;
    font-size: 18px;
  }
  & input{
    padding: 20px 25px;
  }

  `,
)

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 360px;
`
const Header = styled.h1`
  margin-top: 80px;
  margin-bottom: 15px;
  line-height: 26px;
  font-weight: 700;
  font-size: 26px;
  color: ${Colors.white};
`

const TabContainer = styled.div(
  ({ isMdUp }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top:${isMdUp ? '63px' : '49px'};
  margin-bottom:${isMdUp ? '77px' : '60px'};
`,
)

const TabHeader = styled.div(
  ({ authType, value }) => `
display: flex;
cursor: pointer;
border-bottom: 1px solid ${
    authType === value ? Colors.green : 'rgba(255, 255, 255, 0.03)'
  };
font-family: Archivo;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 26px;
padding-bottom: 18px;
width: 50%;
justify-content: center;
align-items: center;
color: ${authType === value ? Colors.white : 'rgba(255, 255, 255, 0.4)'} ;
`,
)

const TextInputContainer = styled.div`
  width: 100%;
`

const ForgotPassword = styled.div(
  ({ isMdUp }) => `
  display: flex;
  cursor: pointer;
  justify-content: ${isMdUp ? 'end' : 'center'};
  width: 360px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin-top: 20px;
  font-size: 16px;
  line-height: 26px;
  text-align: right;
  color: ${Colors.white};
  `,
)

const Policy = styled.div`
  & p {
    margin-top: 8px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    color: white;
  }
  & a {
    color: white;
    text-decoration: underline;
  }
`
