import { AuthGuard } from "components/AuthGuard";
import { Button } from "components/Button";
import { Colors } from 'styles/Colors'
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { LoadingComponent } from "components/LoadingComponent";
import { NewSectionHeading } from "components/Headings/NewSectionHeading";
import { useGetCompanyVacanciesQuery } from "store/userAPI";
import { useNavigate } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import { VideoPlaceholder } from "components/VideoPlaceholder";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";

export const Dashboard = React.memo(() => {
  const size = useResponsiveLayout();

  const { data, loading } = useGetCompanyVacanciesQuery();

  const navigate = useNavigate();

  /* Search */
  const searchItemsPerPage = 10
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  // const thumbnail = (vacancy) => {
  //   console.log(vacancy)
  //   const videoData = vacancy.videoURL
  //   if (!videoData) return

  //   return vacancy.thumbnail
  //     // Check for http. If not present this is a new format. Thumbnail is an ID and extension within the database
  //     ? vacancy.thumbnail.toString().toLowerCase().indexOf('http') === -1 ? `${BASE_THUMBNAIL_URL}/${formatFilePath(videoData, 'jpg', true).nameAndExtension}`
  //       // Legacy format - thumbnail is a full URL within the database
  //       : vacancy.thumbnail
  //     // Thumbnail not provided, use the video ID 
  //     : `${BASE_THUMBNAIL_URL}/${formatFilePath(videoData, 'jpg', true).nameAndExtension}`
  // }

  useEffect(() => {
    if (data && data.length > 0) {
      const endOffset = itemOffset + searchItemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / searchItemsPerPage));
    }
  }, [itemOffset, searchItemsPerPage, data]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * searchItemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  if (loading) return <LoadingComponent />;

  return (
    <AuthGuard redirect="/" criteria={[
      { value: 'EMPLOYER', check: 'userDetails.userType' },
    ]}>
      <Container size={size}>
        <NewSectionHeading
          companyName
          title="DASHBOARD"
          // search
          placeholder="Search"
        />
        {data?.length ? (
          <VacancyGrid>
            {
              currentItems?.map((v, i) =>
                <Vacancy onClick={() => v.videoURL ? navigate(`/employer/dashboard/vacancy/${v.id}`) : navigate(`/employer/company/vacancy/edit/${v.id}`)} key={i}>
                  <header>
                    {v.videoURL ?
                      <VideoCardJobBoard
                        borderRadius="0"
                        videoData={v.videoURL}
                        action={<></>}
                        width={'100%'}
                        height={'auto'}></VideoCardJobBoard> : <VideoPlaceholder borderRadius="0">No video recorded</VideoPlaceholder>
                    }
                    {/* <img alt="Vacancy thumbnail" src={thumbnail(v)}></img> */}
                    <JobTitle>
                      <h2>{v.title}</h2>
                      <span
                        className={
                          'flex px-4 py-2 text-xl rounded-lg items-center justify-self-start gap-2' +
                          (v.disabled ? " bg-red-900 text-red-300" : " bg-green-900 text-green-300") +
                          (!v.videoURL ? " bg-yellow-900 text-yellow-300" : "")
                        }
                      >
                        {!v.videoURL ? "INCOMPLETE" : v.disabled ? "CLOSED" : "OPEN"}
                      </span>
                    </JobTitle>
                  </header>
                  <section style={{ alignContent: 'start' }}>
                    <p><strong>Internal Id:</strong></p>
                    <p>{v.internalId || "None"}</p>
                    <p><strong>Industry:</strong></p>
                    <p>{v.industry}</p>
                    <p><strong>Salary:</strong></p>
                    <p>£{v.salary?.start} - £{v.salary?.end}</p>
                    <p><strong>Contract Type:</strong></p>
                    <p>{v.contractType}</p>
                    <p><strong>Updated:</strong></p>
                    <p>{new Date(v.updatedAt).toLocaleString()}</p>
                  </section>
                </Vacancy>
              )
            }
          </VacancyGrid>
        ) : (
          <CreateJob size={size}>
            <Button
              button
              leftIcon={faPlus}
              color="transparent"
              onClick={() => navigate("/employer/company/vacancy/create")}
            >
              Create your first job
            </Button>
          </CreateJob>
        )}
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName={
            "pagination large"
          } /* as this work same as bootstrap class */
          subContainerClassName={
            "pages pagination large"
          } /* as this work same as bootstrap class */
          activeClassName={"active"} /* as this work same as bootstrap class */

        />
      </Container></AuthGuard>
  );
});

const Container = styled.div(
  ({ size }) => `
  display: grid;
  gap: 32px;
  grid-template-rows: auto 1fr;
  max-width: var(--max-width);
  margin: 0 auto;
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-bottom: 50px;
`
);

const VacancyGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
`

const Vacancy = styled.article`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  // padding: 32px;

  border-radius: 6px;
  background-color: #232424;
  border: 1px solid transparent;

  color: white;
  cursor: pointer;
  transition: border-color 200ms ease-in-out;

  overflow: hidden;

  &:hover {
    border-color: ${Colors.velvet};
  }

  header {
    display: grid;
    gap: 24px;
    justify-self: center;
    width: 100%;
    // text-align: center;

    // img {
    //   --size: 80px;
    //   width: var(--size);
    //   height: var(--size);
    //   border-radius: var(--size);
    //   aspect-ratio: 1/1;
    //   object-fit: cover;
    //   margin: 0 auto;
    // }

    // h2 {
    //   padding: 0 32px;
    // }
  }
  
  section {
    display: grid;
    gap: 10px 20px;
    font-size: 14px;
    grid-template-columns: auto 1fr;
    padding: 0 32px 32px;
    > :nth-child(odd) {
      color: ${Colors.greyText};
    }
  }
`

const CreateJob = styled.div`
  display: grid;
  text-align: center;
  justify-self: center;
  margin-top: 100px;

  & button {
    font-size: 26px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0px;
    max-width: 385px;
  }
  & svg {
    color: #6bff00;
    margin-right: 25px !important;
  }
`

const JobTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0 32px;
  gap: 10px;
  align-items: center;
`;