import { faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";

import { Colors } from "styles/Colors";
import { Icon } from "components/Icon";

export const TagComponent = React.memo(
  ({ children, onClick, icon, className }) => (
    <TagContainer onClick={onClick} className={className}>
      {children}
      {onClick && icon && <Icon icon={faXmark} size={8} />}
    </TagContainer>
  )
);

const TagContainer = styled.p`
  background-color: ${Colors.green};
  color: ${Colors.ash};
  padding: 7px 15px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 18px;
  border-radius: 80px;
  width: max-content;
  display: inline-flex;
  flex-direction: horizontal;
  min-width: 88px;
  min-height: 33px;
  align-items: center;
  justify-content: space-between;
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
`;

// const CloseButton = styled(Icon)(
//   ({ color }) => `
//   margin-left: 10px;
//   position: absolute;
//   color: ${Colors.ash} !important;

//     right: 5px !important;
//     top: 40px !important;

// `
// );
