import { Button } from "components/Button";
import { useNavigate } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

export const EmailVerified = React.memo(() => {
  const { isMdUp } = useResponsiveLayout();

  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const userType = params.get("userType") || "CANDIDATE";

  return (
    <Container isMdUp={isMdUp}>
      <h2>Congratulations</h2>
      <p>Your email has successfully been verified. Continue to sign in.</p>

      <Button
        button
        fullWidth
        onClick={() => {
          return userType === "CANDIDATE"
            ? navigate("/candidate/account")
            : navigate("/employer/account");
        }}
      >
        Sign In
      </Button>
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${isMdUp ? "50px" : "30px"};
  padding-left : ${isMdUp ? "122px" : "16px"};
  padding-right :${isMdUp ? "122px" : "16px"};
  text-align:center;
  & h2 {
    font-size: 26px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 36.56px;
    margin-bottom: ${isMdUp ? "50px" : "120px"};
  }
  & p {
    font-size: 16px;
    color: white;
    font-weight: 400;
    line-height: 28.8px;
    font-family: "Roboto";
    width: ${isMdUp ? "40%" : "80%"};
  }
  & button {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 600;
  }
`
);
