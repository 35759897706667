import { Button } from "components/Button";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";
export const ManageProfileFooter = React.memo(
  ({ title, subtitle, bg, save, disabled, loading, cancel }) => {
    const size = useResponsiveLayout();
    return (
      <Container size={size} bg={bg}>
        <Wrapper size={size}>

          {title && (
            <Button button fullWidth={!size.isSmUp} type="alt" size="large" onClick={cancel}>
              {title}
            </Button>
          )}
          {subtitle && (
            <Button
              button
              fullWidth={!size.isSmUp}
              disabled={disabled || loading}
              loading={loading}
              size="large"
              onClick={save}
            >
              {subtitle}
            </Button>
          )}
        </Wrapper>
      </Container>
    );
  }
);

const Container = styled.div(
  ({ size, bg }) => `
background-color: ${bg ? bg : "#232424"};
display: ${size.isMdUp ? "flex" : "inline-block"};
z-index: 1000;
position: fixed;
bottom: 0;
width: 100%;
padding: 20px;
padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
text-align: center;
`
);

const Wrapper = styled.div(
  ({ size }) => `
display: flex;
flex-direction: ${size.isSmUp ? 'row' : 'column'};
width: 100%;
margin: 0 auto;
max-width: var(--max-width);
justify-content: flex-end;
text-align: center;
gap: 16px;
`,
)
