import { faXmark } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import styled from 'styled-components'

import { Colors } from 'styles/Colors'
import { Icon } from 'components/Icon'

/**
 * Replacement for TagComponent on the JobPost page.
 * Usually wrappd by flex, try css class `.flex-column-wrapper` for a standard "fit as many as you can on a row, then wrap"
 * Do not have scope to replace every instance of TagComponent, so will leave those in place and we should aim to replace over time for consistent placement.
 */
export const NewTagComponent = React.memo(
  ({ children, onClick, icon, className }) => (
    <TagContainer onClick={onClick} className={className}>
      <Label>{children}</Label>
      {onClick && <Icon icon={icon ?? faXmark} size={16} />}
    </TagContainer>
  ),
)

const TagContainer = styled.div`
  background-color: ${Colors.green};
  color: ${Colors.ash};
  padding: 7px 15px;
  cursor: pointer;
  border-radius: 80px;
  width: max-content;
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  align-items: center;
  height: fit-content;

  svg {
    color: ${Colors.ash};
  }
`

const Label = styled.span`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${Colors.ash};
`
