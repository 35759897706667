import * as Yup from "yup";
import { AddressLookup } from "components/form/AddressLookup";
import { AuthGuard } from "components/AuthGuard";
import { AvatarPicker } from "components/AvatarPicker";
import { checkImageFileSize } from "utils/functions";
import { Colors } from "styles/Colors";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faGlobe,
  faMultiply,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FormikProvider, useFormik } from "formik";
import { FormikTextField, RadioComponent } from "components/form";
import {
  getCompanyMembers,
  updateCompany,
  uploadMedia,
  useAuthDispatch,
  useAuthState,
} from "Context";
import { Icon } from "components/Icon";
import { LoadingComponent } from "components/LoadingComponent";
import { ManageInfo } from "components/ManageInfo/ManageInfo";
import { ManageProfileFooter } from "components/ProfileFooter/ManageProfileFooter";
import { MyCompanyVideos } from "components/MyCompanyVideos";
import { SectionHeading } from "components/Headings/SectionHeading";
import {
  useDeleteCompanyMembersMutation,
  useDeleteCompanyMutation,
  useGetCompanyQuery,
} from "store/userAPI";
import { useLogger } from "hooks/useLogger";
import { useNavigate } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const COMPANY_SIZES = [
  "1-10",
  "11-50",
  "51-200",
  "201-500",
  "501-1000",
  "1001-5000",
  "5001-10000",
  "10000+",
];


// Regex checks for socials. Not bullet proof, but best we can do with given requirements
// "Loose" regex checks against channel names
// Needs to NOT be a link, and not contain certain characterrs
const urlRegex = /^https?:\/\/.*\.{1}.?/i;

// "Strict" regex checks where the user has inputted a full URL
// Tries to checks the provided link is at least from the right domain etc
const socialRegex = {
  instagram: {
    // An Instagram username is limited to 30 characters and must contain only letters, numbers, periods, and underscores.
    loose: [
      /^[A-Za-z0-9_.]{1,30}$/
    ],
    strict: [
      /^http(s)?:\/\/(www\.)?instagram.com\/[A-Za-z0-9_.]{1,30}$/i,
    ],
  },
  facebook: {
    // Facebook usernames can only contain alphanumeric characters (A–Z, 0–9. -) and periods (".")
    loose: [
      /^[A-Za-z0-9-.]+$/
    ],
    strict: [
      /^http(s)?:\/\/(www\.)?fb.me\/[A-Za-z0-9-.]+$/i,
      /^http(s)?:\/\/(www\.)?facebook.com\/[A-Za-z0-9-.]+$/i
    ],
  },
  linkedIn: {
    // LinkedIn custom URL must contain 3-100 letters, numbers and dashes Please do not use spaces, symbols, or special characters
    loose: [
      /^[A-Za-z0-9-]{3,100}$/
    ],
    strict: [
      /^http(s)?:\/\/(www\.)?linkedin.com\/company\/[A-Za-z0-9-]{3,100}$/i
    ],
  },
  youtube: {
    // YouTube appears to have little to no restrictions. Wild.
    // Just check whatever is given doesn't start with @ since YouTube adds that itself.
    loose: [
      /^(?!@).+$/i
    ],
    strict: [
      /^http(s)?:\/\/(www\.)?youtube.com\/@.+$/i,
    ],
  },
  tiktok: {
    // TikTok usernames are limited to 24 characters. Usernames can only contain letters, numbers, periods, and underscores.
    loose: [
      /^[A-Za-z0-9_.]{1,24}$/
    ],
    strict: [
      /^http(s)?:\/\/(www\.)?tiktok.com\/@[A-Za-z0-9_.]{1,24}$/i,
    ],
  }
}

export const ManageProfile = React.memo(() => {
  const { logger } = useLogger()

  const { data: company, isLoading, error, refetch } = useGetCompanyQuery({});
  const [deleteMemberMutation] = useDeleteCompanyMembersMutation();
  const [deleteCompanyMutation] = useDeleteCompanyMutation();

  const dispatch = useAuthDispatch();

  const { companyMembers } = useAuthState() || {};

  useEffect(() => {
    if (!companyMembers?.length) {
      getCompanyMembers(dispatch);
    }
  }, [companyMembers, dispatch]);

  const navigate = useNavigate();

  const [tabvalue, setTabvalue] = useState("CompanyDetails");
  const [canDeleteCompany, setCanDeleteCompany] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState();
  const [addresses, setAddresses] = useState(company?.address || []);
  const [sizes, setSizes] = useState([]);
  const [logoUrl, setLogoUrl] = useState(company?.avatar);
  const [loading, setLoading] = useState(false);
  const [subtitle, setSubtitle] = useState("Save Changes");
  const [benefits, setBenefits] = useState(company?.benefits || []);
  const [hasChangedLogo, setHasChangedLogo] = useState(false);
  const [websiteError, setWebsiteError] = useState();

  const size = useResponsiveLayout();

  const handleRemove = async (id) => {
    try {
      let response = await deleteMemberMutation({ membershipId: id });
      if (response?.error) return alert(response?.error?.data?.message);
      if (!response.data) return;
      if (response.error) return alert(response.message);
      getCompanyMembers(dispatch);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCompany = async () => {
    try {
      let response = await deleteCompanyMutation({ id: company?.id });
      if (response?.error) return alert(response?.error?.data?.message);
      if (!response.data) return;
      if (response.error) return alert(response.message);
      localStorage.clear();
      navigate("/job-board");
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDeleteCheckbox = () => {
    setCanDeleteCompany(!canDeleteCompany);
  };

  /**
   * @param {keyof typeof socialRegex} checks - regex check group (youtube/facebook/instagram)
   * @param {string} value - value to check
   * @returns {boolean} - pass/fail
   */
  const multiRegexCheck = (checks, value) => {
    if (!value) return true
    // If the value provided contains "http" we're going to check against `checks.strict` array
    // If it doesn't, we're going to check against `checks.loose` array
    const checkGroup = value.toLowerCase().indexOf('http') > -1 ? 'strict' : 'loose'
    let isValid = false;
    checks[checkGroup].forEach(check => {
      let res = check.test(value.toLowerCase())
      if (res) isValid = true;
    });
    return isValid
  }

  const validationSchema = Yup.object().shape({
    company: Yup.object().shape({
      description: Yup.string().required("Enter a description"),
      size: Yup.array().required("Select company size"),
      instagram: Yup.string().test('instagram', 'Enter a valid Instagram page.', (value) => multiRegexCheck(socialRegex.instagram, value)),
      facebook: Yup.string().test('facebook', 'Enter a valid Facebook page.', (value) => multiRegexCheck(socialRegex.facebook, value)),
      linkedIn: Yup.string().test('linkedin', 'Enter a valid LinkedIn company. Format is either: my-company-name or https://www.linkedin.com/company/my-company-name', (value) => multiRegexCheck(socialRegex.linkedIn, value)),
      youtube: Yup.string().test('youtube', 'Enter a valid YouTube channel. Format is either: mycompanyhandle or https://www.youtube.com/@mycompanyhandle', (value) => multiRegexCheck(socialRegex.youtube, value)),
      tiktok: Yup.string().test('tiktok', 'Enter a valid TikTok channel.', (value) => multiRegexCheck(socialRegex.tiktok, value)),
      website: Yup.string().matches(urlRegex, "Enter a valid url"),
    }),
  });

  const formikbag = useFormik({
    initialValues: {
      company: {
        description: company?.description || "",
        size: company?.size || [],
        instagram: company?.instagram || "",
        facebook: company?.facebook || "",
        linkedIn: company?.linkedIn || "",
        youtube: company?.youtube || "",
        tiktok: company?.tiktok || "",
        benefit: company?.benefit || "",
        primaryAddress: company?.primaryAddress || "",
        address_other: "",
        avatar: company?.avatar || "",
        website: company?.website || "",
      },
    },
    validationSchema,
    enableReinitialize: true,
  });

  const { values, validateForm, setFieldValue, isValid, errors } = formikbag;

  useEffect(() => {
    if (company) {
      setAddresses([...company?.address])
      setBenefits([...company?.benefits]);
      setSizes([...company?.size]);
    }

    // Yay for arbitrary timeouts
    // I've no time to troubleshoot this now..
    setTimeout(() => {
      validateForm()
    }, 500);
  }, [company, validateForm]);

  const addAddress = (value, index) => {
    if (!isNaN(index)) {
      addresses[index] = value;
      setAddresses(addresses);
    } else if (!!value) {
      setAddresses([...addresses, value]);
    }
  };

  const deleteAddress = (index) => {
    setAddresses(addresses.filter((_, i) => i !== index));
  };

  const hiddenFileInput = React.useRef(null);

  const onClickAvatar = () => {
    hiddenFileInput.current.click();
  };

  const onLogoChange = async (event) => {
    setHasChangedLogo(true)
    const file = event.target.files[0];
    // Check the image minimum size an aspect ratio
    const imageErrors = await checkImageFileSize(file, 100000000);
    if (imageErrors.length === 0) {
      setLogoUrl(URL.createObjectURL(file));
      setSelectedLogo(file);
    } else {
      const res = `Invalid image selected:\n${imageErrors.join('\n')}`
      alert(res)
      logger.error(res, { type: "ImageUpload" })
    }
  };

  const uploadLogo = async () => {

    let data = new FormData();
    data.append("file", selectedLogo);
    try {
      let response = await uploadMedia(data);
      if (!response) return alert("An error has occured.");
      if (response.error) return alert(response?.message);
      const avatarUrl = response?.url;
      console.log("Avatar url", avatarUrl)
      setFieldValue("company.avatar", avatarUrl);
      values.company.avatar = avatarUrl
    } catch (error) {
      console.log(error);
    }
  };

  const addTeamMembers = () => {
    navigate("/employer/company/addteammembersuccess");
  };

  const onSubmit = async () => {
    validateForm();
    // setLoading(true);

    // Check the website first
    const isWebsiteOk = await checkWebsiteSafeList(values.company.website)
    if (!isWebsiteOk) return

    if (hasChangedLogo) await uploadLogo()
    try {
      delete values.company.benefit;
      values.company.benefits = benefits;

      console.log({
        ...values?.company,
        address: addresses,
        size: sizes,
      })

      let response = await updateCompany(dispatch, {
        ...values.company,
        address: addresses,
        size: sizes,
      });

      if (!response) return;
      if (response.error) return alert(response.message);
      setSubtitle("Saved!");
      setTimeout(() => {
        setSubtitle("Save Changes");
      }, 3000);
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  /**
   * Calls Google's WebRisk API to check the website the user provided hasn't been flagged as unsafe
   * @param {string} url - url to check
   * @returns {boolean} true/false for presence on threat list
   */
  const checkWebsiteSafeList = async (url) => {
    // Hit Google's safety check 
    const res = await fetch(`https://webrisk.googleapis.com/v1/uris:search?threatTypes=MALWARE&uri=${url}&key=${process.env.REACT_APP_GOOGLE_SAFE_BROWSING_API_KEY}`, {
      method: 'GET',
    });

    // Get the response
    const data = await res.json()

    // Handle failure
    if (!res.ok) {
      const message = "There was an issue checking your website for safety, please try a different address."
      setWebsiteError(message)
      logger.error(message, { error: data })
      alert(message)
      return false
    }

    // Check for presence on unsafe list
    if (data.threat) {
      const message = `That website has been reported as unsafe, please try a different address.`
      setWebsiteError(message)
      logger.error(message, { error: data, url })
      alert(message)
      return false
    }
    return true
  }

  if (error) return alert(error?.error);

  if (isLoading && !error && !!!companyMembers?.length)
    return <LoadingComponent />;

  return (
    <AuthGuard redirect="/candidate/manage-profile" criteria={[
      { value: 'EMPLOYER', check: 'userDetails.userType' },
    ]}>
      <Container size={size}>
        <SectionHeading companyName title="MANAGE MY ACCOUNT" />
        <hr />
        <ProfileWrapper size={size}>
          <TabHeadercontainer>
            <TabHeader
              tabvalue={tabvalue}
              value="CompanyDetails"
              onClick={() => setTabvalue("CompanyDetails")}
            >
              Company Details
            </TabHeader>
            <TabHeader
              tabvalue={tabvalue}
              value="videos"
              onClick={() => setTabvalue("videos")}
            >
              Videos
            </TabHeader>
          </TabHeadercontainer>
          {tabvalue === "CompanyDetails" ? (
            <FormikProvider value={formikbag}>
              <section className="max-w-screen-md grid gap-[16px]">
                <h2 className="text-white">About the company</h2>
                <FormikTextField
                  placeholder="Tell us about your company"
                  fullWidth
                  Container={TextAreaContainer}
                  type="textArea"
                  value={values?.company?.description}
                  name="company.description"
                />
              </section>

              <hr />
              <section className="max-w-screen-md grid gap-[16px]">
                <h2 className="text-white flex items-center">Your logo <span className="text-xl text-gray-300 font-thin ml-4">(Click to change)</span></h2>
                <p className="font-light"><strong>Tip: </strong>The recommended image size is at least 256px * 256px and should have a 1:1 (square) aspect ratio.</p>
                <AvatarPicker onClick={onClickAvatar} imageUrl={logoUrl || company?.avatar}>
                  <figure />
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    onChange={onLogoChange}
                  />
                </AvatarPicker>
              </section>

              <hr />

              <section className="max-w-screen-md">
                <AddressLookup
                  id="addressLookup"
                  label="Primary Location"
                  onResult={(result) => {
                    setFieldValue('company.primaryAddress', result)
                  }}
                  value={values.company.primaryAddress} />
              </section>
              <hr />
              {(values?.company?.primaryAddress || addresses.length > 0) && (
                <>
                  <section className="max-w-screen-md grid gap-[16px]">
                    <h2 className="text-white">Other locations</h2>
                    <InputButtonGroup size={size}>
                      <FormikTextField
                        placeholder="New location"
                        fullWidth
                        name="company.address_other"
                        value={values?.company?.address_other}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            addAddress(values?.company?.address_other);
                            setFieldValue("company.address_other", "");
                          }
                        }}
                        spacer={false}
                      />
                      <button
                        className="bg-secondary text-white py-6 px-8 rounded-lg hover:bg-secondary/80 h-full"
                        disabled={!values?.company?.address_other}
                        onClick={() => {
                          addAddress(values?.company?.address_other);
                          setFieldValue("company.address_other", "");
                        }}
                      >
                        Add Location
                      </button>
                    </InputButtonGroup>
                    {addresses.length > 0 && <>
                      {addresses.map((address, index) => (
                        <ListItem key={index}>
                          <div className="flex flex-row gap-4">
                            <span>{index + 1}.</span>
                            <span>{address}</span>
                          </div>
                          <button type="text"
                            onClick={() => {
                              deleteAddress(index)
                            }}>
                            <Icon
                              size={size}
                              icon={faTrashCan}
                            />
                          </button>
                        </ListItem>
                      ))}
                    </>
                    }
                  </section>
                  <hr />
                </>
              )}
              <section className="max-w-screen-md grid gap-[16px]">
                <h2 className="text-white">Company size</h2>
                <Size size={size}>
                  <RadiosContainer size={size}>
                    {COMPANY_SIZES.map((size, index) => (
                      <RadioComponent
                        key={index}
                        size={size}
                        item={size}
                        onClick={() => {
                          if (sizes.indexOf(size) === -1) {
                            sizes.push(size);
                            setSizes([...sizes]);
                            console.log("sizes", sizes);
                          } else {
                            sizes.splice(sizes.indexOf(size), 1);
                            setSizes([...sizes]);
                            console.log("sizes", sizes);
                          }
                        }}
                        selected={sizes.indexOf(size) > -1}
                      />
                    ))}
                  </RadiosContainer>
                </Size>
              </section>
              <hr />
              <section className="max-w-screen-md grid gap-[16px]">
                <h2 className="text-white">Benfits of working with us</h2>
                <p className="text-[16px] text-gray-300 font-thin mb-4">
                  <strong>Tip:</strong> We will automatically add these on Vacancies that you create.
                </p>
                <InputButtonGroup size={size}>
                  <FormikTextField
                    placeholder="Type your benefit"
                    fullWidth
                    name="company.benefit"
                    value={values?.company?.benefit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && values?.company?.benefit?.length > 0) {
                        console.log(e.currentTarget.value);
                        setFieldValue("company.benefit", "");
                        benefits.push(e.currentTarget.value);
                      }
                    }}
                    spacer={false}
                  />
                  <button
                    className="bg-secondary text-white py-6 px-8 rounded-lg hover:bg-secondary/80 h-full"
                    disabled={!values?.company?.benefit}
                    onClick={() => {
                      benefits.push(values?.company?.benefit);
                      setFieldValue("company.benefit", "");
                    }}
                  >
                    Add Benefit
                  </button>
                </InputButtonGroup>

                {benefits &&
                  benefits.map((benefit, index) => {
                    return (
                      <ListItem key={index}>
                        <div className="flex flex-row gap-4">
                          <span>{index + 1}.</span>
                          <span>{benefit}</span>
                        </div>
                        <button type="text"
                          onClick={() => {
                            setBenefits(
                              benefits.filter((item, i) => i !== index)
                            );
                          }}>
                          <Icon
                            size={size}
                            icon={faTrashCan}
                          />
                        </button>
                      </ListItem>
                    );
                  })}

              </section>
              <hr />
              <ManageInfo title="Team members" edit={addTeamMembers}>
                <ImageBody size={size}>
                  {!!companyMembers?.length &&
                    companyMembers?.map((member) => (
                      <div key={member?.id}>
                        <Image size={size}>
                          <img
                            src={"/dummy-profile-picture.png"}
                            alt="user profile"
                          />
                          <Icon
                            icon={faMultiply}
                            onClick={() => handleRemove(member?.id)}
                          />
                        </Image>
                        <p>{member?.user?.name || ""}</p>
                      </div>
                    ))}
                </ImageBody>
              </ManageInfo>
              <hr />

              <section className="max-w-screen-md grid gap-[16px]">
                <h2 className="text-white">Social accounts</h2>
                <p className="text-gray-400">
                  Share more with your applicants through social media
                </p>

                <Socials>
                  <div className="grid grid-cols-[auto_1fr] gap-6 items-start">
                    <Icon
                      icon={faInstagram}
                      className="bg-white text-black rounded-full w-12 h-12 p-5"
                      style={{
                        color: "#1C1D1D",
                      }}
                    />
                    <div className="grid gap-2">
                      <FormikTextField
                        placeholder="Instagram page"
                        fullWidth
                        name="company.instagram"
                        value={values?.company?.instagram}
                        spacer={false}
                        onBlur={() => {
                          setFieldValue("company.instagram", values?.company?.instagram);
                        }}
                      />
                      {errors?.company?.instagram && <span className="text-red-500 text-2xl">{errors.company.instagram}</span>}
                    </div>
                  </div>
                  <div className="grid grid-cols-[auto_1fr] gap-6 items-start">
                    <Icon
                      icon={faFacebookF}
                      className="bg-white text-black rounded-full w-12 h-12 p-5"
                      style={{
                        color: "#1C1D1D",
                      }}
                    />
                    <div className="grid gap-2">
                      <FormikTextField
                        placeholder="Facebook page"
                        fullWidth
                        name="company.facebook"
                        value={values?.company?.facebook}
                        spacer={false}
                        onBlur={() => {
                          setFieldValue("company.facebook", values?.company?.facebook);
                        }}
                      />
                      {errors?.company?.facebook && <span className="text-red-500 text-2xl">{errors.company.facebook}</span>}
                    </div>
                  </div>
                  <div className="grid grid-cols-[auto_1fr] gap-6 items-start">
                    <Icon
                      icon={faLinkedin}
                      className="bg-white text-black rounded-full w-12 h-12 p-5"
                      style={{
                        color: "#1C1D1D",
                      }}
                    />
                    <div className="grid gap-2">
                      <FormikTextField
                        placeholder="LinkedIn company name"
                        fullWidth
                        name="company.linkedIn"
                        value={values?.company?.linkedIn}
                        spacer={false}
                        onBlur={() => {
                          setFieldValue("company.linkedIn", values?.company?.linkedIn);
                        }}
                      />
                      {errors?.company?.linkedIn && <span className="text-red-500 text-2xl">{errors.company.linkedIn}</span>}
                    </div>
                  </div>
                  <div className="grid grid-cols-[auto_1fr] gap-6 items-start">
                    <Icon
                      icon={faYoutube}
                      className="bg-white text-black rounded-full w-12 h-12 p-5"
                      style={{
                        color: "#1C1D1D",
                      }}
                    />
                    <div className="grid gap-2">
                      <FormikTextField
                        placeholder="Channel name"
                        fullWidth
                        name="company.youtube"
                        value={values?.company?.youtube}
                        spacer={false}
                        onBlur={() => {
                          setFieldValue("company.youtube", values?.company?.youtube);
                        }}
                      />
                      {errors?.company?.youtube && <span className="text-red-500 text-2xl">{errors.company.youtube}</span>}
                    </div>
                  </div>
                  <div className="grid grid-cols-[auto_1fr] gap-6 items-start">
                    <Icon
                      icon={faTiktok}
                      className="bg-white text-black rounded-full w-12 h-12 p-5"
                      style={{
                        color: "#1C1D1D",
                      }}
                    />
                    <div className="grid gap-2">
                      <FormikTextField
                        placeholder="TikTok name"
                        fullWidth
                        name="company.tiktok"
                        value={values?.company?.tiktok}
                        spacer={false}
                        onBlur={() => {
                          setFieldValue("company.tiktok", values?.company?.tiktok);
                        }}
                      />
                      {errors?.company?.tiktok && <span className="text-red-500 text-2xl">{errors.company.tiktok}</span>}
                    </div>
                  </div>
                  <div className="grid grid-cols-[auto_1fr] gap-6 items-start">
                    <Icon
                      icon={faGlobe}
                      className="bg-white text-black rounded-full w-12 h-12 p-5"
                      style={{
                        color: "#1C1D1D",
                      }}
                    />
                    <div className="grid gap-2">
                      <FormikTextField
                        placeholder="https://website.com"
                        fullWidth
                        name="company.website"
                        value={values?.company?.website}
                        spacer={false}
                        onBlur={() => {
                          setFieldValue("company.website", values?.company?.website);
                        }}
                        onChange={() => setWebsiteError()}
                      />
                      {errors?.company?.website && <span className="text-red-500 text-2xl">{errors.company.website}</span>}
                      {websiteError && <span className="text-red-500 text-2xl break-all">{websiteError}</span>}
                    </div>
                  </div>
                </Socials>
              </section>
              <hr />

              <section className="max-w-screen-md grid gap-[16px]">
                <h2 className="text-white">Delete my account</h2>

                <p className="text-gray-400">Permanently delete your account</p>
                <div className="grid gap-[16px] text-white text-[16px] font-light" style={{ gridTemplateColumns: 'auto 1fr' }}>
                  <input
                    type="checkbox"
                    id="deleteaccount"
                    name="user"
                    value={canDeleteCompany}
                    onClick={toggleDeleteCheckbox}
                  />
                  <label htmlFor="deleteaccount">
                    I understand that by deleting my account all data,
                    including application data will be permanently erased and
                    cannot be recovered.
                  </label>
                </div>

                <button
                  className="border-2 border-secondary text-white py-6 px-16 rounded-lg hover:bg-secondary/80 h-full w-fit"
                  disabled={!canDeleteCompany}
                  onClick={deleteCompany}
                >
                  Delete my account
                </button>

              </section>
            </FormikProvider>
          ) : (
            <MyCompanyVideos videoId={company?.video} />
          )}
        </ProfileWrapper>
      </Container>

      <ManageProfileFooter
        title="Cancel"
        subtitle={subtitle}
        save={onSubmit}
        cancel={() => navigate("/job-board")}
        loading={loading}
        disabled={loading || !isValid}
      />
    </AuthGuard >
  );
});

const Container = styled.main(
  ({ size }) => `
 max-width: var(--max-width);
 margin: 0 auto;
 padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
 padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
 padding-bottom: 180px;

 & p{
   color: white;
   line-height: 25.6px;
   font-size: 16px;

 }
 & hr{
  border-color: #8d8e8e;
  margin-top: 30px;
  margin-bottom: 20px;
 }
`
);


const TabHeadercontainer = styled.div`
 display: flex;
 margin-top: 20px;
 margin-bottom: 40px;
 position: relative;
`;
const TabHeader = styled.h2(
  ({ tabvalue, value }) => `
 font-family: Archivo;
 font-style: normal;
 cursor: pointer;
 font-weight: bold;
 font-size: 18px;
 line-height: 26px;
 padding-bottom: 18px;
 margin-right: 35px;
 color: ${tabvalue === value ? Colors.white : "rgba(255, 255, 255, 0.4)"} ;
 `
);

const RadiosContainer = styled.div(
  ({ size }) => `
 display: flex;
 width: 100%;
 flex-direction: ${size.isMdUp ? "row" : "row"};
 align-items:${size.isMdUp ? "center" : "flex-start"};
 flex-wrap: wrap;
  & div{
   margin-right: 10px;
   align-items: center;

   & input {
    margin: 0px;
   }
  }
`
);

const Size = styled.div(
  ({ size }) => `
& form {

 display: ${size.isMdUp ? "flex" : "block"};
}

`
);

const ImageBody = styled.div(
  ({ size }) => `
 display: ${size.isMdUp ? "flex" : "block"};
`
);
const Image = styled.div(
  ({ size }) => `
 position: relative;
 margin-top: ${size.isMdUp ? "0px" : "20px"};
 & img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 30px;
 }
 & svg {
  color: white !important;
  right: 21px;
  top: 38px;
  left: ${size.isMdUp ? "" : "87px"};
  background-color: red;
  border-radius: 50%;
  height: 22px;
  width: 22px;
 }
`
);

const TextAreaContainer = styled.div`
 height: 120px;
`;

const ListItem = styled.div`
 display: grid;
 grid-template-columns: 1fr auto;
 align-items: center;
 justify-content: space-between;
 padding: 17px 30px;
 color: ${Colors.white};
 background-color: ${Colors.darkBorder};
 border-radius: 5px;
`;

const ProfileWrapper = styled.div(
  ({ size }) => `
 margin-left: ${size.isLgUp ? "100px" : "0px"};
`
);

const Socials = styled.div`
  display: grid;
  gap: 16px 32px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  > div {
    display: grid;
    grid-template-columns: 55px 1fr;
    gap: 16px;
    align-items: center;
}
`

const InputButtonGroup = styled.div(({ size }) => `
  display: grid;
  gap: 16px;
  align-items: center;
  grid-template-columns: ${size.isSmUp ? '1fr auto' : '1fr'};
`)