import { DropDown } from "components/DropDown/DropDown";
import { option, option1, option2, tags } from "./data";
import { TagComponent } from "components/TagComponent";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

export const StepTwo = React.memo(({ children, state, setArrayValue }) => {
  const { isMdUp } = useResponsiveLayout();

  const setImportantThings = (value, index) => {
    setArrayValue("importantThings", value, index);
  };

  return (
    <Container isMdUp={isMdUp}>
      <Wrapper isMdUp={isMdUp}>
        {children}
        <DropDown
          placeholder="choose something you care about +"
          bg
          icon
          active={state.importantThings[0]}
          options={option}
          handleChange={(value) => setImportantThings(value, 0)}
        />
      </Wrapper>
      <Wrapper1 isMdUp={isMdUp}>
        <DropDown
          bg
          icon
          placeholder="choose something you care about +"
          active={state.importantThings[1]}
          options={option1}
          handleChange={(value) => setImportantThings(value, 1)}
        />

        <h5>AND</h5>
        <DropDown
          bg
          icon
          placeholder="choose something you care about +"
          active={state.importantThings[2]}
          options={option2}
          handleChange={(value) => setImportantThings(value, 2)}
        />
      </Wrapper1>

      <Tag>
        {state.importantThings[1] === "Other +" ? (
          <TagWrapper>
            {tags.map((i) => (
              <TagComponent onClick={() => setImportantThings(i, 1)}>
                {i}
              </TagComponent>
            ))}
          </TagWrapper>
        ) : (
          <>
            {state.importantThings[2] === "Other +" ? (
              <TagWrapper>
                {tags.map((i) => (
                  <TagComponent onClick={() => setImportantThings(i, 2)}>
                    {i}
                  </TagComponent>
                ))}
              </TagWrapper>
            ) : null}
          </>
        )}
      </Tag>
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  position: relative;
  & button {
    background: transparent !important;
    font-size: ${isMdUp ? "26px" : "16px"};
    font-weight: 700;
    padding: 0px !important;
    padding-left: 10px;
    border-radius: 0px !important;
    padding-left: 0px;
  }
  & h5 {
    font-size: 26px;
    font-weight: 700;
    color: white;
    margin: 0px 20px;
    margin-left: ${isMdUp ? "20px" : "0px"};
  }
`
);

const Wrapper = styled.div(
  ({ isMdUp }) => `
  display: ${isMdUp ? "flex" : "block"};
`
);

const Wrapper1 = styled.div(
  ({ isMdUp }) => `
  display: ${isMdUp ? "flex" : "block"};
  align-items: center;
  width: 100%;
  margin-top: 20px;
`
);

const Tag = styled.div`
  width: 90%;
`;

const TagWrapper = styled.div`
  margin-top: 358px;
`;
