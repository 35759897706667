import * as Yup from 'yup'
import { Button } from 'components/Button'
import { Colors } from 'styles/Colors'
import { DropdownInput } from 'components/form'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FormikProvider } from 'formik'
import { FormikTextField } from 'components/form/FormikTextField'
import { headerHeight } from 'styles/Layout'
import { Icon } from 'components/Icon'
import { INDUSTRIES } from '../CreateRole/data'
import { updateCompany, updateTeamMember, useAuthDispatch } from 'Context'
import { useAuthState } from 'Context'
import { useFormik } from 'formik'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export const SignupStep2= () => {
  const navigate = useNavigate()

  const size = useResponsiveLayout()

  const dispatch = useAuthDispatch()

  const { userDetails } = useAuthState()

  const [searchParams, setSearchParams] = useSearchParams()
  const [token, setToken] = useState()

  const validationSchema = Yup.object().shape({
    user: Yup.object().shape(
      token
        ? {
            name: Yup.string().required('Name is required'),
          }
        : {
            name: Yup.string().required('Name is required'),
            department: Yup.string().required('Department is required'),
            industry: Yup.string().required('Industry is required'),
          },
    ),
  })

  const goToAddTeamMemberPage = () => {
    validateForm()
    if (!isValid) return alert('Please complete form first')
    onSubmit(null, true)
    navigate('/employer/company/addteammember')
  }

  const onSubmit = async (_, addTeamMember) => {
    validateForm()

    if (!isValid) return alert('Please complete form')
    const payload = token
      ? {
          name: values.user.name,
        }
      : {
          name: values.user.name,
          department: values.user.department,
          role: 'ADMIN',
        }

    console.log('payload', payload)

    // Only try to update company if this isn't an invited user
    if (!token) {
      try {
        const companyPayload = {
          industry: values.user.industry,
        }
        console.log('companyPayload', companyPayload)
        let response = await updateCompany(dispatch, companyPayload)
        if (!response) return
        if (response.error) return alert(response.message)
      } catch (error) {
        console.log(error)
      }
    }

    try {
      let response = await updateTeamMember(dispatch, userDetails.id, payload)

      if (!response) return
      if (response.error) {
        return alert(response.message)
      } else if (!addTeamMember) {
        if (token) {
          navigate('/employer/dashboard/')
        } else {
          navigate('/employer/company/')
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const formikbag = useFormik({
    initialValues: {
      user: token
        ? {
            name: '',
          }
        : {
            name: '',
            department: '',
            industry: '',
          },
    },
    onSubmit,
    validationSchema,
  })

  const { values, setFieldValue, validateForm, isValid } = formikbag

  useEffect(() => {
    const _token = searchParams.get('token')
    if (_token) {
      setToken(_token)
    }
  }, [searchParams])

  return (
    <Container size={size}>
      <FormContainer size={size}>
        <Header>WELCOME {size.isMdUp && 'TO SEESY'}</Header>
        <div className="grid gap-6">
          <FormikProvider value={formikbag}>
            <FormikTextField
              placeholder="Name "
              fullWidth
              Container={TextInputContainer}
              type="text"
              name="user.name"
            />
            {!token && (
              <>
                <FormikTextField
                  placeholder="Department"
                  fullWidth
                  Container={TextInputContainer}
                  type="text"
                  name="user.department"
                />

                <DropdownInput
                  useFirstOption={true}
                  name="user.industry"
                  fullWidth={size.isMdUp ? false : true}
                  options={INDUSTRIES}
                  label={'Select Industry'}
                  maxListHeight={'145px'}
                  onClick={(selectedValue) =>
                    setFieldValue('user.industry', selectedValue)
                  }
                />

                <AddTeamMember onClick={goToAddTeamMemberPage}>
                  <Icon
                    icon={faPlus}
                    style={{ marginRight: 20 }}
                    color={Colors.green}
                  />
                  Add a team member
                </AddTeamMember>
              </>
            )}

            <Button button onClick={onSubmit} fullWidth disabled={!isValid}>
              Save & Continue
            </Button>
          </FormikProvider>
        </div>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div(
  ({ size }) => `
  display: grid;
  place-items: center;
  height: calc(100vh - ${headerHeight}px);
  height: calc(100svh - ${headerHeight}px);
  overflow-y: auto;
  padding: 20px;
  border-radius: 5px;
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};

  `,
)

const FormContainer = styled.div`
  display: grid;
  gap: 32px;
  width: 360px;
`
const Header = styled.h1(
  () => `
  line-height: 26px;
  font-weight: 700;
  font-size: 26px;
  color: ${Colors.white};
  text-align: center;
`,
)

const TextInputContainer = styled.div`
  width: 100%;
`

const AddTeamMember = styled.div(
  () => `
    cursor: pointer;
    color: ${Colors.white};
    font-size: 16px;
    font-weight: 400;
    padding: 12px;
    text-align: center;
`,
)
