import { Colors } from "styles/Colors";
import { Link } from "react-router-dom";
import { signal } from "@preact/signals-react";
import { useSignals } from "@preact/signals-react/runtime";
import React from "react";
import styled from "styled-components";

export const menuOpen = signal(false)
const toggle = () => menuOpen.value = !menuOpen.value;
export const closeMenu = () => menuOpen.value = false;

 
export const DropdownMenu = ({ trigger, items, variant, path, className }) => {
    useSignals()

    return (
      <Container className={className}>
        <Trigger>{trigger({ toggle, isOpen: menuOpen.value })}</Trigger>
        {menuOpen.value && (
          <Menu
            
            variant={variant}
            className="mt-4 rounded-lg"
          >
            {items.map((item) => {
              if (!item) return null;

              if (item.action) {
                const onClick = (e) => {
                  e.preventDefault();
                  item.action();
                };
                return (
                  <MenuClickable
                    className="hover:bg-white hover:text-black"
                    key={item.text}
                    href="#"
                    onClick={onClick}
                  >
                    {item.text}
                  </MenuClickable>
                );
              } else if (item.to) {
                return (
                  <MenuLink
                    className="hover:bg-white hover:text-black"
                    variant={variant}
                    key={item.text}
                    to={item.to}
                    active={
                      path && path.pathname && path.pathname === item.to
                        ? "true"
                        : "false"
                    }
                    onClick={(e) => closeMenu()}
                  >
                    {item.text}
                  </MenuLink>
                );
              } else if (item.href) {
                return (
                  <MenuLink
                    className="hover:bg-white hover:text-black"
                    key={item.text}
                    href={item.href}
                    as="a"
                    target={item.target}
                  >
                    {item.text}
                  </MenuLink>
                );
              } else {
                return null;
              }
            })}
          </Menu>
        )}
      </Container>
    );
  }
;

const Container = styled.div`
  position: relative;
`;
const Trigger = styled.div`
  cursor: pointer;
`;

const variantFilled = `
  background-color: #9400FF;
  border-radius: 4px;
  position: absolute;
  bottom: 8px;
  right: 0px;
`;
const Menu = styled.div(
  ({ variant }) => `
  padding: 20px 6px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background-color: ${Colors.ash};
  border: 1px solid ${Colors.velvet};
  border-radius: 4px;
  position: absolute;
  bottom: 0px;
  right: 0;
  transform: translateY(100%);
  z-index: 100;

  a {
    border-bottom: 1px solid ${Colors.velvet};
  }
  a:last-child {
    border-bottom: 0;
  }
  ${variant === "filled" ? variantFilled : ""}
`
);

const MenuClickable = styled.a`
  display: block;
  text-align: center;
  padding: 10px 30px;
  border-radius: 10px;

  white-space: nowrap;
  color: ${Colors.white};
`;

const variantFilledLink = `
color: black;
border-color: transparet !important;
background: transparent;
border-radius: 10px;
margin: 7px;
color: ${Colors.white}
`;

const activeState = `
border-color: #5e4d99 !important;
background: #5E4D99;
`;
const MenuLink = styled(Link)`
  display: block;
  text-align: center;
  padding: 10px;
  padding: 10px 40px;
  white-space: nowrap;
  color: ${Colors.white};
  ${(props) => (props.variant === "filled" ? variantFilledLink : "")};
  ${(props) => (props.active === true ? activeState : "")}
`;
