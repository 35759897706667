import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";

import { Colors } from "styles/Colors";
import { ErrorMessage } from "./ErrorMessage";
import { Icon } from "components/Icon";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";

export const TextField = React.memo(
  React.forwardRef(
    (
      {
        label,
        Container = null,
        error,
        fullWidth = false,
        fieldType = "standard",
        max = "00",
        type = "standard",
        textArea = true,
        search = false,
        symbol,
        spacer = true,
        ...rest
      },
      ref
    ) => {
      const ContainerComponent = Container || DefaultContainer;
      const { isMdUp } = useResponsiveLayout();

      return (
        <ContainerComponent>
          {label && <Label>{label}</Label>}
          {type !== "search" && type !== "textArea" && (
            <TextInputContainer className={symbol ? "pr-4 " : ""}>
              <StyledInput
                className={
                  symbol
                    ? ""
                    : "border focus:shadow-2xl transition-all duration-100 focus:border-secondary/50 focus:border  drop-shadow-lg  focus:shadow-secondary/25"
                }
                fullWidth={fullWidth}
                type={type}
                ref={ref}
                disabled={rest.disabled}
                fieldType={fieldType}
                {...rest}
              />
              {type === "counter" && (
                <>
                  {rest.value?.length}/{max}
                </>
              )}
              {symbol && <>{symbol}</>}
            </TextInputContainer>
          )}
          {type === "textArea" && (
            <StyledTextArea
              ref={ref}
              fieldType={fieldType}
              {...rest}
              className="border focus:shadow-2xl transition-all duration-100 focus:border-secondary/50 focus:border drop-shadow-lg  focus:shadow-secondary/25"
            />
          )}
          {type === "search" && (
            <SearchContainer>
              <StyledInput
                fullWidth={fullWidth}
                ref={ref}
                type={type}
                {...rest}
              />
              <Icon
                icon={faMagnifyingGlass}
                style={{ marginTop: -7, width: 25, height: 25 }}
              />
            </SearchContainer>
          )}
          {spacer && <Spacer isMdUp={isMdUp} />}
          <ErrorMessage error={error} />
        </ContainerComponent>
      );
    }
  )
);

const DefaultContainer = styled.div``;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  color: ${Colors.white};
`;

const StyledInput = styled.input(
  ({ type, fieldType, fullWidth }) => `
padding: ${type === "search" ? "0px 0px" : "16px 25px"};
border-radius: ${type === "search" ? 0 : "4px"};
-webkit-appearance: none;
outline: none;
font-size: 16px;
font-weight: 500;
-webkit-appearance: none;
color: ${fieldType === "alt" || type === "search" ? Colors.white : Colors.ash};
width: ${fullWidth ? "100%" : "80%"};
background-color: ${
    type === "search"
      ? "transparent"
      : fieldType === "alt"
      ? Colors.ash
      : Colors.white
  };
border: ${
    fieldType === "alt" && type !== "search" && type !== "textArea"
      ? `1px solid ${Colors.ghostAsh}`
      : `0px solid`
  };
&::placeholder {
  color: ${fieldType === "alt" ? Colors.white : '#757676'};
}
`
);

const Spacer = styled.div(
  ({ isMdUp }) => `
  margin-bottom: ${isMdUp ? "10px" : "20px"};
`
);

const StyledTextArea = styled.textarea(
  (fieldType) => `
  -webkit-transform: translate3d(0, 0, 0);
padding: 14px 25px;
background: none;
box-shadow: none;
border-radius: 4px;
-webkit-appearance: none;
outline: none;
-webkit-appearance: none;
color: ${fieldType === "alt" ? Colors.white : Colors.ash};
width: 100%;
height: 100%;
background-color: ${fieldType === "alt" ? Colors.ash : Colors.white};
border: ${fieldType === "alt" ? `3px solid ${Colors.ghostAsh}` : null};
font-family: Roboto;
&::placeholder {
  color: ${fieldType === "alt" ? Colors.white : Colors.ash};
}
`
);

const SearchContainer = styled.div`
  display: flex;
  padding: 13px 24px 9px 24px;
  border-radius: 105px;
  font-weight: 500;
  font-size: 1.3rem;
  color: ${Colors.white};
  background-color: rgba(255, 255, 255, 0.04);
  justify-content: space-between;
  align-items: center;
  min-height: 46px;
`;

const TextInputContainer = styled.div`
  display: flex;
  border-radius: 4px;
  font-weight: 500;
  color: ${Colors.lightGrey};
  background-color: ${Colors.white};
  justify-content: space-between;
  align-items: center;
  min-height: 46px;
  font-family: Roboto;
  font-style: normal;
  font-size: 16px;
`;