import { Button } from "components/Button";
import { Collapse } from "components/collapse/Collapse";
import { Colors } from "styles/Colors";
import { EmployerNotes } from "components/ApplicationComments";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import { ReadMore } from "components/ReadMore";
import { useAuthState } from "Context";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";
import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";

import { useState } from "react";

export const ProfileContent = React.memo(({ data, isCandidate }) => {
  const { userDetails } = useAuthState()
  const size = useResponsiveLayout();
  const [tabvalue, setTabvalue] = useState("Applicant Answer");

  return (
    <Container>
      <ContentWrapper>
        <TabValues size={size}>
          <TabHeadercontainer>
            <TabHeader
              tabvalue={tabvalue}
              value="Applicant Answer"
              onClick={() => setTabvalue("Applicant Answer")}
            >
              Applicant Answers
            </TabHeader>
            <TabHeader
              tabvalue={tabvalue}
              value="Applicant Profile Videos"
              onClick={() => setTabvalue("Applicant Profile Videos")}
            >
              Applicant Profile Videos
            </TabHeader>
          </TabHeadercontainer>
          {tabvalue === "My Videos" && (
            <div>
              <Button button size="medium">
                Add Video
              </Button>
            </div>
          )}
        </TabValues>
      </ContentWrapper>
      {tabvalue === "Applicant Answer" ? (
        <>
          <Videos>{
            data?.application?.answers.length > 0 ?
              data?.application?.answers.filter(a => a && a.answer).map((answer, index) => (
                <div key={index}>
                  <div className="justify-left pb-8">
                    Q{`${index + 1}`}). {answer.question}
                  </div>

                  <VideoCardJobBoard
                    key={`${answer.answer}`}
                    action
                    width={'100%'}
                    height={'auto'}
                    thumbnail={answer.thumbnail}
                    videoStats={true}
                    videoData={answer.answer}
                    userData={data.application}
                    gatherStats={!isCandidate}
                  />
                </div>
              )) : <Placeholder className="mb-16">No videos available</Placeholder>}
          </Videos>
          <ContentWrapperBodyAbout isCandidate={isCandidate}>
            <h3>About {data?.name}</h3>
            <ReadMore defaultOpen={!size.isMdUp}><p>{data?.candidate?.description}</p></ReadMore>
          </ContentWrapperBodyAbout>
        </>
      ) : data.videos ?
        <Videos>
          {data.videos.map((item, index) => (
            <VideoCardJobBoard
              key={`${item}`}
              action
              videoData={item}
              title={"VIDEO #" + (index + 1)}
              width={'100%'}
              height={'auto'}
              preload='metadata'
            ></VideoCardJobBoard>

          ))}</Videos>
        : (
          <Videos>
            {data.candidate.videos?.length > 0
              ? data.candidate.videos.map((item, index) => (
                <VideoCardJobBoard
                  key={`${item}`}
                  action
                  videoData={item}
                  title={"VIDEO #" + (index + 1)}
                  width={'100%'}
                  height={'auto'}
                  preload='metadata'
                ></VideoCardJobBoard>
              ))
              : <Placeholder>No videos available</Placeholder>}
          </Videos>
        )
      }

      {/* <ContentWrapperBody>
        <Collapse bg="#372545" title="Work" fullWidth>
          {data.work.map((i) => (
            <div>
              <h3>{i.title}</h3>
              <h6>{i.description}</h6>
            </div>
          ))}
        </Collapse>
      </ContentWrapperBody> */}
      {
        data?.candidate?.cv && (
          <ContentWrapperBody>
            <Collapse defaultOpen bdr title={`CV`} fullWidth>
              <Files>
                <a href={data?.candidate?.cv} target="_blank" rel="noreferrer">
                  <Icon icon={faFileLines} /> <h6 className="text-white">Download CV</h6>
                </a>
              </Files>
            </Collapse>
          </ContentWrapperBody>
        )
      }
      {userDetails?.userType !== 'CANDIDATE' &&
        <ContentWrapperBody>
          <EmployerNotes
            applicationId={data?.application.id}
          />
        </ContentWrapperBody>
      }

      {/* <ContentWrapperBody>
        <Collapse bg="#372545" defaultOpen title={`Notes (${data.notes.length})`} fullWidth>
          {data.notes
            ? data.notes.map((i) => (
                <div>
                  <Notes
                    userImage={i.avatar}
                    name={i.name}
                    comment={i.comment}
                    date={i.date}
                  />
                </div>
              ))
            : ""}
        </Collapse>
      </ContentWrapperBody> */}
    </Container >
  );
});

const TabHeadercontainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  position: relative;
`;
const TabHeader = styled.h2(
  ({ tabvalue, value }) => `
  font-family: Archivo;
  font-style: normal;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 18px;
  margin-right: 35px;
  color: ${tabvalue === value ? Colors.white : "rgba(255, 255, 255, 0.4)"};
  `
);
const TabValues = styled.div(
  ({ size }) => `
  display: ${size.isMdUp ? "flex" : "block"};
  align-items: center;
  justify-content: space-between;
  & > div:nth-child(2) {
    & button {
      padding: 18px 30px;
      font-size: 18px;
    }
  }
`
);

const Container = styled.div(
  () => `
  position: relative;
  color: white;
  grid-area: content;
  & h3{
    font-size: 18px;
    margin-top: 0px !important;
    font-weight: 400;
    margin-top: 22px;
    margin-bottom: 0px;
    text-transform: capitalize;;
  }
  & > div:nth-child(2) > div {
    width: 100%;
    img{
      width: 100%;
    }
   
  }
  & p{
      margin-top: 10px;
      font-size: 15px;
      font-weight: 400 !important;
      line-height: 25px;
  }
  & h6{
    line-height: 25px;
    font-size: 15px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  & span{
    margin-right: 5px;
    color: #999999;
    font-size: 16px;
    font-weight: 400px;
  }

  `
);

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ContentWrapperBody = styled.div`
  margin-top: 30px;
`;
const ContentWrapperBodyAbout = styled.div(({ isCandidate }) => `
  background: ${isCandidate ? '#372545' : '#232424'};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px 20px;
`)

const Files = styled.div`
  display: flex;
  & svg {
    font-size: 80px;
    margin-top: 30px;
    margin-right: 30px;
  }
`;

const Placeholder = styled.div`
  padding: 16px 26px;
  border-radius: 5px;
  background: rgba(8, 9, 9, 0.66);
  width: 100%;
`


const Videos = styled.div`
  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 64px 32px;
  margin-bottom: 30px;
`