import { DropDown } from "components/DropDown/DropDown";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

export const StepSix = React.memo(({ children, handleChange, state }) => {
  const { isMdUp } = useResponsiveLayout();

  const setCompanySize = (value) => {
    handleChange("companySize", toObjectConverter(value));
  };

  const optionsStrings = [
    "Massive  (over 1,000 people)",
    "Kind of massive (300 - 999 people)",
    "Large ( 50 - 299 people)",
    "Smaller (1 - 49 people)",
  ];

  const optionsObject = [
    { start: 1000 },
    { start: 300, end: 999 },
    { start: 50, end: 299 },
    { start: 1, end: 49 },
  ];

  const toStringConverter = (option) =>
    optionsStrings[
      optionsObject.findIndex((object) => {
        return object.start === option.start;
      })
    ];

  const toObjectConverter = (option) =>
    optionsObject[optionsStrings.indexOf(option)];

  return (
    <Container isMdUp={isMdUp}>
      <Wrapper isMdUp={isMdUp}>
        {children}
        <DropDown
          placeholder="choose a company size +"
          bg
          icon
          active={toStringConverter(state.companySize)}
          options={optionsStrings}
          handleChange={(option) => setCompanySize(option)}
        />
        <Heading isMdUp={isMdUp}>company.</Heading>
      </Wrapper>
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  position: relative;
  & button {
    background: transparent !important;
    font-size: ${isMdUp ? "26px" : "18px"};
    font-weight: 700;
    padding: 0px !important;
    padding-left: 10px;
    border-radius: 0px !important;
    padding-left: 0px;
  }
  & h5 {
    font-size: 26px;
    font-weight: 700;
    color: white;
    margin: 0px 20px;
  }
`
);
const Wrapper = styled.div(
  ({ isMdUp }) => `
  display: ${isMdUp ? "flex" : "block"};
`
);

const Heading = styled.h4(
  ({ isMdUp }) => `
  text-transform: uppercase;
  color: #fff;
  margin: 0px;
  margin-left: ${isMdUp ? "18px" : "0px"};
  margin-top: 5px;
  font-size: 26px;
  font-weight: 700;
`
);
