import styled from "styled-components";


export const UploadingFader = styled.div`
position: fixed;
width: 100vw;
height: 100vh;
left: 0;
top: 0;
background: rgba(0,0,0,0.8);
backdrop-filter: blur(2px);
display: grid;
place-items: center;
z-index: 10;
div {
  pointer-events: none;
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  gap: 16px;
  font-size: 24px;
  color: #FFF;
}

`