export const trackingTypes = {
  USER_LOGGED_IN: 'user-logged-in',
  USER_REGISTERED: 'user-registered',
  APPLICATION_STARTED: "application-started",
  APPLICATION_COME_BACK_LATER: 'application-come-back-later',
  APPLICATION_UPDATED: 'application-updated',
  APPLICATION_COMPLETED: 'application-completed',
  ANSWER_SUBMITTED: 'answer-submitted',
  ANSWER_FAILED: 'answer-failed'
}

