import { Transcript } from "../../Dashboard/Transcript";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import { UserProfile } from "components/UserProfile/UserProfile";
import React, { useState } from "react";

import styled from "styled-components";
import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";

export const InterviewVideos = React.memo(
  ({ jobApplications, candidates, viewProfile }) => {
    const { isMdUp } = useResponsiveLayout();
    const [open, setOpen] = useState(false);
    const [selectedTranscipt, setSelectedTranscript] = useState({});

    const transcriptData = (video, user) => {
      const schema = {
        video,
        user,
      };
      setOpen(true);
      setSelectedTranscript(schema);
    };

    return (
      <>
        <Container open={open}>
          {jobApplications?.map((item, index) => (
            <div key={index}>
              <SectionHeaderStyle>
                {candidates && candidates.length > 0 && (
                  () => {
                    const _candidate = candidates.find(c => c.id === item.candidateId)

                    return _candidate ?
                      (
                        <UserProfile
                          userImage={_candidate.candidate?.avatar}
                          name={_candidate?.name}
                          status={item.status?.toUpperCase()}
                          viewProfile={() =>
                            viewProfile({
                              ..._candidate,
                              application: item,
                            })
                          }
                        // rating={item.user.rating}
                        />) : <></>
                  }
                )()}
              </SectionHeaderStyle>
              <SectionContentStyle
                isMdUp={isMdUp}
                className="  overflow-hidden"
              >
                {item.answers
                  ? item?.answers?.map((i, indexB) => (
                    <VideoCardJobBoard
                      key={indexB}
                      width={'100%'}
                      height={'auto'}
                      title={`Q${indexB + 1}: ${i?.question}`}
                      videoData={i?.answer}
                      action={<></>}
                      gatherStats={true}
                      userData={item}
                    />
                  ))
                  : "no data"}
              </SectionContentStyle>
              <hr />
            </div>
          ))}
        </Container>
        {open ? (
          <Transcript
            selectedTranscipt={selectedTranscipt}
            setOpen={() => setOpen(!open)}
            open={open}
          />
        ) : null}
      </>
    );
  }
);

const SectionHeaderStyle = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
`;

const SectionContentStyle = styled.div(
  ({ isMdUp }) => `
display: flex;
  justify-content: ${!isMdUp ? "center" : "start"};

  flex-wrap: wrap;
  & > div {
    margin-bottom: 40px;
    margin-left: ${!isMdUp ? "0px" : "100px"};
  }
`
);

const Container = styled.div(
  ({ open }) => `
  & hr {
    border: 2px solid rgba(255, 255, 255, 0.2);
  }
filter: ${open ? "blur(3px)" : ""};

`
);
