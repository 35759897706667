import avatar1 from "images/member1.png";
import desi from "images/desi.png";
import rex from "images/rex.png";
import rexround from "images/rexround.png";

import userimg from "images/video.png";

export const data = [
  {
    user: {
      id: 0,
      avatar: userimg,
      name: "Phil Anselmo",
      firstName: "philip ",
      lastName: "anselmo",
      candidateRef: "PA1234",
      ContractType: "Perm / Part time",
      Location: "Tewkesbury, UK",
      tel: "07891 349 221",
      email: "phil@gmail.com",
      active: true,
      Rating: 5,
      duration: "10 Mins",
    },
    threads: [
      {
        sender: false,
        avatar: avatar1,
        date: "02-02-2022",
        message: "Hi there, ",
      },
      {
        sender: true,
        avatar: rexround,
        date: "04-04-2022",
        message: "Hi there, ",
      },
    ],
  },
  {
    user: {
      id: 1,

      avatar: rex,
      name: "Jone Anselmo",
      firstName: "Jone ",
      lastName: "anselmo",
      candidateRef: "PA1234",
      ContractType: "Perm / Part time",
      Location: "Tewkesbury, UK",
      tel: "07891 349 221",
      email: "phil@gmail.com",
      active: true,
      duration: "2MB",
    },
    threads: [
      {
        sender: false,
        avatar: rexround,
        date: "02-02-2022",
        message: "Helo there, ",
      },
      {
        sender: true,
        avatar: desi,
        date: "04-04-2022",
        message: "Yes! I am here, ",
      },
    ],
  },
  {
    user: {
      id: 2,

      avatar: userimg,
      name: "Jone Anselmo",
      firstName: "Jone ",
      lastName: "anselmo",
      candidateRef: "PA1234",
      ContractType: "Perm / Part time",
      Location: "Tewkesbury, UK",
      tel: "07891 349 221",
      email: "phil@gmail.com",
      active: true,
      duration: "2MB",
    },
    threads: [
      {
        sender: false,
        avatar: rexround,
        date: "02-02-2022",
        message: "Helo there, ",
      },
      {
        sender: true,
        avatar: desi,
        date: "04-04-2022",
        message: "Yes! I am here, ",
      },
    ],
  },
  {
    user: {
      id: 3,

      avatar: userimg,
      name: "Jone Anselmo",
      firstName: "Jone ",
      lastName: "anselmo",
      candidateRef: "PA1234",
      ContractType: "Perm / Part time",
      Location: "Tewkesbury, UK",
      tel: "07891 349 221",
      email: "phil@gmail.com",
      active: true,
      duration: "1 MB",
    },
    threads: [
      {
        sender: false,
        avatar: rexround,
        date: "02-02-2022",
        message: "Helo there, ",
      },
      {
        sender: true,
        avatar: desi,
        date: "04-04-2022",
        message: "Yes! I am here, ",
      },
    ],
  },
  {
    user: {
      id: 4,

      avatar: userimg,
      name: "Jone Anselmo",
      firstName: "Jone ",
      lastName: "anselmo",
      candidateRef: "PA1234",
      ContractType: "Perm / Part time",
      Location: "Tewkesbury, UK",
      tel: "07891 349 221",
      email: "phil@gmail.com",
      active: true,
      duration: "1 MB",
    },
    threads: [
      {
        sender: false,
        avatar: rexround,
        date: "02-02-2022",
        message: "Helo there, ",
      },
      {
        sender: true,
        avatar: desi,
        date: "04-04-2022",
        message: "Yes! I am here, ",
      },
    ],
  },
];
