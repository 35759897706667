import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { memo } from "react";
import styled from "styled-components";

import Slider from "react-slick";

import { Icon } from "components/Icon";

import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

export const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Icon
      icon={faArrowAltCircleRight}
      onClick={onClick}
      className={
        className +
        " shadow-2xl shadow-primary "
      }
      style={{ ...style, display: "block", width: "28px", height: "28px" }}

    />
  );
}
export const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Icon
      icon={faArrowAltCircleLeft}
      onClick={onClick}
      className={
        className +
        " " +
        " shadow-2xl shadow-primary "
      }
      style={{ ...style, display: "block", width: "28px", height: "28px", zIndex: 2 }}

    />
  );
}

export const SlickSlider = memo(({ data, Card, path }) => {
  const size = useResponsiveLayout();

  const settings = {
    dots: true,
    infinite: false,
    speed: 900,
    fade: false,
    slidesToShow: size.isLgUp ? 3 : size.isMdUp ? 2 : 1,
    slidesToScroll: size.isLgUp ? 3 : size.isMdUp ? 2 : 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Container isMdUp={size.isMdUp}>
      <div className="overflow-y-visible py-6 lg:w-full">
        <Slider {...settings}>
          {!!data?.length && Card ? Card({ data: data, path: path }) : null}
        </Slider>
      </div>
    </Container>
  );
});
const Container = styled.div(
  () => `
  .slick-list{
    overflow: visible;
  }
  svg.slick-prev, svg.slick-next {
    top: 40%; // Move to it doesn't cover the play button
  }
`
);
