import { hotjar } from "react-hotjar";
import { useAuthState } from "./context";
import React, { createContext } from "react";

const HotjarContext = createContext();

export const HotjarProvider = ({ children }) => {
    const { userDetails } = useAuthState();

    hotjar.initialize(
        process.env.REACT_APP_HOTJAR_ID,
        process.env.REACT_APP_HOTJAR_VERSION
    );

    // Check if Hotjar has been initialized before calling its methods
    if (hotjar.initialized()) {
        hotjar.identify(userDetails.id, {
            role: userDetails.userType,
            verified: userDetails.emailVerified,
        });
    }

    return (
        <HotjarContext.Provider value={hotjar}>
            {children}
        </HotjarContext.Provider>
    );
};
