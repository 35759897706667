import React from "react";
import styled from "styled-components";

import { Colors } from "styles/Colors";

export const ChatBubble = React.memo(({ children, sender = false, avatar }) => (
  <ChatBubbleContainer sender={sender}>
    {!sender && (
      <>
        <img src={avatar} alt="imge" /> <ChatTriangle sender={sender} />
      </>
    )}
    <ChatTextContainer sender={sender}>{children}</ChatTextContainer>
    {sender && (
      <>
        <ChatTriangle sender={sender} /> <img src={avatar} alt="imge" />
      </>
    )}
  </ChatBubbleContainer>
));

const ChatTextContainer = styled.div(
  ({ sender }) => `
  display: flex;
  width:100%;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 10px 15px 30px;
  background: ${sender ? "rgba(153, 153, 153, 0.16)" : Colors.green};
  border-radius: 4px;
  word-wrap: break-word;
  color:  ${sender ? Colors.white : Colors.ash};
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
 
`
);

const ChatTriangle = styled.div(
  ({ sender }) => `
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 12px solid
    ${sender ? "rgba(153, 153, 153, 0.16)" : Colors.green};
    align-self: center;
margin-right: ${sender ? "0px" : "-2px"};
margin-left: ${sender ? "0px" : "-2px"};
transform :${sender ? "rotate(180deg)" : "rotate(0deg)"};
`
);

const ChatBubbleContainer = styled.div(
  ({ sender }) => `
display: flex;
margin-left: 50px;
  flex-direction: row;
  align-items: ${sender ? "flex-start" : "flex-end"};
  float: ${sender ? "right" : "left"};
  & img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 10px;
  }
`
);
