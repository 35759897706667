import { Button } from "components/Button";
import { useNavigate } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

import Lottie from "lottie-react";
import RocketAnimation from "./rocket.json";

export const ApplicationCongratulations = React.memo(() => {
  const { isMdUp } = useResponsiveLayout();
  const navigate = useNavigate();
  return (
    <Container isMdUp={isMdUp}>
      <h1 className="text-7xl text-white font-extrabold uppercase">
        Your video Application
        <br /> has been submitted{" "}
      </h1>
      <Lottie
        animationData={RocketAnimation}
        loop={true}
        className="w-96 mb-5 mt-10 bg-blend-overlay"
      />
      {/* <p>Thank you for applying </p> */}

      <div className="flex text-lg sm:text-3xl space-x-5  my-7 sm:my-20">
        <button
          onClick={() => navigate("/job-board")}
          className="border-secondary border-4 hover:bg-secondary duration-200 transition-colors py-4 sm:py-8 px-4 text-white rounded-lg"
        >
          Back to Job Board
        </button>
        {/* <button
          className="bg-secondary py-10 px-4 text-white rounded-lg"
          onClick={() => navigate("/job-board")}
        >
          Jobs Matches For You
        </button> */}
      </div>
      {/* <ButtonContainer isMdUp={isMdUp}>
        <Button
          button
          type="alt"
          size={isMdUp ? "large" : "small"}
          onClick={() => navigate("/job-board")}
        >
          Back to job board
        </Button>
      </ButtonContainer> */}
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${isMdUp ? "50px" : "30px"};
  padding-left : ${isMdUp ? "122px" : "16px"};
  padding-right :${isMdUp ? "122px" : "16px"};
  text-align:center;
  & h2 {
    font-size: 26px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 36.56px;
    margin-bottom: ${isMdUp ? "69px" : "80px"};
  }
  & p {
    font-size: 18px;
    color: white;
    font-weight: 500;
    line-height: 28.8px;
    margin: 0px;
    margin-top: 15px;
    font-family: "Roboto";
    width: ${isMdUp ? "40%" : "100%"};
  }
`
);