import { Button } from "components/Button";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

export const VacancyFooter = React.memo(({ editJob, saveAndPublishJob }) => {
  const size = useResponsiveLayout();
  return (
    <Container size={size}>
      <Wrapper size={size}>
        <Button
          fullWidth={!size.isSmUp}
          button
          type="alt"
          size="large"
          onClick={editJob}
        >
          Edit
        </Button>
        <Button button
          fullWidth={!size.isSmUp}
          size="large"
          onClick={saveAndPublishJob}>
          Save & Publish
        </Button>

      </Wrapper>
    </Container>
  );
});

const Container = styled.div(
  ({ size }) => `
background-color: #232424;
z-index: 33;
margin-top: 40px;
padding: 20px;
padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
display: ${size.isMdUp ? "flex" : "inline-block"};
align-items: center;
position: fixed;
bottom: 0;
width: 100%;
justify-content: space-between;

& p{
  text-align: ${size.isMdUp ? "" : "center"};
  color: #777777;
  font-size: 18px;
  margin-top:${size.isMdUp ? "0px" : "10px"}
}
& span{
  display: ${size.isMdUp ? "inline" : "block"};
  color: white;
  font-size: 18px;
  margin: 0px;
  
}

`
);

const Wrapper = styled.div(
  ({ size }) => `
display: flex;
flex-direction: ${size.isSmUp ? 'row' : 'column'};
width: 100%;
margin: 0 auto;
max-width: var(--max-width);
justify-content: flex-end;
text-align: center;
gap: 16px;
`,
)
