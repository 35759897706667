import { AuthGuard } from "components/AuthGuard";
import { faCopy, faEyeSlash,  } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import { LoadingComponent } from "components/LoadingComponent";
import { SectionHeading } from "components/Headings/SectionHeading";
import { selectText } from "utils/functions";
import { useAuthState } from "Context";
import { useGetVacancyByIdQuery } from "store/userAPI";
import { useNavigate, useParams } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import { VacancyContent } from "./LocalComponents/VacancyContent";
import { VacancyFooter } from "./LocalComponents/VacancyFooter";
import { VacancyInfo } from "./LocalComponents/VacancyInfo";
import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";

export const VacancyPreview = React.memo(() => {
  const { userDetails } = useAuthState()
  const size = useResponsiveLayout();

  const { vacancyId } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useGetVacancyByIdQuery({ id: vacancyId });

  const targetCopyText = useRef()

  const isPrivate = useMemo(() => !data?.isPublic && userDetails.userType !== "CANDIDATE", [data, userDetails.userType])
  const publicVacancyLink = useMemo(() => `${window.location.protocol}//${window.location.host}/job-board/post/${data?.id}`, [data])

  if (loading || !Object.keys(data || {}).length) return <LoadingComponent />;

  const actions = () => (
    <ButtonGroupWrapperStyle size={size}>
      <p>{`£ ${data?.salary?.start} - £ ${data?.salary?.end} Per Annum`}</p>
    </ButtonGroupWrapperStyle>
  );

  const editJob = () => {
    navigate(`/employer/company/vacancy/edit/${vacancyId}`);
  };

  const saveAndPublishJob = () => {
    if (!data?.videoURL) {
      alert(
        "Please add a video to this vacancy. Progress has been saved, but the vacancy will not be published."
      );
    } else {
      navigate("/employer/dashboard");
    }
  };

  if (!data) {
    return <></>;
  }

  console.log(data)

  return (
    <AuthGuard criteria={[
      { value: data.companyId, check: 'membership.companyId' },
      { value: 'EMPLOYER', check: 'userDetails.userType' },
    ]}>
      <Container size={size} isPrivate={isPrivate}>
        <SectionHeading title={data?.title} companyName actions={actions()} />
        {isPrivate &&
          <div className="grid grid-cols-[auto_1fr] gap-8 items-center p-8 bg-[#232424] text-white rounded-lg" style={{ gridArea: 'private' }}>
            <Icon icon={faEyeSlash} />
            <div className="grid gap-2">
              <h3>This vacancy is private</h3>
              <div className="text-white text-2xl md:items-center flex gap-4 flex-col md:flex-row">
                <p className="md:whitespace-nowrap">Candidates can view and apply by using this link:</p>
                <div className="border border-dashed border-primary pl-4 rounded-xl grid grid-cols-[1fr_auto] gap-4 items-center w-fit min-w-[200px] text-xl">
                  <p className="inline-block flex-1 truncate text-white" id="targetText" ref={targetCopyText} onClick={() => selectText(targetCopyText)}>{publicVacancyLink}</p>
                  <button onClick={() => navigator.clipboard.writeText(publicVacancyLink)} className="flex gap-3 items-center bg-primary text-black px-4 py-2 relative -mt-[1px] -mb-[1px] -mr-[1px] rounded-tr-lg rounded-br-lg"><Icon icon={faCopy} color="primary" />Copy</button>
                </div>
              </div>
            </div>
          </div>
        }
        <hr style={{ gridArea: 'hr' }} />
        <VacancyInfo data={data} />
        <VacancyContent data={data} />
      </Container>
      <VacancyFooter editJob={editJob} saveAndPublishJob={saveAndPublishJob} />
    </AuthGuard >
  );
});

const Container = styled.div(
  ({ size, isPrivate }) => `
  max-width: min(var(--max-width), 100vw);
  margin: 0 auto;
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-bottom: 120px;
  display: grid;
  gap: ${size.isLgUp ? '24px 64px' : '32px'};
  grid-template-columns: ${size.isLgUp ? 'minmax(320px, 1fr) 3fr' : '1fr'};
  grid-template-areas: ${isPrivate ? size.isLgUp
      ? `'header header'
      'private private'
      'hr hr'
      'info content'
      'info content'`
      : `'header'
      'private'
      'hr'
      'content'
      'info'`
      : size.isLgUp ?
      `'header header'
      'hr hr'
      'info content'
      'info content'`
      : `'header'
      'hr'
      'content'
      'info'`
    };
& hr {
    display : ${size.isMdUp ? "block" : "none"};
    border: 2px solid #494A4A;
    margin-top: 30px;
}
 
`
);
const ButtonGroupWrapperStyle = styled.div`
  color: white;
  font-weight: bold;
  font-size: 18px;
`;