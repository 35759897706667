import { Button } from "components/Button";
import { Collapse } from "components/collapse/Collapse";
import { LoadingComponent } from "./LoadingComponent";
import { TextField } from "components/form";
import { useAuthState } from "Context";
import { useCreateApplicationCommentMutation, useGetApplicationCommentsByApplicationIdQuery } from "store/userAPI";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";

export const EmployerNotes = React.memo(({ applicationId }) => {
    const { userDetails } = useAuthState()
    // Compose comment
    const [comment, setComment] = useState('')
    const [createComment] = useCreateApplicationCommentMutation()
    const inputRef = useRef()
    const [isSubmitting, setIsSubmitting] = useState(false)

    // Get comments / store
    const { data: applicationComments, refetch, isLoading } = useGetApplicationCommentsByApplicationIdQuery({ id: applicationId });
    const [sortedData, setSortedData] = useState([])

    // Pagination
    const searchItemsPerPage = 3;
    const [pageCount, setPageCount] = useState(0);
    const [searchItemOffset, setSearchItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);

    /**
     * Pagination click handler
     */
    const handlePageClick = (event) => {
        if (!applicationComments) return
        const newOffset = (event.selected * searchItemsPerPage) % applicationComments.length;
        setSearchItemOffset(newOffset);
    };

    /**
     * Submit handler
     */
    const onSubmit = async () => {
        setIsSubmitting(true)
        const newComment = {
            applicationId,
            text: comment
        }
        await createComment(newComment)
        inputRef.current.value = ''
        setIsSubmitting(false)
        // React query has "refetch" but only if you use 
        await refetch()
    }

    /** 
     * Sorts on populate
     */
    useEffect(() => {
        if (!applicationComments || applicationComments.length === 0) return
        setSortedData([...applicationComments].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
    }, [applicationComments])


    /**
     * Calculates pagination - use the sorted data
     */
    useEffect(() => {
        if (sortedData && sortedData.length > 0) {
            const endOffset = searchItemOffset + searchItemsPerPage;
            setCurrentItems(sortedData.slice(searchItemOffset, endOffset));
            setPageCount(Math.ceil(sortedData.length / searchItemsPerPage));
        }
    }, [searchItemOffset, searchItemsPerPage, sortedData]);

    return (
        <Collapse defaultOpen bdr title={`Employer Notes (${applicationComments?.length ?? 0})`} fullWidth>
            <div className="grid gap-4 w-full">
                {isLoading && <LoadingComponent />}
                {currentItems.length === 0 && <h5 className="text-center font-medium my-8 text-2xl text-gray-400">No comments</h5>}
                {currentItems.map((note, i) => {
                    const isMe = note.user.id === userDetails.id
                    return (
                        <div className="grid gap-4 p-8 rounded-[5px] bg-[#232424]" key={i}>
                            <div className="flex gap-4 items-center">
                                <img
                                    alt={`Avatar for ${note.user.userName}`}
                                    src={note.user.avatar ? note.user.avatar : '/dummy-profile-picture.png'}
                                    className="w-[30px] h-[30px] bg-white rounded-full object-cover"
                                />
                                <div className="grid">
                                    <h5 className="text-2xl">{isMe ? "You" : note.user.name}</h5>
                                    <span className="text-xl font-light">{new Date(note.createdAt).toLocaleString()}</span>
                                </div>
                            </div>
                            <p className="mt-0">{note.text}</p>
                        </div>
                    )
                })}

                <div className="grid gap-4" style={{ gridTemplateColumns: "1fr auto" }}>
                    <TextField
                        onChange={(e) => setComment(e.target.value)}
                        fullWidth={true}
                        spacer={false}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && comment) {
                                onSubmit(comment);
                            }
                        }}
                        ref={inputRef}
                    />
                    <Button
                        button={true}
                        size="small"
                        onClick={(e) => onSubmit(comment)}
                        disabled={isSubmitting || isLoading}
                    >
                        Send
                    </Button>
                </div>

                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName={
                        "pagination small"
                    }
                    subContainerClassName={
                        "pages pagination small"
                    }
                    activeClassName={"active"}

                />
            </div>
        </Collapse>
    )
})