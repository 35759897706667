import { Button } from "components/Button";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

export const ProfileFooter = React.memo(({ back, isCandidate }) => {
  const size = useResponsiveLayout();
  return (
    <Container size={size}>
      <Wrapper size={size}>
        <Button
          fullWidth={!size.isSmUp}
          button
          type="alt"
          size="large"
          onClick={back}
        >
          Back To {isCandidate ? 'Applications' : 'Candidates'}
        </Button>
      </Wrapper>
    </Container>
  );
});

const Container = styled.div(
  ({ size }) => `
background-color: #232424;
margin-top: 40px;
display: ${size.isMdUp ? "flex" : "inline-block"};
position: fixed;
bottom: 0;
width: 100%;
padding: 20px;
padding-left: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
padding-right: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
z-Index: 1000;
`
);

const Wrapper = styled.div(({ size }) => `
display: flex;
width: 100%;
margin: 0 auto;
max-width: var(--max-width);
justify-content: ${size.isSmUp ? 'flex-end' : 'stretch'};
text-align: center;
`);