import styled from 'styled-components'

export const VideoPlaceholder = styled.div(
  ({ aspectRatio, borderRadius }) => `
  position: relative;
  padding: 16px 26px;
  border-radius: ${borderRadius ?? '16px'};
  min-height: 240px;
  width: 100%;
  aspect-ratio: ${aspectRatio ?? '4/3'};
  display: grid;
  place-content: center;
  color: rgba(255,255,255,0.66);
  text-align: center;
  background: linear-gradient(to bottom, rgba(225,200,255,0) 2px, rgba(225,200,255,0.1) 2px, rgba(225,200,255,0.1) 6px, rgba(225,200,255,0) 6px), rgba(8, 9, 9, 0.66);
  background-repeat: repeat-all;
  background-size: 12px 12px;
  background-position: 0px 0px;
  animation-name: background-v;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 0;
  overflow: hidden;
  
  :before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    transform: rotate(2deg);
    background: radial-gradient(circle, rgba(225,150,255,0.05) 0px, rgba(225,150,255,0) 4px);
    background-repeat: repeat-all;
    background-size: 12px 12px;
    background-position: 0px 0px;
    animation-name: background-h;
    animation-duration: 1s;
    animation-direction: reverse;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: -2;
 }
  :after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    z-index: -1;
 }
}
`,
)
