import { DropDown } from "components/DropDown/DropDown";
import { TagComponent } from "components/TagComponent";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

export const StepFive = React.memo(({ children, state, setArrayValue }) => {
  const { isMdUp } = useResponsiveLayout();

  const role = ["Sales", "Marketing", "Other +"];

  const roles = [
    {
      name: "Software development",
      job: "17",
    },
    {
      name: "Media production",
      job: "21",
    },
    {
      name: "Financial Services",
      job: "1",
    },
  ];

  const setPreferredRoles = (value, index) => {
    setArrayValue("preferredRoles", value, index);
  };

  return (
    <Container isMdUp={isMdUp}>
      <Wrapper isMdUp={isMdUp}>
        {children}
        <DropDown
          bg
          icon
          placeholder="choose a role +"
          active={state.preferredRoles[0]}
          options={role}
          handleChange={(xd) => setPreferredRoles(xd, 0)}
        />
        <h5>AND</h5>
        <DropDown
          bg
          icon
          placeholder="choose a role +"
          active={state.preferredRoles[1]}
          options={role}
          handleChange={(xd) => setPreferredRoles(xd, 1)}
        />
      </Wrapper>
      {state.preferredRoles[0] === "Other +" ||
      state.preferredRoles[1] === "Other +" ? (
        <Tag>
          {state.preferredRoles[0] === "Other +" ? (
            <TagWrapper>
              {roles.map((role) => (
                <TagComponent onClick={() => setPreferredRoles(role.name, 0)}>
                  {role.name}
                  {role.job !== "" ? <span>{`(${role.job})`}</span> : null}
                </TagComponent>
              ))}
            </TagWrapper>
          ) : (
            <>
              {state.preferredRoles[1] === "Other +" ? (
                <TagWrapper>
                  {roles.map((role) => (
                    <TagComponent
                      onClick={() => setPreferredRoles(role.name, 1)}
                    >
                      {role.name}
                      {role.job !== "" ? <span>{`(${role.job})`}</span> : null}
                    </TagComponent>
                  ))}
                </TagWrapper>
              ) : null}
            </>
          )}
        </Tag>
      ) : null}
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  position: relative;
  & button {
    background: transparent !important;
    font-size: 26px;
    font-weight: 700;
    padding: 0px !important;
    padding-left: 10px;
    border-radius: 0px !important;
    padding-left: 0px;
  }
  & h5 {
    font-size: 26px;
    font-weight: 700;
    margin-top: 5px !important;
    color: white;
    margin: 0px 20px;
    margin-left: ${isMdUp ? "20px" : "0px"};
  }
`
);

const Wrapper = styled.div(
  ({ isMdUp }) => `
  display: ${isMdUp ? "flex" : "block"};
`
);

const Tag = styled.div`
  width: 90%;
`;

const TagWrapper = styled.div`
  margin-top: 200px;
`;
