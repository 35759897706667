import {
  clearVacancyResponse,
  setSelectedVacancy,
} from "store/reducers/vacancyReducer";
import { SectionHeading } from "components/Headings/SectionHeading";
import ReactPaginate from "react-paginate";

import { useDispatch } from "react-redux";
import { useGetJobsQuery } from "store/userAPI";
import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import heroImage from "images/hero.png";
import styled from "styled-components";

import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";

import { Icon } from "components/Icon";

import { faGlobe } from "@fortawesome/free-solid-svg-icons";

export const SeeAllJobs = React.memo(() => {
  const test = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(clearVacancyResponse());
  }, [dispatch]);

  const size = useResponsiveLayout();

  const { data: jobs = [] } = useGetJobsQuery();

  const data = Array.isArray(jobs)
    ? jobs?.filter(
      (vacancy) =>
        vacancy?.videoURL &&
        vacancy?.title &&
        vacancy?.industry
    )
    : [];

  const viewJobPost = (item) => {
    dispatch(clearVacancyResponse());
    dispatch(setSelectedVacancy(item));
    navigate(`/job-board/post/${item?.id}`);
  };

  const latestSort = (arr) => {
    const arr1 = [...arr];
    arr1.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    return arr1;
  };

  const latestPost = () => {
    const latest = data?.filter((el) => el?.videoURL);
    switch (test.param) {
      case "latest vacancies":
        return latestSort(latest);
      case "top picks":
        return data;
      default:
        return null;
    }
  };

  return (
    <Container size={size}>
      <Wrapper size={size}>
        <Headers>
          <SectionHeading
            title={"Job Board (" + latestPost().length + ")"}
            placeholder={
              size.isMdUp
                ? "Search by industry, salary, location or skills"
                : "search"
            }
          />
        </Headers>
        <hr />
      </Wrapper>
      <Name> {test.param}</Name>
      <VideoWrapper>
        {latestPost()
          ? latestPost().length && (
            <PaginatedItems size itemsPerPage={21} items={latestPost()} />
          )
          : null}

        {data
          ?.filter((el) => el.industry === test.param)
          .map((i, index) => {
            return (
              <VideoAndTitle key={i.videoURL + index} size={size}>
                <VideoCardJobBoard
                  action
                  type="play"
                  width={'100%'}
                  height={'auto'}
                  videoData={i?.videoURL}
                />
                <h2 onClick={() => viewJobPost(i)}>{i?.title}</h2>
              </VideoAndTitle>
            );
          })}
      </VideoWrapper>
    </Container>
  );
});

function Items({ currentItems, size }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const viewJobPost = (item) => {
    dispatch(clearVacancyResponse());
    dispatch(setSelectedVacancy(item));
    navigate(`/job-board/post/${item?.id}`);
  };
  return currentItems.map((i, index) => {
    return (
      <VideoAndTitle key={i.videoURL + index} size={size}>
        <VideoCardJobBoard
          action
          type="play"
          width={'100%'}
          height={'auto'}
          videoData={i?.videoURL}
        />
        <div className="overflow-hidden flex basis-3/4 justify-around sm:rounded-lg">
          <div className="px-2 bg-transparent text-left py-5 sm:px-6">
            <h3
              onClick={() => viewJobPost(i)}
              className="text-2xl font-medium leading-6 text-gray-100
                  underline underline-offset-1"
            >
              {i?.title}
            </h3>
            <p className="mt-1 max-w-2xl text-xl text-gray-300">
              {i?.companyName}
            </p>

            <p className="text-primary font-light text-xl">
              {`£ ${i.salary.start}`}
              {" - "}
              {`£ ${i.salary.end}`}
            </p>
            <p className="text-primary text-xl">
              <Icon icon={faGlobe} className="mr-2 text-primary text-sm" />
              {i?.location}
            </p>
          </div>
          <div className="basis-1/4 mr-0 m-auto text-right">
            <button
              onClick={() => viewJobPost(i)}
              className=" border px-2 py-1 whitespace-nowrap hover:bg-white hover:text-gray-800 transition-all duration-200  text-white  m-auto rounded-lg inline  text-lg font-medium"
            >
              View Details
            </button>
          </div>
        </div>
      </VideoAndTitle>
    );
  });
}

function PaginatedItems({ itemsPerPage, items, size, viewJobPost }) {

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(items);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, items, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  return (
    <>
      <Items
        currentItems={currentItems}
        viewJobPost={viewJobPost}
        size={size}
      />
      <div style={{ gridColumn: '1/-1' }}>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName={
            "pagination large"
          } /* as this work same as bootstrap class */
          subContainerClassName={
            "pages pagination large"
          } /* as this work same as bootstrap class */
          activeClassName={"active"} /* as this work same as bootstrap class */
        />
      </div>
    </>
  );
}

const Container = styled.div(
  ({ size }) => `
  max-width: var(--max-width);
  margin: 0 auto;
  padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
  padding-bottom: 20px;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  min-height: 100%;
  background-image: linear-gradient(180.5deg, transparent, rgba(196, 196, 196, 0) 60%), url(${heroImage});
  background-size: contain;
  background-position: top center;
  & hr {
      display : ${size.isMdUp ? "block" : "none"};
      border: 1px solid rgba(255, 255, 255, 0.2);
      margin-top: 30px;
  }
  `
);

const Wrapper = styled.div(
  ({ size }) => `
    // padding-right: ${size.isMdUp ? "122px" : "20px"};
  `
);
const VideoWrapper = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
gap: 32px;
`;

const Headers = styled.div`
  padding-top: 40px;
  & input {
    background-color: rgba(0, 0, 0, 0.44);
  }
`;
const Name = styled.h2`
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 10px;
  color: white;
  text-transform: uppercase;
`;

const VideoAndTitle = styled.div(
  ({ size }) => `
  width: ${size.isMdUp ? "30%" : "100%"};
  @media (max-width: 468px) {
    width: 100%;
  }
  margin-top: 20px;
  margin-right: 15px;
  & h2 {
    color: white;
    font-size: 22px;
    letter-spacing: 0.4px;
    margin-top: 14px;
    cursor: pointer;
    text-decoration: underline;
  }
`
);