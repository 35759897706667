export const Colors = {
  ash: "#1C1D1D",
  bgGradient: "linear-gradient(180deg, #1C1D1D 0%, #250140 100%)",
  darkBorder: "#232424",
  ghostAsh: "#232424",
  green: "#6BFF00",
  greyText: "#999999",
  lightGrey: "#808080",
  primaryGreen: "#66E12C",
  red: "red",
  transparent: "rgba(0,0,0,0)",
  white: "#FFFFFF",
  velvet: "#9400FF",
  black: "#000",
  amber: "#f5c414"
};
