export const option = [
  "Creativity",
  "Innovation and creativity",
  "Diversity and inclusion",
  "Other +",
];
export const option1 = [
  "Creativity",
  "Innovation and creativity",
  "Diversity and inclusion",
  "Other +",
];
export const option2 = [
  "Creativity",
  "Innovation and creativity",
  "Diversity and inclusion",
  "Other +",
];
export const tags = [
  "Challenging work",
  "Meaningful work",
  "Development and progression",
  "Flexibility and wellbeing",
  "Recognition and reward",
  "Working with great people",
  "Transparency and respect",
  "Progressive leadership",
  "Data driven decisions",
  "Great tech and tools",
];
