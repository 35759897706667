import { analytics, trackingTypes } from "utils/analytics";

const ROOT_URL = process.env.REACT_APP_API_URL

let token = localStorage.getItem('currentUser')
  ? JSON.parse(localStorage.getItem('currentUser')).jwt
  : ''

export async function getUser(dispatch) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    dispatch({ type: 'REQUEST_GET_USER' })
    let response = await fetch(`${ROOT_URL}/user/me`, requestOptions)
    let data = await response.json()
    if (data.user) {
      dispatch({ type: 'GET_USER_SUCCESS', payload: data })

      return data
    }

    dispatch({ type: 'GET_USER_ERROR', error: data.errors[0] })
    return
  } catch (error) {
    dispatch({ type: 'GET_USER_ERROR', error: error })
  }
}

export async function loginUser(dispatch, loginPayload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(loginPayload),
  }

  try {
    dispatch({ type: 'REQUEST_LOGIN' })
    let response = await fetch(`${ROOT_URL}/user/login/email`, requestOptions)
    let data = await response.json()
    if (data.user) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: data })
      localStorage.setItem('currentUser', JSON.stringify(data))
      analytics.identify(data.user.id, {
        ...data.user
      })
      analytics.track(trackingTypes.USER_LOGGED_IN, {
        userId: data.user.id,
        ...data.user
      })
      return data
    } else {
      dispatch({ type: 'LOGIN_ERROR', error: data.errors })
      return data
    }
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error })
  }
}

export async function registerCompany(dispatch, loginPayload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(loginPayload),
  }

  try {
    dispatch({ type: 'REQUEST_SIGNUP' })
    let response = await fetch(
      `${ROOT_URL}/user/employer/register/email`,
      requestOptions,
    )

    let data = await response.json()
    if (data.user) {
      dispatch({ type: 'SIGNUP_SUCCESS', payload: data })
      localStorage.setItem('currentUser', JSON.stringify(data))
      analytics.identify(data.user.id, {
        ...data.user
      })
      analytics.track(trackingTypes.USER_REGISTERED, {
        userId: data.user.id,
        ...data.user
      })
      return data
    } else {
      dispatch({ type: 'SIGNUP_ERROR', error: data.errors })
      return data
    }
  } catch (error) {
    dispatch({ type: 'SIGNUP_ERROR', error: error })
  }
}

export async function registerCandidate(dispatch, loginPayload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(loginPayload),
  }

  try {
    dispatch({ type: 'REQUEST_SIGNUP' })
    let response = await fetch(
      `${ROOT_URL}/user/candidate/register/email`,
      requestOptions,
    )

    let data = await response.json()
    if (data.user) {
      dispatch({ type: 'SIGNUP_SUCCESS', payload: data })
      localStorage.setItem('currentUser', JSON.stringify(data))
      analytics.identify(data.user.id, {
        ...data.user
      })
      analytics.track(trackingTypes.USER_REGISTERED, {
        userId: data.user.id,
        ...data.user
      })
      return data
    } else {
      dispatch({ type: 'SIGNUP_ERROR', error: data.errors })
      return data
    }
  } catch (error) {
    dispatch({ type: 'SIGNUP_ERROR', error: error })
  }
}

export async function addTeamMember(dispatch, addTeamMemberPayload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(addTeamMemberPayload),
  }
  try {
    dispatch({ type: 'REQUEST_ADD_TEAM_MEMBER' })
    let response = await fetch(`${ROOT_URL}/company/addMember`, requestOptions)
    let data = await response.json()
    if (data) {
      dispatch({ type: 'ADD_TEAM_MEMBER_SUCCESS' })
      return data
    }

    dispatch({ type: 'ADD_TEAM_MEMBER_ERROR', error: data.errors[0] })
    return
  } catch (error) {
    dispatch({ type: 'ADD_TEAM_MEMBER_ERROR', error: error })
  }
}

export async function updateTeamMember(
  dispatch,
  membershipId,
  updateTeamMemberPayload,
) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(updateTeamMemberPayload),
  }

  try {
    dispatch({ type: 'REQUEST_UPDATE_TEAM_MEMBER' })
    let response = await fetch(
      `${ROOT_URL}/company/member?membershipId=${membershipId}`,
      requestOptions,
    )
    let data = await response.json()
    if (data) {
      dispatch({ type: 'UPDATE_TEAM_MEMBER_SUCCESS' })
      return data
    }

    dispatch({ type: 'UPDATE_TEAM_MEMBER_ERROR', error: data.errors[0] })
    return
  } catch (error) {
    dispatch({ type: 'UPDATE_TEAM_MEMBER_ERROR', error: error })
  }
}

export async function updateCompany(dispatch, payload) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  }

  try {
    dispatch({ type: 'REQUEST_UPDATE_COMPANY' })
    let response = await fetch(`${ROOT_URL}/company/update`, requestOptions)
    let data = await response.json()
    if (data) {
      dispatch({ type: 'UPDATE_COMPANY_SUCCESS' })
      return data
    }

    dispatch({ type: 'UPDATE_COMPANY_ERROR', error: data.errors[0] })
    return
  } catch (error) {
    dispatch({ type: 'UPDATE_COMPANY_ERROR', error: error })
  }
}

export async function getCompanyMembers(dispatch) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    dispatch({ type: 'REQUEST_COMPANY_MEMBERS' })
    let response = await fetch(`${ROOT_URL}/company/members`, requestOptions)
    let data = await response.json()

    if (data) {
      dispatch({ type: 'COMPANY_MEMBERS_SUCCESS', payload: data })
      return data
    }

    dispatch({ type: 'COMPANY_MEMBERS_ERROR', error: data.errors[0] })
    return
  } catch (error) {
    dispatch({ type: 'COMPANY_MEMBERS_ERROR', error: error })
  }
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' })
  localStorage.removeItem('currentUser')
  localStorage.removeItem('token')
}

export async function createVideoView(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
  }

  try {
    let response = await fetch(`${ROOT_URL}/video-view`, requestOptions)
    let data = await response.json()

    if (data) {
      return data
    }

    return
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function updateVideoView(payload) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
  }

  try {
    let response = await fetch(`${ROOT_URL}/video-view`, requestOptions)
    let data = await response.json()

    if (data) {
      return data
    }

    return
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function uploadMedia(payload) {
  // let newtoken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImF0cml4ZGlnaXRhbEBnbWFpbC5jb20iLCJ1c2VyVHlwZSI6IkNBTkRJREFURSIsImlkIjoiNjMwODFmZWQ5ZjdiMzY5MjBhYTRjYjgyIiwiaWF0IjoxNjYxNDc2ODQ1LCJleHAiOjE2ODczOTY4NDV9.s8e1mjSYrkkIGMD283dAJOtZZ9VAZK03iJZQFXJXNPE`;
  let newtoken = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser')).jwt
    : ''
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${newtoken}`,
    },
    body: payload,
  }
  try {
    let response = await fetch(`${ROOT_URL}/media/upload`, requestOptions)
    let data = await response.json()
    if (data) {
      return data
    }

    return
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function performFacialRecognition(userId, imageUrl) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: userId,
      url: imageUrl,
      filename: userId,
    }),
  }
  try {
    let response = await fetch(
      `${ROOT_URL}/user/facial-recognition`,
      requestOptions,
    )

    const resContent = await response.text()
    if (response.status === 200) {
      // If we got here the recognition was successful, update our local storage so don't run this again.
      // Logging out > logging in will also clear this
      // Originally tried dispatch and authContect but it doesn't update and I'm past caring about this React mess.
      const storageUser = localStorage.getItem('currentUser')
      const currentUser = JSON.parse(storageUser)
      // New users do not have a candidate, create one if this is the case
      if (!currentUser.user.candidate) {
        currentUser.user.candidate = {}
      }
      currentUser.user.candidate.estimatedAge = 'processing'
      currentUser.user.candidate.estimatedEthnicity = 'processing'
      currentUser.user.candidate.estimatedGender = 'processing'
      localStorage.setItem('currentUser', JSON.stringify(currentUser))
      return resContent
    } else {
      throw resContent
    }
  } catch (error) {
    console.log(error)
    return error
  }
}
