import { Button } from "components/Button";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import React from "react";
import styled from "styled-components";

export const ManageInfo = React.memo(
  ({ title, children, uneditable = false, edit, save, editing }) => {
    return (
      <>
        <ManageBody uneditable={uneditable}>
          <h2>{title}</h2>
          {children}
          {editing ? (
            <Button button onClick={save} size={"small"}>
              Save
            </Button>
          ) : !uneditable ? (
            <Icon icon={faPencil} onClick={edit} />
          ) : null}
        </ManageBody>
      </>
    );
  }
);

const ManageBody = styled.div(
  ({ uneditable }) => `
position: relative;
& h2 {
  color: white;
  font-family: "Archivo";
  font-size: 18px;
  margin-bottom: 16px;
}
& svg {
  position: absolute;
  top: 0;
  right: 0;
  color: #9400ff;
  cursor: pointer;
}
${
  !uneditable &&
  `& button:not([type='text']) {
  position: absolute;
  top: 0;
  right: 0;
}`
}
`
);
