import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Colors } from "styles/Colors";

export const Icon = React.memo(
  ({ size, style, className, color = Colors.white, href = false, ...props }) =>
    href ? (
      <a
        href={href?.includes("https://") ? href : `https://${href}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <FontAwesomeIcon
          {...props}
          style={{ ...style, fontSize: size }}
          color={color}
          className={className}
          size="lg"
        />
      </a>
    ) : (
      <FontAwesomeIcon
        {...props}
        className={className}
        style={{ ...style, fontSize: size }}
        color={color}
        size="lg"
      />
    )
);
