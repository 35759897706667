export const INDUSTRIES = [
  "Accounting and finance",
  "Fashion",
  "Financial services",
  "Hospitality",
  "Media and Advertising",
  "Real estate",
  "Retail",
  "Staffing and recruiting",
  "Technology",
  "Other",
];
