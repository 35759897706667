import { Colors } from "styles/Colors";
import {
    faChevronDown,
    faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import { FlexColumnWrapper } from "components/FlexColumnWrapper";
import { Icon } from "components/Icon";
import { NewTagComponent } from "components/NewTagComponent";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

export const MultiSelectDropdownInput = React.memo(
    ({
        options,
        bg,
        onClick,
        label,
        placeholder,
        type = "standard",
        maxListHeight,
        disabled,
        style,
        isLoading,
        value,
    }) => {
        const [isOpen, setIsOpen] = useState(false);
        const toggling = () => setIsOpen(!isOpen);

        const onOptionClicked = (option) => () => {
            if (!value.includes(option)) {
                const selectedOptions = [...value, option];
                onClick(selectedOptions);
            }
            setIsOpen(false);
        };

        const onRemove = (option) => () => {
            const selectedOptions = value.filter((e) => e !== option);
            onClick(selectedOptions);
        };

        const isDisabled = useCallback(() => {
            return disabled || isLoading || !options || !options.length > 0;
        }, [disabled, isLoading, options]);

        return (
            <div className="grid gap-6">
                <DropDownContainer style={{ ...style }} disabled={isDisabled()}>
                    {label && <Label>{label}</Label>}
                    <DropDownHeader
                        onClick={() => !isDisabled() && toggling()}
                        type={type}
                        bg={bg}
                        isOpen={isOpen}
                    >
                        {isLoading ? (
                            <div
                                style={{
                                    textAlign: "left",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Icon
                                    spin
                                    icon={faCircleNotch}
                                    style={{
                                        color:
                                            type === "alt" ? "white" : "black",
                                        marginLeft: 0,
                                    }}
                                />
                                <span style={{ marginLeft: 10 }}>Loading</span>
                            </div>
                        ) : (
                            placeholder || "Select Multiple"
                        )}
                        <Icon
                            icon={faChevronDown}
                            className=" stroke-2 h-8 w-8"
                            color={type === "alt" ? Colors.white : Colors.ash}
                        />
                    </DropDownHeader>
                    {isOpen && options && (
                        <DropDownList
                            type={type}
                            maxListHeight={maxListHeight}
                            className="thin-scrollbar"
                        >
                            {options
                                .filter((option) => !value.includes(option))
                                .map((option, i) => (
                                    <ListItem
                                        onClick={onOptionClicked(option)}
                                        className={
                                            type === "alt"
                                                ? "hover:bg-black/30"
                                                : "hover:bg-secondary/10" +
                                                  value.includes(option)
                                                ? " hover:bg-secondary/10"
                                                : ""
                                        }
                                        key={i}
                                    >
                                        {option}
                                    </ListItem>
                                ))}
                        </DropDownList>
                    )}
                </DropDownContainer>
                <FlexColumnWrapper>
                    {value.map((selectedOption, i) => (
                        <NewTagComponent
                            key={i}
                            onClick={onRemove(selectedOption)}
                        >
                            {selectedOption}
                        </NewTagComponent>
                    ))}
                </FlexColumnWrapper>
            </div>
        );
    }
);

const DropDownContainer = styled.div(
    ({ disabled }) => `
  position: relative;
  width: 100%;
  opacity: ${disabled ? "0.5" : "1"};
`
);

const DropDownHeader = styled.div(
    ({ type, bg, isOpen }) => `
display: flex;
position: relative;
padding: 12px 12px 12px 0px;
padding-left 25px;
border-radius: ${isOpen ? "5px 5px 0 0" : "5px"};
box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
font-weight: 500;
font-size: 16px;
color: ${type === "alt" ? Colors.white : Colors.ash};
background-color: ${bg ? bg : Colors.white};
backdrop-filter: blur(30px);
justify-content: space-between;
align-items: center;
height: 56px;
cursor: pointer;
& svg{
  margin-left: 15px;
 font-size: 13px;
 cursor: pointer;
}
`
);

const DropDownList = styled.ul(
    ({ type, maxListHeight }) => `
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  width: 100%;
  max-height: ${maxListHeight ? maxListHeight : "300px"};
  font-size: 16px;
  font-weight: 500;
  color: ${type === "alt" ? Colors.white : Colors.ash};
  background-color: ${type === "alt" ? "rgba(0, 0, 0, 0.4)" : Colors.white};
  backdrop-filter: blur(30px);
  border-top: 1px solid ${
      type === "alt" ? "rgba(255, 255, 255, 0.2)" : "#e5e5e5"
  };
  border-radius: 0 0 5px 5px;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  overflow-y:auto;
  &:first-child {
    padding-top: 0.8em;
  }
`
);

const ListItem = styled.li`
    list-style: none;
    padding: 12px;
    border-radius: 5px;
    margin: 8px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 10px;
    color: ${Colors.white};
`;
