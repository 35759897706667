import { Colors } from "styles/Colors";
import { Oval } from "react-loader-spinner";
import React from "react";
import styled from "styled-components";

export const LoadingComponent = React.memo(() => (
  <CentralContainer>
    <Oval
      ariaLabel="loading-indicator"
      height={100}
      width={100}
      strokeWidth={5}
      strokeWidthSecondary={5}
      color={Colors.velvet}
      secondaryColor={Colors.ash}
    />
  </CentralContainer>
));

const CentralContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: L 100%;
  justify-content: center;
  align-items: center;
`;
