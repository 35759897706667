import { Colors } from 'styles/Colors'
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import styled from 'styled-components'

import { Icon } from 'components/Icon'
import { Link } from 'react-router-dom'
import { useResponsiveLayout } from 'hooks/useResponsiveLayout'

export const Button = ({
  fullWidth = false,
  disabled = false,
  type = 'standard',
  color = Colors.velvet,
  borderColor = null,
  size = 'medium',
  button = false,
  children,
  leftIcon,
  rightIcon,
  icon,
  add = false,
  loading = false,
  onClick = null,
  ...props
}) => {
  let as
  if (button) {
    as = 'button'
  } else if (disabled) {
    as = 'span'
  } else {
    as = (p) => (
      <Link className={p.className} {...props} onClick={onClick}>
        {children}
      </Link>
    )
  }

  const rSize = useResponsiveLayout()

  const sizes = {
    xs: {
      fontSize: '1rem',
      paddingHorizontal: '8px',
      paddingVertical: '4px',
      borderRadius: '4px',
    },
    small: {
      fontSize: '1.2rem',
      paddingHorizontal: '20px',
      paddingVertical: '8px',
    },
    medium: {
      fontSize: '1.5rem',
      paddingHorizontal: '20px',
      paddingVertical: '12px',
    },
    large: {
      fontSize: rSize.isMdUp ? '1.8rem' : '1.5rem',
      paddingHorizontal: '20px',
      paddingVertical: '12px',
    },
  }

  return (
    <StyledLink
      type={type}
      disabled={disabled}
      fullWidth={fullWidth}
      color={color}
      borderColor={borderColor}
      size={size}
      as={as}
      style={button || disabled ? props.style : {}}
      onClick={disabled ? null : onClick}
      sizes={sizes}
      {...props}
    >
      {add && <Icon icon={faPlus} style={{ marginRight: children ? 10 : 0 }} />}
      {icon && <Icon icon={icon} />}
      {leftIcon && <Icon icon={leftIcon} style={{ marginRight: children ? 10 : 0 }} />}
      {rightIcon && (
        <Icon icon={rightIcon} style={{ float: 'right', marginLeft: children ? 10 : 0 }} />
      )}
      {children}
      {loading && <Icon spin icon={faSpinner} style={{ marginLeft: children ? 10 : 0 }} />}
    </StyledLink>
  )
}

const StyledLink = styled.div(
  ({ fullWidth, type, disabled, size, color, borderColor, sizes }) => `
display: ${fullWidth ? 'block' : 'initial'};
width: ${fullWidth ? '100%' : 'initial'};
outline-color: ${borderColor || color};
background-color: ${type === 'alt' ? Colors.transparent : color};
outline-width: ${type === 'alt' ? '3px' : '0px'};
outline-offset: ${type === 'alt' ? '-3px' : '0px'};
text-align: center;
outline-style: ${type === 'alt' ? 'solid' : 'none'} !important;
opacity: ${disabled ? 0.5 : 1};
padding-left: ${fullWidth ? '20px' : sizes[size].paddingHorizontal};
padding-right: ${fullWidth ? '20px' : sizes[size].paddingHorizontal};
padding-top: ${sizes[size].paddingVertical};
padding-bottom: ${sizes[size].paddingVertical};
border-radius: ${sizes[size].borderRadius || '5px'};
color: ${Colors.white};
font-size: ${sizes[size].fontSize};
text-decoration: none;
cursor: ${disabled ? 'initial' : 'pointer'};
box-sizing: border-box;
max-width: ${fullWidth ? '100%' : '361px'};
border-style: none; 
`,
)