import React from 'react'
import styled from 'styled-components'

import { Colors } from 'styles/Colors'

export const RadioComponent = React.memo(
  ({ style, item, onClick, selected, isMdUp, value, ...rest }) => (
    <RadioContainer isMdUp={isMdUp} style={style}>
      <Radio
        type="radio"
        name="status"
        onClick={(e) => {
          onClick(e.currentTarget.value)
        }}
        label={item}
        value={value || item}
        selected={selected}
        {...rest}
      />
      <Label data-tip={rest['data-tip']} data-for={rest['data-for']}>
        {item}
      </Label>
    </RadioContainer>
  ),
)

const Radio = styled.input(
  ({ selected }) => `
  -webkit-appearance: none;
  appearance: none;
  background-color: ${selected ? '#250140' : 'rgb(69, 53, 81)'};
  height: 16px;
  width: 16px;
  aspect-ratio: 1/1;
  border-radius: 16px;
  border: 3px solid ${selected ? Colors.green : 'rgb(69, 53, 81)'};
  cursor: pointer;
`,
)

const RadioContainer = styled.div(
  ({ isMdUp }) => `
  display: flex;
  flex-direction: row;
  margin-bottom: ${isMdUp ? '0px' : '24px'};
`,
)

const Label = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin-left: 14.5px;
  color: ${Colors.white};
  margin-top: -2px;
`
