import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const ReadMore = (
    ({
        children,
        defaultOpen = false,
        maxHeight = '200',
        labelClosed,
        labelOpen,
        color,
        ...rest
    }) => {

        const [isOpen, setIsOpen] = useState(defaultOpen)
        const [canExpand, setCanExpand] = useState(true)
        const [contentHeight, setContentHeight] = useState(0)
        const contentRef = useRef()

        /**
         * If defaultOpen changes, react to it and change state
         */
        useEffect(() => {
            setIsOpen(defaultOpen)
        }, [defaultOpen])

        /**
         * Check the height of the given content. If its smaller than maxHeight then there's no need to expand/collapse
         */
        useEffect(() => {
            const contentHeight = contentRef.current.clientHeight
            setContentHeight(contentHeight)
            if (contentHeight < maxHeight) {
                setCanExpand(false)
            } else {
                setCanExpand(true)
            }
        }, [children, maxHeight, contentRef])


        return <div {...rest}><Wrapper data-open={isOpen || !canExpand} maxHeight={maxHeight} contentHeight={contentHeight}><div ref={contentRef}>{children}</div></Wrapper>
            {canExpand && <TextButton color={color} type="button" onClick={() => setIsOpen(!isOpen)}>{isOpen ? (labelOpen ?? "Show less") : (labelClosed ?? "Read more")}<Icon icon={isOpen ? faCaretUp : faCaretDown} /></TextButton>}
        </div>

    })

const Wrapper = styled.div(({ maxHeight, contentHeight }) => `
    max-height: ${maxHeight}px;
    overflow: hidden;
    transition: all 200ms ease-out;
    white-space: pre-wrap;
    mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) calc(100% - 60px),
        rgba(0, 0, 0, 0) 100%
    );
    &[data-open='true'] {
        // 100% and fit-content cannot be animated. If an element ever needs to exceed 100vh, change this.
        max-height: ${contentHeight + 10}px;
        mask-image: none;
    }
`)

const TextButton = styled.button(({ color }) => `
    margin-top: 10px;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr auto;
    // I hate this. STOP using !important everywhere for the love of all that is holy.
    color: ${color ? color : 'inherit'}!important;
    color: white;
`)