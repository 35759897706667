import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { headerHeight } from "styles/Layout";
import { Icon } from "components/Icon";
import { UploadingFader } from "components/UploadingFader";
import {
  useGetUserQuery,
  useGetVideoUploadUrlsMutation,
  useUpdateCandidateMutation,
} from "store/userAPI";
import { useNavigate, } from "react-router-dom";
import bgImage from 'images/recorder-bg-1.jpg'
import React, { useState } from "react";
import VideoRecorder from "components/VideoRecorder/VideoRecorder";

export const RecordCandidateVideo = React.memo(() => {

  const navigate = useNavigate();

  const [updateCandidate] = useUpdateCandidateMutation();
  const [getVideoUploadUrls] = useGetVideoUploadUrlsMutation();
  const [isUploading, setIsUploading] = useState(false)

  const {
    data: { user: { candidate = {} } = {} } = {},
  } = useGetUserQuery({});

  const onSubmit = async (embedding) => {

    try {
      setIsUploading(true)

      // Construct the payload for the DB
      const payload = {
        ...candidate,
        videos: [...candidate.videos, `${embedding.recordingId}.mp4`],
      };

      // Get signed URLs for S3, and chuck our files over
      const res = await getVideoUploadUrls({ name: embedding.recordingId })
      const thumbnailUrl = res.data.thumbnailUrl

      // Upload thumbnail if it was selected
      if (embedding.thumbnail) {
        const thumbnailRes = await fetch(thumbnailUrl, {
          method: 'PUT',
          headers: { 'Content-Type': 'image/jpeg' },
          body: embedding.thumbnail
        });
        if (!thumbnailRes.ok) {
          console.error(thumbnailRes)
          alert("There was an issue uploading the thumbnail, please refresh and try again.")
          throw Error("Failed to upload thumbnail")
        }
      }

      console.log("final-payload", payload);
      let response = await updateCandidate(payload);
      if (!response.data) return;
      if (response.error) return alert(response.message);

      console.log("candidate", candidate);

      navigate(`/candidate/manage-profile`);
    } catch (ex) {
      console.error(ex)
      alert("An error occurred when submitting your video, please refresh and try again.")
    }
    finally {
      setIsUploading(false)
    }
  };

  return isUploading ?
    <UploadingFader>
      <div><Icon spin icon={faSpinner} /><span>Uploading, please wait...</span></div>
    </UploadingFader>
    :
    <div>
      <VideoRecorder
        allowUpload={false}
        height={`calc(100svh - ${headerHeight}px)`}
        janusServer={process.env.REACT_APP_JANUS_URL ?? 'https://videos-graviton.seezyhire.com/janus'}
        onComplete={onSubmit}
        onExit={() => {
          navigate(`/candidate/manage-profile`);
        }}
        requiredVideoLength={process.env.REACT_APP_MIN_CANDIDATE_RECORDING_LENGTH}
        showGuide={true}
        thumbnailSelection={true}
        overlayText={["“Talk about:", "- Your personality + interests", "- Your experience + skills", "- What motivates you”"]}
        backgroundImage={bgImage}
      /></div>

})