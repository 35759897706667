import { Button } from "components/Button";
import { Colors } from "styles/Colors";
import { faCloudArrowUp, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

import { useEffect } from "react";

const videoFormats = ["webm", "mpeg", "ogg", "mp4", "m4p", "m4v", "mov"];

const imageFormats = ["png", "jpeg", "jpg", "svg", "gif"];

const fileFormats = ["pdf", "doc", "docx"];

export const FileUpload = React.memo(
  ({ cloudFile = null, count = 1, setSelectedFile, type }) => {
    const [localUrl, setLocalUrl] = useState();
    const [mimeType, setMimeType] = useState("*");

    const [active, setActive] = useState(false);

    useEffect(() => {
      if (cloudFile) {
        let ext = cloudFile.split(".").pop();
        setLocalUrl(cloudFile);
        setMimeType(ext.toLowerCase());
      }
    }, [cloudFile]);

    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setActive(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setActive(false);
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setActive(true);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setActive(false);
      const formats =
        type === "image"
          ? imageFormats
          : type === "file"
          ? fileFormats.toString()
          : videoFormats;
      const { files } = e.dataTransfer;

      if (count && count < files.length) {
        alert(
          `Only ${count} file${
            count !== 1 ? "s" : ""
          } can be uploaded at a time`
        );
        return;
      }

      // check if some uploaded file is not in one of the allowed formats
      if (
        formats &&
        Object.values(files).some(
          (file) =>
            !formats.some((format) =>
              file.name.toLowerCase().endsWith(format.toLowerCase())
            )
        )
      ) {
        alert(
          `Only following file formats are acceptable: ${formats.join(", ")}`
        );
        return;
      }

      if (files && files.length) {
        const fileUploaded = e?.dataTransfer?.files[0];
        setLocalUrl(URL.createObjectURL(fileUploaded));
        setSelectedFile(fileUploaded);
      }
    };

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
      hiddenFileInput.current.click();
    };

    const handleChange = (event) => {
      const fileUploaded = event.target.files[0];
      setSelectedFile(fileUploaded);
      handleMimeType(fileUploaded);
      setLocalUrl(URL.createObjectURL(fileUploaded));
    };
    const handleMimeType = (file) => {
      const mimeType = file.type;
      return setMimeType(mimeType);
    };

    return (
      <>
        <DragArea
          active={active}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {/* 
        
        (
          mimeType == "application/pdf" ? (
            <embed
              src={localUrl}
              type="application/pdf"
              width="100%"
              height="100%"
            />
          ) :
        */}
          {!!localUrl ? (
            mimeType.includes("pdf") ? (
              <embed
                src={localUrl}
                type="application/pdf"
                width="100%"
                height="100%"
              />
            ) : /image|png|jpeg|jpg|gif/.test(mimeType) ? (
              <img
                src={localUrl}
                width="100%"
                height="100%"
                className=" object-cover"
              />
            ) : mimeType.includes("word") ? (
              <a
                className="flex flex-col text-center space-y-4 hover:opacity-75"
                href={localUrl}
                target="_blank" rel="noreferrer"
              >
                <Icon icon={faFileWord} className="w-96 h-96" />
                <span className="text-white font-extrabold">
                  Click to View Document
                </span>
              </a>
            ) : mimeType.includes("video") ? (
              <ReactPlayer
                url={localUrl}
                width="100%"
                height="100%"
                controls
                playing
                loop
                muted
              />
            ) : (
              <a href={localUrl} target="_blank" rel="noreferrer">
                Click to view uploaded file
              </a>
            )
          ) : (
            <>
              <Icon
                icon={faCloudArrowUp}
                color={Colors.primaryGreen}
                style={{ fontSize: "100px" }}
              />
              <DragAreaHeader>Drag & Drop to Upload File</DragAreaHeader>
              <span>OR</span>
              <Button button size="small" onClick={handleClick}>
                <input
                  type="file"
                  accept={
                    type === "image"
                      ? "image/*"
                      : type === "file"
                      ? fileFormats
                      : "video/*"
                  }
                  style={{ display: "none" }}
                  ref={hiddenFileInput}
                  onChange={handleChange}
                />
                Choose File
              </Button>
            </>
          )}
        </DragArea>
        {!!localUrl && (
          <Spacer>
            <Button
              button
              size="small"
              onClick={() => {
                setLocalUrl();
                setSelectedFile();
              }}
            >
              Delete File
            </Button>
          </Spacer>
        )}
      </>
    );
  }
);

const DragArea = styled.div(
  ({ active }) => `
border: 2px dashed ${Colors.primaryGreen};
height: 400px;
width: 300px;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

${active && `border: 2px solid ${Colors.primaryGreen};`}

& span {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  margin: 10px 0 15px 0;
}

& button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #fff;
  color: ${Colors.primaryGreen};
  border-radius: 5px;
  cursor: pointer;
}

& img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
}
`
);

const DragAreaHeader = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  align-self: center;
`;

const Spacer = styled.div`
  margin: 25px 0px;
`;
