import { Button } from "components/Button";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";
export const ApplicationPreviewFooter = React.memo(({ submit }) => {
  const size = useResponsiveLayout();
  return (
    <Container size={size}>
      <Wrapper size={size}>
        <Button button type="alt" size="large" fullWidth={!size.isSmUp} onClick={submit}>
          Submit application
        </Button>
      </Wrapper>
    </Container>
  );
});

const Container = styled.div(
  ({ size }) => `
background-color: #3D2854;
margin-top: 40px;
display: ${size.isMdUp ? "flex" : "inline-block"};
z-index: 100;
position: fixed;
bottom: 0;
width: 100%;
padding: 20px;
padding-left: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
padding-right: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
justify-content: flex-end;
text-align: center;
`);

const Wrapper = styled.div(({size}) => `
display: flex;
width: 100%;
margin: 0 auto;
max-width: var(--max-width);
justify-content: ${size.isSmUp ? 'flex-end' :'stretch'};
text-align: center;
`);