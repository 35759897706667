import { NavLink } from "react-router-dom";
import React from "react";
import styled from "styled-components";


export const HeaderContent = React.memo(({ isCandidate }) => (
  <Container className="flex justify-around space-x-24">
    {isCandidate ? (
      <>
        <NavLink
          to="/candidate/dashboard"
          className={({ isActive }) =>
            "hover:bg-secondary px-3 py-2 transition-all duration-150 font-extrabold rounded-lg " +
            (isActive ? "bg-secondary" : "")
          }
        >
          My Applications
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            "hover:bg-secondary px-3 py-2 transition-all duration-150 font-extrabold rounded-lg " +
            (isActive ? "bg-secondary" : "")
          }
          to="/candidate/manage-profile"
        >
          Manage my account
        </NavLink>
        {/* <NavLink
          className={({ isActive }) =>
            "hover:bg-secondary px-3 py-2 transition-all duration-150 font-extrabold rounded-lg " +
            (isActive ? "bg-secondary" : "")
          } to="/candidate/dashboard">My Favourites</NavLink> */}
        <NavLink
          className={({ isActive }) =>
            "hover:bg-secondary px-3 py-2 transition-all duration-150 font-extrabold rounded-lg " +
            (isActive ? "bg-secondary" : "")
          }
          to="/job-board"
        >
          See Jobs
        </NavLink>
      </>
    ) : (
      <>
        <NavLink
          className={({ isActive }) =>
            "hover:bg-secondary px-3 py-2 transition-all duration-150 font-extrabold rounded-lg " +
            (isActive ? "bg-secondary" : "")
          }
          to="/employer/dashboard"
        >
          My Jobs
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            "hover:bg-secondary px-3 py-2 transition-all duration-150 font-extrabold rounded-lg " +
            (isActive ? "bg-secondary" : "")
          }
          to="/employer/company/vacancy/create"
        >
          Create Role
        </NavLink>
        {/* <NavLink
          className={({ isActive }) =>
            "hover:bg-secondary px-3 py-2 transition-all duration-150 font-extrabold rounded-lg " +
            (isActive ? "bg-secondary" : "")
          } to="/">Buy Tokens</NavLink> */}
        <NavLink
          className={({ isActive }) =>
            "hover:bg-secondary px-3 py-2 transition-all duration-150 font-extrabold rounded-lg " +
            (isActive ? "bg-secondary" : "")
          }
          to="/employer/manage-profile"
        >
          My Company
        </NavLink>
      </>
    )}
  </Container>
));

const Container = styled.div`
  & a {
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
`;
