import { useField } from 'formik'
import React from 'react'

import { TextField } from './TextField'

export const FormikTextField = React.memo(
  React.forwardRef(({ name, onChange, ...rest }, ref) => {
    const [field, meta] = useField(name)

    return (
      <TextField
        onChange={(e) => {
          field.onChange(name)(e.target.value)
          onChange && onChange({ name, value: e.target.value })
        }}
        onBlur={field.onBlur(name)}
        value={field.value}
        error={meta.touched && meta.error}
        disabled={field.disabled}
        ref={ref}
        {...rest}
      />
    )
  }),
)
