import { DropDown } from "components/DropDown/DropDown";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import moment from "moment";
import React from "react";
import styled from "styled-components";

export const StepSeven = React.memo(({ children, handleChange, state }) => {
  const { isMdUp } = useResponsiveLayout();

  const setPreferredStartDate = (value) => {
    handleChange("preferredJobStartDate", toDateConverter(value));
  };

  const optionsStrings = [
    "Immediately",
    "In about 3 months",
    "This year",
    "When I am ready to make a change",
  ];

  const optionsDate = [
    moment().toISOString(),
    moment().add(3, "M").toISOString(),
    moment().add(12, "M").toISOString(),
    "",
  ];

  const toStringConverter = (option) => {
    if (option === undefined) {
      return;
    } else if (option === "") {
      return optionsStrings[3];
    } else {
      return optionsStrings[
        optionsDate.findIndex(
          (object) =>
            moment(object).year() === moment(option).year() &&
            moment(object).month() === moment(option).month()
        )
      ];
    }
  };

  const toDateConverter = (option) =>
    optionsDate[optionsStrings.indexOf(option)];

  return (
    <Container isMdUp={isMdUp}>
      <Wrapper isMdUp={isMdUp}>
        {children}
        <DropDown
          placeholder="choose a time frame +"
          bg
          icon
          active={toStringConverter(state.preferredJobStartDate)}
          options={optionsStrings}
          handleChange={(option) => setPreferredStartDate(option)}
        />
        <Heading isMdUp={isMdUp}>.</Heading>
      </Wrapper>
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  position: relative;
  & button {
    background: transparent !important;
    font-size: ${isMdUp ? "26px" : "16px"};
    font-weight: 700;
    padding: 0px !important;
    padding-left: 10px;
    border-radius: 0px !important;
    padding-left: 0px;
  }
  & h5 {
    font-size: 26px;
    font-weight: 700;
    color: white;
    margin: 0px 20px;
  }
`
);
const Wrapper = styled.div(
  ({ isMdUp }) => `
  display: ${isMdUp ? "flex" : "block"};
`
);

const Heading = styled.h4(
  ({ isMdUp }) => `
  text-transform: uppercase;
  color: #fff;
  margin: 0px;
  margin-left: ${isMdUp ? "18px" : "0px"};
  margin-top: 5px;
  font-size: 26px;
  font-weight: 700;
`
);
