import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

export const StepOne = React.memo(
  ({ children, handleNext, handleChange, state }) => {
    const { isMdUp } = useResponsiveLayout();

    const onKeyUp = (e) => {
      if (e.keyCode === 13) {
        handleNext();
      }
    };

    const onchange = (e) => {
      handleChange("name", e.target.value);
    };

    useEffect(() => {
      ref && ref.current.focus();
    }, [state.name]);

    const ref = useRef(null);

    return (
      <Container isMdUp={isMdUp} value={state.name}>
        {children && children}
        <input
          ref={ref}
          placeholder="Type first name"
          value={state.name}
          onKeyUp={onKeyUp}
          onChange={onchange}
        />
        <Heading isMdUp={isMdUp}>.</Heading>
      </Container>
    );
  }
);

const Container = styled.div(
  ({ isMdUp, value }) => `
  display: ${isMdUp ? "flex" : "block"};
  & input {
    background-color: transparent;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
    color: ${value ? "#6BFF00" : "#b5b5b6"};
    margin-left: ${isMdUp ? "15" : "0px"};
    outline: none;
    border: none;
    max-width: 240px;
    border-bottom: ${value ? "5px solid #6BFF00" : "5px solid #9e9c9f"};
    padding-bottom: 5px;
  }
`
);
const Heading = styled.h4(
  ({ isMdUp }) => `
  text-transform: uppercase;
  color: #fff;
  margin: 0px;
  margin-left: ${isMdUp ? "18px" : "0px"};
  margin-top: 5px;
  font-size: 26px;
  font-weight: 700;
`
);
