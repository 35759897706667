import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import { searchAPI } from './searchAPI'
import { searchReducer } from './reducers/searchReducer'
import { setupListeners } from '@reduxjs/toolkit/query'
import { userAPI } from './userAPI'
import { vacancyReducer } from './reducers/vacancyReducer'
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
  [userAPI.reducerPath]: userAPI.reducer,
  vacancy: vacancyReducer,
  search: searchReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['userAPI', 'search'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(userAPI.middleware)
      .concat(searchAPI.middleware),
})

export const persistor = persistStore(store)

setupListeners(store.dispatch)
