import { Button } from "components/Button";
import { Colors } from "styles/Colors";
import {
  faCamera,
  faPlusCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Icon } from "components/Icon";
import { IndexedDB } from 'utils/indexedDb'
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import {
  useGetUserQuery,
  useLazyGetCandidateByIdQuery,
  useUpdateCandidateMutation,
} from "store/userAPI";

export const MyVideos = React.memo(({ data }) => {
  const navigate = useNavigate();
  const {
    data: { user: { candidate = {} } = {} } = {},
    error,
    isSuccess,
  } = useGetUserQuery({});
  const { isMdUp } = useResponsiveLayout();
  const db = useRef(new IndexedDB())

  const [localVideos, setLocalVideos] = useState([])


  const btnActions = (index) => (
    <ActionWrapper isMdUp={isMdUp} className=" ">
      <Button
        button
        color="transparent"
        onClick={() => deleteProfileVideo(index)}
      >
        <Icon icon={faXmark} color={Colors.primaryGreen} size={22}></Icon>
        Remove
      </Button>
      {/* <Button
        button
        color="transparent"
        onClick={() => {
          navigate("/candidate/uploadvideo");
        }}
      >
        <Icon icon={faPencil} color={Colors.primaryGreen} size={16}></Icon>
        Edit
      </Button> */}
      {/* <Button button color="transparent">
        <Icon icon={faEye} color={Colors.primaryGreen} size={16}></Icon>
        View
      </Button> */}
    </ActionWrapper>
  );

  const [updateCandidate] = useUpdateCandidateMutation();

  const deleteProfileVideo = async (index) => {
    console.log("delete video", index);
    let arr = Object.assign([], candidate.videos);
    arr.splice(index, 1);

    console.log("candidate-videos", arr);
    let response = await updateCandidate({
      ...candidate,
      videos: arr,
    });
  };

  /**
   * Check for locally cached videos in IndexedDB
   * Videos are stored as ArrayBuffers due to an issue with Safari storing Blobs in IndexedDB
   * https://stackoverflow.com/questions/68386273/error-loading-blob-to-img-in-safari-webkitblobresource-error-1
   */
  useEffect(() => {
    const work = async () => {
      let _videos = {}
      for (let i = 0; i < data.videos.length; i++) {

        // Sometimes the DB may have "null" - handle it.
        if (!data.videos[i]) continue

        // Keys don't have the file extension
        const key = data.videos[i].split('.')[0]

        // Get all the blobs from IndexedDB
        const blobs = await db.current.get(key)
        if (blobs) {
          // Combine the individual blobs
          const combined = new Blob(blobs, { type: blobs[0].type })
          _videos[data.videos[i]] = { url: URL.createObjectURL(combined), type: combined.type }
        } else {
          // No local store
          _videos[data.videos[i]] = data.videos[i]
        }
      }
      setLocalVideos(_videos)
    }
    work().finally()
  }, [db, data])

  /** 
   * Clear local video cache on leave
   */
  useEffect(() => {
    const ref = db.current
    return () => {
      const work = async () => {
        await ref.clear()
      }
      work().finally()
    }
  }, []);


  const addNew = () => (
    <AddNew onClick={() => {
      navigate("/candidate/uploadvideo");
    }}>
      <header>
        <div className="icon"><Icon icon={faPlusCircle} style={{ fontSize: '24px' }} /></div>
        <p className="title">Add new video</p>
      </header>
      <hr />
      <p>Keep it short and snappy - between 60-90 seconds</p>
      <p>
        Tell us about yourself:
      </p>
      <ul style={{ listStyle: 'circle' }}>
        <li>Your personality and interests </li>
        <li>Your experience and skills </li>
        <li>What motivates you</li>
      </ul></AddNew>
  )


  if (!data.videos || data.videos.length === 0) {
    return (
      <div className="container">
        <div className="flex flex-col sm:flex-row  justify-center bg-cover bg-no-repeat rounded-xl  ">
          <div className="order-2 sm:order-1 flex flex-col text-center  text-4xl text-wrap max-w-5xl rounded-lg ">
            <p className="text-white">
              You haven’t recorded any profile videos yet. Add a video now.
            </p>
            {addNew()}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-full grid gap-[16px]" style={{ gridTemplateColumns: `repeat(auto-fill, minmax(340px, 1fr))` }}>
      {data.videos
        ? data.videos.map((item, index) => (
          <VideoCardJobBoard
            key={index}
            videoData={localVideos[item]}
            title={"VIDEO #" + (Number(index) + 1)}
            width={'100%'}
            height={'auto'}
            action={btnActions(index)}
          />
        ))
        : null}

      {data.videos.length < 3 && (
        addNew()
      )}
    </div>
  );
});

const ActionWrapper = styled.div(
  ({ isMdUp }) => `
    margin-top: 10px;
    margin-bottom: ${!isMdUp ? "10px" : "0px"};
  `
);


const AddNew = styled.button`
  display: grid;
  gap: 16px;
  align-items: center;
  width: 100%;
  height: fit-content;
  min-height: 240px;
  padding: 24px;
  background: rgba(8, 9, 9, 0.66);
  border-radius: 16px;
  color: white;
  text-align: left;
  font-size: 16px;
  transition: background 200ms ease-out;
  cursor: pointer;
  margin-top: 20px;
  
  &:hover {
    background: #9400FF;
    hr {
      width: 100%;
      border-color: rgba(255, 255, 255, 1);
    }
  }
  
  header {
    display: grid;
    gap: 16px;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-self: center;
    width: fit-content;
    div {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }
  
  
  hr {
    width: 50%;
    margin: 0 auto!important;
    transition: all 200ms ease-out;
    border-color: rgba(255, 255, 255, 0.1);
  }

  p {
    margin: 0!important; /* I hate important flags */
  }

  ul {
    list-display-style: circle;
    font-size: inherit;
    padding-left: 16px;
  }

  li {
    font-size: inherit;
  }
`