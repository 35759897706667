import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

export const Info = React.memo(({ img, info, mt, fb, fs }) => {
  const { isMdUp } = useResponsiveLayout();
  return (
    <Container isMdUp={isMdUp} mt={mt} fb={fb} fs={fs}>
      <AvatarStyle src={img || "/dummy-profile-picture.png"} />
      <h2>{info}</h2>
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp, mt, fb, fs }) => `
  display: ${isMdUp ? "flex" : "block"};
  align-items: flex-start;
  flex-basis:${fb ? fb : "36%"};
  margin-top: ${mt ? mt : "0px"};
  overflow: hidden;
 }

& h2{
    font-size: ${fs || '48px'};
    font-weight: 800;
    color: white;
    margin: auto;
    text-transform: uppercase;
    word-break: break-word;
}
`
);

const AvatarStyle = styled.img(
  () => `
border: 4px solid transparent;
border-radius: 50%;
height: 73px;
width: 73px;
text-align: center;
margin-right: 15px;
`
);
