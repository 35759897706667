import { Logtail } from "@logtail/browser";
import { useAuthState } from "Context";
import { useMemo } from "react";

export const useLogger = () => {
    const { userDetails } = useAuthState();

    return useMemo(() => {
        // Intercept the log and add user details to the content
        async function enrichLogs(log) {
            return {
                ...log,
                userId: userDetails.id,
                email: userDetails.email,
                browser: {
                    userAgent: navigator.userAgent,
                    userAgentData: JSON.stringify(navigator.userAgentData),
                },
            };
        }

        // Also console log
        async function consoleLogs(log) {
            if (process.env.REACT_APP_ENV !== "production") {
                console.log(log);
            }
            return log;
        }
        const logger = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN);
        logger.use(enrichLogs);
        logger.use(consoleLogs);
        return { logger };
    }, [userDetails.email, userDetails.id]);
};
