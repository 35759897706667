import { Breakpoints } from 'styles/responsive'
import { useWindowDimensions } from 'components/WindowDimensionsProvider'

export const useResponsiveLayout = () => {
  const { width } = useWindowDimensions()

  const isXs = width < Breakpoints.sm - 1
  const isSm = width > Breakpoints.sm && width < Breakpoints.md - 1
  const isMd = width > Breakpoints.md && width < Breakpoints.lg - 1
  const isLg = width > Breakpoints.lg && width < Breakpoints.xl - 1
  const isXl = width > Breakpoints.xl

  let currentSize = null
  if (isXl) {
    currentSize = 'xl'
  } else if (isLg) {
    currentSize = 'lg'
  } else if (isMd) {
    currentSize = 'md'
  } else if (isSm) {
    currentSize = 'sm'
  } else {
    currentSize = 'xs'
  }

  return {
    isXs,
    isSm,
    isSmUp: width >= Breakpoints.sm,
    isMd,
    isMdUp: width >= Breakpoints.md,
    isLg,
    isLgUp: width >= Breakpoints.lg,
    isXl,
    currentSize,
    width
  }
}
