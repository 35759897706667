const logoutAction = () => {
  localStorage.clear();
  setTimeout(() => {
    document.location.reload();
  }, 500);
}

export const candidateMenuItems =
  [
    {
      text: "Dashboard",
      to: "/candidate/dashboard",
    },
    {
      text: "Manage My Account",
      to: "/candidate/manage-profile",
    },
    {
      text: "Log Out",
      action: logoutAction,
    },
  ]

export const employerMenuItems = [

  {
    text: "Dashboard",
    to: "/employer/dashboard",
  },
  {
    text: "Manage My Account",
    to: "/employer/manage-profile",
  },
  {
    text: "Log Out",
    action: logoutAction
  },
]
export const candidateMobileItems = [
  {
    text: "See Jobs",
    to: "/job-board",
  },
  { text: "My Applications", to: "/candidate/dashboard" },
  {
    text: "My Videos",
    to: "/candidate/manage-profile",
  },
  // { text: "My Favourites", to: "/candidate/dashboard" },
  {
    text: "Manage My Account",
    to: "/candidate/manage-profile",
  },
  {
    text: "Log Out",
    action: logoutAction
  },
]

export const employerMobileItems = [
  { text: "My Jobs", to: "/employer/dashboard" },
  {
    text: "Create Role",
    to: "/employer/company/vacancy/create/",
  },
  // { text: "Buy Tokens", to: "/" },
  { text: "My Company", to: "/employer/manage-profile" },
  {
    text: "Manage My Account",
    to: "/employer/manage-profile",
  },
  {
    text: "Log Out",
    action: logoutAction
  },
]

