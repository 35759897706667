import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { formatStatus, getStatusCssClasses } from "utils/functions";
import { Icon } from "components/Icon";
import { Rating } from "components/Ratings";
import React from "react";
import styled from "styled-components";

export const UserProfile = React.memo(
  ({
    userImage,
    rating,
    name,
    action,
    para,
    active,
    selectedUser,
    viewProfile,
    isMdUp,
    status,
  }) => {

    const statusClasses = getStatusCssClasses(status)
    const formattedStatus = formatStatus(status)

    return (
      <UserProfileRoot para={para} active={selectedUser}>
        <UserContent para={para}>
          <img
            src={userImage || "/dummy-profile-picture.png"}
            alt="Avatar"
            style={{ backgroundColor: "white", objectFit: 'cover' }}
            onClick={viewProfile}
          />
          {active ? <Icon icon={faCircle}></Icon> : null}

          <UserProfileBody>
            {name ? <Name onClick={viewProfile}>{name}</Name> : null}
            {status ? <p className={`px-4 py-2 w-fit rounded-full text-xl ${statusClasses}`}>{formattedStatus}</p> : null}
            {para ? <p>{para}</p> : null}
            {rating ? <Rating rating={rating}></Rating> : null}
          </UserProfileBody>
        </UserContent>
        {/* <Button
          button
          type="alt"
          size={isMdUp ? "large" : "small"}
          onClick={viewProfile}
        >
          View Profile
        </Button> */}

        {action && action}
      </UserProfileRoot>
    );
  }
);

const UserProfileRoot = styled.div(
  ({ active }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  & img {
    border-radius: 50%;
  }
  & p{
    margin: 0px;
    color: black;
  }
  & h4 {
    color: ${active ? "black" : "white"};
    font-weight: 700;
    font-size: 18px;
    margin: 0px;
    margin-bottom: 4px;
  }
  & .rating {
    margin-left: 22px;
  }
`
);

const UserProfileBody = styled.div`
  margin-left: 22px;
  padding-right: 3px;
`;
const UserContent = styled.div(
  ({ para }) => `
  position: relative;
  display: flex;
  align-items: ${para ? "flex-start" : "center"};
  & svg{

    width: 10px;
    height: 10px;
    position: absolute;
    left: 50px;
    color: #6BFF00;
    top: 3px;
}
  & img{
    cursor: pointer;
    width: 60px;
    height: 60px;
  }
  
}
`
);

const Name = styled.h4`
  cursor: pointer;
`;
