import { DropDown } from "components/DropDown/DropDown";
import { TagComponent } from "components/TagComponent";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React from "react";
import styled from "styled-components";

export const StepFour = React.memo(({ children, state, setArrayValue }) => {
  const { isMdUp } = useResponsiveLayout();

  const industry = ["Real Estate", "Any", "Other +"];

  const setIndustries = (value, index) => {
    if (value === "Any") {
      if (index === 0) {
        setArrayValue("industries", "", 1);
      } else {
        setArrayValue("industries", "", 0);
      }
    }
    setArrayValue("industries", value, index);
  };

  const industries = [
    {
      name: "Technology",
      job: "12",
    },
    {
      name: "Media",
      job: "21",
    },
    {
      name: "Financial Services",
      job: "1",
    },
  ];

  return (
    <Container isMdUp={isMdUp}>
      <Wrapper isMdUp={isMdUp}>
        {children}
        {state.industries[1] !== "Any" ? (
          <DropDown
            bg
            icon
            placeholder="choose an industry +"
            active={state.industries[0]}
            options={industry}
            handleChange={(option) => setIndustries(option, 0)}
          />
        ) : null}
        {!(state.industries[0] === "Any" || state.industries[1] === "Any") && (
          <h5>AND</h5>
        )}
      </Wrapper>
      <Wrapper1 isMdUp={isMdUp}>
        {state.industries[0] !== "Any" ? (
          <DropDown
            bg
            icon
            placeholder="choose an industry +"
            active={state.industries[1]}
            options={industry}
            handleChange={(option) => setIndustries(option, 1)}
          />
        ) : null}
      </Wrapper1>
      {(state.industries[0] === "Other +" && state.industries[1] !== "Any") ||
      (state.industries[1] === "Other +" && state.industries[0] !== "Any") ? (
        <Tag>
          {state.industries[0] === "Other +" ? (
            <TagWrapper>
              {industries.map((dep) => (
                <TagComponent onClick={() => setIndustries(dep.name, 0)}>
                  {dep.name}
                  {dep.job !== "" ? <span>{`(${dep.job})`}</span> : null}
                </TagComponent>
              ))}
            </TagWrapper>
          ) : (
            <>
              {state.industries[1] === "Other +" ? (
                <TagWrapper>
                  {industries.map((dep) => (
                    <TagComponent onClick={() => setIndustries(dep.name, 1)}>
                      {dep.name}
                      {dep.job !== "" ? <span>{`(${dep.job})`}</span> : null}
                    </TagComponent>
                  ))}
                </TagWrapper>
              ) : null}
            </>
          )}
        </Tag>
      ) : null}
    </Container>
  );
});

const Container = styled.div(
  ({ isMdUp }) => `
  position: relative;
  & button {
    background: transparent !important;
    font-size: ${isMdUp ? "26px" : "16px"};
    font-weight: 700;
    padding: 0px !important;
    padding-left: 10px;
    border-radius: 0px !important;
    padding-left: 0px;
  }
  & h5 {
    font-size: 26px;
    font-weight: 700;
    margin-top: 5px !important;
    color: white;
    margin: 0px 20px;
    margin-left: ${isMdUp ? "20px" : "0px"};
  }
`
);
const Wrapper = styled.div(
  ({ isMdUp }) => `
  display: ${isMdUp ? "flex" : "block"};
`
);
const Wrapper1 = styled.div(
  ({ isMdUp }) => `
  display: ${isMdUp ? "flex" : "block"};
  align-items: center;
  width: 100%;
`
);
const Tag = styled.div`
  width: 90%;
`;
const TagWrapper = styled.div`
  margin-top: 200px;
`;
