import { AuthGuard } from "components/AuthGuard";
import { Button } from "components/Button";
import {
  faChevronLeft,
  faChevronRight,
  faGlasses,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart
} from "@fortawesome/free-regular-svg-icons";
import { formatStatus, getStatusCssClasses } from "utils/functions";
import { Icon } from "components/Icon";
import { LikeButton } from "components/LikeButton";
import { LoadingComponent } from 'components/LoadingComponent'
import { NewSectionHeading } from "components/Headings/NewSectionHeading";
import { ProfileContent } from "./LocalComponents/ProfileContent";
import { ProfileFooter } from "./LocalComponents/ProfileFooter";
import { ProfileInfo } from "./LocalComponents/ProfileInfo";
import {
  useAddApplicationLikeMutation,
  useGetApplicationByIDQuery,
  useGetJobApplicationsByVacancyIdQuery,
  useLazyAcceptApplicationQuery,
  useLazyGetApplicationLikesByApplicationIdQuery,
  useLazyGetApplicationViewsByApplicationIdQuery,
  useLazyGetAuditLogsByTypeAndIdQuery,
  useLazyGetCandidateByIdQuery,
  useLazyRejectApplicationQuery,
  useLogApplicationViewMutation,
  useRemoveApplicationLikeMutation,
  useReviewApplicationMutation,
  useSetApplicationViewedMutation,
} from "store/userAPI";
import { useAuthState } from 'Context'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const CandidateProfilePage = React.memo(() => {
  const auth = useAuthState()
  const size = useResponsiveLayout();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const patharray = location.pathname.split("/");
  const applicationId = patharray[patharray.length - 1];

  const [candidate, setCandidate] = useState(null);
  const [applicationViews, setApplicationViews] = useState([]);
  const [auditLogs, setAuditLogs] = useState([]);
  const [doesNotExist, setDoesNotExist] = useState(false);
  const [previousApplication, setPreviousApplication] = useState()
  const [previousBucket, setPreviousBucket] = useState({ name: "none", label: "none" })
  const [nextApplication, setNextApplication] = useState()
  const [nextBucket, setNextBucket] = useState({ name: "none", label: "none" })
  const [currentBucketPosition, setCurrentBucketPosition] = useState(1)
  const [bucketTotal, setBucketTotal] = useState(1)
  const [currentIndex, setCurrentIndex] = useState(1)
  const [totalApplications, setTotalApplications] = useState(1)
  const [applicationLikes, setApplicationLikes] = useState([]);
  const [isLikeLoading, setIsLikeLoading] = useState(false)
  const [isLiked, setIsLiked] = useState(false)

  const [acceptApplication] = useLazyAcceptApplicationQuery();
  const [rejectApplication] = useLazyRejectApplicationQuery();
  const [reviewApplication] = useReviewApplicationMutation();
  const [setApplicationViewed] = useSetApplicationViewedMutation();
  const [logApplicationView] = useLogApplicationViewMutation();
  const [addApplicationLike] = useAddApplicationLikeMutation()
  const [removeApplicationLike] = useRemoveApplicationLikeMutation()

  const { data: application, refetch, isLoading: isApplicationLoading } = useGetApplicationByIDQuery({
    id: applicationId,
  });

  const [getCandidate] = useLazyGetCandidateByIdQuery();
  const [getApplicationViews] = useLazyGetApplicationViewsByApplicationIdQuery();
  const [getAuditLogs] = useLazyGetAuditLogsByTypeAndIdQuery();
  const [getApplicationLikes] = useLazyGetApplicationLikesByApplicationIdQuery();

  // Enables next/previous navigation - we need app applications to the parent vacancy
  // Todo maybe later - put in a persisted store so the state can be shared across both pages?
  const {
    data: applications,
  } = useGetJobApplicationsByVacancyIdQuery({
    id: application?.vacancyId,
  })

  // Ref to a timer to avoid regenerating on rerenders
  const checkViewedTimer = useRef()

  const refreshLikes = useCallback(async () => {
    try {
      const allApplicationLikes = await getApplicationLikes({
        id: application.id,
      }).unwrap();
      setApplicationLikes(allApplicationLikes);

      // Have we liked this application?
      if (allApplicationLikes.filter(l => l.userId === auth.userDetails.id).length > 0) {
        setIsLiked(true)
      } else {
        setIsLiked(false)
      }
    } catch (e) {
      console.log(`Failed to get Application Likes`, e);
      setApplicationLikes([]);
    }
  }, [getApplicationLikes, application, auth])


  const onToggleLike = async (likeId) => {
    setIsLikeLoading(true)
    if (isLiked) {
      console.log(`Removing like ${likeId}`)
      await removeApplicationLike({ id: likeId })
    } else {
      console.log(`Adding like for ${applicationId}`)
      await addApplicationLike({ applicationId })
    }
    await refreshLikes()
    setTimeout(() => {
      setIsLikeLoading(false)
    }, 500);
  }

  useEffect(() => {
    const getCandidates = async () => {
      if (!application?.candidateId) return
      try {
        const promise = getCandidate(
          { id: application?.candidateId },
          true
        ).unwrap();
        const candidate = await promise;
        setCandidate({ ...candidate, application });
      } catch (e) {
        console.log(e);
      }
    };

    getCandidates();
  }, [getCandidate, application]);

  /** 
   * Get views on application init
   */
  useEffect(() => {
    const work = async () => {
      if (!application) return
      console.log(`Getting views for ${application.id}`)
      try {
        const views = await getApplicationViews(
          { id: application?.id }
        ).unwrap();
        setApplicationViews(views);
      } catch (e) {
        console.log(e);
      }
    };

    work().finally();
  }, [application, getApplicationViews]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  /** 
  * Get audit log on application init
  */
  useEffect(() => {
    const work = async () => {
      if (!application) return
      console.log(`Getting audit logs for ${application.id}`)
      try {
        const auditLogs = await getAuditLogs(
          { type: 'application', id: application?.id }
        ).unwrap();
        setAuditLogs(auditLogs);
      } catch (e) {
        console.log(e);
      }
    };

    work().finally();
  }, [application, getAuditLogs]);

  /** 
  * Get likes  on application init
  */
  useEffect(() => {
    const work = async () => {
      if (!application) return
      await refreshLikes()
    };

    work().finally();
  }, [application, refreshLikes]);

  /**
   * Get applications for the related vacancy id for previous/next candidate navigation
   */
  useEffect(() => {
    // If we're a candidate, do nothing
    if (auth.userDetails.userType.toLowerCase() === "candidate") return

    // If there's 0 or one application, stop
    if (!applications || applications.length === 1) return

    const buckets = [
      { name: 'candidates', label: 'candidates', status: 'APPLIED' },
      { name: 'inReview', label: 'in-review', status: 'IN-REVIEW' },
      { name: 'shortlisted', label: 'shortlisted', status: 'ACCEPTED' },
      { name: 'rejected', label: 'rejected', status: 'REJECTED' },
      { name: 'inProgress', label: 'in-progress', status: 'INCOMPLETED' }
    ]

    /**
     * Helper to get get the name of the target bucket based on the status of the application passed in
     * This exists because DB entries to not match what we actually want to display
     * @param application
     * @returns {string}
     */
    const getBucketFromStatus = (application) => {
      const targetBucket = buckets.find(t => t.status.toLowerCase() === application.status.toLowerCase())
      return targetBucket ? targetBucket.name : "unknown"
    }

    /**
     * Helper to calculate and set state for the "previous candidate" button
     * */
    const calculatePrevious = (tabs, activeTabIndex, sameStatusApplications, currentPositionInBucket) => {
      // If we're not at index 0, this is a simple back operation
      if (currentPositionInBucket > 0) {
        setPreviousApplication(sameStatusApplications[currentPositionInBucket - 1])
        setPreviousBucket(tabs[activeTabIndex])
        return
      }

      // We're at the start of the bucket, we need to go "back" to the next bucket with an application, and start at the END of that new bucket.
      // If we're in the first tab, we need to loop to the last
      // We need to keep doing this until we find a bucket with applications 
      let targetApplications
      let currentTabIndex = activeTabIndex
      for (let i = 0; i < tabs.length; i++) {
        const newIndex = currentTabIndex <= 0 ? tabs.length - 1 : currentTabIndex - 1
        targetApplications = applications.filter(a => getBucketFromStatus(a) === tabs[newIndex].name)
        if (targetApplications.length > 0) {
          setPreviousApplication(targetApplications[targetApplications.length - 1])
          setPreviousBucket(tabs[newIndex])
          break
        };
        currentTabIndex = newIndex
      }
    }

    /**
     * Helper to calculate and set state for the "next candidate" button
     * */
    const calculateNext = (tabs, activeTabIndex, sameStatusApplications, currentPositionInBucket) => {

      // If we're not at `length - 1`, this is a simple page forward
      if (currentPositionInBucket !== sameStatusApplications.length - 1) {
        setNextApplication(sameStatusApplications[currentPositionInBucket + 1])
        setNextBucket(tabs[activeTabIndex])
        return
      }

      // We're at the end of the bucket, we need to go "forward" a tab and start at the START of that new bucket.
      // If we're in the last tab, we need to loop to the first
      // We need to keep doing this until we find a bucket with applications
      let targetApplications
      let currentTabIndex = activeTabIndex
      for (let i = 0; i < tabs.length; i++) {
        const newIndex = currentTabIndex === tabs.length - 1 ? 0 : currentTabIndex + 1
        targetApplications = applications.filter(a => getBucketFromStatus(a) === tabs[newIndex].name)
        if (targetApplications.length > 0) {
          setNextApplication(targetApplications[0])
          setNextBucket(tabs[newIndex])
          break
        };
        currentTabIndex = newIndex
      }
    }

    // Where are we now?
    const currentBucket = getBucketFromStatus(application)
    const activeTabIndex = buckets.findIndex(t => t.name === currentBucket)

    // Get applications in the same status as us and use it as a bucket
    const sameStatusApplications = applications.filter(a => a.status.toLowerCase() === application.status.toLowerCase())
    const currentPositionInBucket = sameStatusApplications.findIndex(a => a.id === application.id)

    // We need to get all applications in their buckets and flatten it out
    // This will allow us to get our current position across the entire set of applications across all buckets
    const sortedApplications = buckets.reduce((a, c) => a = [...a, ...applications.filter(a => a.status === c.status)], [])

    // The new `sortedApplications` so we paginate properly
    // Get position and count of ALL applications for tracking in the UI
    setCurrentIndex(1 + sortedApplications.findIndex(a => a.id === application.id))
    setTotalApplications(sortedApplications.length)

    // Set the totals for the current buckets
    setCurrentBucketPosition(currentPositionInBucket + 1)
    setBucketTotal(sameStatusApplications.length)

    // Run the helpers to calculate previous/next applications and bucket labels
    calculatePrevious(buckets, activeTabIndex, sameStatusApplications, currentPositionInBucket)
    calculateNext(buckets, activeTabIndex, sameStatusApplications, currentPositionInBucket)
  }, [application, applications, auth.userDetails.userType])


  /** 
   * Handle marking as viewed, logging a view
   * Operates on a timeout to avoid marking things as viewed by mistake
   * Does not action if the current user is not a member of the company
   */
  useEffect(() => {
    clearTimeout(checkViewedTimer.current);
    const work = async () => {
      if (!application && !isApplicationLoading) {
        setDoesNotExist(true)
      }
      else if (application && !isApplicationLoading) {
        // We might be an admin user.
        // Check if we're an admin / member of this company. If not, do nothing
        if (auth.membership?.companyId !== application.companyId) {
          return;
        }

        // Wait 5 seconds before we do anything at all
        checkViewedTimer.current = setTimeout(async () => {
          // Log a view
          await logApplicationView({ id: application.id })

          // Check if this application has already been viewed, and mark it accordingly
          if (!application.isViewed) {
            await setApplicationViewed({
              id: application.id,
            })
          }

          refetch()

          const views = await getApplicationViews(
            { id: application?.id }
          ).unwrap();
          setApplicationViews(views);

          const auditLogs = await getAuditLogs(
            { type: 'application', id: application?.id }
          ).unwrap();
          setAuditLogs(auditLogs);

        }, 5000);
      }
    }
    work().finally()

    return () => {
      clearTimeout(checkViewedTimer.current);
    }
  }, [application, auth.membership, getApplicationViews, getAuditLogs, isApplicationLoading, logApplicationView, refetch, setApplicationViewed])


  if (doesNotExist) {
    return (
      <div className="flex flex-col text-center justify-center text-[2.5rem] pt-32">
        <h1 className="text-gray-300">This application does not exist</h1>
      </div>
    );
  }

  if (!candidate) {
    return (
      <LoadingComponent />
    );
  }

  const rejectCandadidateApplication = async () => {
    try {
      console.log("rejecting");
      let response = await rejectApplication({
        id: candidate?.application?.id,
      });
      if (!response.data) return;
      if (response.error) return alert(response.message);
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const acceptCandadidateApplication = async () => {
    console.log("accept");
    try {
      let response = await acceptApplication({
        id: candidate?.application?.id,
      });
      console.log("response", response);
      if (!response.data) return;
      if (response.error) return alert(response.message);
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const reviewCandidateApplication = async () => {
    try {
      let response = await reviewApplication({
        id: candidate?.application?.id,
      });
      if (!response.data) return;
      if (response.error) return alert(response.message);
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const goToPrevious = async () => {
    if (!previousApplication || !previousBucket) return
    navigate(`/employer/dashboard/vacancy/candidate/${previousApplication.id}?bucket=${previousBucket.name}`)
  }

  const goToNext = async () => {
    if (!nextApplication) return
    navigate(`/employer/dashboard/vacancy/candidate/${nextApplication.id}?bucket=${nextBucket.name}`)
  }

  const actions = () => (
    <>
      <Button
        button
        leftIcon={faGlasses}
        type="alt"
        color="black"
        size="large"
        onClick={reviewCandidateApplication}
      >
        {size.isMdUp
          ? application?.status === "IN-REVIEW"
            ? "In review"
            : "Review"
          : null}
      </Button>
      <Button
        button
        leftIcon={faXmark}
        type="alt"
        color="black"
        size="large"
        onClick={rejectCandadidateApplication}
      >
        {size.isMdUp
          ? application?.status === "REJECTED"
            ? "Rejected"
            : "Reject"
          : null}
      </Button>
      {/* <Button
        button
        color="black"
        icon={faFileLines}
        type="alt"
        size="large"
      /> */}
      <Button
        button
        leftIcon={faHeart}
        type="alt"
        color="black"
        size="large"
        onClick={acceptCandadidateApplication}
      >
        {size.isMdUp
          ? application?.status === "ACCEPTED"
            ? "Shortlisted"
            : "Shortlist"
          : null}
      </Button>
      {/* <Button
        button
        type="alt"
        color="black"
        size="large"
        icon={faShareNodes}
      ></Button>
      <Button button size="large" icon={faCalendarMinus} />
      <ButtonOutlined button size="large" icon={faVideo} /> */}
    </>
  );

  const statusClasses = getStatusCssClasses(application.status)
  const formattedStatus = formatStatus(application.status)

  return (
    <AuthGuard criteria={auth.userDetails.userType === 'CANDIDATE' ?
      [
        // User is an candidate, check they're THE candidate
        { value: application.candidateId, check: 'userDetails.id' }
      ]
      : [
        // User is an employer, check company membership matches this application
        { value: application.companyId, check: 'company.id' },
      ]
    }>
      <Container size={size} isCandidate={auth.userDetails.userType.toLowerCase() === 'candidate'}>
        <NewSectionHeading
          companyName={auth.userDetails.userType !== 'CANDIDATE'}
          title={<div className="grid items-center">
            <span>{candidate.name}</span>
            <div className="flex gap-4">
              <LikeButton active={isLiked} disabled={isLikeLoading} likes={applicationLikes} onToggle={(likeId) => onToggleLike && onToggleLike(likeId)}></LikeButton>
              <span className={`flex px-4 py-2 text-xl rounded-lg items-center justify-self-start gap-2 ${statusClasses}`}>{formattedStatus}{auth.userDetails.userType.toLowerCase() !== "candidate" && <span className="text-sm"> ({currentBucketPosition} of {bucketTotal})</span>}</span>
            </div>

          </div>}
          actions={auth.userDetails.userType !== 'CANDIDATE' && actions()}
          style={{ gridArea: 'header' }}
        />
        <hr style={{ gridArea: 'hr', margin: 0 }} />

        {auth.userDetails.userType.toLowerCase() !== "candidate" &&
          <div className="grid gap-4 [grid-area:pagination] grid-cols-3 space-between items-center w-full">
            <button onClick={goToPrevious} className="grid grid-cols-[auto_1fr] gap-4 items-center justify-self-start group disabled:pointer-events-none" disabled={!previousApplication}>
              <Icon className="text-primary transition-all ease-out group-hover:-translate-x-2" icon={faChevronLeft} />
              <span className="grid justify-items-start">
                <span className="text-2xl text-white group-hover:text-primary">Previous</span>
                <span className="text-gray-400 text-xl font-light capitalize group-hover:text-white">{previousBucket.label}</span>
              </span>
            </button>
            <span className="justify-self-center text-white text-2xl">{currentIndex} of {totalApplications} total applicants</span>
            <button onClick={goToNext} className="grid grid-cols-[1fr_auto] gap-4 items-center justify-self-end group disabled:pointer-events-none" disabled={!nextApplication}>
              <span className="grid justify-items-end">
                <span className="text-2xl text-white group-hover:text-primary">Next</span>
                <span className="text-gray-400 text-xl font-light capitalize group-hover:text-white">{nextBucket.label}</span>
              </span>
              <Icon className="text-primary transition-all ease-out group-hover:translate-x-2" icon={faChevronRight} />
            </button>
          </div>}

        <ProfileInfo
          title={candidate.name}
          img={candidate?.candidate?.avatar}
          tags={candidate?.candidate?.skills}
          data={candidate}
          views={applicationViews}
          auditLogs={auditLogs}
        />
        <ProfileContent data={candidate} isCandidate={auth.userDetails.userType === 'CANDIDATE'} />

      </Container>
      <ProfileFooter
        back={
          () => auth.userDetails.userType === 'CANDIDATE'
            ? navigate('/candidate/dashboard')
            : navigate(`/employer/dashboard/vacancy/${application.vacancyId}?bucket=${searchParams.get("bucket") ?? 'candidates'}&y=${searchParams.get("y") ?? 0}`)
        }
        isCandidate={auth.userDetails.userType === 'CANDIDATE'} />
    </AuthGuard >
  );
});

const Container = styled.div(
  ({ size, isCandidate }) => `
      max-width: min(var(--max-width), 100vw);
      margin: 0 auto;
      padding-left: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
      padding-right: ${size.isXl ? '122px' : size.isMdUp ? '60px' : '20px'};
      display: grid;
      gap: ${size.isLgUp ? '24px 64px' : '32px'};
      grid-template-columns: ${size.isLgUp ? 'minmax(360px, 1fr) 3fr' : '1fr'};
      grid-template-areas: ${isCandidate ?
      size.isLgUp
        ? `'header header'
              'hr hr'
              'info content'`
        : `'header'
              'hr'
              'video'
              'content'
              'info'` :

      size.isLgUp
        ? `'header header'
          'pagination pagination'
          'hr hr'
          'info content'`
        : `'header'
          'pagination'
          'hr'
          'video'
          'content'
          'info'`
    };
      padding-bottom: 120px;
      & hr {
        border: 2px solid #494A4A;
      margin-top: 30px;
}`
);