import { AuthGuard } from "components/AuthGuard";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { headerHeight } from "styles/Layout";
import { Icon } from "components/Icon";
import { UploadingFader } from "components/UploadingFader";
import {
  useGetVacancyByIdQuery,
  useGetVideoUploadUrlsMutation,
  useUpdateCompanyMutation,
  useUpdateVacancyMutation,
} from "store/userAPI";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import bgImage from 'images/recorder-bg-2.jpg'
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import VideoRecorder from "components/VideoRecorder/VideoRecorder";

export const RecordVacancyVideo = React.memo(() => {
  const navigate = useNavigate();
  const [play, setPlay] = useState(false);
  const { vacancyId } = useParams();

  const [updateVacancy] = useUpdateVacancyMutation();

  const [updateCompany] = useUpdateCompanyMutation();
  const [getVideoUploadUrls] = useGetVideoUploadUrlsMutation();
  const [isUploading, setIsUploading] = useState(false)

  const { data: existingVacancy } = useGetVacancyByIdQuery(
    { id: vacancyId },
    { skip: !vacancyId }
  );

  const location = useLocation();

  useEffect(() => {
    !!existingVacancy && existingVacancy?.videoURL && setPlay(true);
  }, [existingVacancy]);

  const onSubmit = async (embedding) => {

    try {
      setIsUploading(true)

      // Get signed URLs for S3, and chuck our files over
      const res = await getVideoUploadUrls({ name: embedding.recordingId })
      const videoUrl = res.data.videoUrl
      const thumbnailUrl = res.data.thumbnailUrl

      // Upload video to S3 directly if this was an upload and not a recording
      if (!embedding.wasRecorded) {
        const videoRes = await fetch(videoUrl, {
          method: 'PUT',
          headers: { 'Content-Type': 'video/mp4' },
          body: embedding.video
        });
        if (!videoRes.ok) {
          console.error(videoRes)
          alert("There was an issue uploading your video, please refresh and try again.")
          throw Error("Failed to upload video")
        }
      }

      // Upload thumbnail if it was selected
      if (embedding.thumbnail) {
        const thumbnailRes = await fetch(thumbnailUrl, {
          method: 'PUT',
          headers: { 'Content-Type': 'image/jpeg' },
          body: embedding.thumbnail
        });
        if (!thumbnailRes.ok) {
          console.error(thumbnailRes)
          alert("There was an issue uploading the thumbnail, please refresh and try again.")
          throw Error("Failed to upload thumbnail")
        }
      }

      if (!!vacancyId) {
        const payload = {
          videoURL: `${embedding.recordingId}.mp4`,
          thumbnail: `${embedding.recordingId}.jpg`
        };
        try {
          let response = await updateVacancy({
            vacancyId: { vacancyId },
            payload,
          });
          if (!response.data) return;
          if (response.error) return alert(response.message);

          navigate(`/employer/company/preview-vacancy/${vacancyId}`);
        } catch (error) {
          console.log(error);
        }
      } else if (location?.pathname === "/employer/company/record-video") {
        const payload = {
          youtube: `${embedding.recordingId}.mp4`,
        };

        try {
          let response = await updateCompany(payload);

          if (!response) return;
          if (response.error) return alert(response.message);
          navigate("/employer/dashboard");
        } catch (error) {
          console.log(error);
        }
      }
    } catch (ex) {
      console.error(ex)
      alert("An error occurred when submitting your video, please refresh and try again.")
    }
    finally {
      setIsUploading(false)
    }
  };

  return (

    <AuthGuard criteria={[
      { value: 'EMPLOYER', check: 'userDetails.userType' },
      (existingVacancy && { value: existingVacancy.companyId, check: 'membership.companyId' }),
    ]}>{isUploading && <UploadingFader>
      <div><Icon spin icon={faSpinner} /><span>Uploading, please wait...</span></div></UploadingFader>}

      <div>
        <VideoRecorder
          allowThumbnailUpload={true}
          allowUpload={true}
          backgroundImage={bgImage}
          height={`calc(100svh - ${headerHeight}px)`}
          janusServer={process.env.REACT_APP_JANUS_URL ?? 'https://videos-graviton.seezyhire.com/janus'}
          onComplete={onSubmit}
          onExit={() => {
            navigate("/employer/dashboard");
          }}
          overlayText={existingVacancy ? ["“Tell us about the role”", "“Talk about the company”", "“Who are you looking for?”"] : ["“Upload your corporate video”", "“Maybe show off the offices”", "“Perhaps hear from the team”"]}
          requiredVideoLength={existingVacancy ? process.env.REACT_APP_MIN_VACANCY_RECORDING_LENGTH : process.env.REACT_APP_MIN_EMPLOYER_RECORDING_LENGTH}
          showGuide={true}
          thumbnailSelection={true}
        />
      </div></AuthGuard>
  );
});