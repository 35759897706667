import { Colors } from "styles/Colors";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";
import React, { useState } from "react";
import styled from "styled-components";

/**
 * A simple modal element with a title, body, and list of actions
 * If no actions are provided, defaults to an "Ok" button that hides the modal
 * Does not use the <dialog> DOM element due to React Styled Components not working correctly with ::backdrop and I cannot be bothered to figure out why I don't even want to use Styled components.
 */
export const Modal = ({ isVisible = true, title, actions, children }) => {

    const size = useResponsiveLayout();
    const [overrideIsVisible, setOverrideIsVisible] = useState(isVisible)

    // If no actions are provided, set to an "Ok" button that closes the modal
    const [overrideActions] = useState(
        !actions || actions.length === 0
            ? [{ onClick: () => setOverrideIsVisible(false), text: "Ok" }]
            : actions
    )

    return <Wrapper
        isVisible={
            // If we have no provided actions, use the override visible value. Else, use the `isVisible` option as visibility is controlled by the parent.
            !actions || actions.length === 0 ? overrideIsVisible : isVisible
        }
        size={size}>
        <div className="content thin-scrollbar light">
            {title &&
                <Header>
                    <h1 className="text-4xl">{title}</h1>
                </Header>
            }
            {children}
            <div className="actions">
                {overrideActions.map((action, i) =>
                    <button
                        key={i}
                        className="bg-secondary border-2 border-solid border-secondary text-white py-4 px-6 rounded-lg"
                        onClick={() => action.onClick()}>
                        {action.text}
                    </button>
                )}
            </div>
        </div>
    </Wrapper>
}

const Wrapper = styled.div(({ isVisible = false, size }) => `
  display: ${isVisible ? 'grid' : 'none'};
  position: fixed;
  inset: 0;
  place-items: center;
  background: rgba(20, 20, 20, 0.4);
  backdrop-filter: blur(2px);
  z-index: 9999;
  .content {
      display: grid;
      gap: 20px;
      max-width: 600px;
      border-radius: 10px;
      background: #F0F0F0;
      padding: 20px;
      margin: 20px;
      animation-name: fade-scale-up;
      animation-timing-function: ease;
      animation-duration: 500ms;
      animation-direction: forwards;
      max-height: 80vh;
      overflow-y: auto;
      
      // Force child text colour
      *:not(:hover) {
        color: black;
      }
      a {
        color: ${Colors.velvet}!important;
      }
    }
    .actions {
        display: flex;
        flex-flow: ${size.isSmUp ? 'row' : 'column'};
        gap: 10px;
        justify-content: ${size.isSmUp ? 'flex-end' : "flex-start"};
    }
    button {
        width: ${size.isSmUp ? 'fit-content' : "100%"};
    }
}
`)

const Header = styled.header`
    display: grid;
    gap: 16px;
    ::after {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
`