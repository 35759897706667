import { analytics } from 'utils/analytics'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { user } from 'store/signals/state/user'


const apiUrl = process.env.REACT_APP_API_URL

let trackingUser;

export const userAPI = createApi({
  reducerPath: 'userAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrl}/`,
    prepareHeaders: (headers) => {
      const user = localStorage.getItem('currentUser');
      let token = user
        ? JSON.parse(user).jwt
        : ''

      if (token) {
        if (!trackingUser) {
          analytics.identify(user.id, user)
          trackingUser = user;
        }
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Vacanies', 'User', 'MyCompany'],
  endpoints: (builder) => ({
    updateCandidate: builder.mutation({
      invalidatesTags: ['User'],
      query: (payload) => {
        return {
          url: `user/candidate`,
          method: 'PATCH',
          body: payload,
        }
      },
    }),
    updateCompany: builder.mutation({
      invalidatesTags: ['User', 'MyCompany'],
      query: (payload) => {
        return {
          url: `company/update`,
          method: 'PATCH',
          body: payload,
        }
      },
    }),
    getUser: builder.query({
      query: () => ({
        url: `user/me`,
        method: 'GET',
      }),
      transformResponse(response) {
        user.value = response.user
        return response
      },
      providesTags: ['User'],
    }),
    createVacancy: builder.mutation({
      query: (credentials) => ({
        url: `company/vacancy`,
        method: 'POST',
        body: credentials,
      }),
      providesTags: ['Vacanies'],
    }),
    updateVacancy: builder.mutation({
      query: ({ vacancyId, payload }) => {
        return {
          url: `company/vacancy`,
          method: 'PATCH',
          body: payload,
          params: vacancyId,
        }
      },
      invalidatesTags: ['Vacanies'],
    }),
    getVacancyById: builder.query({
      query: (vacancyId) => ({
        url: `company/vacancy/byId`,
        params: vacancyId,
      }),
      providesTags: ['Vacanies'],
    }),
    getCompany: builder.query({
      query: () => ({url: `company/my`, method: 'GET'}),
      providesTags: ['MyCompany'],
    }),
    getCompanyById: builder.query({
      query: (companyId) => ({
        url: `company/byId`,
        params: companyId,
      }),
    }),
    getCompanyVacancies: builder.query({
      query: () => ({
        url: `company/vacancy/byMyCompany`,
      }),
      providesTags: ['Vacanies'],
    }),
    getCompanyInvites: builder.query({
      query: () => ({
        url: `company/invites`,
      }),
    }),
    deleteCompany: builder.mutation({
      query: (companyId) => {
        return {
          url: `company/byId`,
          method: 'DELETE',
          params: companyId,
        }
      },
    }),
    deleteCompanyMembers: builder.mutation({
      query: (membershipId) => ({
        url: `company/member`,
        method: 'DELETE',
        params: membershipId,
      }),
    }),
    deleteUser: builder.mutation({
      query: () => ({
        url: `user/me`,
        method: 'DELETE',
      }),
    }),
    getJobs: builder.query({
      query: () => ({
        url: `company/vacancy/explore`,
        method: 'POST',
      }),
    }),
    getJobsSearch: builder.query({
      query: (body) => ({
        url: `company/vacancy/search`,
        method: 'POST',
        body: body,
      }),
    }),
    applyForJob: builder.mutation({
      query: (application) => {
        return {
          url: `application`,
          method: 'POST',
          body: application,
        }
      },
    }),
    updateApplication: builder.mutation({
      query: ({ application, answer, index }) => {
        console.log('UpdateJob Mutation triggered', application, answer, index)
        return {
          url: `application/update`,
          method: 'POST',
          body: {
            application: {
              vacancyId: application.vacancyId,
              id: application.id,
              status: application.status || 'INCOMPLETED',
              rightToWork: application.rightToWork || null,
            },
            answer: answer,
            answerIndex: index
          },
        }
      },
    }),
    getMyJobApplications: builder.query({
      query: () => ({
        url: `application/byMe`,
      }),
      providesTags: ['Vacanies'],
    }),
    getApplicationByID: builder.query({
      query: (params) => ({
        url: `application/byId`,
        params: params,
      }),
      providesTags: ['Vacanies'],
    }),
    getJobApplicationsByVacancyId: builder.query({
      query: (params) => ({
        url: `application/byVacancyId`,
        params: params,
      }),
      providesTags: ['Vacanies'],
    }),
    withdrawApplication: builder.query({
      query: (params) => ({
        url: `application/withdraw`,
        params: params,
      }),
      invalidatesTags: ['Vacanies'],
    }),
    rejectApplication: builder.query({
      query: (params) => ({
        url: `application/reject`,
        params: params,
      }),
      invalidatesTags: ['Vacanies'],
    }),
    acceptApplication: builder.query({
      query: (params) => ({
        url: `application/accept`,
        params: params,
      }),
      invalidatesTags: ['Vacanies'],
    }),
    reviewApplication: builder.mutation({
      query: (body) => ({
        url: `application/review`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Vacanies'],
    }),
    getCandidateById: builder.query({
      query: (params) => ({
        url: `user/byId`,
        params: params,
      }),
    }),
    resetPasswordRequest: builder.mutation({
      query: (params) => ({
        url: `user/password/reset_request`,
        params: params,
        method: 'POST',
      }),
    }),
    resetPassword: builder.mutation({
      query: (params) => ({
        url: `user/password/reset`,
        params: params,
        method: 'POST',
      }),
    }),
    emailVerification: builder.query({
      query: (params) => ({
        url: `user/verifyEmailViaToken`,
        params: params,
      }),
    }),
    resendEmailVerification: builder.query({
      invalidatesTags: ['User'],
      query: () => ({
        url: `user/resendEmailVerification`,
        method: 'POST'
      })
    }),
    getVideoUploadUrls: builder.mutation({
      query: (params) => ({
        url: `media/getPresignedUrlsS3`,
        method: 'GET',
        params,
      }),
    }),
    getAIJobDescription: builder.mutation({
      query: (body) => ({
        url: `ai/jobDescription`,
        method: 'POST',
        body,
      }),
    }),
    deleteInvite: builder.mutation({
      query: (params) => ({
        url: `company/invite`,
        method: 'DELETE',
        params: params,
      }),
    }),
    setApplicationViewed: builder.mutation({
      query: (body) => ({
        url: `application/viewed`,
        body,
        method: 'POST',
        invalidatesTags: ["Vacancies"]
      }),
    }),
    logApplicationView: builder.mutation({
      query: (body) => ({
        url: `application-view`,
        body,
        method: 'POST',
        invalidatesTags: ["applicationViews"]
      }),
    }),
    getApplicationViewsByApplicationId: builder.query({
      query: (params) => ({
        url: `application-view/byApplicationId`,
        method: 'GET',
        params: params,
        providesTags: ["applicationViews"]
      })
    }),
    getAuditLogsByTypeAndId: builder.query({
      query: (params) => ({
        url: `audit-log/byTypeAndId`,
        method: 'GET',
        params: params,
        providesTags: ["auditLogs"]
      })
    }),
    getApplicationViewsByVacancyId: builder.query({
      query: (params) => ({
        url: `application-view/byVacancyId`,
        method: 'GET',
        params: params,
        providesTags: ["applicationViews"]
      })
    }),
    getApplicationCommentsByApplicationId: builder.query({
      query: (params) => ({
        url: `application-comment/byApplicationId`,
        method: 'GET',
        params: params,
        providesTags: ["applicationComments"]
      })
    }),
    createApplicationComment: builder.mutation({
      query: (body) => ({
        url: `application-comment`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['applicationComments'],
    }),
    getApplicationLikesByVacancyId: builder.query({
      query: (params) => ({
        url: `application-like/byVacancyId`,
        method: 'GET',
        params: params,
        providesTags: ["applicationLikes"]
      })
    }),
    getApplicationLikesByApplicationId: builder.query({
      query: (params) => ({
        url: `application-like/byApplicationId`,
        method: 'GET',
        params: params,
        providesTags: ["applicationLikes"]
      })
    }),
    addApplicationLike: builder.mutation({
      query: (body) => ({
        url: `application-like`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['applicationLikes'],
    }),
    removeApplicationLike: builder.mutation({
      query: (params) => ({
        url: `application-like`,
        method: 'DELETE',
        params: params,
      }),
      invalidatesTags: ['applicationLikes'],
    }),
    notifyCannotCreateVacancies: builder.mutation({
      query: (body) => {
        return {
          url: `company/notifyCannotCreateVacancies`,
          method: 'POST',
          body,
        }
      },
    }),
  }),
})

export const {
  useUpdateCompanyMutation,
  useUpdateCandidateMutation,
  useGetUserQuery,
  useCreateVacancyMutation,
  useUpdateVacancyMutation,
  useGetVacancyByIdQuery,
  useLazyGetVacancyByIdQuery,
  useGetCompanyVacanciesQuery,
  useGetCompanyQuery,
  useGetCompanyByIdQuery,
  useDeleteCompanyMutation,
  useDeleteCompanyMembersMutation,
  useGetCompanyInvitesQuery,
  useDeleteUserMutation,
  useGetJobsQuery,
  useApplyForJobMutation,
  useUpdateApplicationMutation,
  useGetMyJobApplicationsQuery,
  useGetJobApplicationsByVacancyIdQuery,
  useLazyWithdrawApplicationQuery,
  useLazyRejectApplicationQuery,
  useLazyAcceptApplicationQuery,
  useReviewApplicationMutation,
  useLazyGetCandidateByIdQuery,
  useGetApplicationByIDQuery,
  useLazyGetJobsSearchQuery,
  useResetPasswordRequestMutation,
  useResetPasswordMutation,
  useLazyEmailVerificationQuery,
  useGetVideoUploadUrlsMutation,
  useGetAIJobDescriptionMutation,
  useDeleteInviteMutation,
  useSetApplicationViewedMutation,
  useLogApplicationViewMutation,
  useLazyGetApplicationViewsByApplicationIdQuery,
  useLazyGetAuditLogsByTypeAndIdQuery,
  useLazyGetApplicationViewsByVacancyIdQuery,
  useGetApplicationCommentsByApplicationIdQuery,
  useCreateApplicationCommentMutation,
  useLazyGetApplicationLikesByApplicationIdQuery,
  useLazyGetApplicationLikesByVacancyIdQuery,
  useAddApplicationLikeMutation,
  useRemoveApplicationLikeMutation,
  useNotifyCannotCreateVacanciesMutation,
  useResendEmailVerificationQuery
} = userAPI
