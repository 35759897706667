import { Button } from "components/Button";
import { canApply } from "store/signals/state/user";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { JobContent } from "./LocalComponents/JobContent";
import { JobInfo } from "./LocalComponents/JobInfo";
import { LoadingComponent } from "components/LoadingComponent";
import { NewSectionHeading } from "components/Headings/NewSectionHeading";
import {
  SampleNextArrow,
  SamplePrevArrow,
} from "components/VideoSlider/SlickSlider";
import { setSelectedVacancy } from "store/reducers/vacancyReducer";
import { useAuthState } from "Context";
import { useDispatch } from "react-redux";
import {
  useGetCompanyByIdQuery,
  useGetUserQuery,
  useGetVacancyByIdQuery,
} from "store/userAPI";
import { useNavigate, useParams } from "react-router-dom";
import { useResponsiveLayout } from "hooks/useResponsiveLayout";

import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import VideoCardJobBoard from "components/Cards/VideoCardJobBoard";

export const JobPost = React.memo(() => {
  const size = useResponsiveLayout();

  const navigate = useNavigate();
  const { jobId } = useParams();

  const { data: { user: { candidate = {} } = {} } = {} } = useGetUserQuery({});

  const dispatch = useDispatch();

  const { data: selectedVacancy, isLoading: loadingVacancy } =
    useGetVacancyByIdQuery({
      id: jobId,
    });

  const { data: companyDetails, isLoading: loadingCompany } =
    useGetCompanyByIdQuery({
      id: selectedVacancy?.companyId,
    });

  const { userDetails } = useAuthState() || {};

  const isCandidate = userDetails?.userType === "CANDIDATE";

  const applyForJob = () => {
    dispatch(setSelectedVacancy(selectedVacancy));
    if (!canApply.value) {
      return navigate("/candidate/manage-profile");
    }
    if (!!selectedVacancy?.questions.length) {
      navigate("/candidate/application");
    } else {
      navigate("/job-board/candidate/application-preview");
    }
  };

  const actions = () => (
    <>
      <Button
        button
        size="large"
        type="alt"
        onClick={() => navigate("/job-board")}
      >
        Back to job board
      </Button>
      <Button button size="large" onClick={applyForJob}>
        Apply
      </Button>
    </>
  );

  const useractions = () => (
    <Button button leftIcon={faVideo} size="large" onClick={applyForJob}>
      Quick Apply
    </Button>
  );

  if (loadingCompany || (!selectedVacancy?.id && loadingVacancy))
    return <LoadingComponent />;

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 200,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return !selectedVacancy || !selectedVacancy.videoURL ? (
    <div className="flex flex-col text-center justify-center text-[2.5rem] pt-32">
      <h1 className="text-gray-300">This vacancy does not exist</h1>
    </div>
  ) : (
    <>
      <Container size={size}>
        <NewSectionHeading
          title={selectedVacancy?.title}
          caption={`£ ${selectedVacancy?.salary.start} - £ ${selectedVacancy?.salary.end} Per Annum`}
          actions={isCandidate ? useractions() : actions()}
          style={{ gridArea: "header", marginBottom: 0 }}
        />
        <hr style={{ gridArea: "hr" }} />
        <VideoWrapper size={size}>
          {companyDetails?.video ? (
            <Slider {...sliderSettings} className={"no-margin"}>
              <VideoCardJobBoard
                borderRadius={size.isLgUp ? "16px" : "0px"}
                action
                aspectRatio={"16/9"}
                key={`video-${selectedVacancy.videoURL}`}
                type="play"
                width={"100%"}
                height={"auto"}
                thumbnail={selectedVacancy.thumbnail}
                videoData={selectedVacancy.videoURL}
              />
              <VideoCardJobBoard
                borderRadius={size.isLgUp ? "16px" : "0px"}
                action
                aspectRatio={"16/9"}
                key="video-youtube"
                type="play"
                width={"100%"}
                height={"auto"}
                thumbnail={selectedVacancy.thumbnail}
                videoData={companyDetails?.video}
                preload={"metadata"}
              />
            </Slider>
          ) : (
            <VideoCardJobBoard
              borderRadius={size.isLgUp ? "16px" : "0px"}
              key={`video-${selectedVacancy.videoURL}`}
              aspectRatio={"16/9"}
              action
              width={"100%"}
              videoData={selectedVacancy.videoURL}
              height={"auto"}
              thumbnail={selectedVacancy.thumbnail}
            />
          )}
        </VideoWrapper>
        <JobInfo data={selectedVacancy} companyDetails={companyDetails} />
        <JobContent data={selectedVacancy} companyDetails={companyDetails} />
      </Container>
    </>
  );
});

const Container = styled.div(
  ({ size }) => `
max-width: min(var(--max-width), 100vw);
margin: 0 auto;
padding-left: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
padding-right: ${size.isXl ? "122px" : size.isMdUp ? "60px" : "20px"};
padding-bottom: 40px;
display: grid;
gap: ${size.isLgUp ? "24px 64px" : "32px"};
grid-template-columns: ${size.isLgUp ? "minmax(320px, 1fr) 3fr" : "1fr"};
grid-template-areas: ${
    size.isLgUp
      ? `'header header'
        'hr hr'
        'info video'
        'info content'`
      : size.isMdUp
      ? `
        'video'
        'header'
        'hr'
        'info'
        'content'`
      : `
        'video'
        'header'
        'info'
        'content'`
  };
& hr {
    display : ${size.isMdUp ? "block" : "none"};
    border: 2px solid #494A4A;
    margin: 15px 0;
}
`
);

const VideoWrapper = styled.div(
  ({ size }) => `
  background: black;
  border-radius: ${size.isLgUp ? "16px" : "0"};
  grid-area: video;
  // Width fix
  // Would prefer to use Grid, but SlickSlider causes the width to exponentially grow when nested in a Grid, which IMO is garbage.
  // https://github.com/kenwheeler/slick/issues/3991
  display: flex;
  flex-flow: column;
  min-width: 0;
  
  // Break out of parent container padding on small viewports
  width: ${
    size.isLgUp
      ? "100%"
      : size.isMdUp
      ? "calc(100% + 120px)"
      : "calc(100% + 40px)"
  }; 
  position: relative;
  left: ${size.isLgUp ? "0px" : size.isMdUp ? "-60px" : "-20px"}; 
  ${
    !size.isLgUp
      ? `
    .slick-next { 
      right: 10px;
    }
    .slick-prev { 
      left: 10px;
    }
    `
      : null
  }
  `
);
